import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { UpdateRuleGroupSettingNightShiftsMutation as Mutation } from "./types";

const mutation = graphql`
  mutation UpdateRuleGroupSettingNightShiftsMutation(
    $input: UpdateRuleGroupSettingInput!
  ) {
    updateRuleGroupSetting(input: $input) {
      ok
      ruleGroupSetting {
        ...RuleGroupSettingNightShifts_fragment
      }
    }
  }
`;

export const useUpdateRuleGroupSettingNightShifts = () =>
  useMutation<Mutation>(mutation);
