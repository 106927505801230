import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";
import { WeightField } from "settings/common/TeamGroupOnlyForms";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { SmallSwitch } from "components/forms/Switch";
import { SettingBox } from "components/setting/common";

import { splitShiftsSetting } from "../SplitShiftsSetting";

type FormProps = { readOnly?: boolean };

export function SplitShiftsForm({ readOnly }: FormProps) {
  const disabled = !useIsConstraintModuleEnabled(splitShiftsSetting.moduleName);
  const allowSplitShifts = useFormikState<boolean>("allowSplitShifts")?.value;

  return (
    <SettingBox>
      <Stack gap={3}>
        <Stack gap={1}>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant="h4">
              Tillåt att delade turer förekommer
            </Typography>
            <SmallSwitch
              name="allowSplitShifts"
              disabled={disabled}
              readOnly={readOnly}
            />
          </Stack>
          <Typography>
            Schemats olika passtyper anges under fliken som heter{" "}
            <Link to="/shifts">
              <b>Passprofil</b>
            </Link>
            . Härifrån, med denna knapp, har du möjlighet att köra över de
            inställningarna genom att stänga av möjligheten för schemat att
            innehålla delade turer.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant="h4">
              Fördela delade turer lika mellan medarbetare
            </Typography>
            <SmallSwitch
              name="optimizeSplitShiftsDistributionBetweenEmployees"
              disabled={disabled || !allowSplitShifts}
              readOnly={readOnly}
            />
          </Stack>
          <Typography>
            Om denna inställning är påslagen så kommer algoritmen eftersträva en
            jämn fördelning av delade turer mellan medarbetare.
          </Typography>
        </Stack>
        <WeightField
          weightFieldName="splitShiftsWeight"
          moduleName={splitShiftsSetting.moduleName}
        />
      </Stack>
    </SettingBox>
  );
}
