import { Suspense } from "react";

import { Visible } from "components/common/Visible";
import { SettingLevel3Placeholder } from "components/loading/pages";
import type { Shift as ParttimeShift } from "components/setting/common/forms/Advanced/FullAndPartTimeMeasureField/useGraphData";
import { Level3Box } from "components/setting/common/Level3Box";

import { RuleGroupSettingBoxAB1 } from "./RuleGroupSettingBoxAB1";
import { RuleGroupSettingBoxAB2 } from "./RuleGroupSettingBoxAB2";
import { RuleGroupSettingBoxBase2 } from "./RuleGroupSettingBoxBase2";
import { RuleGroupSettingBoxBase7 } from "./RuleGroupSettingBoxBase7";
import { RuleGroupSettingBoxFree1 } from "./RuleGroupSettingBoxFree1";
import { RuleGroupSettingBoxRhythm2 } from "./RuleGroupSettingBoxRhythm2";
import { RuleGroupSettingBoxRhythm3 } from "./RuleGroupSettingBoxRhythm3";
import { RuleGroupSettingBoxTypes3 } from "./RuleGroupSettingBoxTypes3";
import { RuleGroupSettingGroupAdvanced } from "./RuleGroupSettingGroupAdvanced";

type Props = {
  ruleId?: string;
  fragmentRef: any;
  shiftTypes: any[];
  periodLengthWeeks: number;
  shifts: ParttimeShift[];
};

export function SettingBoxes({
  ruleId = "",
  fragmentRef,
  shiftTypes,
  periodLengthWeeks,
  shifts,
}: Props) {
  return (
    <Suspense fallback={<SettingLevel3Placeholder />}>
      <Level3Box>
        <Visible visible={ruleId === "d-1"}>
          <RuleGroupSettingBoxBase2
            fragmentRef={fragmentRef}
            periodLengthWeeks={periodLengthWeeks}
          />
        </Visible>
        <Visible visible={ruleId === "b-6"}>
          <RuleGroupSettingBoxBase7 fragmentRef={fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "ab-0"}>
          <RuleGroupSettingBoxAB1 fragmentRef={fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "ab-1"}>
          <RuleGroupSettingBoxAB2 fragmentRef={fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "r-1"}>
          <RuleGroupSettingBoxRhythm2 fragmentRef={fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "r-2"}>
          <RuleGroupSettingBoxRhythm3 fragmentRef={fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "w-0"}>
          <RuleGroupSettingBoxFree1
            fragmentRef={fragmentRef}
            periodLengthWeeks={periodLengthWeeks}
          />
        </Visible>
        <Visible visible={ruleId === "s-2"}>
          <RuleGroupSettingBoxTypes3
            fragmentRef={fragmentRef}
            shiftTypes={shiftTypes}
          />
        </Visible>
        <RuleGroupSettingGroupAdvanced
          fragmentRef={fragmentRef}
          ruleId={ruleId}
          periodLengthWeeks={periodLengthWeeks}
          shifts={shifts}
        />
      </Level3Box>
    </Suspense>
  );
}
