import type { ChangeEvent } from "react";
import type { TextFieldProps } from "@mui/material";
import { TextField } from "@mui/material";
import { DateTime } from "luxon";

type Props = Omit<TextFieldProps, "value" | "onChange" | "type"> & {
  value: Date;
  onChange: (date: Date) => void;
};

type ChangeType = ChangeEvent<HTMLInputElement>;

export function CurrentDate({
  value,
  onChange,
  size = "small",
  ...props
}: Props) {
  const valueStr = DateTime.fromJSDate(value).toFormat("yyyy-MM-dd");
  return (
    <TextField
      {...props}
      size={size}
      type="date"
      value={valueStr}
      onChange={(e: ChangeType) => onChange(new Date(e.target.value))}
      sx={{ background: "background", borderRadius: 1 }}
    />
  );
}
