/**
 * @generated SignedSource<<a595cef257d2e8b2a9a4571b4cf1be7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RuleGroupSettingBoxRhythm3_fragment$data = {
  readonly id: string;
  readonly maxConsecutiveWorkDays: number;
  readonly maxConsecutiveWorkDaysSoft: boolean;
  readonly minConsecutiveWorkDays: number;
  readonly minConsecutiveWorkDaysSoft: boolean;
  readonly ruleGroup: {
    readonly name: string;
  };
  readonly settingModules: ReadonlyArray<string>;
  readonly " $fragmentType": "RuleGroupSettingBoxRhythm3_fragment";
};
export type RuleGroupSettingBoxRhythm3_fragment$key = {
  readonly " $data"?: RuleGroupSettingBoxRhythm3_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RuleGroupSettingBoxRhythm3_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RuleGroupSettingBoxRhythm3_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveWorkDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveWorkDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveWorkDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveWorkDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RuleGroupNode",
      "kind": "LinkedField",
      "name": "ruleGroup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RuleGroupSettingNode",
  "abstractKey": null
};

(node as any).hash = "d6ab5a7a6a72689fb629f3d43d093af0";

export default node;
