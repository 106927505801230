import type { StackProps } from "@mui/material";
import useFormikState from "hooks/useFormikState";
import { LabeledColorDot } from "settings/common/LabeledColorDot";
import type { NN } from "types";

import type { TeamDemandSetting as TeamDemandSettingType } from "components/setting/types";

type Team = NN<TeamDemandSettingType>["team"];

type Props = Omit<StackProps, "color"> & {
  name: string;
};

export function TeamLabel({ name, ...stackProps }: Props) {
  const { value } = useFormikState<Team>(name);
  return (
    <LabeledColorDot label={value.name} color={value.color} {...stackProps} />
  );
}
