import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import type { IEnvironment } from "relay-runtime";

import type {
  UpdateUserSettingBoxRhythm2Mutation as Mutation,
  UpdateUserSettingBoxRhythm2Mutation$variables as Variables,
} from "./types";

const mutation = graphql`
  mutation UpdateUserSettingBoxRhythm2Mutation(
    $input: UpdateUserSettingInput!
  ) {
    updateUserSetting(input: $input) {
      ok
      userSetting {
        ...UserSettingBoxRhythm2_fragment
      }
    }
  }
`;

export async function UpdateUserSettingBoxRhythm2(
  environment: IEnvironment,
  variables: Variables,
) {
  return commitMutation<Mutation>(environment, {
    mutation,
    variables,
  });
}
