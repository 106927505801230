import type { ComponentProps } from "react";
import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";

import { AutoSubmit } from "components/setting/common";
import { shiftsEmploymentDegreeLogicChoices } from "components/setting/team_group/setting_boxes/constants";

import { ParttimesForm as Form } from "../forms";
import { useUpdateRuleGroupSettingParttimes } from "../mutations";
import type { UpdateRuleGroupSettingInput as FormValues } from "../types";

import type { RuleGroupSettingParttimes_fragment$key as Key } from "./types";

type Props = {
  fragmentRef: Key;
  periodLengthWeeks: number;
  shifts: ComponentProps<typeof Form>["shifts"];
};

const fragment = graphql`
  fragment RuleGroupSettingParttimes_fragment on RuleGroupSettingNode {
    id
    settingModules
    hoursEmploymentDegreeFactor
    shiftsEmploymentDegreeFactor
    shiftsEmploymentDegreeLogic
    shiftsPerWeek
    hoursPerWeek
    ruleGroup {
      name
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  constraintModules: yup.array().of(yup.string()).required(),
  hoursEmploymentDegreeFactor: yup
    .number()
    .min(0, "Måste vara minst 0%")
    .max(100, "Måste vara högst 100%")
    .required("Får ej vara tomt"),
  shiftsEmploymentDegreeFactor: yup
    .number()
    .min(0, "Måste vara minst 0%")
    .max(100, "Måste vara högst 100%")
    .required("Får ej vara tomt"),
  shiftsEmploymentDegreeLogic: yup
    .string()
    .oneOf(shiftsEmploymentDegreeLogicChoices)
    .required(),
});

export function RuleGroupSettingParttimes({
  fragmentRef,
  periodLengthWeeks,
  shifts,
}: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useUpdateRuleGroupSettingParttimes();
  const { name = "" } = data?.ruleGroup ?? {};

  const initialValues = useMemo<FormValues>(
    () => ({
      id: data.id,
      constraintModules: data.settingModules.slice(),
      hoursEmploymentDegreeFactor: data.hoursEmploymentDegreeFactor,
      shiftsEmploymentDegreeFactor: data.shiftsEmploymentDegreeFactor,
      shiftsEmploymentDegreeLogic: data.shiftsEmploymentDegreeLogic,
    }),
    [data],
  );

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Form
            name={name}
            periodLengthWeeks={periodLengthWeeks}
            shiftsPerWeek={data.shiftsPerWeek}
            hoursPerWeek={data.hoursPerWeek}
            shifts={shifts}
          />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}
