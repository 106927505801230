import type { ApiShiftPartPartTypeChoices } from "../types";

// Form default values
const DEFAULT_NAME = "A1";
const DEFAULT_START = "08:00";
const DEFAULT_END = "17:00";
export const DEFAULT_SPLITSTART = "12:00";
export const DEFAULT_SPLITEND = "14:00";
const DEFAULT_BREAK_TIME = 30;
const DEFAULT_SHIFT_PART_TYPE: ApiShiftPartPartTypeChoices = "P";

export const initialShiftPart = {
  partType: DEFAULT_SHIFT_PART_TYPE,
  start: DEFAULT_START,
  end: DEFAULT_END,
};
export const initialShift = {
  name: DEFAULT_NAME,
  start: DEFAULT_START,
  end: DEFAULT_END,
  breakTime: DEFAULT_BREAK_TIME,
  shiftParts: [],
};
