/**
 * @generated SignedSource<<875e28680e0e1fed36d251089ac987ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiShiftPartPartTypeChoices = "B" | "J" | "M" | "P" | "Z" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShiftForm_fragment$data = ReadonlyArray<{
  readonly days: ReadonlyArray<number>;
  readonly shifts: ReadonlyArray<{
    readonly breakTime: number;
    readonly end: string;
    readonly id: string;
    readonly name: string;
    readonly shiftParts: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly end: string;
          readonly id: string;
          readonly partType: ApiShiftPartPartTypeChoices;
          readonly start: string;
        } | null;
      } | null>;
    };
    readonly start: string;
  }>;
  readonly " $fragmentType": "ShiftForm_fragment";
}>;
export type ShiftForm_fragment$key = ReadonlyArray<{
  readonly " $data"?: ShiftForm_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftForm_fragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ShiftForm_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "days",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftNode",
      "kind": "LinkedField",
      "name": "shifts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "breakTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShiftPartNodeConnection",
          "kind": "LinkedField",
          "name": "shiftParts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShiftPartNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShiftPartNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "partType",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ShiftGroup",
  "abstractKey": null
};
})();

(node as any).hash = "12737c29e2f3b3a8dd06f54506e0b3e4";

export default node;
