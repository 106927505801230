import graphql from "babel-plugin-relay/macro";
import environment from "environment";
import { commitMutation } from "libs/commitMutation";

import { DeletePeriodMutation } from "./__generated__/DeletePeriodMutation.graphql";

const mutation = graphql`
  mutation DeletePeriodMutation($id: ID!) {
    deletePeriod(id: $id) {
      ok
    }
  }
`;

export default async function DeletePeriod(id: string) {
  return commitMutation<DeletePeriodMutation>(environment, {
    mutation,
    variables: { id },
  }).then((response) => {
    if (!response.deletePeriod?.ok) {
      return Promise.reject("Could not delete period.");
    }
  });
}
