/**
 * @generated SignedSource<<c211907c76fa601ea9f9d1c6f5549671>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApiSettingShiftsEmploymentDegreeLogicChoices = "E" | "O" | "%future added value";
export type ApiUserSettingNightShiftRestrictionChoices = "L0" | "L1" | "L2" | "L3" | "N" | "%future added value";
export type ApiUserSettingNightShiftRotationChoices = "N" | "R1" | "R2" | "R3" | "%future added value";
export type UpdateRuleGroupSettingInput = {
  clientMutationId?: string | null;
  constraintModules?: ReadonlyArray<string> | null;
  currentTeamGroupId?: string | null;
  dayBreakAlternatives?: ReadonlyArray<number> | null;
  freeEveryXOfYWeekends?: number | null;
  freeWeekendConstraintTypeEveryX?: boolean | null;
  freeWeekendConstraintTypeXOfY?: boolean | null;
  freeWeekendSoft?: boolean | null;
  hourlyCost?: number | null;
  hoursEmploymentDegreeFactor?: number | null;
  hoursPerWeek?: number | null;
  id: string;
  maxConsecutiveFreeDays?: number | null;
  maxConsecutiveFreeDaysSoft?: boolean | null;
  maxConsecutiveWorkDays?: number | null;
  maxConsecutiveWorkDaysSoft?: boolean | null;
  minConsecutiveFreeDays?: number | null;
  minConsecutiveFreeDaysSoft?: boolean | null;
  minConsecutiveWorkDays?: number | null;
  minConsecutiveWorkDaysSoft?: boolean | null;
  nightShiftRestriction?: ApiUserSettingNightShiftRestrictionChoices | null;
  nightShiftRotation?: ApiUserSettingNightShiftRotationChoices | null;
  prohibitedShiftTypes?: ReadonlyArray<string | null> | null;
  shiftsEmploymentDegreeFactor?: number | null;
  shiftsEmploymentDegreeLogic?: ApiSettingShiftsEmploymentDegreeLogicChoices | null;
  shiftsPerWeek?: number | null;
  weekBreakDaysAlternatives?: ReadonlyArray<number> | null;
  weekBreakHourAlternatives?: ReadonlyArray<number> | null;
  workEveryUnfreeWeekend?: boolean | null;
  workEveryUnfreeWeekendSoft?: boolean | null;
  workEveryXWeekend?: number | null;
};
export type UpdateRuleGroupSettingBoxTypes3Mutation$variables = {
  input: UpdateRuleGroupSettingInput;
};
export type UpdateRuleGroupSettingBoxTypes3Mutation$data = {
  readonly updateRuleGroupSetting: {
    readonly ok: boolean | null;
    readonly ruleGroupSetting: {
      readonly " $fragmentSpreads": FragmentRefs<"RuleGroupSettingBoxTypes3_fragment">;
    } | null;
  } | null;
};
export type UpdateRuleGroupSettingBoxTypes3Mutation = {
  response: UpdateRuleGroupSettingBoxTypes3Mutation$data;
  variables: UpdateRuleGroupSettingBoxTypes3Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateRuleGroupSettingBoxTypes3Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRuleGroupSettingPayload",
        "kind": "LinkedField",
        "name": "updateRuleGroupSetting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleGroupSettingNode",
            "kind": "LinkedField",
            "name": "ruleGroupSetting",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RuleGroupSettingBoxTypes3_fragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateRuleGroupSettingBoxTypes3Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRuleGroupSettingPayload",
        "kind": "LinkedField",
        "name": "updateRuleGroupSetting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleGroupSettingNode",
            "kind": "LinkedField",
            "name": "ruleGroupSetting",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ShiftNodeConnection",
                "kind": "LinkedField",
                "name": "prohibitedShiftTypes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShiftNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShiftNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ShiftPartNodeConnection",
                            "kind": "LinkedField",
                            "name": "shiftParts",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ShiftPartNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ShiftPartNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "settingModules",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RuleGroupNode",
                "kind": "LinkedField",
                "name": "ruleGroup",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "659d73f9e79b70e74f6330f75016e823",
    "id": null,
    "metadata": {},
    "name": "UpdateRuleGroupSettingBoxTypes3Mutation",
    "operationKind": "mutation",
    "text": "mutation UpdateRuleGroupSettingBoxTypes3Mutation(\n  $input: UpdateRuleGroupSettingInput!\n) {\n  updateRuleGroupSetting(input: $input) {\n    ok\n    ruleGroupSetting {\n      ...RuleGroupSettingBoxTypes3_fragment\n      id\n    }\n  }\n}\n\nfragment RuleGroupSettingBoxTypes3_fragment on RuleGroupSettingNode {\n  id\n  prohibitedShiftTypes {\n    edges {\n      node {\n        id\n        name\n        shiftParts {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n  settingModules\n  ruleGroup {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "472f660f3e8b721df63339b0bd8d66de";

export default node;
