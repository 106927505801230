import type { ComponentProps, PropsWithChildren } from "react";
import { Paper } from "@mui/material";

type PaperProps = PropsWithChildren<ComponentProps<typeof Paper>>;

type Props = PaperProps & {
  width: number;
};

export default function Panel({ width, children, sx = {}, ...props }: Props) {
  const paperSx = {
    maxWidth: width,
    width: "100%",
    borderRightWidth: 1,
    ...sx,
  };
  return (
    <Paper {...props} variant="panel" sx={paperSx}>
      {children}
    </Paper>
  );
}

export function Panel1({ children, ...props }: PaperProps) {
  return (
    <Panel {...props} width={230}>
      {children}
    </Panel>
  );
}

export function Panel2({ children, ...props }: PaperProps) {
  return (
    <Panel {...props} width={240}>
      {children}
    </Panel>
  );
}
