import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { splitShiftsWeightChoices } from "./constants";
import { Types1Form } from "./forms";
import { UpdateSettingBoxTypes1 } from "./mutations";
import type {
  SettingBoxTypes1_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment SettingBoxTypes1_fragment on SettingNode {
    id
    allowSplitShifts
    optimizeSplitShiftsDistributionBetweenEmployees
    splitShiftsWeight
    constraintModules
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string(),
  allowSplitShifts: yup.boolean().required(),
  optimizeSplitShiftsDistributionBetweenEmployees: yup.boolean().required(),
  splitShiftsWeight: yup.string().oneOf(splitShiftsWeightChoices).required(),
  constraintModules: yup.array().of(yup.string()),
});

type Props = {
  fragmentRef: Key;
};

function Content({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const teamGroup = useCurrentTeamGroup();
  const data = useFragment<Key>(fragment, fragmentRef);
  const initialValues: FormValues = {
    id: data?.id || "",
    allowSplitShifts: data?.allowSplitShifts !== false, // default false
    optimizeSplitShiftsDistributionBetweenEmployees:
      data?.optimizeSplitShiftsDistributionBetweenEmployees !== false, // default false
    splitShiftsWeight: data?.splitShiftsWeight || "M",
    constraintModules: data?.constraintModules.slice() || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateSettingBoxTypes1(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Types1Form groupName={teamGroup?.name || ""} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxTypes1({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
