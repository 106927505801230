import type { FC } from "react";
import AddIcon from "@mui/icons-material/Add";
import type { ButtonProps } from "@mui/material";
import { Box, Button, Stack } from "@mui/material";
import { useFormikContext } from "formik";

import { CreateDemandsInput as FormValues } from "../types";

type Props = { onAdd: () => void; addLabel: string };

const AddButton: FC<ButtonProps & { label: string }> = ({
  label,
  ...props
}) => (
  <Button variant="primary" startIcon={<AddIcon />} {...props}>
    {label}
  </Button>
);

export function Footer({ onAdd, addLabel }: Props) {
  const { isValid, dirty, isSubmitting, values, resetForm, submitForm } =
    useFormikContext<FormValues>();
  const handleSubmit = async () => {
    await submitForm();
    resetForm({ values });
  };
  return (
    <Stack direction="row" gap={2} justifyContent="space-between">
      <Box>
        <AddButton label={addLabel} onClick={onAdd} />
      </Box>
      <Stack
        direction="row"
        gap={2}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          variant="outlined"
          disabled={!dirty || isSubmitting}
          onClick={() => resetForm()}
        >
          Återställ
        </Button>
        <Button
          variant="secondary"
          disabled={!dirty || isSubmitting || !isValid}
          onClick={handleSubmit}
        >
          Spara ändringar
        </Button>
      </Stack>
    </Stack>
  );
}
