import { useCallback, useMemo, useState } from "react";
import Timeline, { DateHeader, TimelineHeaders } from "react-calendar-timeline";
import { useFragment } from "react-relay/hooks";
import { Stack, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useRCTResizer } from "hooks/useRCTResizer";
import resizeDetector from "libs/react-calendar-timeline/container-resize-detector";
import { DateTime, Interval } from "luxon";
import type { Holiday } from "pages/types";
import type { Unwrap } from "relay-help/arrays";
import { connectionToArray } from "relay-help/arrays";
import { rrulestr } from "rrule";

import SelectDurationSpan from "components/schedule/SelectDurationSpan";

import {
  fromRfc2445String,
  toRfc2445String,
} from "../../common/Recurrence/utils";
import { TranslateBlockedTimeSlotType } from "../../types";

import { getStartAndEndTimeForTimeslot } from "./getStartAndEndTimeForTimeslot";
import { TimelineItemRenderer } from "./TimelineItemRenderer";
import type {
  BlockedTimeSlot,
  BlockedTimeSlotTimeline_fragment$data as Data,
  BlockedTimeSlotTimeline_fragment$key as Key,
  BlockedTimeSlotTimelineItem,
  BlockedTimeSlotWithOriginalBlockedTimeSlotId,
} from "./types";

const HOUR = 60 * 60 * 1000;

const fragment = graphql`
  fragment BlockedTimeSlotTimeline_fragment on UserNode @relay(plural: true) {
    id
    fullName
    blockedTimeSlots {
      edges {
        node {
          id
          start
          end
          timeslotType
          approvedByAdmin
          shift {
            id
            start
            end
          }
          shiftDate
          recurrences
          users {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
    memberOf {
      edges {
        node {
          id
          name
          group {
            id
          }
        }
      }
    }
  }
`;

function getBlockedTimeSlotTitle(timeslot: BlockedTimeSlot) {
  return `${TranslateBlockedTimeSlotType(timeslot?.timeslotType)} ${
    timeslot?.approvedByAdmin ? "(godkänd)" : "(förfrågad)"
  }`;
}

type QueryUser = Unwrap<Data>;

type Props = {
  fragmentRef: Key;
  teamGroupId: string;
  teamId: string | null;
  userId: string | null;
  onItemSelect: (activityId: string) => void;
  onCreateCopyFrom: (
    timeslot: BlockedTimeSlotWithOriginalBlockedTimeSlotId,
  ) => void;
  periodStart: Date | null;
  periodEnd: Date | null;
  holidays: Holiday[];
};

export function BlockedTimeSlotTimeline({
  fragmentRef,
  teamGroupId,
  teamId,
  userId,
  onItemSelect,
  onCreateCopyFrom,
  periodStart,
  periodEnd,
  holidays,
}: Props) {
  const usersOnTeamGroup = useFragment<Key>(fragment, fragmentRef);
  const initStart =
    periodStart || DateTime.now().startOf("day").minus({ days: 15 }).toJSDate();
  const initEnd =
    periodEnd || DateTime.now().endOf("day").plus({ days: 15 }).toJSDate();
  const [from, setFrom] = useState(initStart);
  const [to, setTo] = useState(initEnd);

  const filteredData = useMemo(
    () =>
      (usersOnTeamGroup || []).filter((user) => {
        if (!user) return false;
        if (
          teamId &&
          !user.memberOf?.edges?.some((edge) => edge?.node?.id === teamId)
        )
          return false;
        if (userId && user.id !== userId) return false;
        return true;
      }),
    [usersOnTeamGroup, teamId, userId],
  );

  const mapUser = useCallback(
    (user: QueryUser) => ({
      id: user?.id || "",
      title: user?.fullName || "",
      team:
        connectionToArray(user?.memberOf).find(
          (t) => t.group.id === teamGroupId,
        )?.name || "",
    }),
    [teamGroupId],
  );

  // Find groups
  const groups = useMemo(() => {
    return (filteredData || []).map(mapUser);
  }, [filteredData, mapUser]);

  const unpackRecurrencesReducer = useCallback(
    (acc: any, blockedTimeSlot: BlockedTimeSlot) => {
      if (!blockedTimeSlot) return acc;

      if (!blockedTimeSlot?.recurrences) {
        return [...acc, blockedTimeSlot];
      }

      const {
        start: blockedTimeSlotStart,
        end: blockedTimeSlotEnd,
        recurrences: btsRecurrences,
      } = blockedTimeSlot;

      if (!blockedTimeSlotStart || !blockedTimeSlotEnd) {
        return [...acc, blockedTimeSlot];
      }

      const btsStartDt = DateTime.fromISO(blockedTimeSlotStart);
      const btsEndDt = DateTime.fromISO(blockedTimeSlotEnd);

      const blockedTimeSlotDur = Interval.fromDateTimes(
        btsStartDt,
        btsEndDt,
      ).length();

      const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const recurrences = rrulestr(btsRecurrences, {
        tzid,
      });

      const equalsStart = (dt: Date) => dt.getTime() === btsStartDt.toMillis();

      const unpacked = recurrences
        .between(from, to)
        .filter((start_dt: Date) => !equalsStart(start_dt))
        .map((start: Date) => {
          const end = DateTime.fromMillis(
            start.getTime() + blockedTimeSlotDur,
          ).toJSDate();

          const id = `${blockedTimeSlot?.id}-${start.getTime()}`;
          return {
            ...blockedTimeSlot,
            id,
            originalTimeslotId: blockedTimeSlot?.id,
            start,
            end,
          };
        });
      return [...acc, blockedTimeSlot, ...unpacked];
    },
    [from, to],
  );

  function getItemProps(timeslot: BlockedTimeSlot) {
    return {
      className: [
        timeslot?.timeslotType.toLowerCase(),
        timeslot?.approvedByAdmin ? "a" : "w",
      ].join(""),
    };
  }

  // Sidebar styling
  type GroupsType = typeof groups;
  function renderSidebar({ group }: { group: GroupsType[number] }) {
    return (
      <Typography variant="caption">
        {group.id === "holidays" ? "" : group.title}
      </Typography>
    );
  }

  const mapBlockedTimeSlot = useCallback(
    (
      timeslot: BlockedTimeSlotWithOriginalBlockedTimeSlotId,
      user: { id: string | null } | null,
    ): BlockedTimeSlotTimelineItem => {
      const { startTime, endTime } = getStartAndEndTimeForTimeslot(timeslot);
      return {
        id: `${timeslot?.id}-${user?.id}`, // id needs to be unique even between users for the Timeline component
        timeslotId: timeslot?.id || "",
        group: user?.id || "",
        title: getBlockedTimeSlotTitle(timeslot),
        start_time: startTime,
        end_time: endTime,
        shiftId: timeslot.shift?.id,
        shiftDate: timeslot.shiftDate,
        recurrences: timeslot?.recurrences || "",
        timeslotType: timeslot?.timeslotType || "",
        approvedByAdmin: timeslot?.approvedByAdmin || false,
        originalTimeslotId: timeslot?.originalTimeslotId || "",
        users: connectionToArray(timeslot?.users).map((x) => ({
          id: x?.id,
        })),
        itemProps: getItemProps(timeslot),
      };
    },
    [],
  );

  // Find items
  const items = useMemo(
    () =>
      (filteredData || [])
        .map((user) =>
          connectionToArray(user?.blockedTimeSlots)
            .reduce(unpackRecurrencesReducer, [])
            .map((v: BlockedTimeSlotWithOriginalBlockedTimeSlotId) =>
              mapBlockedTimeSlot(v, user),
            ),
        )
        .flat(),
    [filteredData, mapBlockedTimeSlot, unpackRecurrencesReducer],
  );

  useRCTResizer();

  function onDateIntervalChange(start: Date, end: Date) {
    setFrom(start);
    setTo(end);
  }

  function onSelect(id: string) {
    const item = items.find((i) => i.id === id);
    if (!item) return;
    if (item.originalTimeslotId) {
      const { recurrences } = item;
      const adjustedRecurrences = toRfc2445String(
        fromRfc2445String(recurrences),
      );
      onCreateCopyFrom({
        ...item,
        recurrences: adjustedRecurrences,
      });
    } else {
      onItemSelect(item.timeslotId);
    }
  }

  // Convert holiday items
  const holidayItems =
    holidays?.map((h, i) => ({
      id: i,
      title: h?.title || "",
      start_time: Date.parse(h?.startTime || ""),
      end_time: Date.parse(h?.endTime || ""),
      group: "holidays",
      timeslotType: "H",
      users: [{ id: "holidays" }],
      itemProps: { className: h?.itemProps.className || "" },
    })) || [];
  const holidayGroups = [{ id: "holidays", team: "", title: "Röda dagar" }];

  return (
    <Stack gap={1}>
      <Stack direction="row" sx={{ py: 0.5 }}>
        <SelectDurationSpan
          periodStart={initStart}
          periodEnd={initEnd}
          from={from}
          to={to}
          onChange={onDateIntervalChange}
        />
      </Stack>
      <Timeline
        groups={[...holidayGroups, ...groups]}
        items={[...holidayItems, ...items]}
        horizontalLineClassNamesForGroup={(group) =>
          group.id === "holidays" ? ["holidays"] : [""]
        }
        defaultTimeStart={initStart}
        visibleTimeStart={from.getTime()}
        defaultTimeEnd={initEnd}
        visibleTimeEnd={to.getTime()}
        canChangeGroup={false}
        canMove={false}
        canResize={false}
        itemHeightRatio={0.6}
        minZoom={24 * HOUR}
        onItemSelect={onSelect}
        onItemClick={onSelect}
        groupRenderer={renderSidebar}
        itemRenderer={TimelineItemRenderer}
        resizeDetector={resizeDetector as any}
      >
        <TimelineHeaders>
          <Typography variant="caption">
            <DateHeader unit="month" height={23} />
            <DateHeader unit="week" height={23} />
            <DateHeader unit="day" height={23} />
          </Typography>
        </TimelineHeaders>
      </Timeline>
    </Stack>
  );
}
