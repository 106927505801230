import { useCallback } from "react";
import Timeline, { DateHeader, TimelineHeaders } from "react-calendar-timeline";
import { useFragment } from "react-relay/hooks";
import { Box, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useRCTResizer } from "hooks/useRCTResizer";
import resizeDetector from "libs/react-calendar-timeline/container-resize-detector";

import { ShiftTimeline_fragment$key as Key } from "./__generated__/ShiftTimeline_fragment.graphql";
import { useShiftTimeline } from "./hooks";
import { TimelineItemRenderer } from "./TimelineItemRenderer";

const fragment = graphql`
  fragment ShiftTimeline_fragment on ShiftTimeline {
    shifts {
      shiftId
      startTime
      endTime
      breakTime
      shiftType
      dayType
    }
    shiftTypes {
      id
      name
    }
  }
`;

type Props = {
  fragmentRef: Key;
};

export function ShiftTimeline({ fragmentRef }: Props) {
  useRCTResizer();

  const data = useFragment<Key>(fragment, fragmentRef);
  const { groups, items } = useShiftTimeline(data);

  type GroupsType = typeof groups;

  function renderSidebar({ group }: { group: GroupsType[number] }) {
    return <Typography variant="caption">{group.title}</Typography>;
  }

  const itemRenderer = useCallback(
    (props: any) => <TimelineItemRenderer {...props} />,
    [],
  );

  const defaultMin = Date.parse("2021-07-12T00:00:00");
  const defaultMax = Date.parse("2021-07-19T00:00:00");

  if (!data?.shifts?.length) return null;

  const height = 10 + 31 * groups.length;
  return (
    <Box sx={{ height, pr: 3.25 }}>
      <Timeline
        groups={groups}
        items={items}
        visibleTimeStart={defaultMin}
        visibleTimeEnd={defaultMax}
        buffer={1}
        canChangeGroup={false}
        canMove={false}
        canResize={false}
        itemHeightRatio={0.5}
        sidebarWidth={112}
        itemRenderer={itemRenderer}
        groupRenderer={renderSidebar}
        resizeDetector={resizeDetector as any}
      >
        <TimelineHeaders>
          <Typography variant="caption">
            <DateHeader unit="day" labelFormat="dddd" height={23} />
          </Typography>
        </TimelineHeaders>
      </Timeline>
    </Box>
  );
}
