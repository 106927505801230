import { useState } from "react";
import { Navigate } from "react-router-dom";
import {
  Button,
  Container,
  FormGroup,
  FormLabel,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import environment from "environment";
import { Form as FormikForm, Formik, useFormikContext } from "formik";
import { refresh as refreshMe, useMe } from "hooks/Me";
import * as yup from "yup";

import CustomField from "components/forms/CustomField";

import Logo from "../components/Logo";
import { commitMutation } from "../libs/commitMutation";

import { LoginMutation } from "./__generated__/LoginMutation.graphql";

const mutation = graphql`
  mutation LoginMutation($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
    }
  }
`;

const validationSchema = yup.object({
  username: yup.string().required("Obligatoriskt"),
  password: yup.string().required("Obligatoriskt"),
});

type FormValues = {
  username: string;
  password: string;
};

function Form({ loginError }: { loginError: string | null }) {
  const { isSubmitting } = useFormikContext<FormValues>();

  return (
    <Stack gap={4}>
      <Stack gap={2}>
        <FormGroup>
          <FormLabel>Användarnamn</FormLabel>
          <CustomField name="username" required />
        </FormGroup>
        <FormGroup>
          <FormLabel>Lösenord</FormLabel>
          <CustomField type="password" name="password" required />
        </FormGroup>
        {!!loginError && <Typography color="error">{loginError}</Typography>}
      </Stack>
      <Button variant="primary" type="submit" disabled={isSubmitting}>
        Logga in
      </Button>
    </Stack>
  );
}

export function Login() {
  const [loginError, setLoginError] = useState<string | null>(null);
  const me = useMe();
  const loggedIn = Boolean(me?.id);

  const initialValues = { username: "", password: "" };

  async function onSubmit(variables: FormValues) {
    setLoginError(null);

    await commitMutation<LoginMutation>(environment, {
      mutation,
      variables,
    })
      .then((response) => {
        if (response.tokenAuth?.token) {
          refreshMe();
        }
      })
      .catch((error) => {
        setLoginError(error.message);
      });
  }

  if (loggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <Container maxWidth="xs">
      <Stack gap={6} sx={{ pt: 10 }}>
        <Logo variant="color" height={50} />
        <Paper variant="box" sx={{ p: 2 }}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <FormikForm autoComplete="off">
              <Form loginError={loginError} />
            </FormikForm>
          </Formik>
        </Paper>
      </Stack>
    </Container>
  );
}
