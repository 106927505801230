import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import type { IEnvironment } from "relay-runtime";

import type {
  UpdateUserSettingBoxAB1Mutation as Mutation,
  UpdateUserSettingBoxAB1Mutation$variables as Variables,
} from "./types";

const mutation = graphql`
  mutation UpdateUserSettingBoxAB1Mutation($input: UpdateUserSettingInput!) {
    updateUserSetting(input: $input) {
      ok
      userSetting {
        ...UserSettingBoxAB1_fragment
      }
    }
  }
`;

export async function UpdateUserSettingBoxAB1(
  environment: IEnvironment,
  variables: Variables,
) {
  return commitMutation<Mutation>(environment, {
    mutation,
    variables,
  });
}
