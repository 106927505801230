import { TableCell, TableHead, TableRow } from "@mui/material";

export function ShiftTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ minWidth: 150 }}>Namn</TableCell>
        <TableCell sx={{ width: 135 }}>Starttid</TableCell>
        <TableCell sx={{ width: 135 }}>Sluttid</TableCell>
        <TableCell sx={{ width: 100 }}>Paus</TableCell>
        <TableCell sx={{ width: 69 }}>Längd</TableCell>
        <TableCell sx={{ width: 69 }}>Arbetstid</TableCell>
        <TableCell sx={{ width: 89 }}>Dygnstyp</TableCell>
        <TableCell sx={{ width: 132 }}>Passdelar</TableCell>
        <TableCell sx={{ width: 71 }}></TableCell>
      </TableRow>
    </TableHead>
  );
}
