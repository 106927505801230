import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { Types3Form } from "./forms";
import { UpdateUserSettingBoxTypes3 } from "./mutations";
import type {
  UpdateUserSettingInput as FormValues,
  UserSettingBoxTypes3_fragment$key as Key,
} from "./types";

const fragment = graphql`
  fragment UserSettingBoxTypes3_fragment on UserSettingNode {
    id
    prohibitedShiftTypes {
      edges {
        node {
          id
          name
          shiftParts {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
    settingModules
    user {
      fullName
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  currentTeamGroupId: yup.string().required(),
  prohibitedShiftTypes: yup.array().of(yup.string()).required(),
  constraintModules: yup.array().of(yup.string()).required(),
});

type Props = {
  fragmentRef: Key;
  shiftTypes: any[];
};

const FormContainer = ({ fragmentRef, shiftTypes }: Props) => {
  const environment = useRelayEnvironment();
  const data = useFragment<Key>(fragment, fragmentRef);
  const { fullName: name = "" } = data?.user || {};
  const teamGroup = useCurrentTeamGroup();
  const currentTeamGroupId = teamGroup?.id || "";
  const initialValues = {
    id: data?.id || "",
    currentTeamGroupId,
    prohibitedShiftTypes:
      data?.prohibitedShiftTypes.edges.map((s) => s?.node?.id || "") || [],
    constraintModules: data?.settingModules.slice() || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateUserSettingBoxTypes3(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Types3Form name={name} shiftTypes={shiftTypes} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
};

export function UserSettingBoxTypes3({ fragmentRef, shiftTypes }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && (
        <FormContainer fragmentRef={fragmentRef} shiftTypes={shiftTypes} />
      )}
    </Suspense>
  );
}
