import { Suspense, useEffect } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { useCurrentTeamGroup, useRequireMe } from "hooks";
import { connectionToArray } from "relay-help/arrays";

import { UserForm } from "components/admin/forms/common";
import type { TeamGroupType } from "components/admin/types";

import { useEditUser } from "../mutations";
import type { EditUserInput as FormValues } from "../types";

import type {
  EditUserForm_fragment$key as Key,
  EditUserFormQuery as Query,
} from "./types";

export const fragment = graphql`
  fragment EditUserForm_fragment on UserNode {
    id
    firstName
    lastName
    fullName
    email
    employmentDegree
    ruleGroups {
      edges {
        node {
          id
          teamGroup {
            id
          }
        }
      }
    }
    employmentForm
    employmentTitle
    timebankBaseBalance
    memberOf {
      edges {
        node {
          id
        }
      }
    }
    competences {
      edges {
        node {
          id
        }
      }
    }
    groupMemberOf {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const query = graphql`
  query EditUserFormQuery($userId: ID!) {
    user(id: $userId) {
      ...EditUserForm_fragment
    }
  }
`;

type CommonProps = {
  open: boolean;
  onClose: () => void;
  teamGroups: TeamGroupType[];
};
type Props = CommonProps & { userId: string; teamGroupId: string };
type ContentProps = CommonProps & { queryRef: PreloadedQuery<Query> };

function Content({ open, onClose, queryRef, teamGroups }: ContentProps) {
  const { user: userFragment } = usePreloadedQuery<Query>(query, queryRef);
  const selected = useFragment<Key>(fragment, userFragment);
  const [commit] = useEditUser();
  const me = useRequireMe();
  const teamGroupId = useCurrentTeamGroup()?.id;

  const title = `Redigera ${selected?.fullName || "<användare>"}`;
  const initialValues: FormValues = {
    id: selected?.id || "",
    teamGroups: connectionToArray(selected?.groupMemberOf).map((tg) => tg.id),
    teams: connectionToArray(selected?.memberOf).map((t) => t.id),
    firstName: selected?.firstName || "",
    lastName: selected?.lastName || "",
    email: selected?.email || "",
    employmentDegree: selected?.employmentDegree ?? 100,
    ruleGroup:
      connectionToArray(selected?.ruleGroups).find(
        (r) => r.teamGroup.id === teamGroupId,
      )?.id ?? null,
    teamGroupId,
    employmentForm: selected?.employmentForm.toLowerCase() || "p",
    employmentTitle: selected?.employmentTitle.toLowerCase() || "a",
    competences: connectionToArray(selected?.competences).map((c) => c.id),
    timebankBaseBalance: selected?.timebankBaseBalance || 0,
    invite: false,
  };

  async function onSubmit(input: FormValues) {
    try {
      await commit({ variables: { input } });
      onClose();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <UserForm<FormValues>
      open={open}
      onClose={onClose}
      title={title}
      teamGroups={teamGroups}
      initialValues={initialValues}
      onSubmit={onSubmit}
      showPass={me?.isSuperuser}
      name={selected?.fullName}
    />
  );
}

export function EditUserForm({
  userId,
  open,
  onClose,
  teamGroups,
  teamGroupId,
}: Props) {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);

  useEffect(() => {
    if (!userId) return;
    loadQuery({ userId });
  }, [userId, teamGroupId, loadQuery]);

  return (
    <Suspense fallback={null}>
      {!!queryRef && (
        <Content
          queryRef={queryRef}
          open={open}
          onClose={onClose}
          teamGroups={teamGroups}
        />
      )}
    </Suspense>
  );
}
