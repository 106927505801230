import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

import { PageHeader } from "components/layout/PageWrapper";

type Props = {
  onNew: () => void;
};

export function BlockedTimeSlotHeader({ onNew }: Props) {
  return (
    <PageHeader
      title="Händelser"
      actions={
        <Button variant="primary" onClick={onNew} endIcon={<AddIcon />}>
          Ny händelse
        </Button>
      }
    />
  );
}
