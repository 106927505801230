import graphql from "babel-plugin-relay/macro";
import environment from "environment";
import { commitMutation } from "libs/commitMutation";

import { AddExdateToBlockedTimeSlotMutation } from "./__generated__/AddExdateToBlockedTimeSlotMutation.graphql";

const mutation = graphql`
  mutation AddExdateToBlockedTimeSlotMutation($id: ID!, $date: DateTime!) {
    addExdateToBlockedTimeSlot(timeslotId: $id, date: $date) {
      ok
    }
  }
`;

export async function AddExdateToBlockedTimeSlot(id: string, date: string) {
  return commitMutation<AddExdateToBlockedTimeSlotMutation>(environment, {
    mutation,
    variables: {
      id,
      date,
    },
  }).then((res) => {
    if (!res.addExdateToBlockedTimeSlot?.ok) {
      return Promise.reject("Could not add exdate to time slot");
    }
    return res;
  });
}
