import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { ClockwiseShiftRotationOldForm } from "settings/ClockwiseRotations/form/ClockwiseShiftRotationOldForm";
import * as yup from "yup";

import { AutoSubmit } from "components/setting/common";

import { clockwiseRotationWeightChoices } from "../constants";
import { useUpdateSettingClockwiseShiftRotation } from "../mutations";
import type { UpdateSettingInput as FormValues } from "../types";

import type { SettingClockwiseShiftRotation_fragment$key as Key } from "./types";

type Props = {
  fragmentRef: Key;
};

const fragment = graphql`
  fragment SettingClockwiseShiftRotation_fragment on SettingNode {
    id
    constraintModules
    clockwiseRotationLimitHours
    clockwiseRotationSoft
    clockwiseRotationWeight
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  constraintModules: yup.array().of(yup.string()),
  clockwiseRotationLimitHours: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(10000, "Måste vara högst 10 000")
    .required("Får ej vara tomt"),
  clockwiseRotationSoft: yup.boolean().required(),
  clockwiseRotationWeight: yup
    .string()
    .oneOf(clockwiseRotationWeightChoices)
    .required(),
});

export function SettingClockwiseShiftRotation({ fragmentRef }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useUpdateSettingClockwiseShiftRotation();
  const initialValues = useMemo<FormValues>(
    () => ({
      id: data.id,
      constraintModules: data.constraintModules.slice(),
      clockwiseRotationLimitHours: data.clockwiseRotationLimitHours,
      clockwiseRotationSoft: data.clockwiseRotationSoft,
      clockwiseRotationWeight: data.clockwiseRotationWeight,
    }),
    [data],
  );

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <ClockwiseShiftRotationOldForm />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}
