import {
  TextField,
  Typography as Text,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/common/Accordion/FormAccordion";
import { FormikPasswordField } from "components/forms/PasswordField";

type InputProps = {
  label: string;
  name: string;
  disabled?: boolean;
};

function Input({ label, name, disabled }: InputProps) {
  const { value, setValue } = useFormikState(name);
  return (
    <TextField
      size="small"
      label={label}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      disabled={disabled}
      fullWidth
    />
  );
}

type Props = {
  isAdminForm: boolean;
  expanded: boolean;
  onChange: () => void;
  isCreate?: boolean;
  showPass: boolean;
};

export function NameAccordion({
  isAdminForm,
  expanded,
  onChange,
  isCreate,
  showPass,
}: Props) {
  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary>
        <Text variant="h4">Namn</Text>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid xs={12} sm={6}>
            <Input name="firstName" label="Förnamn" />
          </Grid>
          <Grid xs={12} sm={6}>
            <Input name="lastName" label="Efternamn" />
          </Grid>
          {isAdminForm && (
            <Grid xs={12}>
              <Input name="email" label="E-postadress" disabled={!isCreate} />
            </Grid>
          )}
          {isAdminForm && (
            <Grid xs={12}>
              <FormikPasswordField name="password" label="Lösenord" fullWidth />
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
