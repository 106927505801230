import { useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { Box, Stack, Typography } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import graphql from "babel-plugin-relay/macro";

import ColorDot from "components/common/ColorDot";
import {
  xGrids,
  xticks,
  xticksFormat,
  yformat,
} from "components/common/GraphAxisFormat";
import TooltipBox from "components/common/TooltipBox";

import type { ColorFocusType } from "../types";
import { TranslateProfileSeriesType } from "../types";

import { chartColor } from "./constants";
import {
  ScheduleProfileChartQuery as Query,
  ScheduleProfileChartQuery$data as Data,
} from "./types";

const query = graphql`
  query ScheduleProfileChartQuery($id: ID!) {
    schedule(id: $id) {
      scheduleProfile {
        viewer {
          teamId
          competenceId
          maxY
          categories {
            seriesId
            data
          }
        }
      }
    }
  }
`;

type ScheduleProfileViewer = NonNullable<
  Data["schedule"]
>["scheduleProfile"]["viewer"];

type Props = {
  scheduleId: string;
  minDate: string;
  maxDate: string;
  selectedTeam: string | null;
  selectedCompetence: string | null;
  colorFocus: ColorFocusType;
};

export function ScheduleProfileChart({
  scheduleId: id,
  minDate,
  maxDate,
  selectedTeam,
  selectedCompetence,
}: Props) {
  const data = useLazyLoadQuery<Query>(query, { id });
  const [hiddenIds, setHiddenIds] = useState<string[]>([]); //TODO this hook looks to already exist in nivo, can that data be extracted?

  // Filter data based on axis selections
  const scheduleProfileViewer = useMemo<ScheduleProfileViewer>(
    () => data?.schedule?.scheduleProfile?.viewer ?? [],
    [data?.schedule],
  );
  const filteredData = useMemo(() => {
    const filteredViewer = scheduleProfileViewer
      .filter((p) => p.teamId === selectedTeam)
      .filter((p) => p.competenceId === selectedCompetence);

    // Filter the data points so that they're within minDate-maxDate
    const filteredProfile = (filteredViewer?.[0]?.categories || []).map(
      (profile) => {
        const filtered_data = JSON.parse(profile.data).filter(
          (point: any) =>
            Date.parse(minDate) <= Date.parse(point.x) &&
            Date.parse(point.x) <= Date.parse(maxDate),
        );
        return { id: profile.seriesId, data: filtered_data };
      },
    );
    return filteredProfile;
  }, [
    scheduleProfileViewer,
    minDate,
    maxDate,
    selectedTeam,
    selectedCompetence,
  ]);

  // TODO: Calculate this on frontend via filtered_profile instead?
  const selectedMaxY =
    scheduleProfileViewer.length > 0
      ? Math.max(
          1,
          ...scheduleProfileViewer
            .filter((x) => x.teamId === selectedTeam)
            .filter((x) => x.competenceId === selectedCompetence)
            .map((x) => x.maxY || 0),
        )
      : 7;

  const legendsData = [
    {
      id: "overstaffed",
      label: "Spilltid",
      color: chartColor.OVERSTAFFED,
    },
    {
      id: "unfixed_resources",
      label: "Resurstid",
      color: chartColor.RESOURCE,
    },
    {
      id: "understaffed_apt",
      label: "APT",
      color: chartColor.UNDERSTAFFEDCOVERED,
    },
    {
      id: "understaffed_actual",
      label: "Underbemannat",
      color: chartColor.UNDERSTAFFED,
    },
    {
      id: "fixed_resources",
      label: "Täckt underbemanning",
      color: chartColor.FIXED_RESOURCE,
    },
    {
      id: "covered_demand",
      label: "Bemannat",
      color: chartColor.SHIFT,
    },
  ];

  const LEGEND_WIDTH = 170;
  const height = Math.min(110 + 12 * selectedMaxY, 220);

  return (
    <Stack>
      <Box sx={{ height, width: "100%", zIndex: 120 }}>
        <ResponsiveLine
          data={filteredData}
          margin={{ top: 20, right: 0, bottom: 15, left: LEGEND_WIDTH }}
          xScale={{
            type: "time",
            format: "%Y-%m-%d %H:%M:%S",
            useUTC: false,
            min: minDate,
            max: maxDate,
          }}
          xFormat="time:%Y-%m-%d %H:%M:%S"
          yScale={{
            min: 0,
            type: "linear",
            stacked: true,
            //max: selectedMaxY,
          }}
          //gridYValues={Array.from(Array((selectedMaxY || 0) + 1).keys())}
          gridXValues={[xGrids(maxDate, minDate)]}
          curve="stepAfter"
          useMesh={true}
          lineWidth={0}
          enableArea={true}
          areaOpacity={1}
          areaBlendMode="normal"
          enablePoints={false}
          colors={[
            chartColor.SHIFT, // covered_demand
            chartColor.FIXED_RESOURCE, // fixed_resources
            chartColor.UNDERSTAFFED, // understaffed_actual
            chartColor.UNDERSTAFFEDCOVERED, //understaffed_apt
            chartColor.RESOURCE, // resources
            chartColor.OVERSTAFFED, // overstaffed
          ]}
          animate={false}
          axisBottom={null}
          axisRight={null}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Antal personer",
            legendOffset: -30,
            legendPosition: "middle",
            format: yformat,
          }}
          axisTop={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: 36,
            legendPosition: "middle",
            format: xticksFormat(maxDate, minDate),
            tickValues: xticks(maxDate, minDate),
          }}
          legends={[
            {
              data: legendsData.map(({ id, color, label }) => ({
                color: hiddenIds.includes(id) ? "rgba(1, 1, 1, .1)" : color,
                id,
                label,
              })),
              direction: "column",
              onClick: (datum) => {
                setHiddenIds((state) =>
                  state.includes(String(datum.id))
                    ? state.filter((item) => item !== datum.id)
                    : [...state, String(datum.id)],
                );
              },
              anchor: "left",
              translateX: -LEGEND_WIDTH,
              translateY: 0,
              toggleSerie: true,
              itemsSpacing: 2,
              itemWidth: LEGEND_WIDTH,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
              justify: false,
              symbolSize: 12,
              symbolBorderColor: "rgba(0, 0, 0, .5)",
            },
          ]}
          theme={{
            text: { fontFamily: "Nunito" },
            grid: {
              line: {
                stroke: "#DEE6EA",
                strokeWidth: 0.8,
              },
            },
          }}
          enableSlices="x"
          sliceTooltip={({ slice }) => {
            return (
              <TooltipBox>
                <Stack gap={0.5} p={0.5}>
                  <Typography fontWeight={650} py={0.5}>
                    {slice.points[0].data.xFormatted}
                  </Typography>
                  {slice.points.map((point) => {
                    const value = Number(point.data.yFormatted);
                    return (
                      <Stack
                        key={point.id}
                        direction="row"
                        alignItems="center"
                        gap={1}
                      >
                        <ColorDot color={point.serieColor} size={14} />
                        <Typography
                          variant="caption"
                          fontWeight={value > 0 ? 750 : 400}
                        >
                          {TranslateProfileSeriesType(String(point.serieId))} -{" "}
                          {point.data.yFormatted}{" "}
                          {value === 1 ? "person" : "personer"}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              </TooltipBox>
            );
          }}
        />
      </Box>
    </Stack>
  );
}
