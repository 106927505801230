import { Typography, Unstable_Grid2 as Grid } from "@mui/material";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/common/Accordion/FormAccordion";
import EmploymentFormSelect from "components/common/selectors/EmploymentFormSelect";
import EmploymentTitleSelect from "components/common/selectors/EmploymentTitleSelect";
import { RuleGroupSelect } from "components/common/selectors/RuleGroupSelect";

import EmploymentDegreeInput from "./EmploymentDegreeInput";

type Props = {
  expanded: boolean;
  onChange: () => void;
  hideRuleGroup: boolean;
};

export function EmploymentAccordion({
  expanded,
  onChange,
  hideRuleGroup,
}: Props) {
  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary>
        <Typography variant="h4">Anställning</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} columns={2}>
          <Grid xs={1}>
            <EmploymentTitleSelect name="employmentTitle" label="Yrkestitel" />
          </Grid>
          <Grid xs={1}>
            <EmploymentFormSelect
              name="employmentForm"
              label="Anställningsform"
            />
          </Grid>
          <Grid xs={1}>
            {!hideRuleGroup && (
              <RuleGroupSelect name="ruleGroup" label="Regelgrupp" />
            )}
          </Grid>
          <Grid xs={1}>
            <EmploymentDegreeInput
              name="employmentDegree"
              label="Tjänstegrad"
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
