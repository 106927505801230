import React from "react";
import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import type { TeamDemandSetting as TeamDemandSettingType } from "components/setting/types";

import { TeamDemandSetting } from "./TeamDemandSetting";

type Props = { name: string; disabled?: boolean; readOnly?: boolean };

export function TeamDemandSettings({ name, disabled, readOnly }: Props) {
  const { value } = useFormikState<TeamDemandSettingType[]>(name);

  return (
    <Stack gap={1}>
      {value?.map((_, index) => (
        <React.Fragment key={index}>
          <TeamDemandSetting
            name={`${name}.${index}`}
            disabled={disabled}
            readOnly={readOnly}
          />
        </React.Fragment>
      ))}
    </Stack>
  );
}
