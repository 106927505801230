import AddIcon from "@mui/icons-material/Add";
import { Button, Stack, Tooltip, Typography } from "@mui/material";

import { PageHeader } from "components/layout/PageWrapper";

type Props = {
  onAdd: () => void;
  addDisabled?: boolean;
};

export default function PeriodHeader({ onAdd, addDisabled }: Props) {
  return (
    <PageHeader>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography variant="h2">Schemaoptimeringar</Typography>
        <Tooltip title={addDisabled ? "Körning pågår" : undefined}>
          <span>
            <Button
              variant="primary"
              endIcon={<AddIcon />}
              onClick={onAdd}
              disabled={addDisabled}
            >
              Ny schemaoptimering
            </Button>
          </span>
        </Tooltip>
      </Stack>
    </PageHeader>
  );
}
