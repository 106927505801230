/**
 * @generated SignedSource<<f70d94c45462db98331e2af4be15db94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingConsecutiveFreeDaysWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SettingBoxRhythm2_fragment$data = {
  readonly consecutiveFreeDaysWeight: ApiSettingConsecutiveFreeDaysWeightChoices;
  readonly constraintModules: ReadonlyArray<string>;
  readonly id: string;
  readonly maxConsecutiveFreeDays: number;
  readonly maxConsecutiveFreeDaysSoft: boolean;
  readonly minConsecutiveFreeDays: number;
  readonly minConsecutiveFreeDaysSoft: boolean;
  readonly " $fragmentType": "SettingBoxRhythm2_fragment";
};
export type SettingBoxRhythm2_fragment$key = {
  readonly " $data"?: SettingBoxRhythm2_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingBoxRhythm2_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingBoxRhythm2_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveFreeDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveFreeDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveFreeDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveFreeDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "consecutiveFreeDaysWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "dfe73db8f0bf1b83dd706c5c7d2d8494";

export default node;
