import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import * as yup from "yup";

import Loading from "components/loading/Loading";
import { AutoSubmit } from "components/setting/common";

import { minDailyRestAbWeightChoices } from "./constants";
import { AB1Form } from "./forms";
import { UpdateSettingBoxAB1 } from "./mutations";
import {
  SettingBoxAB1_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment SettingBoxAB1_fragment on SettingNode {
    id
    minDailyRestAbHours
    minDailyRestAbSoft
    minDailyRestAbWeight
    dayBreakAlternatives
    constraintModules
  }
`;

const validationSchema = yup.object().shape({
  minDailyRestAbHours: yup
    .number()
    .min(0, "Måste vara minst 0 timmar")
    .max(24, "Måste vara högst 24 timmar")
    .required("Får ej vara tomt"),
  minDailyRestAbSoft: yup.boolean(),
  minDailyRestAbWeight: yup
    .string()
    .oneOf(minDailyRestAbWeightChoices, "Felaktig vikt")
    .required("Måste anges"),
  dayBreakAlternatives: yup
    .array()
    .of(yup.number().min(0, "Måste vara minst 0 minuter"))
    .required("Måste anges"),
  constraintModules: yup.array().of(yup.string()).required("Måste anges"),
});

type CommonProps = {
  fragmentRef: Key;
};

type Props = CommonProps & {
  fragmentRef: Key;
};

type ContentProps = CommonProps & {
  fragmentRef: Key;
};

function Content({ fragmentRef }: ContentProps) {
  const environment = useRelayEnvironment();
  const teamGroup = useCurrentTeamGroup();
  const data = useFragment<Key>(fragment, fragmentRef);
  const initialValues = {
    id: data?.id || "",
    minDailyRestAbHours: data?.minDailyRestAbHours || 0,
    minDailyRestAbSoft: data?.minDailyRestAbSoft !== false,
    minDailyRestAbWeight: data?.minDailyRestAbWeight || "M",
    dayBreakAlternatives: data?.dayBreakAlternatives.slice() || [30],
    constraintModules: data?.constraintModules.slice() || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateSettingBoxAB1(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <AB1Form groupName={teamGroup?.name || ""} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxAB1({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
