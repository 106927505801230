import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import type { IEnvironment } from "relay-runtime";

import type {
  UpdateUserSettingBoxTypes3Mutation as Mutation,
  UpdateUserSettingBoxTypes3Mutation$variables as Variables,
} from "./types";

const mutation = graphql`
  mutation UpdateUserSettingBoxTypes3Mutation($input: UpdateUserSettingInput!) {
    updateUserSetting(input: $input) {
      ok
      userSetting {
        ...UserSettingBoxTypes3_fragment
      }
    }
  }
`;

export async function UpdateUserSettingBoxTypes3(
  environment: IEnvironment,
  variables: Variables,
) {
  return commitMutation<Mutation>(environment, {
    mutation,
    variables,
  });
}
