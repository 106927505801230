/**
 * @generated SignedSource<<0844e293b17d3528d4ba969bb4cd6103>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShiftsQuery$variables = {
  teamGroupId: string;
};
export type ShiftsQuery$data = {
  readonly settingForTeamGroup: {
    readonly eveningStartsMorningEnds: string;
    readonly fullDayDuration: number;
    readonly morningStartsNightEnds: string;
    readonly nightStartsEveningEnds: string;
  } | null;
  readonly shiftProfile: {
    readonly " $fragmentSpreads": FragmentRefs<"ShiftProfileChart_fragment">;
  };
  readonly shiftTimeline: {
    readonly " $fragmentSpreads": FragmentRefs<"ShiftTimeline_fragment">;
  };
  readonly shifts: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ShiftForm_fragment">;
  }>;
};
export type ShiftsQuery = {
  response: ShiftsQuery$data;
  variables: ShiftsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamGroupId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "teamGroupId",
    "variableName": "teamGroupId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "morningStartsNightEnds",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eveningStartsMorningEnds",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nightStartsEveningEnds",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullDayDuration",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "breakTime",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShiftsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ShiftProfileViewer",
        "kind": "LinkedField",
        "name": "shiftProfile",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ShiftProfileChart_fragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ShiftTimeline",
        "kind": "LinkedField",
        "name": "shiftTimeline",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ShiftTimeline_fragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ShiftGroup",
        "kind": "LinkedField",
        "name": "shifts",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ShiftForm_fragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SettingNode",
        "kind": "LinkedField",
        "name": "settingForTeamGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShiftsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ShiftProfileViewer",
        "kind": "LinkedField",
        "name": "shiftProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ShiftProfileEvent",
            "kind": "LinkedField",
            "name": "profile",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dateTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minStaffed",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ShiftTimeline",
        "kind": "LinkedField",
        "name": "shiftTimeline",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ShiftActivityType",
            "kind": "LinkedField",
            "name": "shifts",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shiftId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endTime",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shiftType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dayType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ShiftNode",
            "kind": "LinkedField",
            "name": "shiftTypes",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ShiftGroup",
        "kind": "LinkedField",
        "name": "shifts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "days",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ShiftNode",
            "kind": "LinkedField",
            "name": "shifts",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ShiftPartNodeConnection",
                "kind": "LinkedField",
                "name": "shiftParts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShiftPartNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShiftPartNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "partType",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SettingNode",
        "kind": "LinkedField",
        "name": "settingForTeamGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b373fbb42ed232f528608b05b9e08f8a",
    "id": null,
    "metadata": {},
    "name": "ShiftsQuery",
    "operationKind": "query",
    "text": "query ShiftsQuery(\n  $teamGroupId: ID!\n) {\n  shiftProfile(teamGroupId: $teamGroupId) {\n    ...ShiftProfileChart_fragment\n  }\n  shiftTimeline(teamGroupId: $teamGroupId) {\n    ...ShiftTimeline_fragment\n  }\n  shifts(teamGroupId: $teamGroupId) {\n    ...ShiftForm_fragment\n  }\n  settingForTeamGroup(teamGroupId: $teamGroupId) {\n    morningStartsNightEnds\n    eveningStartsMorningEnds\n    nightStartsEveningEnds\n    fullDayDuration\n    id\n  }\n}\n\nfragment ShiftForm_fragment on ShiftGroup {\n  days\n  shifts {\n    id\n    name\n    start\n    end\n    breakTime\n    shiftParts {\n      edges {\n        node {\n          id\n          start\n          end\n          partType\n        }\n      }\n    }\n  }\n}\n\nfragment ShiftProfileChart_fragment on ShiftProfileViewer {\n  profile {\n    dateTime\n    minStaffed\n  }\n}\n\nfragment ShiftTimeline_fragment on ShiftTimeline {\n  shifts {\n    shiftId\n    startTime\n    endTime\n    breakTime\n    shiftType\n    dayType\n  }\n  shiftTypes {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "24d67d9bb6b02e5615759e2ec5dcb557";

export default node;
