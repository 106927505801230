import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { connectionToArray } from "relay-help/arrays";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";
import { workDayCadenceRestrictionSchema } from "components/setting/common/forms";

import { consecutiveWorkDaysWeightChoices } from "./constants";
import { Rhythm3Form } from "./forms";
import { UpdateSettingBoxRhythm3 } from "./mutations";
import type {
  SettingBoxRhythm3_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment SettingBoxRhythm3_fragment on SettingNode {
    id
    maxConsecutiveWorkDays
    maxConsecutiveWorkDaysSoft
    minConsecutiveWorkDays
    minConsecutiveWorkDaysSoft
    consecutiveWorkDaysWeight
    workDayCadenceRestrictions {
      edges {
        node {
          id
          isSoft
          maxCadence
          calendarDays
        }
      }
    }
    constraintModules
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string(),
  maxConsecutiveWorkDays: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(1000, "Måste vara högst 1000")
    .required("Får ej vara tomt"),
  maxConsecutiveWorkDaysSoft: yup.boolean().required(),
  minConsecutiveWorkDays: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(1000, "Måste vara högst 1000")
    .required("Får ej vara tomt"),
  minConsecutiveWorkDaysSoft: yup.boolean().required(),
  consecutiveWorkDaysWeight: yup
    .string()
    .oneOf(consecutiveWorkDaysWeightChoices)
    .required(),
  workDayCadenceRestrictions: yup
    .array()
    .of(workDayCadenceRestrictionSchema)
    .required(),
  constraintModules: yup.array().of(yup.string()),
});

type Props = {
  fragmentRef: Key;
};

function Content({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const teamGroup = useCurrentTeamGroup();
  const data = useFragment<Key>(fragment, fragmentRef);
  const initialValues: FormValues = {
    id: data?.id || "",
    maxConsecutiveWorkDays: data?.maxConsecutiveWorkDays || 2,
    maxConsecutiveWorkDaysSoft: data?.maxConsecutiveWorkDaysSoft !== false, // default true
    minConsecutiveWorkDays: data?.minConsecutiveWorkDays || 2,
    minConsecutiveWorkDaysSoft: data?.minConsecutiveWorkDaysSoft !== false, // default true
    consecutiveWorkDaysWeight: data?.consecutiveWorkDaysWeight || "M",
    workDayCadenceRestrictions: connectionToArray(
      data.workDayCadenceRestrictions,
    ),
    constraintModules: data?.constraintModules.slice() || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateSettingBoxRhythm3(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Rhythm3Form groupName={teamGroup?.name || ""} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxRhythm3({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
