/**
 * @generated SignedSource<<0a3bc9248d967e01f8e27d165fac0b0c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiCompetenceDemandSettingSnapshotUnderstaffingWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamDemandSettingSnapshotOverstaffingWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamDemandSettingSnapshotUnderstaffingWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamDemandSettingSnapshotWeekendOverstaffingWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamDemandSettingSnapshotWeekendUnderstaffingWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamGroupSettingSnapshotClockwiseRotationWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamGroupSettingSnapshotConsecutiveFreeDaysWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamGroupSettingSnapshotConsecutiveWorkDaysWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamGroupSettingSnapshotDayTypeCadenceWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamGroupSettingSnapshotDayTypeDistributionWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamGroupSettingSnapshotDayTypeFairnessWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamGroupSettingSnapshotFreeWeekendWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamGroupSettingSnapshotMinDailyRestAbWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamGroupSettingSnapshotMinDailyRestWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamGroupSettingSnapshotMinWeeklyRestAbWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamGroupSettingSnapshotNightShiftRestrictionChoices = "L0" | "L1" | "L2" | "L3" | "N" | "%future added value";
export type ApiTeamGroupSettingSnapshotNightShiftRotationChoices = "N" | "R1" | "R2" | "R3" | "%future added value";
export type ApiTeamGroupSettingSnapshotResourceShiftsWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamGroupSettingSnapshotShiftTypeDistributionWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamGroupSettingSnapshotShiftsEmploymentDegreeLogicChoices = "E" | "O" | "%future added value";
export type ApiTeamGroupSettingSnapshotSplitShiftsWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamGroupSettingSnapshotTimebankModeChoices = "S" | "T" | "%future added value";
export type ApiTeamGroupSettingSnapshotWishedFreeDaysWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TeamGroupSettings_fragment$data = {
  readonly adminHoursMax: number;
  readonly adminHoursMin: number;
  readonly allowSplitShifts: boolean;
  readonly cadenceRestrictions: any;
  readonly clockwiseRotationLimitHours: number;
  readonly clockwiseRotationSoft: boolean;
  readonly clockwiseRotationWeight: ApiTeamGroupSettingSnapshotClockwiseRotationWeightChoices;
  readonly comprestCanCutDaybreakAbjour: boolean;
  readonly comprestCanCutDaybreakBilagaj: boolean;
  readonly consecutiveFreeDaysWeight: ApiTeamGroupSettingSnapshotConsecutiveFreeDaysWeightChoices;
  readonly consecutiveWorkDaysWeight: ApiTeamGroupSettingSnapshotConsecutiveWorkDaysWeightChoices;
  readonly constraintModules: ReadonlyArray<string>;
  readonly constraintModulesBridge: ReadonlyArray<string>;
  readonly dayBreakAlternatives: ReadonlyArray<number>;
  readonly dayShiftDistributionShare: number;
  readonly dayTypeCadenceWeight: ApiTeamGroupSettingSnapshotDayTypeCadenceWeightChoices;
  readonly dayTypeDistributionWeight: ApiTeamGroupSettingSnapshotDayTypeDistributionWeightChoices;
  readonly dayTypeFairnessWeight: ApiTeamGroupSettingSnapshotDayTypeFairnessWeightChoices;
  readonly eveningShiftDistributionShare: number;
  readonly eveningStartsMorningEnds: string;
  readonly freeEveryXOfYWeekends: number;
  readonly freeWeekendConstraintTypeEveryX: boolean;
  readonly freeWeekendConstraintTypeXOfY: boolean;
  readonly freeWeekendSoft: boolean;
  readonly freeWeekendWeight: ApiTeamGroupSettingSnapshotFreeWeekendWeightChoices;
  readonly fullDayDuration: number;
  readonly fullDayShiftDistributionShare: number;
  readonly hourlyCost: number;
  readonly hoursEmploymentDegreeFactor: number;
  readonly hoursPerWeek: number;
  readonly id: string;
  readonly maxConsecutiveFreeDays: number;
  readonly maxConsecutiveFreeDaysSoft: boolean;
  readonly maxConsecutiveWorkDays: number;
  readonly maxConsecutiveWorkDaysSoft: boolean;
  readonly maxTimebankPeriod: number;
  readonly maxTimebankTotal: number;
  readonly maximizeApprovedWishes: boolean;
  readonly minConsecutiveFreeDays: number;
  readonly minConsecutiveFreeDaysSoft: boolean;
  readonly minConsecutiveWorkDays: number;
  readonly minConsecutiveWorkDaysSoft: boolean;
  readonly minDailyRestAbHours: number;
  readonly minDailyRestAbSoft: boolean;
  readonly minDailyRestAbWeight: ApiTeamGroupSettingSnapshotMinDailyRestAbWeightChoices;
  readonly minDailyRestHours: number;
  readonly minDailyRestSoft: boolean;
  readonly minDailyRestWeight: ApiTeamGroupSettingSnapshotMinDailyRestWeightChoices;
  readonly minTimebankPeriod: number;
  readonly minTimebankTotal: number;
  readonly minWeeklyRestAbHours: number;
  readonly minWeeklyRestAbSoft: boolean;
  readonly minWeeklyRestAbWeight: ApiTeamGroupSettingSnapshotMinWeeklyRestAbWeightChoices;
  readonly minWeeklyRestHours: number;
  readonly mipgapLimit: number;
  readonly morningStartsNightEnds: string;
  readonly nightShiftDistributionShare: number;
  readonly nightShiftRestriction: ApiTeamGroupSettingSnapshotNightShiftRestrictionChoices;
  readonly nightShiftRotation: ApiTeamGroupSettingSnapshotNightShiftRotationChoices;
  readonly nightStartsEveningEnds: string;
  readonly offsetTimebankImbalance: boolean;
  readonly optimizeApprovedWishesDistributionBetweenEmployees: boolean;
  readonly optimizeDayTypeFairness: boolean;
  readonly optimizeDayTypeFairnessWeekend: boolean;
  readonly optimizeEvenShiftTypeDistributionBetweenEmployees: boolean;
  readonly optimizeResourceDistributionBetweenEmployees: boolean;
  readonly optimizeResourceDistributionBetweenTeams: boolean;
  readonly optimizeResourceDistributionWithinTeams: boolean;
  readonly optimizeSplitShiftsDistributionBetweenEmployees: boolean;
  readonly periodLengthWeeks: number;
  readonly prohibitedShiftTypes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly resourceHoursMax: number;
  readonly resourceHoursMin: number;
  readonly resourceShiftsWeight: ApiTeamGroupSettingSnapshotResourceShiftsWeightChoices;
  readonly schedule: {
    readonly competences: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly demandSetting: {
            readonly allowUnderstaffing: boolean;
            readonly competence: {
              readonly color: string;
              readonly name: string;
            };
            readonly id: string;
            readonly understaffingWeight: ApiCompetenceDemandSettingSnapshotUnderstaffingWeightChoices;
          } | null;
        } | null;
      } | null>;
    };
    readonly teams: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly demandSetting: {
            readonly allowUnderstaffing: boolean;
            readonly id: string;
            readonly overstaffingWeight: ApiTeamDemandSettingSnapshotOverstaffingWeightChoices;
            readonly team: {
              readonly color: string;
              readonly id: string;
              readonly name: string;
            };
            readonly understaffingWeight: ApiTeamDemandSettingSnapshotUnderstaffingWeightChoices;
            readonly weekendOverstaffingWeight: ApiTeamDemandSettingSnapshotWeekendOverstaffingWeightChoices;
            readonly weekendUnderstaffingWeight: ApiTeamDemandSettingSnapshotWeekendUnderstaffingWeightChoices;
          } | null;
        } | null;
      } | null>;
    };
  };
  readonly shiftDayTypeAllowedErrorMargin: number;
  readonly shiftDayTypeDistributionSoft: boolean;
  readonly shiftTypeDistributionWeight: ApiTeamGroupSettingSnapshotShiftTypeDistributionWeightChoices;
  readonly shiftsEarlierEndNotOk: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly shiftsEarlierStartNotOk: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly shiftsEmploymentDegreeFactor: number;
  readonly shiftsEmploymentDegreeLogic: ApiTeamGroupSettingSnapshotShiftsEmploymentDegreeLogicChoices;
  readonly shiftsLaterEndNotOk: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly shiftsLaterStartNotOk: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly shiftsPerWeek: number;
  readonly splitShiftsWeight: ApiTeamGroupSettingSnapshotSplitShiftsWeightChoices;
  readonly timebankMode: ApiTeamGroupSettingSnapshotTimebankModeChoices;
  readonly totalHoursMargin: number;
  readonly totalShiftsMargin: number;
  readonly uniqueWeeks: boolean;
  readonly weekBreakDaysAlternatives: ReadonlyArray<number>;
  readonly weekBreakHourAlternatives: ReadonlyArray<number>;
  readonly weekendEndsAt: string;
  readonly weekendEndsAtDay: number;
  readonly weekendStartsAt: string;
  readonly weekendStartsAtDay: number;
  readonly weeklyDailyCanOverlap: boolean;
  readonly wishedFreeDaysWeight: ApiTeamGroupSettingSnapshotWishedFreeDaysWeightChoices;
  readonly workDayCadenceRestrictions: any;
  readonly workEveryUnfreeWeekend: boolean;
  readonly workEveryUnfreeWeekendSoft: boolean;
  readonly workEveryXWeekend: number;
  readonly wrapAroundPeriodEnd: boolean;
  readonly " $fragmentType": "TeamGroupSettings_fragment";
};
export type TeamGroupSettings_fragment$key = {
  readonly " $data"?: TeamGroupSettings_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamGroupSettings_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ShiftSnapshotNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ShiftSnapshotNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowUnderstaffing",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "understaffingWeight",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeamGroupSettings_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mipgapLimit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodLengthWeeks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uniqueWeeks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wrapAroundPeriodEnd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModulesBridge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hourlyCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursPerWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsPerWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursEmploymentDegreeFactor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsEmploymentDegreeFactor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsEmploymentDegreeLogic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalHoursMargin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalShiftsMargin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timebankMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offsetTimebankImbalance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxTimebankPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minTimebankPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxTimebankTotal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minTimebankTotal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayTypeCadenceWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeDayTypeFairness",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeDayTypeFairnessWeekend",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayTypeFairnessWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "morningStartsNightEnds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eveningStartsMorningEnds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightStartsEveningEnds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftDayTypeDistributionSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftDayTypeAllowedErrorMargin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eveningShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullDayShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayTypeDistributionWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullDayDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minDailyRestAbHours",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minDailyRestAbSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minDailyRestAbWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayBreakAlternatives",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minWeeklyRestAbHours",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weeklyDailyCanOverlap",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minWeeklyRestAbSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minWeeklyRestAbWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekBreakHourAlternatives",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekBreakDaysAlternatives",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minDailyRestHours",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minDailyRestSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minDailyRestWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minWeeklyRestHours",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveWorkDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveWorkDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveWorkDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveWorkDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "consecutiveWorkDaysWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveFreeDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveFreeDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveFreeDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveFreeDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "consecutiveFreeDaysWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clockwiseRotationLimitHours",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clockwiseRotationSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clockwiseRotationWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendStartsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendStartsAtDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendEndsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendEndsAtDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workEveryXWeekend",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeEveryXOfYWeekends",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendConstraintTypeEveryX",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendConstraintTypeXOfY",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workEveryUnfreeWeekend",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workEveryUnfreeWeekendSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maximizeApprovedWishes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeApprovedWishesDistributionBetweenEmployees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wishedFreeDaysWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminHoursMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminHoursMax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resourceHoursMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resourceHoursMax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowSplitShifts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeSplitShiftsDistributionBetweenEmployees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "splitShiftsWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeResourceDistributionWithinTeams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeResourceDistributionBetweenTeams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeResourceDistributionBetweenEmployees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resourceShiftsWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeEvenShiftTypeDistributionBetweenEmployees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftTypeDistributionWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftRestriction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftRotation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comprestCanCutDaybreakBilagaj",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comprestCanCutDaybreakAbjour",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftSnapshotNodeConnection",
      "kind": "LinkedField",
      "name": "prohibitedShiftTypes",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftSnapshotNodeConnection",
      "kind": "LinkedField",
      "name": "shiftsLaterEndNotOk",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftSnapshotNodeConnection",
      "kind": "LinkedField",
      "name": "shiftsLaterStartNotOk",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftSnapshotNodeConnection",
      "kind": "LinkedField",
      "name": "shiftsEarlierStartNotOk",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftSnapshotNodeConnection",
      "kind": "LinkedField",
      "name": "shiftsEarlierEndNotOk",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScheduleNode",
      "kind": "LinkedField",
      "name": "schedule",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamSnapshotNodeConnection",
          "kind": "LinkedField",
          "name": "teams",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamSnapshotNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamSnapshotNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TeamDemandSettingSnapshotNode",
                      "kind": "LinkedField",
                      "name": "demandSetting",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "weekendUnderstaffingWeight",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "overstaffingWeight",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "weekendOverstaffingWeight",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TeamSnapshotNode",
                          "kind": "LinkedField",
                          "name": "team",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v4/*: any*/),
                            (v5/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompetenceSnapshotNodeConnection",
          "kind": "LinkedField",
          "name": "competences",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompetenceSnapshotNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CompetenceSnapshotNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CompetenceDemandSettingSnapshotNode",
                      "kind": "LinkedField",
                      "name": "demandSetting",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CompetenceSnapshotNode",
                          "kind": "LinkedField",
                          "name": "competence",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/),
                            (v5/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cadenceRestrictions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workDayCadenceRestrictions",
      "storageKey": null
    }
  ],
  "type": "TeamGroupSettingSnapshotNode",
  "abstractKey": null
};
})();

(node as any).hash = "a83c4822b6f5f49cfaa00e4dc5552bb4";

export default node;
