import type { Dispatch } from "react";
import { Fragment, useCallback, useMemo } from "react";
import { Typography, Unstable_Grid2 as Grid } from "@mui/material";

import type { Option as SelectOption } from "components/common/selectors/RuleGroupSelect";
import { RuleGroupSelectRenderer } from "components/common/selectors/RuleGroupSelect";

import { useGroupedOptions } from "./hooks";
import type { Option } from "./types";

export type RendererProps = {
  value?: string[];
  defaultValue?: string[];
  onChange: Dispatch<string[]>;
  options: Option[];
  teamGroupsToShow?: string[];
};

type BaseOptionValues = Pick<Option, "id" | "name">;

type TeamGroupRuleGroupSelectProps = {
  value?: string | null;
  defaultValue?: string | null;
  onChange: Dispatch<string | null>;
  options: BaseOptionValues[];
};

const toSelectOption = ({ id, name }: BaseOptionValues): SelectOption => ({
  value: id,
  label: name,
});
const get = (options: Option[], groupId: string): string | undefined =>
  options.find((o) => o.teamGroup.id === groupId)?.id;

function TeamGroupRuleGroupSelect({
  value: valueProp,
  defaultValue: defaultValueProp,
  onChange,
  options: optionsProp,
}: TeamGroupRuleGroupSelectProps) {
  const options = useMemo(() => optionsProp.map(toSelectOption), [optionsProp]);
  const value = useMemo(
    () => options.find((o) => valueProp === o.value),
    [valueProp, options],
  );
  const defaultValue = useMemo(
    () => options.find((o) => defaultValueProp === o.value),
    [defaultValueProp, options],
  );
  const handleChange = useCallback(
    (_e: unknown, option: SelectOption) => onChange(option.value),
    [onChange],
  );

  return (
    <RuleGroupSelectRenderer
      label="Regelgrupp"
      value={value}
      defaultValue={defaultValue}
      onChange={handleChange}
      options={options}
      fullWidth
    />
  );
}

export function Renderer({
  onChange,
  value: allValue,
  defaultValue: allDefaultValue,
  options: allOptions,
  teamGroupsToShow,
}: RendererProps) {
  const groupedOptions = useGroupedOptions(allOptions);
  const filteredGroups = useMemo(
    () =>
      Object.values(groupedOptions).filter(
        ({ teamGroup }) =>
          !teamGroupsToShow || teamGroupsToShow.includes(teamGroup.id),
      ),
    [groupedOptions, teamGroupsToShow],
  );
  const getValue = useCallback(
    (groupId: string): string | undefined =>
      get(
        allOptions.filter((o) => (allValue ?? []).includes(o.id)),
        groupId,
      ),
    [allOptions, allValue],
  );
  const getDefaultValue = useCallback(
    (groupId: string): string | undefined =>
      get(
        allOptions.filter((o) => (allDefaultValue ?? []).includes(o.id)),
        groupId,
      ),
    [allOptions, allDefaultValue],
  );
  const handleChange = useCallback(
    (groupId: string) => (val: string | null) => {
      /** Remove old group's rule group. If `val` is not null, add it. */
      let newValue = allOptions
        .filter((o) => o.teamGroup.id !== groupId && o.id !== val)
        .map((x) => x.id);
      if (val) {
        newValue.push(val);
      }
      onChange(newValue);
    },
    [allOptions, onChange],
  );
  const renderGroup = useCallback(
    ({ teamGroup, options }: (typeof filteredGroups)[number]) => (
      <Fragment key={teamGroup.id}>
        <Grid xs={1} alignContent="center">
          <Typography>{teamGroup.name}</Typography>
        </Grid>
        <Grid xs={2}>
          <TeamGroupRuleGroupSelect
            value={getValue(teamGroup.id)}
            defaultValue={getDefaultValue(teamGroup.id)}
            onChange={handleChange(teamGroup.id)}
            options={options}
          />
        </Grid>
      </Fragment>
    ),
    [getValue, getDefaultValue, handleChange],
  );

  return (
    <Grid container spacing={1} columns={3}>
      {filteredGroups.map(renderGroup)}
    </Grid>
  );
}
