import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Divider, List, Stack } from "@mui/material";
import { useCurrentTeamGroup, useRequireMe } from "hooks";
import type { Route } from "types/Route";

import CurrentTeamGroupSelect from "./CurrentTeamGroupSelect";
import {
  adminRoute,
  demandRoute,
  groupSettingRoute,
  homeRoute,
  memberRoute,
  periodRoute,
  settingRoute,
  shiftRoute,
  vacationRoute,
} from "./navigation_routes";
import { NavListItem } from "./NavLink";

export default function NavList() {
  const me = useRequireMe();
  const teamGroup = useCurrentTeamGroup();
  const location = useLocation();

  const renderRoute = useCallback(
    (route: Route) =>
      !route?.condition || route.condition(me, teamGroup?.id) ? (
        <NavListItem
          key={route.to}
          pathname={location.pathname}
          route={route}
        />
      ) : null,
    [me, teamGroup, location.pathname],
  );

  return (
    <List dense>
      {renderRoute(homeRoute)}
      {renderRoute(adminRoute)}
      <Divider sx={{ my: 0.5, borderColor: "ture.60" }} />
      <Stack sx={{ pt: 2, pb: 1, px: 3 }}>
        <CurrentTeamGroupSelect />
      </Stack>
      {renderRoute(groupSettingRoute)}
      {renderRoute(memberRoute)}
      {renderRoute(settingRoute)}
      {renderRoute(shiftRoute)}
      {renderRoute(demandRoute)}
      {renderRoute(vacationRoute)}
      {renderRoute(periodRoute)}
      <Divider sx={{ my: 0.5, borderColor: "ture.60" }} />
    </List>
  );
}
