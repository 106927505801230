/**
 * @generated SignedSource<<1f0b245783c04db5769d40c1138567ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApiSettingShiftsEmploymentDegreeLogicChoices = "E" | "O" | "%future added value";
export type ApiUserSettingNightShiftRestrictionChoices = "L0" | "L1" | "L2" | "L3" | "N" | "%future added value";
export type ApiUserSettingNightShiftRotationChoices = "N" | "R1" | "R2" | "R3" | "%future added value";
export type UpdateRuleGroupSettingInput = {
  clientMutationId?: string | null;
  constraintModules?: ReadonlyArray<string> | null;
  currentTeamGroupId?: string | null;
  dayBreakAlternatives?: ReadonlyArray<number> | null;
  freeEveryXOfYWeekends?: number | null;
  freeWeekendConstraintTypeEveryX?: boolean | null;
  freeWeekendConstraintTypeXOfY?: boolean | null;
  freeWeekendSoft?: boolean | null;
  hourlyCost?: number | null;
  hoursEmploymentDegreeFactor?: number | null;
  hoursPerWeek?: number | null;
  id: string;
  maxConsecutiveFreeDays?: number | null;
  maxConsecutiveFreeDaysSoft?: boolean | null;
  maxConsecutiveWorkDays?: number | null;
  maxConsecutiveWorkDaysSoft?: boolean | null;
  minConsecutiveFreeDays?: number | null;
  minConsecutiveFreeDaysSoft?: boolean | null;
  minConsecutiveWorkDays?: number | null;
  minConsecutiveWorkDaysSoft?: boolean | null;
  nightShiftRestriction?: ApiUserSettingNightShiftRestrictionChoices | null;
  nightShiftRotation?: ApiUserSettingNightShiftRotationChoices | null;
  prohibitedShiftTypes?: ReadonlyArray<string | null> | null;
  shiftsEmploymentDegreeFactor?: number | null;
  shiftsEmploymentDegreeLogic?: ApiSettingShiftsEmploymentDegreeLogicChoices | null;
  shiftsPerWeek?: number | null;
  weekBreakDaysAlternatives?: ReadonlyArray<number> | null;
  weekBreakHourAlternatives?: ReadonlyArray<number> | null;
  workEveryUnfreeWeekend?: boolean | null;
  workEveryUnfreeWeekendSoft?: boolean | null;
  workEveryXWeekend?: number | null;
};
export type UpdateRuleGroupSettingParttimesMutation$variables = {
  input: UpdateRuleGroupSettingInput;
};
export type UpdateRuleGroupSettingParttimesMutation$data = {
  readonly updateRuleGroupSetting: {
    readonly ok: boolean | null;
    readonly ruleGroupSetting: {
      readonly " $fragmentSpreads": FragmentRefs<"RuleGroupSettingParttimes_fragment">;
    } | null;
  } | null;
};
export type UpdateRuleGroupSettingParttimesMutation = {
  response: UpdateRuleGroupSettingParttimesMutation$data;
  variables: UpdateRuleGroupSettingParttimesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateRuleGroupSettingParttimesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRuleGroupSettingPayload",
        "kind": "LinkedField",
        "name": "updateRuleGroupSetting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleGroupSettingNode",
            "kind": "LinkedField",
            "name": "ruleGroupSetting",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RuleGroupSettingParttimes_fragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateRuleGroupSettingParttimesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRuleGroupSettingPayload",
        "kind": "LinkedField",
        "name": "updateRuleGroupSetting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleGroupSettingNode",
            "kind": "LinkedField",
            "name": "ruleGroupSetting",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "settingModules",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hoursEmploymentDegreeFactor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shiftsEmploymentDegreeFactor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shiftsEmploymentDegreeLogic",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shiftsPerWeek",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hoursPerWeek",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RuleGroupNode",
                "kind": "LinkedField",
                "name": "ruleGroup",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1d41f94f101cee6151555fe927df4762",
    "id": null,
    "metadata": {},
    "name": "UpdateRuleGroupSettingParttimesMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateRuleGroupSettingParttimesMutation(\n  $input: UpdateRuleGroupSettingInput!\n) {\n  updateRuleGroupSetting(input: $input) {\n    ok\n    ruleGroupSetting {\n      ...RuleGroupSettingParttimes_fragment\n      id\n    }\n  }\n}\n\nfragment RuleGroupSettingParttimes_fragment on RuleGroupSettingNode {\n  id\n  settingModules\n  hoursEmploymentDegreeFactor\n  shiftsEmploymentDegreeFactor\n  shiftsEmploymentDegreeLogic\n  shiftsPerWeek\n  hoursPerWeek\n  ruleGroup {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "11fbb4bfc1f39027083893cce467fbab";

export default node;
