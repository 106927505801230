/**
 * @generated SignedSource<<5b93308d3a8314f9b4922dff1bbd409c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WarningsDrawerButton_fragment$data = {
  readonly viewer: {
    readonly errors: ReadonlyArray<{
      readonly hardError: boolean;
    }> | null;
  };
  readonly " $fragmentType": "WarningsDrawerButton_fragment";
};
export type WarningsDrawerButton_fragment$key = {
  readonly " $data"?: WarningsDrawerButton_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WarningsDrawerButton_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WarningsDrawerButton_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ValidationResult",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ValidationError",
          "kind": "LinkedField",
          "name": "errors",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hardError",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ScheduleValidationWarnings",
  "abstractKey": null
};

(node as any).hash = "a7d87542db33ae3f1bac3530a3555f97";

export default node;
