import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { connectionToArray } from "relay-help/arrays";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { Types3Form } from "./forms";
import { UpdateRuleGroupSettingBoxTypes3 } from "./mutations";
import type {
  RuleGroupSettingBoxTypes3_fragment$key as Key,
  UpdateRuleGroupSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment RuleGroupSettingBoxTypes3_fragment on RuleGroupSettingNode {
    id
    prohibitedShiftTypes {
      edges {
        node {
          id
          name
          shiftParts {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
    settingModules
    ruleGroup {
      name
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  currentTeamGroupId: yup.string().required(),
  prohibitedShiftTypes: yup.array().of(yup.string().required()).required(),
  constraintModules: yup.array().of(yup.string()).required(),
});

type Props = {
  fragmentRef: Key;
  shiftTypes: any[];
};

const FormContainer = ({ shiftTypes, fragmentRef }: Props) => {
  const teamGroup = useCurrentTeamGroup();
  const environment = useRelayEnvironment();
  const data = useFragment<Key>(fragment, fragmentRef);
  const { name = "" } = data?.ruleGroup || {};
  const currentTeamGroupId = teamGroup?.id || "";
  const initialValues = {
    currentTeamGroupId,
    id: data?.id || "",
    prohibitedShiftTypes: connectionToArray(data.prohibitedShiftTypes).map(
      (s) => s.id,
    ),
    constraintModules: data?.settingModules.slice().filter(Boolean) || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateRuleGroupSettingBoxTypes3(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Types3Form name={name} shiftTypes={shiftTypes} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
};

export function RuleGroupSettingBoxTypes3({ shiftTypes, fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && (
        <FormContainer shiftTypes={shiftTypes} fragmentRef={fragmentRef} />
      )}
    </Suspense>
  );
}
