import type { ReactNode } from "react";
import { InputAdornment, Typography } from "@mui/material";

import Field from "components/forms/CustomField";
import PartTimeGraph from "components/setting/common/components/PartTimeGraph";
import ExampleBox from "components/setting/common/ExampleBox";

import { Title as TitleTemplate } from "./Title";
import type { GraphData } from "./useGraphData";

export const Title = () => (
  <TitleTemplate sx={{ flexGrow: 2 }}>Deltidsskalning för timmar</TitleTemplate>
);

export const Description = ({ part }: { part?: ReactNode }) => (
  <Typography sx={{ flexGrow: 2 }}>
    Detta fält styr hur antalet <i>timmar</i> {part || ""} ska skalas. Tips!
    Hoovra över grafen.
  </Typography>
);

export const HoursEmploymentDegreeFactorField = ({
  disabled,
  readOnly,
}: {
  disabled?: boolean;
  readOnly?: boolean;
}) => (
  <Field
    name="hoursEmploymentDegreeFactor"
    fullWidth
    size="small"
    type="number"
    InputProps={{
      readOnly,
      endAdornment: <InputAdornment position="end">procent</InputAdornment>,
    }}
    disabled={disabled}
  />
);

export const Graph = ({ graphData }: { graphData: GraphData }) => (
  <ExampleBox sx={{ flexGrow: 2 }}>
    {PartTimeGraph(graphData, "hours")}
  </ExampleBox>
);
