import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  defaultConvertTeamGroupDataToInitialValues,
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctions,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import {
  ResponsibilityTimeSettingTeamGroup_fragment$data as TeamGroupData,
  ResponsibilityTimeSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/ResponsibilityTimeSettingTeamGroup_fragment.graphql";
import {
  ResponsibilityTimeSettingUserSetting_fragment$data as UserSettingData,
  ResponsibilityTimeSettingUserSetting_fragment$key as UserSettingKey,
} from "./__generated__/ResponsibilityTimeSettingUserSetting_fragment.graphql";
import { ResponsibilityTimeForm } from "./ResponsibilityTimeForm";

const teamGroupFragment = graphql`
  fragment ResponsibilityTimeSettingTeamGroup_fragment on SettingNode {
    id
    constraintModules
    adminHoursMin
    adminHoursMax
  }
`;

const userSettingFragment = graphql`
  fragment ResponsibilityTimeSettingUserSetting_fragment on UserSettingNode {
    id
    settingModules
    adminHoursMin
    adminHoursMax
  }
`;

const SETTING_NAME = "Ansvarstid";
const MODULE_NAME = "AdminTime";
const SETTING_URL_ID = "wod-time";

type ResponsibilityTimeFormValues = Pick<
  TeamGroupData,
  "id" | "adminHoursMin" | "adminHoursMax" | "constraintModules"
>;

const validationSchema = yup
  .object()
  .shape({
    id: yup.string(),
    adminHoursMin: yup
      .number()
      .min(0, "Måste vara minst 0")
      .required("Får ej vara tomt"),
    adminHoursMax: yup
      .number()
      .min(0, "Måste vara minst 0")
      .required("Får ej vara tomt"),
  })
  .test(
    "max-less-than-min",
    "Minsta värdet får inte vara större än max-värdet",
    function (value: ResponsibilityTimeFormValues, context: any) {
      const { adminHoursMin, adminHoursMax } = value;
      if ((adminHoursMin || adminHoursMax) && adminHoursMin > adminHoursMax) {
        return context.createError({
          message: "Minsta värdet får inte vara större än max-värdet",
          path: "adminHoursMin",
        });
      }
    },
  );

function convertUserDataToInitialValues(
  userData: UserSettingData,
): ResponsibilityTimeFormValues {
  return {
    id: userData.id,
    adminHoursMax: userData.adminHoursMax,
    adminHoursMin: userData.adminHoursMin,
    constraintModules: userData.settingModules,
  };
}

function renderComponent(
  initialValues: ResponsibilityTimeFormValues,
  onSubmit: (formValues: ResponsibilityTimeFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <ResponsibilityTimeForm />
    </BaseSettings>
  );
}

export const responsibilityTimeSetting: OptimizationSetting<
  TeamGroupKey,
  UserSettingKey,
  undefined,
  ResponsibilityTimeFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment,
  ruleGroups: undefined,
  convertTeamGroupDataToInitialValues:
    defaultConvertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctions,
  renderComponent,
};
