import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import type { IEnvironment } from "relay-runtime";

import type {
  UpdateSettingBoxTypes6Mutation as Mutation,
  UpdateSettingBoxTypes6Mutation$variables as Variables,
} from "./types";

const mutation = graphql`
  mutation UpdateSettingBoxTypes6Mutation($input: UpdateSettingInput!) {
    updateSetting(input: $input) {
      ok
      setting {
        ...SettingBoxTypes6_fragment
      }
    }
  }
`;

export async function UpdateSettingBoxTypes6(
  environment: IEnvironment,
  variables: Variables,
) {
  return commitMutation<Mutation>(environment, {
    mutation,
    variables,
  });
}
