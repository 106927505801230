import { Suspense, useCallback, useEffect } from "react";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { OptimizationSetting } from "settings/common/optimizationSetting";
import { ALLOWED_RULE_GROUP_EXCEPTIONS } from "settings/constants";

import { useModifyRuleGroupSettingModulesMutation } from "components/setting/common/mutations/modifyRuleGroupSettingModules";

import { RuleGroupSettingsExceptionsQuery as Query } from "./__generated__/RuleGroupSettingsExceptionsQuery.graphql";
import { RuleGroupSettingExceptionBox } from "./RuleGroupSettingExceptionBox";
import { SettingsExceptions } from "./SettingsExceptions";

const query = graphql`
  query RuleGroupSettingsExceptionsQuery($ruleGroupId: ID!, $teamGroupId: ID!) {
    teamGroup(id: $teamGroupId) {
      setting {
        ...HoursAndShiftsSettingTeamGroup_fragment
        ...DayRestSettingTeamGroup_fragment
        ...ConsecutiveWorkSettingTeamGroup_fragment
        ...WorkDayCadenceSettingTeamGroup_fragment
        ...DayNightFairnessSettingTeamGroup_fragment
        ...ConsecutiveRestSettingTeamGroup_fragment
        ...WeekRestSettingTeamGroup_fragment
        ...WeekendRestSettingTeamGroup_fragment
        ...ShiftDistributionSettingTeamGroup_fragment
        ...ResourceTimeSettingTeamGroup_fragment
        ...ResponsibilityTimeSettingTeamGroup_fragment
        ...NightShiftSettingTeamGroup_fragment
        ...PartTimeSettingTeamGroup_fragment
      }
    }
    ruleGroup(id: $ruleGroupId) {
      id
      name
      ruleGroupSetting {
        id
        settingModules
        ...HoursAndShiftsSettingRuleGroup_fragment
        ...DayRestSettingRuleGroup_fragment
        ...ConsecutiveWorkSettingRuleGroup_fragment
        ...ConsecutiveRestSettingRuleGroup_fragment
        ...WeekRestSettingRuleGroup_fragment
        ...WeekendRestSettingRuleGroup_fragment
        ...ShiftDistributionSettingRuleGroup_fragment
        ...NightShiftSettingRuleGroup_fragment
        ...PartTimeSettingRuleGroup_fragment
      }
    }
  }
`;

type ContentProps = {
  queryRef: PreloadedQuery<Query>;
};
function Content({ queryRef }: ContentProps) {
  const data = usePreloadedQuery<Query>(query, queryRef);

  const [modifyRuleGroupSettingModules] =
    useModifyRuleGroupSettingModulesMutation();

  const addException = useCallback(
    (input: { id: string; constraintModules: string[] }) => {
      modifyRuleGroupSettingModules({
        variables: {
          input,
        },
      });
    },
    [modifyRuleGroupSettingModules],
  );

  const renderExceptionBox = useCallback(
    (setting: OptimizationSetting<any, any, any, any>) => (
      <RuleGroupSettingExceptionBox
        key={`${setting.moduleName}-${setting.name}`}
        setting={setting}
        settingsId={data.ruleGroup?.ruleGroupSetting?.id!}
        settingModules={data.ruleGroup?.ruleGroupSetting?.settingModules!}
        ruleGroupFragmentRef={data.ruleGroup?.ruleGroupSetting!}
        teamGroupFragment={data.teamGroup?.setting!}
      />
    ),
    [data.ruleGroup?.ruleGroupSetting, data.teamGroup?.setting],
  );

  if (data.ruleGroup?.ruleGroupSetting == null) {
    return null;
  }

  return (
    <SettingsExceptions
      name={data.ruleGroup.name}
      settingModules={data.ruleGroup.ruleGroupSetting.settingModules}
      settingsId={data.ruleGroup.ruleGroupSetting.id}
      allowedSettingsList={ALLOWED_RULE_GROUP_EXCEPTIONS}
      modifySettings={addException}
      renderExceptionBox={renderExceptionBox}
    />
  );
}

type Props = {
  ruleGroupId: string;
  teamGroupId: string;
};

export function RuleGroupSettingsExceptions({
  ruleGroupId,
  teamGroupId,
}: Props) {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);

  useEffect(() => {
    loadQuery({ ruleGroupId, teamGroupId });
  }, [loadQuery, ruleGroupId, teamGroupId]);

  return (
    <Suspense fallback={"Laddar..."}>
      {!!queryRef && <Content queryRef={queryRef} />}
    </Suspense>
  );
}
