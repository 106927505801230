import { Suspense, useCallback, useEffect } from "react";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { OptimizationSetting } from "settings/common/optimizationSetting";
import { ALLOWED_USER_EXCEPTIONS } from "settings/constants";

import { useModifyUserSettingModulesMutation } from "components/setting/common/mutations/modifyUserSettingModules";

import { UserSettingsExceptionsQuery as Query } from "./__generated__/UserSettingsExceptionsQuery.graphql";
import { SettingsExceptions } from "./SettingsExceptions";
import { UserSettingExceptionBox } from "./UserSettingExceptionBox";

const query = graphql`
  query UserSettingsExceptionsQuery($userId: ID!, $teamGroupId: ID!) {
    teamGroup(id: $teamGroupId) {
      setting {
        ...HoursAndShiftsSettingTeamGroup_fragment
        ...DayRestSettingTeamGroup_fragment
        ...ConsecutiveWorkSettingTeamGroup_fragment
        ...WorkDayCadenceSettingTeamGroup_fragment
        ...DayNightFairnessSettingTeamGroup_fragment
        ...DayNightDistributionSettingTeamGroup_fragment
        ...DayNightCadenceSettingTeamGroup_fragment
        ...ConsecutiveRestSettingTeamGroup_fragment
        ...WeekRestSettingTeamGroup_fragment
        ...WeekendRestSettingTeamGroup_fragment
        ...ShiftDistributionSettingTeamGroup_fragment
        ...ResourceTimeSettingTeamGroup_fragment
        ...ResponsibilityTimeSettingTeamGroup_fragment
        ...NightShiftSettingTeamGroup_fragment
      }
    }
    user(id: $userId) {
      id
      fullName
      userSetting {
        id
        settingModules
        ...HoursAndShiftsSettingUserSetting_fragment
        ...DayRestSettingUserSetting_fragment
        ...ConsecutiveWorkSettingUserSetting_fragment
        ...WorkDayCadenceSettingUserSetting_fragment
        ...DayNightFairnessSettingUserSetting_fragment
        ...DayNightDistributionSettingUserSetting_fragment
        ...DayNightCadenceSettingUserSetting_fragment
        ...ConsecutiveRestSettingUserSetting_fragment
        ...WeekRestSettingUserSetting_fragment
        ...WeekendRestSettingUserSetting_fragment
        ...ShiftDistributionSettingUserSetting_fragment
        ...ResourceTimeSettingUserSetting_fragment
        ...ResponsibilityTimeSettingUserSetting_fragment
        ...NightShiftSettingUserSetting_fragment
      }
    }
  }
`;

type ContentProps = {
  queryRef: PreloadedQuery<Query>;
};
function Content({ queryRef }: ContentProps) {
  const data = usePreloadedQuery<Query>(query, queryRef);

  const [modifyUserSettingModules] = useModifyUserSettingModulesMutation();

  const addException = useCallback(
    (input: { id: string; constraintModules: string[] }) => {
      modifyUserSettingModules({
        variables: {
          input,
        },
      });
    },
    [modifyUserSettingModules],
  );

  const renderExceptionBox = useCallback(
    (setting: OptimizationSetting<any, any, any, any, any>) => (
      <UserSettingExceptionBox
        key={`${setting.moduleName}-${setting.name}`}
        setting={setting}
        settingsId={data.user?.userSetting?.id!}
        settingModules={data.user?.userSetting?.settingModules!}
        userSettingFragment={data.user?.userSetting!}
        teamGroupFragment={data.teamGroup?.setting!}
      />
    ),
    [data.user?.userSetting, data.teamGroup?.setting],
  );

  if (data.user?.userSetting == null) {
    return null;
  }

  return (
    <SettingsExceptions
      name={data.user.fullName}
      settingModules={data.user?.userSetting.settingModules}
      settingsId={data.user?.userSetting?.id}
      allowedSettingsList={ALLOWED_USER_EXCEPTIONS}
      modifySettings={addException}
      renderExceptionBox={renderExceptionBox}
    />
  );
}

type Props = {
  userId: string;
  teamGroupId: string;
};

export function UserSettingsExceptions({ userId, teamGroupId }: Props) {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);

  useEffect(() => {
    loadQuery({ userId, teamGroupId });
  }, [loadQuery, userId, teamGroupId]);

  return (
    <Suspense fallback={"Laddar..."}>
      {!!queryRef && <Content queryRef={queryRef} />}
    </Suspense>
  );
}
