import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { useFormikState } from "hooks";
import { connectionToArray } from "relay-help/arrays";
import { NightShiftsOldForm } from "settings/NightShift/NightShiftsOldForm";

import { Visible } from "components/common/Visible";
import { FormikMock } from "components/setting/common";
import type { Shift } from "components/setting/common/forms/Advanced/FullAndPartTimeMeasureField/useGraphData";
import {
  AB1Form,
  AB2Form,
  Base2Form,
  Base7Form,
  Free1Form,
  ParttimesForm,
  Rhythm2Form,
  Rhythm3Form,
  Types3Form,
} from "components/setting/rule_group/setting_boxes";

import type {
  RuleGroupSettings_fragment$key as Key,
  RuleGroupSettingsFormValues,
} from "./types";

const fragment = graphql`
  fragment RuleGroupSettings_fragment on RuleGroupSettingSnapshotNode {
    id
    settingModules
    hourlyCost
    hoursPerWeek
    shiftsPerWeek
    hoursEmploymentDegreeFactor
    shiftsEmploymentDegreeFactor
    shiftsEmploymentDegreeLogic
    dayBreakAlternatives
    weekBreakHourAlternatives
    weekBreakDaysAlternatives
    maxConsecutiveWorkDays
    maxConsecutiveWorkDaysSoft
    minConsecutiveWorkDays
    minConsecutiveWorkDaysSoft
    maxConsecutiveFreeDays
    maxConsecutiveFreeDaysSoft
    minConsecutiveFreeDays
    minConsecutiveFreeDaysSoft
    workEveryXWeekend
    freeEveryXOfYWeekends
    freeWeekendConstraintTypeEveryX
    freeWeekendConstraintTypeXOfY
    freeWeekendSoft
    workEveryUnfreeWeekend
    workEveryUnfreeWeekendSoft
    nightShiftRestriction
    nightShiftRotation
    ruleGroup {
      name
    }
    prohibitedShiftTypes {
      edges {
        node {
          id
        }
      }
    }
  }
`;

type Props = {
  fragmentRef: Key;
  ruleId: string;
  periodLengthWeeks: number;
  shifts: ReadonlyArray<Shift>;
};
type GroupAdvancedProps = Pick<
  Props,
  "ruleId" | "periodLengthWeeks" | "shifts"
> & { name: string };

function GroupAdvanced({
  name,
  ruleId,
  periodLengthWeeks,
  shifts,
}: GroupAdvancedProps) {
  const hoursPerWeek = useFormikState<number>("hoursPerWeek")?.value;
  const shiftsPerWeek = useFormikState<number>("shiftsPerWeek")?.value;
  return (
    <>
      <Visible visible={ruleId === "advanced-1"}>
        <ParttimesForm
          name={name}
          periodLengthWeeks={periodLengthWeeks}
          shifts={shifts}
          hoursPerWeek={hoursPerWeek}
          shiftsPerWeek={shiftsPerWeek}
          readOnly
        />
      </Visible>
      <Visible visible={ruleId === "advanced-6"}>
        <NightShiftsOldForm readOnly />
      </Visible>
    </>
  );
}

export function RuleGroupSettings({
  fragmentRef,
  ruleId = "",
  periodLengthWeeks,
  shifts,
}: Props) {
  const { ruleGroup, ...settings } = useFragment<Key>(fragment, fragmentRef);
  const { name } = ruleGroup || {};
  const initialValues = useMemo<RuleGroupSettingsFormValues>(
    () => ({
      ...settings,
      constraintModules: settings.settingModules,
      prohibitedShiftTypes: connectionToArray(
        settings.prohibitedShiftTypes,
      ).map((s) => s.id),
    }),
    [settings],
  );

  return (
    <FormikMock initialValues={initialValues}>
      <Visible visible={ruleId === "ab-0"}>
        <AB1Form readOnly name={name} />
      </Visible>
      <Visible visible={ruleId === "ab-1"}>
        <AB2Form readOnly name={name} />
      </Visible>
      <Visible visible={ruleId === "d-1"}>
        <Base2Form readOnly name={name} periodLengthWeeks={periodLengthWeeks} />
      </Visible>
      <Visible visible={ruleId === "b-6"}>
        <Base7Form readOnly name={name} />
      </Visible>
      <Visible visible={ruleId === "w-0"}>
        <Free1Form readOnly name={name} periodLengthWeeks={periodLengthWeeks} />
      </Visible>
      <Visible visible={ruleId === "r-1"}>
        <Rhythm2Form readOnly name={name} />
      </Visible>
      <Visible visible={ruleId === "r-2"}>
        <Rhythm3Form readOnly name={name} />
      </Visible>
      <Visible visible={ruleId === "s-2"}>
        <Types3Form readOnly name={name} shiftTypes={shifts} />
      </Visible>
      <GroupAdvanced
        ruleId={ruleId}
        name={name}
        shifts={shifts}
        periodLengthWeeks={periodLengthWeeks}
      />
    </FormikMock>
  );
}
