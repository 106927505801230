import { Suspense, useEffect, useMemo } from "react";
import type { PreloadedQuery } from "react-relay";
import { usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import type { CSSObject } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { connectionToArray, isNonNull } from "relay-help/arrays";
import { optimizationRuleUrl } from "utils/urls";

import { UserSettingsPlaceholder as Placeholder } from "components/loading/pages";
import { Level2Menu } from "components/setting/common";
import { menuItems, SettingBoxes } from "components/setting/user";

import { UserSettingsQuery as Query } from "./types";

const query = graphql`
  query UserSettingsQuery($userId: ID!, $teamGroupId: ID!) {
    teamGroup(id: $teamGroupId) {
      setting {
        periodLengthWeeks
      }
    }
    shiftTypes(teamGroupId: $teamGroupId) {
      id
      name
      start
      end
      shiftParts {
        edges {
          node {
            id
          }
        }
      }
    }
    user(id: $userId) {
      userSetting {
        id
        settingModules
        ...UserSettingBoxAB1_fragment
        ...UserSettingBoxAB2_fragment
        ...UserSettingBoxBase2_fragment
        ...UserSettingBoxFree1_fragment
        ...UserSettingBoxRhythm2_fragment
        ...UserSettingBoxRhythm3_fragment
        ...UserSettingBoxTypes3_fragment
        ...UserSettingBoxTypes5_fragment
        ...UserSettingBoxTypes6_fragment
        ...UserSettingBoxTypes7_fragment
        ...UserSettingBoxWorktime1_fragment
        ...UserSettingBoxWorktime2_fragment
        ...UserSettingGroupAdvanced_fragment
      }
    }
  }
`;

type Props = {
  ruleId?: string;
  levelId: string;
  showAdvanced?: boolean;
  sx?: CSSObject;
};

type ContentProps = Props & {
  queryRef: PreloadedQuery<Query>;
};

function Content({
  ruleId,
  levelId,
  showAdvanced,
  queryRef,
  sx,
}: ContentProps) {
  const data = usePreloadedQuery<Query>(query, queryRef);
  const navigate = useNavigate();
  const { settingModules } = data.user?.userSetting || {};
  const shiftTypes = useMemo(
    () =>
      (data?.shiftTypes || []).filter(isNonNull).map((x) => ({
        ...x,
        shiftParts: connectionToArray(x.shiftParts),
      })),
    [data.shiftTypes],
  );

  function onSelect(section: string, item: string) {
    navigate(optimizationRuleUrl(levelId, `${section}-${item}`));
  }

  return (
    <>
      <Level2Menu
        ruleId={ruleId || ""}
        constraintModules={(settingModules || []).slice()}
        onSelect={onSelect}
        menuItems={menuItems}
        showAdvanced={showAdvanced}
        sx={sx}
      />
      <SettingBoxes
        ruleId={ruleId || ""}
        fragmentRef={data.user?.userSetting}
        periodLengthWeeks={data.teamGroup?.setting?.periodLengthWeeks || 0}
        shiftTypes={shiftTypes}
      />
    </>
  );
}

export function UserSettings({
  ruleId,
  levelId,
  showAdvanced,
  sx = {},
}: Props) {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const teamGroup = useCurrentTeamGroup();
  const teamGroupId = teamGroup?.id || "";
  const userId = levelId;

  useEffect(() => {
    if (!teamGroupId || !userId) return;
    loadQuery({ teamGroupId, userId });
  }, [loadQuery, teamGroupId, userId]);

  return (
    <Suspense fallback={<Placeholder />}>
      {!!queryRef && (
        <Content
          ruleId={ruleId}
          levelId={levelId}
          showAdvanced={showAdvanced}
          queryRef={queryRef}
          sx={sx}
        />
      )}
    </Suspense>
  );
}
