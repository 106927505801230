import { useCallback } from "react";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { connectionToArray } from "relay-help/arrays";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  dummySubmit,
  OptimizationSetting,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import { shiftsEmploymentDegreeLogicChoices } from "components/setting/team_group/setting_boxes/constants";
import { useUpdateRuleGroupSettingsMutation } from "components/setting/team_group/setting_boxes/updateRuleGroupSettings";
import { useUpdateSettingsMutation } from "components/setting/team_group/setting_boxes/updateSettings";

import {
  PartTimeSettingRuleGroup_fragment$data as RuleGroupData,
  PartTimeSettingRuleGroup_fragment$key as RuleGroupKey,
} from "./__generated__/PartTimeSettingRuleGroup_fragment.graphql";
import {
  PartTimeSettingTeamGroup_fragment$data as TeamGroupData,
  PartTimeSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/PartTimeSettingTeamGroup_fragment.graphql";
import { FullAndPartTimeMeasureField } from "./PartTimeForm";

const teamGroupFragment = graphql`
  fragment PartTimeSettingTeamGroup_fragment on SettingNode {
    id
    hoursEmploymentDegreeFactor
    shiftsEmploymentDegreeFactor
    shiftsEmploymentDegreeLogic
    periodLengthWeeks
    shiftsPerWeek
    hoursPerWeek
    group {
      shifts {
        edges {
          node {
            worktimeHours
          }
        }
      }
    }
  }
`;

const ruleGroupFragment = graphql`
  fragment PartTimeSettingRuleGroup_fragment on RuleGroupSettingNode {
    id
    hoursEmploymentDegreeFactor
    shiftsEmploymentDegreeFactor
    shiftsEmploymentDegreeLogic
    shiftsPerWeek
    hoursPerWeek
  }
`;

const SETTING_NAME = "Deltider";
const MODULE_NAME = "HoursAndShifts";
const SETTING_URL_ID = "parttime";

type PartTimeSettingsFormValues = Pick<
  TeamGroupData,
  | "id"
  | "hoursEmploymentDegreeFactor"
  | "shiftsEmploymentDegreeFactor"
  | "shiftsEmploymentDegreeLogic"
  | "shiftsPerWeek"
  | "hoursPerWeek"
>;

type Shift = {
  worktimeHours: number;
};

type AdditionalData = {
  periodLengthWeeks: number;
  shifts: ReadonlyArray<Shift>;
};

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  hoursEmploymentDegreeFactor: yup
    .number()
    .min(0, "Måste vara minst 0%")
    .max(100, "Måste vara högst 100%")
    .required("Får ej vara tomt"),
  shiftsEmploymentDegreeFactor: yup
    .number()
    .min(0, "Måste vara minst 0%")
    .max(100, "Måste vara högst 100%")
    .required("Får ej vara tomt"),
  shiftsEmploymentDegreeLogic: yup
    .string()
    .oneOf(shiftsEmploymentDegreeLogicChoices)
    .required(),
});

function useAdditionalDataForTeamGroup(
  fragmentRef: TeamGroupKey,
): AdditionalData {
  const teamGroupData = useFragment(teamGroupFragment, fragmentRef);
  const periodLengthWeeks = teamGroupData.periodLengthWeeks;
  const shifts = connectionToArray(teamGroupData.group.shifts);

  return {
    periodLengthWeeks,
    shifts,
  };
}

function convertDataToInitialValues(
  data: TeamGroupData | RuleGroupData,
): PartTimeSettingsFormValues {
  return {
    id: data.id,
    hoursEmploymentDegreeFactor: data.hoursEmploymentDegreeFactor,
    shiftsEmploymentDegreeFactor: data.shiftsEmploymentDegreeFactor,
    shiftsEmploymentDegreeLogic: data.shiftsEmploymentDegreeLogic,
    shiftsPerWeek: data.shiftsPerWeek,
    hoursPerWeek: data.hoursPerWeek,
  };
}

function useSubmitFunctions() {
  const [updateSettings] = useUpdateSettingsMutation();
  const [updateRuleGroupSettings] = useUpdateRuleGroupSettingsMutation();

  const modifyInput = useCallback(
    ({ shiftsPerWeek, hoursPerWeek, ...input }: PartTimeSettingsFormValues) =>
      input,
    [],
  );

  const onSubmitTeamGroup = useCallback(
    (input: PartTimeSettingsFormValues) => {
      updateSettings({ variables: { input: modifyInput(input) } });
    },
    [modifyInput, updateSettings],
  );

  const onSubmitRuleGroup = useCallback(
    (input: PartTimeSettingsFormValues) => {
      updateRuleGroupSettings({ variables: { input: modifyInput(input) } });
    },
    [modifyInput, updateRuleGroupSettings],
  );

  return { onSubmitTeamGroup, onSubmitUser: dummySubmit, onSubmitRuleGroup };
}

function renderComponent(
  initialValues: PartTimeSettingsFormValues,
  onSubmit: (formValues: PartTimeSettingsFormValues) => void,
  additionalData: AdditionalData,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FullAndPartTimeMeasureField
        periodLengthWeeks={additionalData.periodLengthWeeks}
        shiftsPerWeek={initialValues.shiftsPerWeek}
        hoursPerWeek={initialValues.hoursPerWeek}
        shifts={additionalData.shifts}
      />
    </BaseSettings>
  );
}

export const partTimeSetting: OptimizationSetting<
  TeamGroupKey,
  undefined,
  RuleGroupKey,
  PartTimeSettingsFormValues,
  AdditionalData
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment: undefined,
  ruleGroups: {
    ruleGroupFragment,
    convertDataToInitialValues,
  },
  convertTeamGroupDataToInitialValues: convertDataToInitialValues,
  convertUserDataToInitialValues: undefined,
  useAdditionalDataForTeamGroup,
  useSubmitFunctions,
  renderComponent,
};
