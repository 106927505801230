import type { Dispatch } from "react";
import { useCallback, useMemo } from "react";
import type { QueryParamsReturnType } from "hooks";
import { useQueryParams } from "hooks";

export type TabKey =
  | "users"
  | "teams"
  | "groups"
  | "competences"
  | "ruleGroups";

type State = {
  tab: TabKey;
  newCompetence: boolean;
  newRuleGroup: boolean;
  newTeamGroup: boolean;
  newTeam: boolean;
  newUser: boolean;
};
type Actions = {
  setTab: Dispatch<TabKey>;
  setNewCompetence: Dispatch<boolean>;
  setNewRuleGroup: Dispatch<boolean>;
  setNewTeamGroup: Dispatch<boolean>;
  setNewTeam: Dispatch<boolean>;
  setNewUser: Dispatch<boolean>;
};
type ParamsType = State & Actions & QueryParamsReturnType;

export function useAdminParams() {
  const [state, setState, clear] = useQueryParams<State>((query) => ({
    tab: (query.get("tab") || "users") as TabKey,
    newCompetence: query.has("newCompetence"),
    newRuleGroup: query.has("newRuleGroup"),
    newTeamGroup: query.has("newTeamGroup"),
    newTeam: query.has("newTeam"),
    newUser: query.get("newUser") === "true",
  }));
  const setNewCompetence = useCallback(
    (value: boolean) => setState("newCompetence", value),
    [setState],
  );
  const setNewRuleGroup = useCallback(
    (value: boolean) => setState("newRuleGroup", value),
    [setState],
  );
  const setNewTeamGroup = useCallback(
    (value: boolean) => setState("newTeamGroup", value),
    [setState],
  );
  const setNewTeam = useCallback(
    (value: boolean) => setState("newTeam", value),
    [setState],
  );
  const setNewUser = useCallback(
    (value: boolean) => setState("newUser", value),
    [setState],
  );
  const setTab = useCallback(
    (value: TabKey) => setState("tab", value),
    [setState],
  );

  return useMemo<ParamsType>(
    () => ({
      ...state,
      setNewCompetence,
      setNewRuleGroup,
      setNewTeamGroup,
      setNewTeam,
      setNewUser,
      setTab,
      clear,
    }),
    [
      state,
      setNewCompetence,
      setNewRuleGroup,
      setNewTeamGroup,
      setNewTeam,
      setNewUser,
      setTab,
      clear,
    ],
  );
}
