import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import type { IEnvironment } from "relay-runtime";

import type {
  UpdateSettingBoxAB1Mutation as Mutation,
  UpdateSettingBoxAB1Mutation$variables as Variables,
} from "./types";

const mutation = graphql`
  mutation UpdateSettingBoxAB1Mutation($input: UpdateSettingInput!) {
    updateSetting(input: $input) {
      ok
      setting {
        ...SettingBoxAB1_fragment
      }
    }
  }
`;

export async function UpdateSettingBoxAB1(
  environment: IEnvironment,
  variables: Variables,
) {
  return commitMutation<Mutation>(environment, {
    mutation,
    variables,
  });
}
