/**
 * @generated SignedSource<<ac7e58e3f9dc463b1cf07ee104dc48f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingBoxBase5_fragment$data = {
  readonly eveningStartsMorningEnds: string;
  readonly fullDayDuration: number;
  readonly id: string;
  readonly morningStartsNightEnds: string;
  readonly nightStartsEveningEnds: string;
  readonly " $fragmentType": "SettingBoxBase5_fragment";
};
export type SettingBoxBase5_fragment$key = {
  readonly " $data"?: SettingBoxBase5_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingBoxBase5_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingBoxBase5_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "morningStartsNightEnds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eveningStartsMorningEnds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightStartsEveningEnds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullDayDuration",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "a5126196c75ee063de5f90299cfe22b8";

export default node;
