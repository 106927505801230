import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { Worktime1Form } from "./forms";
import { UpdateUserSettingBoxWorktime1 } from "./mutations";
import type {
  UpdateUserSettingInput as FormValues,
  UserSettingBoxWorktime1_fragment$key as Key,
} from "./types";

const fragment = graphql`
  fragment UserSettingBoxWorktime1_fragment on UserSettingNode {
    id
    adminHoursMin
    adminHoursMax
    settingModules
    user {
      fullName
    }
  }
`;

const validationSchema = yup
  .object()
  .shape({
    id: yup.string().required(),
    adminHoursMin: yup
      .number()
      .min(0, "Måste vara minst 0")
      .required("Får ej vara tomt"),
    adminHoursMax: yup
      .number()
      .min(0, "Måste vara minst 0")
      .required("Får ej vara tomt"),
    constraintModules: yup.array().of(yup.string()).required(),
  })
  .test(
    "max-less-than-min",
    "Minsta värdet får inte vara större än max-värdet",
    function (value: FormValues, context: any) {
      const { adminHoursMin, adminHoursMax } = value;
      if (adminHoursMin && adminHoursMax && adminHoursMin > adminHoursMax) {
        return context.createError({
          message: "Minsta värdet får inte vara större än max-värdet",
          path: "adminHoursMin",
        });
      }
    },
  );

type Props = {
  fragmentRef: Key;
};

function Content({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const data = useFragment<Key>(fragment, fragmentRef);
  const { fullName: name = "" } = data?.user || {};
  const initialValues: FormValues = {
    id: data?.id || "",
    adminHoursMin: data?.adminHoursMin || 0,
    adminHoursMax: data?.adminHoursMax || 0,
    constraintModules: data?.settingModules.slice() || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateUserSettingBoxWorktime1(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Worktime1Form name={name} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function UserSettingBoxWorktime1({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
