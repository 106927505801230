import { useMemo } from "react";

import type { RendererProps } from "./Renderer";
import { Renderer } from "./Renderer";
import type { UserRuleGroupsContext_ruleGroups$data as Data } from "./types";
import { useUserRuleGroupsContext } from "./UserRuleGroupsContext";

type Element = Data[number];
type Option = RendererProps["options"][number];

export type UserRuleGroupsFieldProps = Omit<RendererProps, "options">;

function fillTeamGroup(o: { id: string; name: string } | null | undefined) {
  return {
    id: o?.id ?? "",
    name: o?.name ?? "",
  };
}

function normalize(o: Element): Option {
  return {
    ...o,
    teamGroup: fillTeamGroup(o.teamGroup),
  };
}

export function UserRuleGroupsField(props: UserRuleGroupsFieldProps) {
  const ruleGroups = useUserRuleGroupsContext();
  const options = useMemo(() => ruleGroups.map(normalize), [ruleGroups]);

  return <Renderer {...props} options={options} />;
}
