import { Stack, Typography } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { WeightField } from "settings/common/TeamGroupOnlyForms";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { SmallSwitch } from "components/forms/Switch";

import { dayNightFairnessSetting } from "./DayNightFairnessSetting";

type FormProps = {
  readOnly?: boolean;
};
type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

function TeamForm({ disabled, readOnly }: Props) {
  return (
    <Stack gap={4}>
      <Stack gap={1}>
        <Stack gap={2} direction="row">
          <Typography variant="h4">
            Eftersträva rättvis fördelning av Dag/Kväll/Natt
          </Typography>
          <SmallSwitch
            name="optimizeDayTypeFairness"
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
        <Typography>
          Med denna inställning påslagen, så kommer optimeringsalgoritmen att
          eftersträva en rättvis fördelning av Dag/Kväll/Natt mellan
          medarbetare. Detta är alltid en mjuk regel.
        </Typography>
      </Stack>
      <Stack gap={1}>
        <Stack gap={2} direction="row">
          <Typography variant="h4">
            Eftersträva rättvis fördelning av Dag/Kväll/Natt på helger
          </Typography>
          <SmallSwitch
            name="optimizeDayTypeFairnessWeekend"
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
        <Typography>
          Med denna inställning påslagen, så kommer optimeringsalgoritmen att
          eftersträva en rättvis fördelning av Dag/Kväll/Natt{" "}
          <strong>för helgpass</strong> mellan medarbetare. Detta är alltid en
          mjuk regel.
        </Typography>
      </Stack>
    </Stack>
  );
}

export function DayNightFairnessTeamGroupOnlyForm({ readOnly }: FormProps) {
  const disabled = !useIsConstraintModuleEnabled(
    dayNightFairnessSetting.moduleName,
  );

  return (
    <PaperWrapper>
      <Stack gap={4}>
        <TeamForm readOnly={readOnly} disabled={disabled} />
        <WeightField
          weightFieldName="dayTypeFairnessWeight"
          moduleName={dayNightFairnessSetting.moduleName}
        />
      </Stack>
    </PaperWrapper>
  );
}
