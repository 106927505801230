/**
 * @generated SignedSource<<c105a2bdabe3b417a43decf5701f4b3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingOptimization_fragment$data = {
  readonly id: string;
  readonly mipgapLimit: number;
  readonly " $fragmentType": "SettingOptimization_fragment";
};
export type SettingOptimization_fragment$key = {
  readonly " $data"?: SettingOptimization_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingOptimization_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingOptimization_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mipgapLimit",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "210cbc8df8e0f7c157061e800bd5253d";

export default node;
