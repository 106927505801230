import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { connectionToArray } from "relay-help/arrays";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";
import type { CadenceRestrictionType } from "components/setting/common/forms/Types7";
import { shiftDayTypeChoices } from "components/shifts/types";

import { dayTypeDistributionWeightChoices } from "./constants";
import { Types7Form } from "./forms";
import { UpdateSettingBoxTypes7 } from "./mutations";
import type {
  SettingBoxTypes7_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment SettingBoxTypes7_fragment on SettingNode {
    id
    cadenceRestrictions {
      edges {
        node {
          id
          dayType
          maxCadence
          calendarDays
          isSoft
        }
      }
    }
    dayTypeCadenceWeight
    constraintModules
  }
`;

const cadenceRestrictionSchema = yup
  .object()
  .shape({
    id: yup.string(),
    dayType: yup
      .string()
      .oneOf(shiftDayTypeChoices)
      .required("Får ej vara tomt"),
    maxCadence: yup
      .number()
      .min(1, "Måste vara minst 1")
      .max(20, "Måste vara högst 20")
      .required("Får ej vara tomt"),
    calendarDays: yup
      .number()
      .min(1, "Måste vara minst 1")
      .max(30, "Måste vara högst 30")
      .required("Får ej vara tomt"),
    isSoft: yup.boolean().required("Får ej vara tomt"),
  })
  .test(
    "calendarDays-less-than-maxCadence",
    "maxkadens måste vara mindre än kalenderdagarna",
    (schema: CadenceRestrictionType, ctx: any) => {
      const { calendarDays, maxCadence } = schema;
      if (calendarDays < maxCadence + 1) {
        return ctx.createError({
          message: "Måste vara mindre än kalenderdagarna",
          path: `${ctx.path}.maxCadence`,
        });
      }
      return true;
    },
  );

const validationSchema = yup.object().shape({
  id: yup.string(),
  cadenceRestrictions: yup.array().of(cadenceRestrictionSchema),
  dayTypeCadenceWeight: yup
    .string()
    .oneOf(dayTypeDistributionWeightChoices)
    .required(),
  constraintModules: yup.array().of(yup.string()).required(),
});

type Props = {
  fragmentRef: Key;
};

function FormContainer({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const data = useFragment<Key>(fragment, fragmentRef);
  const teamGroup = useCurrentTeamGroup();
  const groupName = teamGroup?.name || "";

  const initialValues: FormValues = {
    id: data?.id || "",
    dayTypeCadenceWeight: data?.dayTypeCadenceWeight || "M",
    cadenceRestrictions: connectionToArray(data?.cadenceRestrictions),
    constraintModules: data?.constraintModules.slice() || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateSettingBoxTypes7(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Types7Form groupName={groupName} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxTypes7({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <FormContainer fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
