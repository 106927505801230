import { UserForm } from "components/admin/forms/common";
import type { TeamGroupType } from "components/admin/types";

import { useCreateUser } from "../mutations";
import type { CreateUserInput as FormValues } from "../types";

type Props = {
  open: boolean;
  onClose: () => void;
  teamGroups: TeamGroupType[];
  teamGroupId: string;
  initialValues?: Partial<FormValues>;
  refresh?: () => Promise<void>;
};

export function CreateUserForm({
  open,
  onClose,
  teamGroups,
  teamGroupId,
  refresh = () => Promise.resolve(),
  initialValues: initVals = {},
}: Props) {
  const [commit] = useCreateUser();
  const title = "Skapa ny användare";
  const initialValues: FormValues = {
    teamGroups: [],
    teams: [],
    firstName: "",
    lastName: "",
    email: "",
    employmentDegree: 100,
    ruleGroup: "",
    teamGroupId,
    employmentForm: "p",
    employmentTitle: "a",
    competences: [],
    timebankBaseBalance: 0,
    invite: false,
    ...initVals,
  };

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } })
      .then(onClose)
      .then(refresh)
      .catch((error) => console.error(error));
  }

  return (
    <UserForm<FormValues>
      open={open}
      onClose={onClose}
      title={title}
      teamGroups={teamGroups}
      initialValues={initialValues}
      onSubmit={onSubmit}
      isCreate
      showPass
    />
  );
}
