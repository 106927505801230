import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  defaultConvertTeamGroupDataToInitialValues,
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctions,
  useDefaultUserSubmitFunction,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import {
  ResourceTimeSettingTeamGroup_fragment$data as TeamGroupData,
  ResourceTimeSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/ResourceTimeSettingTeamGroup_fragment.graphql";
import {
  ResourceTimeSettingUserSetting_fragment$data as UserSettingData,
  ResourceTimeSettingUserSetting_fragment$key as UserSettingKey,
} from "./__generated__/ResourceTimeSettingUserSetting_fragment.graphql";
import { ResourceTimeForm } from "./form/ResourceTimeForm";
import { ResourceTimeUserOnlyForm } from "./form/ResourceTimeUserOnlyForm";

const teamGroupFragment = graphql`
  fragment ResourceTimeSettingTeamGroup_fragment on SettingNode {
    id
    constraintModules
    resourceHoursMin
    resourceHoursMax
  }
`;

const userSettingFragment = graphql`
  fragment ResourceTimeSettingUserSetting_fragment on UserSettingNode {
    id
    settingModules
    resourceHoursMin
    resourceHoursMax
    prohibitedResourceWeekDays
  }
`;

const SETTING_NAME = "Resurstid";
const MODULE_NAME = "ResourceTime";
const SETTING_URL_ID = "resource-time";

type ResourceTimeFormValues = Pick<
  TeamGroupData,
  "id" | "resourceHoursMin" | "resourceHoursMax" | "constraintModules"
>;

type ResourceTimeUserFormValues = Pick<
  UserSettingData,
  "id" | "prohibitedResourceWeekDays"
> &
  Pick<TeamGroupData, "constraintModules">;

const validationSchema = yup
  .object()
  .shape({
    id: yup.string(),
    resourceHoursMin: yup
      .number()
      .min(0, "Måste vara minst 0")
      .required("Får ej vara tomt"),
    resourceHoursMax: yup
      .number()
      .min(0, "Måste vara minst 0")
      .required("Får ej vara tomt"),
  })
  .test(
    "max-less-than-min",
    "Minsta värdet får inte vara större än max-värdet",
    function (value: ResourceTimeFormValues, context: any) {
      const { resourceHoursMin, resourceHoursMax } = value;
      if (
        (resourceHoursMin || resourceHoursMax) &&
        resourceHoursMin > resourceHoursMax
      ) {
        return context.createError({
          message: "Minsta värdet får inte vara större än max-värdet",
          path: "resourceHoursMin",
        });
      }
    },
  );
const userValidationSchema = yup.object().shape({
  id: yup.string().required(),
  prohibitedResourceWeekDays: yup
    .array()
    .of(yup.number().required())
    .required(),
});

function convertUserDataToInitialValues(
  userData: UserSettingData,
): ResourceTimeFormValues {
  return {
    id: userData.id,
    resourceHoursMax: userData.resourceHoursMax,
    resourceHoursMin: userData.resourceHoursMin,
    constraintModules: userData.settingModules,
  };
}

function convertUserDataToUserSpecificInitialValues(
  userSettingData: UserSettingData,
): ResourceTimeUserFormValues {
  return {
    id: userSettingData.id,
    prohibitedResourceWeekDays: userSettingData.prohibitedResourceWeekDays,
    constraintModules: userSettingData.settingModules,
  };
}

function renderComponent(
  initialValues: ResourceTimeFormValues,
  onSubmit: (formValues: ResourceTimeFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <ResourceTimeForm />
    </BaseSettings>
  );
}

function renderUserComponent(
  initialValues: ResourceTimeUserFormValues,
  onSubmit: (formValues: ResourceTimeUserFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={userValidationSchema}
    >
      <ResourceTimeUserOnlyForm />
    </BaseSettings>
  );
}

export const resourceTimeSetting: OptimizationSetting<
  TeamGroupKey,
  UserSettingKey,
  undefined,
  ResourceTimeFormValues,
  {},
  undefined,
  ResourceTimeUserFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment,
  ruleGroups: undefined,
  convertTeamGroupDataToInitialValues:
    defaultConvertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctions,
  renderComponent,
  userOnly: {
    convertUserDataToInitialValues: convertUserDataToUserSpecificInitialValues,
    useSubmitUserOnly: useDefaultUserSubmitFunction,
    renderComponent: renderUserComponent,
  },
};
