import { UnreachableCaseError } from "ts-essentials";

type ConstraintModuleType =
  | "Basic"
  | "DailyRest"
  | "DailyRestAB"
  | "WeeklyRestAB"
  | "SmoothResources"
  | "SplitShifts"
  | "Competences"
  | "FreeWeekends"
  | "Vacation"
  | "WishedDaysOff"
  | "ShiftTypeDistribution"
  | "ConsecutiveWorkDays"
  | "ConsecutiveFreeDays"
  | "ClockwiseRotation"
  | "BridgeWithPreviousSchedule"
  | "StandbyDuty"
  | "ShiftDayTypeCadence"
  | "NightShifts"
  | "%future added value";

const ConstraintModuleArray: ConstraintModuleType[] = [
  "DailyRest",
  "DailyRestAB",
  "WeeklyRestAB",
  "SmoothResources",
  "SplitShifts",
  "Competences",
  "FreeWeekends",
  "Vacation",
  "WishedDaysOff",
  "ShiftTypeDistribution",
  "ConsecutiveWorkDays",
  "ConsecutiveFreeDays",
  "ClockwiseRotation",
  "BridgeWithPreviousSchedule",
  "StandbyDuty",
  "NightShifts",
];

export const ConstraintModuleBridgeArray: ConstraintModuleType[] = [
  "DailyRest",
  "ConsecutiveWorkDays",
];

export const TranslateConstraintModule = (
  type: ConstraintModuleType | undefined,
): string | undefined => {
  switch (type) {
    case "Basic":
      return "Grundinställningar";
    case "DailyRest":
      return "Vila mellan pass";
    case "DailyRestAB":
      return "Dygnsvila (AB)";
    case "WeeklyRestAB":
      return "Veckovila (AB)";
    case "SmoothResources":
      return "Resurspass";
    case "SplitShifts":
      return "Delade turer";
    case "Competences":
      return "Kompetenser";
    case "FreeWeekends":
      return "Helgvila";
    case "Vacation":
      return "Semester";
    case "WishedDaysOff":
      return "Önskade fridagar";
    case "ShiftTypeDistribution":
      return "Passtypsfördelning";
    case "ConsecutiveWorkDays":
      return "Sammanhängande arbete";
    case "ConsecutiveFreeDays":
      return "Sammanhängande fridagar";
    case "ClockwiseRotation":
      return "Medsols passrotation";
    case "BridgeWithPreviousSchedule":
      return "Överbrygg tidigare schema";
    case "StandbyDuty":
      return "Jourpass";
    case "ShiftDayTypeCadence":
      return "Dag-/Kväll-/Natt-kadens";
    case "NightShifts":
      return "Nattpass";
    case undefined:
    case "%future added value":
      return undefined;
    default:
      throw new UnreachableCaseError(type);
  }
};

export type WarningModuleType =
  | ConstraintModuleType
  | "RequiredShifts"
  | "RequiredHours"
  | "OverlappingShifts"
  | "OverlappingShiftRest"
  | "ConsecutiveWorkDaysMin"
  | "ConsecutiveWorkDaysMax"
  | "WorkDayCadence"
  | "ConsecutiveFreeDaysMin"
  | "ConsecutiveFreeDaysMax"
  | "ShiftDayTypeDistribution"
  | "FixedShifts"
  | "TeamMeetingAPT";

export const WarningModuleArray: WarningModuleType[] = [
  ...ConstraintModuleArray,
  "RequiredShifts",
  "RequiredHours",
  "OverlappingShifts",
  "OverlappingShiftRest",
  "ConsecutiveWorkDaysMin",
  "ConsecutiveWorkDaysMax",
  "ConsecutiveFreeDaysMin",
  "ConsecutiveFreeDaysMax",
];

export const TranslateWarningModule = (
  type: WarningModuleType | undefined,
): string | undefined => {
  switch (type) {
    case "RequiredShifts":
      return "Schemalagda pass";
    case "RequiredHours":
      return "Schemalagda timmar";
    case "OverlappingShifts":
      return "Pass överlappar pass";
    case "OverlappingShiftRest":
      return "Pass överlappar vila eller ledighet";
    case "Basic":
      return "Grundinställningar";
    case "DailyRest":
      return "Vila mellan pass";
    case "DailyRestAB":
      return "Dygnsvila (AB)";
    case "WeeklyRestAB":
      return "Veckovila (AB)";
    case "SmoothResources":
      return "Resurspass";
    case "SplitShifts":
      return "Delade turer";
    case "Competences":
      return "Kompetenser";
    case "FreeWeekends":
      return "Helgvila";
    case "Vacation":
      return "Semester";
    case "WishedDaysOff":
      return "Önskade fridagar";
    case "ShiftTypeDistribution":
      return "Passtypsfördelning";
    case "ShiftDayTypeDistribution":
      return "Dag-/Kväll-/Natt-fördelning";
    case "ConsecutiveWorkDays":
      return "Sammanhängande arbete";
    case "ConsecutiveWorkDaysMin":
      return "Min. sammanhängande arbete";
    case "ConsecutiveWorkDaysMax":
      return "Max. sammanhängande arbete";
    case "WorkDayCadence":
      return "Schemarytm";
    case "ConsecutiveFreeDays":
      return "Sammanhängande fridagar";
    case "ConsecutiveFreeDaysMin":
      return "Min. sammanhängande fridagar";
    case "ConsecutiveFreeDaysMax":
      return "Max. sammanhängande fridagar";
    case "ClockwiseRotation":
      return "Medsols passrotation";
    case "BridgeWithPreviousSchedule":
      return "Överbrygg tidigare schema";
    case "StandbyDuty":
      return "Jourpass";
    case "ShiftDayTypeCadence":
      return "Dag-/Kväll-/Natt-kadens";
    case "NightShifts":
      return "Nattpass";
    case "FixedShifts":
      return "Fasta pass";
    case "TeamMeetingAPT":
      return "APT-möten";
    case undefined:
    case "%future added value":
      return undefined;
    default:
      throw new UnreachableCaseError(type);
  }
};
