import type { ReactNode } from "react";
import { useMemo } from "react";
import { Stack, Unstable_Grid2 as Grid } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  Description as HoursDesc,
  Example as HoursExample,
  HoursPerWeek,
  Title as HoursTitle,
} from "./Advanced/FullAndPartTimeMeasureField/FullTimeHoursField";
import {
  Description as ShiftsDesc,
  Example as ShiftsExample,
  ShiftsPerWeek,
  Title as ShiftsTitle,
} from "./Advanced/FullAndPartTimeMeasureField/FullTimeShiftsField";
import { round } from "./Advanced/FullAndPartTimeMeasureField/utils";

type Props = {
  periodLengthWeeks: number;
  who?: ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
};

export function Base2({ periodLengthWeeks, who, disabled, readOnly }: Props) {
  const { value: hoursPerWeek } = useFormikState<number>("hoursPerWeek");
  const { value: shiftsPerWeek } = useFormikState<number>("shiftsPerWeek");
  const hoursPerPeriod: number = round(hoursPerWeek * periodLengthWeeks);
  const shiftsPerPeriod: number = round(shiftsPerWeek * periodLengthWeeks, 0);
  const shiftAvg = useMemo(
    () => (shiftsPerPeriod > 0 ? hoursPerPeriod / shiftsPerPeriod : 0),
    [hoursPerPeriod, shiftsPerPeriod],
  );
  return (
    <Stack gap={1}>
      <Grid id="titles" container spacing={5} columns={2}>
        <Grid xs={1}>
          <HoursTitle />
        </Grid>
        <Grid xs={1}>
          <ShiftsTitle />
        </Grid>
      </Grid>
      <Grid id="descriptions" container spacing={5} columns={2}>
        <Grid xs={1}>
          <HoursDesc who={who} />
        </Grid>
        <Grid xs={1}>
          <ShiftsDesc who={who} />
        </Grid>
      </Grid>
      <Grid id="fields" container spacing={5} columns={2}>
        <Grid xs={1}>
          <HoursPerWeek disabled={disabled} readOnly={readOnly} />
        </Grid>
        <Grid xs={1}>
          <ShiftsPerWeek disabled={disabled} readOnly={readOnly} />
        </Grid>
      </Grid>
      {!disabled && (
        <Grid id="examples" container spacing={5} columns={2}>
          <Grid xs={1}>
            <HoursExample
              periodLengthWeeks={periodLengthWeeks}
              hoursPerPeriod={hoursPerPeriod}
              shiftAvg={shiftAvg}
            />
          </Grid>
          <Grid xs={1}>
            <ShiftsExample
              periodLengthWeeks={periodLengthWeeks}
              shiftsPerPeriod={shiftsPerPeriod}
              shiftAvg={shiftAvg}
            />
          </Grid>
        </Grid>
      )}
    </Stack>
  );
}
