import type { ReactNode } from "react";
import { Stack, Unstable_Grid2 as Grid } from "@mui/material";
import { useFormikState } from "hooks";
import PaperWrapper from "settings/common/PaperWrapper";

import { AverageShiftLength } from "components/setting/common/forms/Advanced/FullAndPartTimeMeasureField";
import {
  Description as ScalingHoursDesc,
  Graph as ScalingHoursGraph,
  HoursEmploymentDegreeFactorField as ScalingHoursField,
  Title as ScalingHoursTitle,
} from "components/setting/common/forms/Advanced/FullAndPartTimeMeasureField/ParttimeScalingHours";
import {
  Description as ScalingShiftsDesc,
  Graph as ScalingShiftsGraph,
  ShiftsEmploymentDegreeFactorAndLogic as ScalingShiftsField,
  Title as ScalingShiftsTitle,
} from "components/setting/common/forms/Advanced/FullAndPartTimeMeasureField/ParttimeScalingShifts";
import type {
  GraphData,
  Shift,
} from "components/setting/common/forms/Advanced/FullAndPartTimeMeasureField/useGraphData";
import { useGraphData } from "components/setting/common/forms/Advanced/FullAndPartTimeMeasureField/useGraphData";
import type { ApiSettingShiftsEmploymentDegreeLogicChoices } from "components/setting/types";

type Who = {
  part: ReactNode;
  full: ReactNode;
  type: ReactNode;
};

type DisabledAndReadOnlyProps = {
  disabled?: boolean;
  readOnly?: boolean;
};

type Props = DisabledAndReadOnlyProps & {
  periodLengthWeeks: number;
  shiftsPerWeek: number;
  hoursPerWeek: number;
  shifts: ReadonlyArray<Shift>;
  who?: Who;
};

type ScalingProps = DisabledAndReadOnlyProps & {
  part?: Who["part"];
  graphData: GraphData;
};

function Scaling({ part, graphData, disabled, readOnly }: ScalingProps) {
  return (
    <Stack gap={1}>
      <Grid id="titles" container spacing={2} columns={2}>
        <Grid xs={1}>
          <ScalingHoursTitle />
        </Grid>
        <Grid xs={1}>
          <ScalingShiftsTitle />
        </Grid>
      </Grid>
      <Grid id="descs" container spacing={2} columns={2}>
        <Grid xs={1}>
          <ScalingHoursDesc part={part} />
        </Grid>
        <Grid xs={1}>
          <ScalingShiftsDesc part={part} />
        </Grid>
      </Grid>
      <Grid id="inputs" container spacing={2} columns={2}>
        <Grid xs={1}>
          <ScalingHoursField disabled={disabled} readOnly={readOnly} />
        </Grid>
        <Grid xs={1}>
          <ScalingShiftsField disabled={disabled} readOnly={readOnly} />
        </Grid>
      </Grid>
      {!disabled && (
        <Grid id="graphs" container spacing={2} columns={2}>
          <Grid xs={1}>
            <ScalingHoursGraph graphData={graphData} />
          </Grid>
          <Grid xs={1}>
            <ScalingShiftsGraph graphData={graphData} />
          </Grid>
        </Grid>
      )}
    </Stack>
  );
}

export function FullAndPartTimeMeasureField({
  periodLengthWeeks,
  shiftsPerWeek,
  hoursPerWeek,
  shifts,
  disabled,
  readOnly,
}: Props) {
  const totalShiftsMargin = useFormikState<number>("totalShiftsMargin")?.value;
  const totalHoursMargin = useFormikState<number>("totalHoursMargin")?.value;
  const shiftsFactor = useFormikState<number>(
    "shiftsEmploymentDegreeFactor",
  )?.value;
  const shiftsScalingLogic =
    useFormikState<ApiSettingShiftsEmploymentDegreeLogicChoices>(
      "shiftsEmploymentDegreeLogic",
    )?.value.toUpperCase() as ApiSettingShiftsEmploymentDegreeLogicChoices;
  const hoursFactor = useFormikState<number>(
    "hoursEmploymentDegreeFactor",
  )?.value;

  const graphData = useGraphData({
    periodLengthWeeks,
    hoursPerWeek,
    shiftsPerWeek,
    shiftsFactor,
    shiftsScalingLogic,
    hoursFactor,
    shifts,
    hoursMargin: totalHoursMargin,
    shiftsMargin: totalShiftsMargin,
  });

  return (
    <PaperWrapper>
      <Stack gap={4}>
        <Scaling
          graphData={graphData}
          disabled={disabled}
          readOnly={readOnly}
        />
        <AverageShiftLength graphData={graphData} />
      </Stack>
    </PaperWrapper>
  );
}
