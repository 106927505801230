/**
 * @generated SignedSource<<1475ba6bce62c43a3dffe5bc0423c5e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiRuleGroupSettingNightShiftRestrictionChoices = "L0" | "L1" | "L2" | "L3" | "N" | "%future added value";
export type ApiRuleGroupSettingNightShiftRotationChoices = "N" | "R1" | "R2" | "R3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RuleGroupSettingNightShifts_fragment$data = {
  readonly id: string;
  readonly nightShiftRestriction: ApiRuleGroupSettingNightShiftRestrictionChoices;
  readonly nightShiftRotation: ApiRuleGroupSettingNightShiftRotationChoices;
  readonly ruleGroup: {
    readonly name: string;
  };
  readonly settingModules: ReadonlyArray<string>;
  readonly " $fragmentType": "RuleGroupSettingNightShifts_fragment";
};
export type RuleGroupSettingNightShifts_fragment$key = {
  readonly " $data"?: RuleGroupSettingNightShifts_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RuleGroupSettingNightShifts_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RuleGroupSettingNightShifts_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftRestriction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftRotation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RuleGroupNode",
      "kind": "LinkedField",
      "name": "ruleGroup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RuleGroupSettingNode",
  "abstractKey": null
};

(node as any).hash = "7bcc714df2ae0e52f6c5eb0f1cf48e10";

export default node;
