import type { Dispatch } from "react";
import { useCallback, useMemo } from "react";
import type { QueryParamsReturnType } from "hooks";
import { useQueryParams } from "hooks";
import { DateTime } from "luxon";

import { ALL_COMPETENCES, ALL_TEAMS } from "components/demand/form/utils";
import type { ColorFocusType as ColorFocus } from "components/schedule/results/Header";

export const INIT_ACTIVITIES = ["S", "R", "Z", "P", "J", "M", "F"];

type State = {
  activities: string[];
  colorFocus: ColorFocus;
  competence: string;
  edit: string;
  from: Date;
  new: boolean;
  team: string;
  to: Date;
  validation: boolean;
};
type Actions = {
  setValidation: Dispatch<State["validation"]>;
  setNew: Dispatch<State["new"]>;
  setEdit: Dispatch<State["edit"]>;
  setColorFocus: Dispatch<State["colorFocus"]>;
  setTeam: Dispatch<State["team"]>;
  setCompetence: Dispatch<State["competence"]>;
  setActivities: Dispatch<State["activities"]>;
  setFrom: Dispatch<State["from"]>;
  setTo: Dispatch<State["to"]>;
};
type ParamsType = State & Actions & QueryParamsReturnType;
type Params = { periodStart: Date; periodEnd: Date };

export function useResultsParams({ periodStart, periodEnd }: Params) {
  const [state, setState, clear] = useQueryParams<State>((query) => ({
    validation: query.has("validation"),
    new: query.has("new"),
    edit: query.get("edit") ?? "",
    colorFocus: (query.get("colorFocus") ?? "activity_types") as ColorFocus,
    team: query.get("team") ?? ALL_TEAMS,
    competence: query.get("competence") ?? ALL_COMPETENCES,
    activities: query.get("activities")
      ? (query.get("activities") as string).split(",")
      : INIT_ACTIVITIES,
    from: query.get("from")
      ? DateTime.fromMillis(parseInt(query.get("from") as string)).toJSDate()
      : periodStart,
    to: query.get("to")
      ? DateTime.fromMillis(parseInt(query.get("to") as string)).toJSDate()
      : periodEnd,
  }));

  const setValidation = useCallback(
    (value: State["validation"]) => setState("validation", value),
    [setState],
  );
  const setNew = useCallback(
    (value: State["new"]) => setState("new", value),
    [setState],
  );
  const setEdit = useCallback(
    (value: State["edit"]) => setState("edit", value),
    [setState],
  );
  const setColorFocus = useCallback(
    (value: State["colorFocus"]) => setState("colorFocus", value),
    [setState],
  );
  const setTeam = useCallback(
    (value: State["team"]) => setState("team", value),
    [setState],
  );
  const setCompetence = useCallback(
    (value: State["competence"]) => setState("competence", value),
    [setState],
  );
  const setActivities = useCallback(
    (value: State["activities"]) => setState("activities", value),
    [setState],
  );
  const setFrom = useCallback(
    (value: State["from"]) => setState("from", value),
    [setState],
  );
  const setTo = useCallback(
    (value: State["to"]) => setState("to", value),
    [setState],
  );

  return useMemo<ParamsType>(
    () => ({
      ...state,
      setValidation,
      setNew,
      setEdit,
      setColorFocus,
      setTeam,
      setCompetence,
      setActivities,
      setFrom,
      setTo,
      clear,
    }),
    [
      state,
      setValidation,
      setNew,
      setEdit,
      setColorFocus,
      setTeam,
      setCompetence,
      setActivities,
      setFrom,
      setTo,
      clear,
    ],
  );
}
