/**
 * @generated SignedSource<<a9bf18b33936e1bbbddaf217c5864d65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSettingGroupAdvanced_fragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserSettingNightShifts_fragment">;
  readonly " $fragmentType": "UserSettingGroupAdvanced_fragment";
};
export type UserSettingGroupAdvanced_fragment$key = {
  readonly " $data"?: UserSettingGroupAdvanced_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSettingGroupAdvanced_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserSettingGroupAdvanced_fragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserSettingNightShifts_fragment"
    }
  ],
  "type": "UserSettingNode",
  "abstractKey": null
};

(node as any).hash = "ff2f692a5ad65a959f8c25d8b78c13f8";

export default node;
