import type { Preset, Presets } from "types/MaterialReactTable";

function get(key: string) {
  return localStorage.getItem(key);
}
function set(key: string, value: string) {
  return localStorage.setItem(key, value);
}
function remove(key: string) {
  return localStorage.removeItem(key);
}

// material-react-table configs

export function getTableConfig(tableKey: string) {
  const tableConfig = get(tableKey);
  if (tableConfig) {
    return JSON.parse(tableConfig);
  }
  return null;
}
export function setTableConfig(tableKey: string, tableConfig: any) {
  return set(tableKey, JSON.stringify(tableConfig));
}
export function removeTableConfig(tableKey: string) {
  return remove(tableKey);
}

// material-react-table presets

function tablePresetKey(tableKey: string) {
  return `${tableKey}-presets`;
}

export function getTablePresets(tableKey: string): Presets {
  const tablePresets = get(tablePresetKey(tableKey));
  if (tablePresets) {
    return JSON.parse(tablePresets);
  }
  return [];
}

function setTablePresets(tableKey: string, tablePresets: Presets) {
  return set(tablePresetKey(tableKey), JSON.stringify(tablePresets));
}

export function addTablePreset(tableKey: string, tablePreset: Preset) {
  const presets = getTablePresets(tableKey);
  presets.push(tablePreset);
  setTablePresets(tableKey, presets);
}

export function removeTablePreset(tableKey: string, presetKey: string) {
  const presets = getTablePresets(tableKey);
  if (presets) {
    const newPresets = presets.filter(
      (preset: Preset) => preset.key !== presetKey,
    );
    setTablePresets(tableKey, newPresets);
  }
}
