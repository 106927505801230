import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { dayTypeDistributionWeightChoices } from "./constants";
import { Types5Form } from "./forms";
import { UpdateSettingBoxTypes5 } from "./mutations";
import type {
  SettingBoxTypes5_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment SettingBoxTypes5_fragment on SettingNode {
    id
    shiftDayTypeDistributionSoft
    shiftDayTypeAllowedErrorMargin
    dayShiftDistributionShare
    eveningShiftDistributionShare
    nightShiftDistributionShare
    fullDayShiftDistributionShare
    dayTypeDistributionWeight
    constraintModules
    shiftsPerWeek
    periodLengthWeeks
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string(),
  dayTypeDistributionWeight: yup
    .string()
    .oneOf(dayTypeDistributionWeightChoices)
    .required(),
  constraintModules: yup.array().of(yup.string()).required(),
  dayShiftDistributionShare: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(100, "Måste vara högst 100")
    .required("Får ej vara tomt"),
  eveningShiftDistributionShare: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(100, "Måste vara högst 100")
    .required("Får ej vara tomt"),
  nightShiftDistributionShare: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(100, "Måste vara högst 100")
    .required("Får ej vara tomt"),
  fullDayShiftDistributionShare: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(100, "Måste vara högst 100")
    .required("Får ej vara tomt"),
});

type Props = {
  fragmentRef: Key;
};

function FormContainer({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const data = useFragment<Key>(fragment, fragmentRef);
  const teamGroup = useCurrentTeamGroup();
  const groupName = teamGroup?.name || "";
  const totalShifts = Math.round(data.shiftsPerWeek * data.periodLengthWeeks);

  const initialValues: FormValues = {
    id: data?.id || "",
    shiftDayTypeDistributionSoft: data?.shiftDayTypeDistributionSoft || false,
    shiftDayTypeAllowedErrorMargin: data?.shiftDayTypeAllowedErrorMargin || 0,
    dayShiftDistributionShare:
      Math.round(data?.dayShiftDistributionShare * 1000) / 10 ?? 50,
    eveningShiftDistributionShare:
      Math.round(data?.eveningShiftDistributionShare * 1000) / 10 ?? 50,
    nightShiftDistributionShare:
      Math.round(data?.nightShiftDistributionShare * 1000) / 10 ?? 0,
    fullDayShiftDistributionShare:
      Math.round(data?.fullDayShiftDistributionShare * 1000) / 10 ?? 0,
    dayTypeDistributionWeight: data?.dayTypeDistributionWeight || "M",
    constraintModules: data?.constraintModules.slice() || [],
  };

  async function onSubmit(values: FormValues) {
    const input = {
      ...values,
      dayShiftDistributionShare: (values?.dayShiftDistributionShare ?? 0) / 100,
      eveningShiftDistributionShare:
        (values?.eveningShiftDistributionShare ?? 0) / 100,
      nightShiftDistributionShare:
        (values?.nightShiftDistributionShare ?? 0) / 100,
      fullDayShiftDistributionShare:
        (values?.fullDayShiftDistributionShare ?? 0) / 100,
    };
    await UpdateSettingBoxTypes5(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Types5Form groupName={groupName} totalShifts={totalShifts} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxTypes5({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <FormContainer fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
