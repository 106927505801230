import useFormikState from "hooks/useFormikState";

import { DAYS } from "components/shifts";

import { CardWithIndices, Chips } from "./CardWithIndices";

type Props = Omit<React.ComponentProps<typeof CardWithIndices>, "chips"> & {
  checked: number[];
  onToggle: (idx: number) => void;
};

type DayChipsProps = Omit<React.ComponentProps<typeof Chips>, "options">;

type FormikProps = Omit<Props, "checked" | "onToggle"> & {
  name: string;
};

export const DayChips = (props: DayChipsProps) => (
  <Chips {...props} options={DAYS} />
);

function DayCard({ checked, onToggle, ...props }: Props) {
  return (
    <CardWithIndices
      {...props}
      chips={<DayChips checked={checked} onToggle={onToggle} />}
    />
  );
}

export function FormikDayCard({ name, ...props }: FormikProps) {
  const {
    value,
    setValue,
    meta: { error },
  } = useFormikState<number[]>(name);

  function onToggle(idx: number) {
    const checked = (value || []).includes(idx);
    if (checked) {
      setValue(value.filter((x) => x !== idx));
    } else {
      setValue([...value, idx]);
    }
  }

  return (
    <DayCard
      {...props}
      error={!!error}
      helperText={error}
      checked={value || []}
      onToggle={onToggle}
    />
  );
}
