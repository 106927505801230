import { Suspense, useMemo } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { Base2Form } from "./forms";
import { UpdateRuleGroupSettingBoxBase2 } from "./mutations";
import type {
  RuleGroupSettingBoxBase2_fragment$key as Key,
  UpdateRuleGroupSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment RuleGroupSettingBoxBase2_fragment on RuleGroupSettingNode {
    id
    hoursPerWeek
    shiftsPerWeek
    shiftsEmploymentDegreeFactor
    shiftsEmploymentDegreeLogic
    hoursEmploymentDegreeFactor
    settingModules
    ruleGroup {
      name
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  hoursPerWeek: yup
    .number()
    .min(0, "Måste vara minst 0 timmar")
    .max(168, "Måste vara högst 168 timmar")
    .required("Får ej vara tomt"),
  shiftsPerWeek: yup
    .number()
    .min(0, "Måste vara minst 0 skift")
    .max(168, "Måste vara högst 168 skift")
    .required("Får ej vara tomt"),
  shiftsEmploymentDegreeFactor: yup
    .number()
    .min(0, "Måste vara minst 0%")
    .max(100, "Måste vara högst 100%")
    .required("Får ej vara tomt"),
  hoursEmploymentDegreeFactor: yup
    .number()
    .min(0, "Måste vara minst 0%")
    .max(100, "Måste vara högst 100%")
    .required("Får ej vara tomt"),
  constraintModules: yup.array().of(yup.string()).required(),
});

type Props = {
  fragmentRef: Key;
  periodLengthWeeks: number;
};

const Content = ({ fragmentRef, periodLengthWeeks }: Props) => {
  const environment = useRelayEnvironment();
  const data = useFragment<Key>(fragment, fragmentRef);
  const { name = "" } = data?.ruleGroup || {};
  const initialValues = useMemo<FormValues>(
    () => ({
      id: data?.id || "",
      hoursPerWeek: data?.hoursPerWeek ?? 0,
      shiftsPerWeek: data?.shiftsPerWeek ?? 0,
      constraintModules: data?.settingModules.slice().filter(Boolean) ?? [],
      shiftsEmploymentDegreeFactor: data?.shiftsEmploymentDegreeFactor ?? 0,
      shiftsEmploymentDegreeLogic: data?.shiftsEmploymentDegreeLogic || "E",
      hoursEmploymentDegreeFactor: data?.hoursEmploymentDegreeFactor ?? 0,
    }),
    [data],
  );

  async function onSubmit(input: FormValues) {
    await UpdateRuleGroupSettingBoxBase2(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Base2Form name={name} periodLengthWeeks={periodLengthWeeks} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
};

export function RuleGroupSettingBoxBase2({
  fragmentRef,
  periodLengthWeeks,
}: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && (
        <Content
          fragmentRef={fragmentRef}
          periodLengthWeeks={periodLengthWeeks}
        />
      )}
    </Suspense>
  );
}
