import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import type { TeamDemandSetting as TeamDemandSettingType } from "components/setting/types";

import { TeamDemandSetting } from "./TeamDemandSetting";

type Props = { name: string; disabled?: boolean; readOnly?: boolean };

export function TeamDemandSettings({ name, disabled, readOnly }: Props) {
  const { value } = useFormikState<TeamDemandSettingType[]>(name);

  return (
    <Stack gap={5}>
      {value?.map((_, index) => (
        <TeamDemandSetting
          key={index}
          name={`${name}.${index}`}
          disabled={disabled}
          readOnly={readOnly}
        />
      ))}
    </Stack>
  );
}
