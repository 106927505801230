import type { ComponentProps } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { useFormikState } from "hooks";
import { isNonNull } from "relay-help/arrays";

import GenericSelect from "../GenericSelect";

import { useRuleGroupSelectFragmentRef } from "./RuleGroupSelectFragmentRefContext";
import type { RuleGroupSelect_fragment$key as Key } from "./types";

const fragment = graphql`
  fragment RuleGroupSelect_fragment on RuleGroupNode @relay(plural: true) {
    id
    name
  }
`;

export type Option = {
  value: string | null;
  label: string;
};

const NO_CATEGORY: Option = {
  value: null,
  label: "<Ingen regelgrupp>",
};

type Props = {
  name: string;
  label?: string;
};

type RendererProps = ComponentProps<typeof GenericSelect<Option>>;

export const RuleGroupSelectRenderer = (props: RendererProps) => {
  return (
    <GenericSelect<Option>
      {...props}
      isOptionEqualToValue={(option, selected) =>
        option?.value === selected?.value
      }
      noOptionsText="Inga regelgrupper finns tillagda"
      placeholder="Välj regelgrupptyp..."
      insert={{ before: [NO_CATEGORY] }}
    />
  );
};

export const RuleGroupSelect = ({ name, label }: Props) => {
  const fragmentRef = useRuleGroupSelectFragmentRef();
  const ruleGroups = useFragment<Key>(fragment, fragmentRef);
  const { meta, value, setValue } = useFormikState<Option["value"]>(name);
  const options = (ruleGroups || []).filter(isNonNull).map((c) => ({
    value: c?.id,
    label: c?.name,
  }));
  const selectedOption = options?.find((o) => o.value === value) ?? NO_CATEGORY;

  return (
    <RuleGroupSelectRenderer
      label={label}
      options={options}
      value={selectedOption}
      onChange={(_e: unknown, newSelected: Option) =>
        setValue(newSelected?.value)
      }
      error={meta.touched && !!meta.error}
      helperText={meta.touched ? meta.error : undefined}
    />
  );
};
