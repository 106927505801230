import { MenuItem, Select } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import { ShiftPartTypeOption } from "../types";

type Props = React.ComponentProps<typeof Select> & {
  name: string;
  options: ShiftPartTypeOption[];
};

export default function SelectField({ name, options, ...props }: Props) {
  const {
    value,
    field: { onChange },
  } = useFormikState(name);

  return (
    <Select
      size="small"
      name={name}
      value={value}
      onChange={onChange}
      {...props}
    >
      {options.map((o: ShiftPartTypeOption) => (
        <MenuItem key={o.value} value={o.value}>
          {o.label}
        </MenuItem>
      ))}
    </Select>
  );
}
