import { UnreachableCaseError } from "ts-essentials";

import type { ActivityActivityType } from "../types";

import {
  ApiActivityPartPartTypeChoices,
  EditActivityQuery$data,
} from "./mutations/__generated__/EditActivityQuery.graphql";

export * from "./forms/types";
export * from "./mutations/types";
export * from "./validations/types";

export type ActivityPartTypeType = ApiActivityPartPartTypeChoices;

export const activityParts: ActivityPartTypeType[] = ["B", "Z", "P", "J", "M"];

export function TranslateActivityPartType(
  type?: ActivityPartTypeType,
): string | undefined {
  switch (type) {
    case "P":
      return "APT";
    case "Z":
      return "Ansvarstid";
    case "J":
      return "Jour (Bilaga J)";
    case "M":
      return "Jour (Allmänna Bestämmelser)";
    case "B":
      return "Hål";
    case undefined:
    case "%future added value":
      return undefined;
    default:
      throw new UnreachableCaseError(type);
  }
}

type NN<T> = NonNullable<T>;
type Activity = NN<EditActivityQuery$data>["activity"];
type ActivityPartsType = NN<Activity>["activityParts"];
type ActivityPartsEdges = NN<ActivityPartsType>["edges"];
type ActivityPart = NN<ActivityPartsEdges>[number];
export type ActivityPartNodeType = NN<ActivityPart>["node"];

export type ActivityPartFormValues = {
  id?: string;
  start: string;
  end: string;
  partType: ActivityPartTypeType;
};

export type ActivityFormValues = {
  id?: string;
  start: string;
  end: string;
  userId: string;
  teamId?: string;
  breakTime: number;
  activityType: ActivityActivityType;
  activityParts: ActivityPartFormValues[];
};
