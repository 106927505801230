import type { ComponentProps, FC, PropsWithChildren } from "react";
import { useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { Collapse, Stack } from "@mui/material";
import { FieldArray } from "formik";
import useFormikState from "hooks/useFormikState";
import type { FormikFieldArrayFn } from "types/formik";

import { CreateDemandsInput as FormValues, DemandWeeksInput } from "../types";

import { initialDemandDay } from "./constants";
import { DemandDaysForm } from "./DemandDaysForm";
import { DemandWeekCard } from "./DemandWeekCard";

type Props = { name: string };

type ItemProps = Omit<ComponentProps<typeof DemandWeekCard>, "onAdd"> & {
  name: string;
};

const WeekItem: FC<PropsWithChildren<ItemProps>> = ({ name, ...props }) => (
  <FieldArray name={`${name}.demands`}>
    {({ push: pushRow }: FormikFieldArrayFn<FormValues>) => (
      <DemandWeekCard
        {...props}
        name={name}
        onAdd={() => pushRow(initialDemandDay)}
      />
    )}
  </FieldArray>
);

export function DemandWeeksForm({ name }: Props) {
  const [openIdx, setOpenIdx] = useState<number | null>(null);
  const { value: demands } = useFormikState<DemandWeeksInput[]>(name);

  const toggleOpen = (idx: number) => {
    setOpenIdx(openIdx === idx ? null : idx);
  };

  return (
    <FieldArray name={name}>
      {({ remove }: FormikFieldArrayFn<FormValues>) => (
        <Stack gap={2} component={TransitionGroup}>
          {(demands || []).map((_, i) => (
            <Collapse key={i}>
              <WeekItem
                name={`${name}.${i}`}
                expanded={openIdx === i}
                onRemove={() => remove(i)}
                onExpand={() => toggleOpen(i)}
                children={<DemandDaysForm name={`${name}.${i}.demands`} />}
              />
            </Collapse>
          ))}
        </Stack>
      )}
    </FieldArray>
  );
}
