import { Suspense, useEffect, useMemo } from "react";
import type { PreloadedQuery } from "react-relay";
import { usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import type { CSSObject } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { connectionToArray, isNonNull } from "relay-help/arrays";
import { optimizationRuleUrl } from "utils/urls";

import { RuleGroupSettingsPlaceholder as Placeholder } from "components/loading/pages";
import { Level2Menu } from "components/setting/common";
import { menuItems, SettingBoxes } from "components/setting/rule_group";

import { RuleGroupSettingsQuery as Query } from "./types";

const query = graphql`
  query RuleGroupSettingsQuery($teamGroupId: ID!, $empCatId: ID!) {
    teamGroup(id: $teamGroupId) {
      setting {
        periodLengthWeeks
      }
    }
    shiftTypes(teamGroupId: $teamGroupId) {
      id
      name
      start
      end
      shiftParts {
        edges {
          node {
            id
          }
        }
      }
    }
    shifts(teamGroupId: $teamGroupId) {
      shifts {
        worktimeHours
      }
    }
    ruleGroup(id: $empCatId) {
      ruleGroupSetting {
        settingModules
        ...RuleGroupSettingBoxAB1_fragment
        ...RuleGroupSettingBoxAB2_fragment
        ...RuleGroupSettingBoxBase2_fragment
        ...RuleGroupSettingBoxBase7_fragment
        ...RuleGroupSettingBoxFree1_fragment
        ...RuleGroupSettingBoxRhythm2_fragment
        ...RuleGroupSettingBoxRhythm3_fragment
        ...RuleGroupSettingBoxTypes3_fragment
        ...RuleGroupSettingGroupAdvanced_fragment
      }
    }
  }
`;

type Props = {
  ruleId?: string;
  levelId: string;
  showAdvanced?: boolean;
  sx?: CSSObject;
};

type ContentProps = Props & {
  queryRef: PreloadedQuery<Query>;
};

function Content({
  ruleId,
  levelId,
  showAdvanced,
  queryRef,
  sx,
}: ContentProps) {
  const data = usePreloadedQuery<Query>(query, queryRef);
  const navigate = useNavigate();
  const { settingModules } = data.ruleGroup?.ruleGroupSetting || {};
  const shiftTypes = useMemo(
    () =>
      (data?.shiftTypes || []).filter(isNonNull).map((x) => ({
        ...x,
        shiftParts: connectionToArray(x.shiftParts),
      })),
    [data.shiftTypes],
  );

  function onSelect(section: string, item: string) {
    navigate(optimizationRuleUrl(levelId, `${section}-${item}`));
  }

  return (
    <>
      <Level2Menu
        id="setting-menu-employment-category"
        ruleId={ruleId || ""}
        constraintModules={(settingModules || []).slice()}
        onSelect={onSelect}
        menuItems={menuItems}
        showAdvanced={showAdvanced}
        sx={sx}
      />
      <SettingBoxes
        ruleId={ruleId}
        fragmentRef={data.ruleGroup?.ruleGroupSetting}
        periodLengthWeeks={data.teamGroup?.setting?.periodLengthWeeks || 0}
        shiftTypes={shiftTypes}
        shifts={(data?.shifts || []).flatMap((s) => s?.shifts || [])}
      />
    </>
  );
}

export function RuleGroupSettings({
  ruleId,
  levelId,
  showAdvanced,
  sx = {},
}: Props) {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const teamGroup = useCurrentTeamGroup();
  const teamGroupId = teamGroup?.id || "";
  const empCatId = levelId;

  useEffect(() => {
    if (!teamGroupId || !empCatId) return;
    loadQuery({ teamGroupId, empCatId });
  }, [loadQuery, teamGroupId, empCatId]);

  return (
    <Suspense fallback={<Placeholder />}>
      {!!queryRef && (
        <Content
          ruleId={ruleId}
          levelId={levelId}
          showAdvanced={showAdvanced}
          queryRef={queryRef}
          sx={sx}
        />
      )}
    </Suspense>
  );
}
