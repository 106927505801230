/**
 * @generated SignedSource<<c234d92749ac6276fd94dcf85f11a716>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApiShiftDayTypeChoices = "D" | "E" | "F" | "N" | "%future added value";
export type ApiUserSettingNightShiftRestrictionChoices = "L0" | "L1" | "L2" | "L3" | "N" | "%future added value";
export type ApiUserSettingNightShiftRotationChoices = "N" | "R1" | "R2" | "R3" | "%future added value";
export type UpdateUserSettingInput = {
  adminHoursMax?: number | null;
  adminHoursMin?: number | null;
  cadenceRestrictions?: ReadonlyArray<DayTypeCadenceRestrictionInput | null> | null;
  clientMutationId?: string | null;
  constraintModules?: ReadonlyArray<string> | null;
  currentTeamGroupId?: string | null;
  dayBreakAlternatives?: ReadonlyArray<number> | null;
  dayShiftDistributionShare?: number | null;
  eveningShiftDistributionShare?: number | null;
  freeEveryXOfYWeekends?: number | null;
  freeWeekendConstraintTypeEveryX?: boolean | null;
  freeWeekendConstraintTypeXOfY?: boolean | null;
  freeWeekendSoft?: boolean | null;
  fullDayShiftDistributionShare?: number | null;
  hoursPerWeek?: number | null;
  id: string;
  includeInDayTypeWeekendFairness?: boolean | null;
  maxConsecutiveFreeDays?: number | null;
  maxConsecutiveFreeDaysSoft?: boolean | null;
  maxConsecutiveWorkDays?: number | null;
  maxConsecutiveWorkDaysSoft?: boolean | null;
  minConsecutiveFreeDays?: number | null;
  minConsecutiveFreeDaysSoft?: boolean | null;
  minConsecutiveWorkDays?: number | null;
  minConsecutiveWorkDaysSoft?: boolean | null;
  nightShiftDistributionShare?: number | null;
  nightShiftRestriction?: ApiUserSettingNightShiftRestrictionChoices | null;
  nightShiftRotation?: ApiUserSettingNightShiftRotationChoices | null;
  prohibitedResourceWeekDays?: ReadonlyArray<number> | null;
  prohibitedShiftTypes?: ReadonlyArray<string | null> | null;
  resourceHoursMax?: number | null;
  resourceHoursMin?: number | null;
  shiftDayTypeAllowedErrorMargin?: number | null;
  shiftDayTypeDistributionSoft?: boolean | null;
  shiftsPerWeek?: number | null;
  weekBreakDaysAlternatives?: ReadonlyArray<number> | null;
  weekBreakHourAlternatives?: ReadonlyArray<number> | null;
  workDayCadenceRestrictions?: ReadonlyArray<WorkDayCadenceRestrictionInput> | null;
  workEveryUnfreeWeekend?: boolean | null;
  workEveryUnfreeWeekendSoft?: boolean | null;
  workEveryXWeekend?: number | null;
};
export type WorkDayCadenceRestrictionInput = {
  calendarDays: number;
  id?: string | null;
  isSoft: boolean;
  maxCadence?: number | null;
};
export type DayTypeCadenceRestrictionInput = {
  calendarDays: number;
  dayType: ApiShiftDayTypeChoices;
  id?: string | null;
  isSoft: boolean;
  maxCadence?: number | null;
};
export type UpdateUserSettingBoxAB1Mutation$variables = {
  input: UpdateUserSettingInput;
};
export type UpdateUserSettingBoxAB1Mutation$data = {
  readonly updateUserSetting: {
    readonly ok: boolean | null;
    readonly userSetting: {
      readonly " $fragmentSpreads": FragmentRefs<"UserSettingBoxAB1_fragment">;
    } | null;
  } | null;
};
export type UpdateUserSettingBoxAB1Mutation = {
  response: UpdateUserSettingBoxAB1Mutation$data;
  variables: UpdateUserSettingBoxAB1Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateUserSettingBoxAB1Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserSettingPayload",
        "kind": "LinkedField",
        "name": "updateUserSetting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserSettingNode",
            "kind": "LinkedField",
            "name": "userSetting",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserSettingBoxAB1_fragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateUserSettingBoxAB1Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserSettingPayload",
        "kind": "LinkedField",
        "name": "updateUserSetting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserSettingNode",
            "kind": "LinkedField",
            "name": "userSetting",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dayBreakAlternatives",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "settingModules",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e5d1af76041e078aba12d8b7ed09a291",
    "id": null,
    "metadata": {},
    "name": "UpdateUserSettingBoxAB1Mutation",
    "operationKind": "mutation",
    "text": "mutation UpdateUserSettingBoxAB1Mutation(\n  $input: UpdateUserSettingInput!\n) {\n  updateUserSetting(input: $input) {\n    ok\n    userSetting {\n      ...UserSettingBoxAB1_fragment\n      id\n    }\n  }\n}\n\nfragment UserSettingBoxAB1_fragment on UserSettingNode {\n  id\n  dayBreakAlternatives\n  settingModules\n  user {\n    fullName\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "dbdcd3bb6e664105a008470aab6bac8e";

export default node;
