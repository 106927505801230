import useFormikState from "hooks/useFormikState";

import { useWeeks } from "components/demand/form";

import { CardWithIndices, Chips } from "./CardWithIndices";

type Props = Omit<React.ComponentProps<typeof CardWithIndices>, "chips"> & {
  checked: number[];
  onToggle: (idx: number) => void;
  weekCount: number;
};

type WeekChipsProps = Omit<React.ComponentProps<typeof Chips>, "options"> & {
  weekCount: number;
};

type FormikProps = Omit<Props, "checked" | "onToggle"> & {
  name: string;
};

function WeekChips({ weekCount, ...props }: WeekChipsProps) {
  const weeks = useWeeks(weekCount);
  return <Chips {...props} options={weeks} />;
}

function WeekCard({ checked, weekCount, onToggle, ...props }: Props) {
  return (
    <CardWithIndices
      {...props}
      chips={
        <WeekChips
          checked={checked}
          onToggle={onToggle}
          weekCount={weekCount}
        />
      }
    />
  );
}

export function FormikWeekCard({ name, ...props }: FormikProps) {
  const { value, setValue } = useFormikState<number[]>(name);
  function onToggle(idx: number) {
    const checked = (value || []).includes(idx);
    if (checked) {
      setValue(value.filter((x) => x !== idx));
    } else {
      setValue([...value, idx]);
    }
  }
  return <WeekCard {...props} checked={value || []} onToggle={onToggle} />;
}
