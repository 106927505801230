/**
 * @generated SignedSource<<b447e9aad8b79376509d34d695947a55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSettingBoxAB2_fragment$data = {
  readonly id: string;
  readonly settingModules: ReadonlyArray<string>;
  readonly user: {
    readonly fullName: string;
  };
  readonly weekBreakDaysAlternatives: ReadonlyArray<number>;
  readonly weekBreakHourAlternatives: ReadonlyArray<number>;
  readonly " $fragmentType": "UserSettingBoxAB2_fragment";
};
export type UserSettingBoxAB2_fragment$key = {
  readonly " $data"?: UserSettingBoxAB2_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSettingBoxAB2_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserSettingBoxAB2_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekBreakHourAlternatives",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekBreakDaysAlternatives",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserSettingNode",
  "abstractKey": null
};

(node as any).hash = "5c2d639259e130746476759d188424dc";

export default node;
