/**
 * @generated SignedSource<<c8ae9e29206a393f89d54b75e8cf2047>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingShiftsEmploymentDegreeLogicChoices = "E" | "O" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SettingParttimes_fragment$data = {
  readonly hoursEmploymentDegreeFactor: number;
  readonly hoursPerWeek: number;
  readonly id: string;
  readonly periodLengthWeeks: number;
  readonly shiftsEmploymentDegreeFactor: number;
  readonly shiftsEmploymentDegreeLogic: ApiSettingShiftsEmploymentDegreeLogicChoices;
  readonly shiftsPerWeek: number;
  readonly totalHoursMargin: number;
  readonly totalShiftsMargin: number;
  readonly " $fragmentType": "SettingParttimes_fragment";
};
export type SettingParttimes_fragment$key = {
  readonly " $data"?: SettingParttimes_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingParttimes_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingParttimes_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursEmploymentDegreeFactor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsEmploymentDegreeFactor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsEmploymentDegreeLogic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalHoursMargin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalShiftsMargin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodLengthWeeks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsPerWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursPerWeek",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "ed10e66c32bc6422df5b06ec6f792c3e";

export default node;
