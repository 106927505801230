import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {
  refresh as refreshPageQuery,
  usePageQuery,
} from "contexts/PageQueryContext";

import { Me_me$data as Data, Me_me$key as Key } from "./types";

const fragment = graphql`
  fragment Me_me on UserNode {
    id
    fullName
    email
    username
    isSuperuser
    memberOf {
      edges {
        node {
          id
        }
      }
    }
    groupMemberOf {
      edges {
        node {
          id
        }
      }
    }
    teamAdminOf {
      edges {
        node {
          id
        }
      }
    }
    groupAdminOf {
      edges {
        node {
          id
        }
      }
    }
  }
`;

/**
 * Returns the currently logged in user, or null otherwise.
 */
export const useMe = () => {
  const query = usePageQuery();
  return useFragment<Key>(fragment, query.me);
};

/**
 * Returns the currently logged in user, or throw an error otherwise.
 */
export const useRequireMe = (): Data => {
  const data = useMe();

  if (!data) {
    throw new Error(
      "useRequireMe must be used within PageQueryProvider context",
    );
  }

  return data;
};

export const refresh = async () => refreshPageQuery();
