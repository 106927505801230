import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { dayTypeDistributionWeightChoices } from "./constants";
import { Types6Form } from "./forms";
import { UpdateSettingBoxTypes6 } from "./mutations";
import type {
  SettingBoxTypes6_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment SettingBoxTypes6_fragment on SettingNode {
    id
    optimizeDayTypeFairness
    optimizeDayTypeFairnessWeekend
    dayTypeFairnessWeight
    constraintModules
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string(),
  optimizeDayTypeFairness: yup.boolean().required(),
  optimizeDayTypeFairnessWeekend: yup.boolean().required(),
  dayTypeFairnessWeight: yup
    .string()
    .oneOf(dayTypeDistributionWeightChoices)
    .required(),
  constraintModules: yup.array().of(yup.string()).required(),
});

type Props = {
  fragmentRef: Key;
};

function FormContainer({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const teamGroup = useCurrentTeamGroup();
  const data = useFragment<Key>(fragment, fragmentRef);

  const initialValues: FormValues = {
    id: data?.id || "",
    optimizeDayTypeFairness: data?.optimizeDayTypeFairness || false,
    optimizeDayTypeFairnessWeekend:
      data?.optimizeDayTypeFairnessWeekend || false,
    dayTypeFairnessWeight: data?.dayTypeFairnessWeight || "M",
    constraintModules: data?.constraintModules.slice() || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateSettingBoxTypes6(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Types6Form groupName={teamGroup?.name || ""} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxTypes6({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <FormContainer fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
