import { useCallback, useMemo } from "react";
import { useField } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";

import GenericSelect from "../GenericSelect";

import type { Opt } from "./types";

type BaseProps = {
  name: string;
  label?: string;
  multi?: boolean;
  disableCloseOnSelect?: boolean;
  showSelectAll?: boolean;
  teamGroup: ReturnType<typeof useCurrentTeamGroup>;
  options: Opt[];
};

export function BaseUserSelect({
  name,
  label,
  teamGroup,
  options,
  multi,
  disableCloseOnSelect,
  showSelectAll,
}: BaseProps) {
  const [, meta, helpers] = useField<string | string[] | null>(name);
  const { value } = meta;
  const { setValue } = helpers;

  const singleSelected = useMemo<Opt | null>(
    () => options.find((o) => value === o.value) || null,
    [options, value],
  );
  const multiSelected = useMemo<Opt[]>(
    () => options.filter((o) => (value as string[]).includes(o.value)),
    [options, value],
  );

  const noOptionsText = useMemo(
    () => `Inga personer finns tillagda i teamgrupp ${teamGroup?.name}`,
    [teamGroup],
  );

  const placeholder = useMemo<string>(
    () =>
      (multi && multiSelected.length) || singleSelected
        ? `Välj person${multi ? "er" : ""}...`
        : "",
    [multi, multiSelected, singleSelected],
  );

  const getOptionLabel = useCallback((option: Opt) => option.label || "", []);

  const error = useMemo<boolean>(
    () => !!meta.error && meta.touched,
    [meta.error, meta.touched],
  );
  const helperText = useMemo<string | undefined>(
    () => (meta.touched ? meta.error : undefined),
    [meta.error, meta.touched],
  );

  function changeOne(_e: any, newValue: Opt | null) {
    setValue(newValue?.value || "");
  }

  const selectMany = useCallback(
    (options: Opt[]) => {
      setValue(options.map((o) => o.value));
    },
    [setValue],
  );
  function changeMany(_e: any, newValue: Opt[]) {
    selectMany(newValue);
  }
  function onSelectAll() {
    selectMany(options);
  }

  const props = useMemo(
    () => ({
      options,
      noOptionsText,
      getOptionLabel,
      limitTags: 2,
      error,
      helperText,
      placeholder,
      disableCloseOnSelect,
    }),
    [
      options,
      noOptionsText,
      getOptionLabel,
      error,
      helperText,
      placeholder,
      disableCloseOnSelect,
    ],
  );

  if (multi) {
    return (
      <GenericSelect<Opt>
        {...props}
        multiple
        label={label}
        value={multiSelected}
        onChange={changeMany}
        onSelectAll={showSelectAll ? onSelectAll : undefined}
      />
    );
  }

  return (
    <GenericSelect<Opt>
      {...props}
      label={label}
      value={singleSelected ?? undefined}
      onChange={changeOne}
    />
  );
}
