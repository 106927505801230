/**
 * @generated SignedSource<<d39c81958147588dcb211a9f7d30d372>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSettingBoxTypes6_fragment$data = {
  readonly id: string;
  readonly includeInDayTypeWeekendFairness: boolean;
  readonly settingModules: ReadonlyArray<string>;
  readonly user: {
    readonly fullName: string;
  };
  readonly " $fragmentType": "UserSettingBoxTypes6_fragment";
};
export type UserSettingBoxTypes6_fragment$key = {
  readonly " $data"?: UserSettingBoxTypes6_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSettingBoxTypes6_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserSettingBoxTypes6_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "includeInDayTypeWeekendFairness",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserSettingNode",
  "abstractKey": null
};

(node as any).hash = "e62ec8993abc8440342517bc84acefda";

export default node;
