import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { UpdateSettingRestBetweenShiftsMutation as Mutation } from "./types";

const mutation = graphql`
  mutation UpdateSettingRestBetweenShiftsMutation($input: UpdateSettingInput!) {
    updateSetting(input: $input) {
      ok
      setting {
        ...SettingRestBetweenShifts_fragment
      }
    }
  }
`;

export const useUpdateSettingRestBetweenShifts = () =>
  useMutation<Mutation>(mutation);
