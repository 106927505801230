import {
  type ApiBlockedTimeSlotTimeslotTypeChoices,
  BlockedTimeSlotType,
} from "components/blocked_time_slot/types";

import type { RecurrenceRuleType } from "../types";

import {
  adjustLocalToUtc,
  adjustUtcToLocal,
  fromRfc2445String,
  toRfc2445String,
} from "./Rfc2445Converter";

export const nthOptions = [
  { value: 1, label: "Första" },
  { value: 2, label: "Andra" },
  { value: 3, label: "Tredje" },
  { value: 4, label: "Fjärde" },
  { value: -1, label: "Sista" },
  { value: -2, label: "Näst sista" },
  { value: -3, label: "Tredje sista" },
];

export function convertRecurrencesDtstart(
  recurrences: RecurrenceRuleType[],
  newStart: Date,
) {
  const newRecurrences: RecurrenceRuleType[] = recurrences.map((recurrence) => {
    return {
      ...recurrence,
      dtstart: newStart,
    };
  });
  return newRecurrences;
}

export function prepareBeforeForm(rfc2445: string): RecurrenceRuleType[] {
  const rules = fromRfc2445String(rfc2445);
  const adjustedRules = rules.map(adjustUtcToLocal);
  return adjustedRules;
}

type PrepareBeforeSubmitArgs = {
  timeslotType: ApiBlockedTimeSlotTimeslotTypeChoices;
  start?: string | null;
  end?: string | null;
  recurrences: RecurrenceRuleType[] | null;
  shiftId?: string | null;
  shiftDate?: string | null;
};
export function prepareBeforeSubmit({
  timeslotType,
  start,
  end,
  recurrences,
  shiftId,
  shiftDate,
}: PrepareBeforeSubmitArgs) {
  const isFixedShift = timeslotType === BlockedTimeSlotType.FIXED_SHIFT;
  const newStart = isFixedShift ? null : start;
  const newRecurrences =
    recurrences && newStart
      ? prepareRecurrencesBeforeSubmit(recurrences, newStart)
      : null;
  return {
    start: newStart,
    end: isFixedShift ? null : end,
    recurrences: newRecurrences,
    shiftId: isFixedShift ? shiftId : null,
    shiftDate: isFixedShift ? shiftDate : null,
  };
}

function prepareRecurrencesBeforeSubmit(
  rules: RecurrenceRuleType[],
  start: string,
): string {
  const startDate = new Date(start);
  const adjustedRules = rules
    .map((rule) => setTimeForRDates(rule, startDate))
    .map(adjustLocalToUtc);
  const rfc2445 = toRfc2445String(adjustedRules);
  return rfc2445;
}

// Recurrence dates have their time set to midnight local time,
// but we want them to occur at the user-specified time.
function setTimeForRDates(
  rule: RecurrenceRuleType,
  startDate: Date,
): RecurrenceRuleType {
  if (!rule.isDate) {
    return rule;
  }

  rule.dtstart?.setHours(startDate.getHours());
  rule.dtstart?.setMinutes(startDate.getMinutes());
  rule.dtstart?.setSeconds(startDate.getSeconds());
  return rule;
}
