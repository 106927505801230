/**
 * @generated SignedSource<<184b85517d7792e3962fda51da58f81e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingDayTypeFairnessWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SettingBoxTypes6_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly dayTypeFairnessWeight: ApiSettingDayTypeFairnessWeightChoices;
  readonly id: string;
  readonly optimizeDayTypeFairness: boolean;
  readonly optimizeDayTypeFairnessWeekend: boolean;
  readonly " $fragmentType": "SettingBoxTypes6_fragment";
};
export type SettingBoxTypes6_fragment$key = {
  readonly " $data"?: SettingBoxTypes6_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingBoxTypes6_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingBoxTypes6_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeDayTypeFairness",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeDayTypeFairnessWeekend",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayTypeFairnessWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "be3340b47e29153e37c9864c35eddbb3";

export default node;
