import { useMemo } from "react";

import {
  shiftDayTypes,
  TranslateShiftDayType,
  TranslateShiftType,
} from "components/shifts/types";

import type {
  ShiftTimeline_fragment$data as Data,
  ShiftTimelineItem,
} from "./types";

function resolveItemProps(item: ShiftTimelineItem) {
  let className = "";
  if (item?.shiftType === "Z") {
    className = "rct-shift-work";
  } else if (item?.shiftType === "P") {
    className = "rct-shift-apt";
  } else if (item?.shiftType === "J") {
    className = "rct-shift-bilagaj";
  } else if (item?.shiftType === "M") {
    className = "rct-shift-abjour";
  } else {
    className = "rct-shift-pass";
  }
  switch (item?.dayType) {
    case shiftDayTypes.DAY:
      className += "-day";
      break;
    case shiftDayTypes.EVENING:
      className += "-evening";
      break;
    case shiftDayTypes.NIGHT:
      className += "-night";
      break;
    case shiftDayTypes.FULL_DAY:
      className += "-full";
      break;
    default:
      break;
  }
  return {
    className,
  };
}

function convertActivity(a: ShiftTimelineItem, i: number) {
  return {
    id: i,
    group: a?.shiftId || "",
    title: TranslateShiftType(a?.shiftType),
    day_type_title: TranslateShiftDayType(a?.dayType),
    day_type: a?.dayType,
    start_time: Date.parse(a?.startTime || ""),
    end_time: Date.parse(a?.endTime || ""),
    break_time: a?.breakTime || 0,
    itemProps: resolveItemProps(a),
  };
}

export function useShiftTimeline(data: Data) {
  const items = useMemo(
    () => (data.shifts || []).map((a: any, i: number) => convertActivity(a, i)),
    [data],
  );

  const groups = useMemo(() => {
    const groups = (data?.shiftTypes || []).map((d: any) => ({
      id: d?.id,
      title: d?.name,
    }));
    return groups;
  }, [data]);

  return useMemo(() => ({ groups, items }), [groups, items]);
}
