import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import type { IEnvironment } from "relay-runtime";

import type {
  UpdateRuleGroupSettingBoxBase7Mutation as Mutation,
  UpdateRuleGroupSettingBoxBase7Mutation$variables as Variables,
} from "./types";

const mutation = graphql`
  mutation UpdateRuleGroupSettingBoxBase7Mutation(
    $input: UpdateRuleGroupSettingInput!
  ) {
    updateRuleGroupSetting(input: $input) {
      ok
      ruleGroupSetting {
        ...RuleGroupSettingBoxBase7_fragment
      }
    }
  }
`;

export async function UpdateRuleGroupSettingBoxBase7(
  environment: IEnvironment,
  variables: Variables,
) {
  return commitMutation<Mutation>(environment, {
    mutation,
    variables,
  });
}
