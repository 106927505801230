/**
 * @generated SignedSource<<adada087404b16708ac9f3dff3427cde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RuleGroupSettingBoxRhythm2_fragment$data = {
  readonly id: string;
  readonly maxConsecutiveFreeDays: number;
  readonly maxConsecutiveFreeDaysSoft: boolean;
  readonly minConsecutiveFreeDays: number;
  readonly minConsecutiveFreeDaysSoft: boolean;
  readonly ruleGroup: {
    readonly name: string;
  };
  readonly settingModules: ReadonlyArray<string>;
  readonly " $fragmentType": "RuleGroupSettingBoxRhythm2_fragment";
};
export type RuleGroupSettingBoxRhythm2_fragment$key = {
  readonly " $data"?: RuleGroupSettingBoxRhythm2_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RuleGroupSettingBoxRhythm2_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RuleGroupSettingBoxRhythm2_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveFreeDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveFreeDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveFreeDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveFreeDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RuleGroupNode",
      "kind": "LinkedField",
      "name": "ruleGroup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RuleGroupSettingNode",
  "abstractKey": null
};

(node as any).hash = "e8992ca6b1eaf1b7b312b1b2461bd9eb";

export default node;
