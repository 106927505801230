import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { UpdateRuleGroupSettingParttimesMutation as Mutation } from "./types";

const mutation = graphql`
  mutation UpdateRuleGroupSettingParttimesMutation(
    $input: UpdateRuleGroupSettingInput!
  ) {
    updateRuleGroupSetting(input: $input) {
      ok
      ruleGroupSetting {
        ...RuleGroupSettingParttimes_fragment
      }
    }
  }
`;

export const useUpdateRuleGroupSettingParttimes = () =>
  useMutation<Mutation>(mutation);
