export * from "./Base5";
export * from "./DayEveNightFields";
export * from "./types";

export const base5DefaultValues = {
  id: "",
  morningStartsNightEnds: "07:00",
  eveningStartsMorningEnds: "16:00",
  nightStartsEveningEnds: "21:00",
};
