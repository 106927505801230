import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import type { IEnvironment } from "relay-runtime";

import type {
  UpdateUserSettingBoxWorktime2Mutation as Mutation,
  UpdateUserSettingBoxWorktime2Mutation$variables as Variables,
} from "./types";

const mutation = graphql`
  mutation UpdateUserSettingBoxWorktime2Mutation(
    $input: UpdateUserSettingInput!
  ) {
    updateUserSetting(input: $input) {
      ok
      userSetting {
        ...UserSettingBoxWorktime2_fragment
      }
    }
  }
`;

export async function UpdateUserSettingBoxWorktime2(
  environment: IEnvironment,
  variables: Variables,
) {
  return commitMutation<Mutation>(environment, {
    mutation,
    variables,
  });
}
