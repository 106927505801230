/**
 * @generated SignedSource<<17c7179fa2e74cc67dcc711728c824ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingShiftTypeDistributionWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SettingBoxTypes3_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly id: string;
  readonly optimizeEvenShiftTypeDistributionBetweenEmployees: boolean;
  readonly periodLengthWeeks: number;
  readonly prohibitedShiftTypes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly shiftTypeDistributionWeight: ApiSettingShiftTypeDistributionWeightChoices;
  readonly shiftsPerWeek: number;
  readonly " $fragmentType": "SettingBoxTypes3_fragment";
};
export type SettingBoxTypes3_fragment$key = {
  readonly " $data"?: SettingBoxTypes3_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingBoxTypes3_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingBoxTypes3_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsPerWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodLengthWeeks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeEvenShiftTypeDistributionBetweenEmployees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftTypeDistributionWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftNodeConnection",
      "kind": "LinkedField",
      "name": "prohibitedShiftTypes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ShiftNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShiftNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};
})();

(node as any).hash = "fcb2eb2e273630f5fe5afbf7c788f03b";

export default node;
