import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { connectionToArray, ExtractNode } from "relay-help/arrays";
import * as yup from "yup";

import { AutoSubmit } from "components/setting/common";
import { ShiftType } from "components/setting/types";
import { useSnackbar } from "components/Snackbar";

import { timebankModeChoices } from "./constants";
import { TimebankForm } from "./forms";
import { useUpdateTimebankMutation } from "./mutations";
import type {
  Timebank_fragment$data as Data,
  Timebank_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

type Props = {
  fragmentRef: Key;
  shiftTypes: ReadonlyArray<ShiftType>;
};

const fragment = graphql`
  fragment Timebank_fragment on SettingNode {
    id
    timebankMode
    offsetTimebankImbalance
    maxTimebankPeriod
    minTimebankPeriod
    maxTimebankTotal
    minTimebankTotal
    shiftsLaterStartNotOk {
      edges {
        node {
          id
          name
        }
      }
    }
    shiftsLaterEndNotOk {
      edges {
        node {
          id
          name
        }
      }
    }
    shiftsEarlierStartNotOk {
      edges {
        node {
          id
          name
        }
      }
    }
    shiftsEarlierEndNotOk {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

type ShiftEarlierEndNotOk = ExtractNode<Data["shiftsEarlierEndNotOk"]>;
type ShiftEarlierStartNotOk = ExtractNode<Data["shiftsEarlierStartNotOk"]>;
type ShiftLaterEndNotOk = ExtractNode<Data["shiftsLaterEndNotOk"]>;
type ShiftLaterStartNotOk = ExtractNode<Data["shiftsLaterStartNotOk"]>;

const validationSchema = yup.object().shape({
  id: yup.string().required("Får ej vara tomt"),
  timebankMode: yup.string().oneOf(timebankModeChoices).required(),
  maxTimebankPeriod: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(168, "Måste vara högst 168 timmar")
    .required("Får ej vara tomt"),
  minTimebankPeriod: yup
    .number()
    .min(-168, "Måste vara minst -168 timmar")
    .max(0, "Måste vara högst 0")
    .required("Får ej vara tomt"),
  maxTimebankTotal: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(168, "Måste vara högst 168 timmar")
    .required("Får ej vara tomt"),
  minTimebankTotal: yup
    .number()
    .min(-168, "Måste vara minst -168 timmar")
    .max(0, "Måste vara högst 0")
    .required("Får ej vara tomt"),
  offsetTimebankImbalance: yup.boolean().required(),
  shiftsLaterStartNotOk: yup.array().of(yup.string()).required("Måste anges"),
  shiftsLaterEndNotOk: yup.array().of(yup.string()).required("Måste anges"),
  shiftsEarlierStartNotOk: yup.array().of(yup.string()).required("Måste anges"),
  shiftsEarlierEndNotOk: yup.array().of(yup.string()).required("Måste anges"),
});

export function Timebank({ fragmentRef, shiftTypes }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useUpdateTimebankMutation();
  const { addSnack } = useSnackbar();

  const initialValues = useMemo<FormValues>(
    () => ({
      id: data.id,
      timebankMode: data.timebankMode,
      maxTimebankPeriod: data.maxTimebankPeriod,
      minTimebankPeriod: data.minTimebankPeriod,
      maxTimebankTotal: data.maxTimebankTotal,
      minTimebankTotal: data.minTimebankTotal,
      offsetTimebankImbalance: data.offsetTimebankImbalance,
      shiftsLaterStartNotOk: connectionToArray<ShiftLaterStartNotOk>(
        data.shiftsLaterStartNotOk,
      ).map((s) => s.id),
      shiftsLaterEndNotOk: connectionToArray<ShiftLaterEndNotOk>(
        data.shiftsLaterEndNotOk,
      ).map((s) => s.id),
      shiftsEarlierStartNotOk: connectionToArray<ShiftEarlierStartNotOk>(
        data.shiftsEarlierStartNotOk,
      ).map((s) => s.id),
      shiftsEarlierEndNotOk: connectionToArray<ShiftEarlierEndNotOk>(
        data.shiftsEarlierEndNotOk,
      ).map((s) => s.id),
    }),
    [data],
  );

  const onSubmit = useCallback(
    async (input: FormValues) => {
      await commit({ variables: { input } }).catch(() => {
        addSnack({ severity: "error", message: "Uppdatering misslyckades" });
      });
    },
    [commit, addSnack],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <TimebankForm shiftTypes={shiftTypes} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}
