import { Suspense, useEffect, useMemo } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from "react-relay";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useTeamGroups } from "contexts/TeamGroupsContext";
import { Form as FormikForm, Formik, useFormikContext } from "formik";
import { commitMutation } from "libs/commitMutation";
import { connectionToArray } from "relay-help/arrays";

import { DialogTitle } from "components/common/Dialog";
import { TeamSelectFragmentRefProvider } from "components/common/selectors/TeamSelect/TeamSelectFragmentRefContext";
import { TeamAccordionContent } from "components/forms/UserForm/TeamAccordion";
import type { MemberTeamGroupType } from "components/organisation/types";

import { UserAdminFormMutation } from "./__generated__/UserAdminFormMutation.graphql";
import { UserAdminFormQuery } from "./__generated__/UserAdminFormQuery.graphql";

const query = graphql`
  query UserAdminFormQuery($id: ID!) {
    user(id: $id) {
      id
      fullName
      groupAdminOf {
        edges {
          node {
            id
          }
        }
      }
      teamAdminOf {
        edges {
          node {
            id
          }
        }
      }
    }
    teams {
      ...TeamSelect_fragment
    }
  }
`;

const mutation = graphql`
  mutation UserAdminFormMutation(
    $userId: ID!
    $groupIds: [ID!]!
    $teamIds: [ID!]!
  ) {
    setUserAdminOf(
      input: { userId: $userId, groupAdminOf: $groupIds, teamAdminOf: $teamIds }
    ) {
      ok
      user {
        id
        groupAdminOf {
          edges {
            node {
              id
            }
          }
        }
        teamAdminOf {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

type FormValues = {
  userId: string;
  groupIds: string[];
  teamIds: string[];
};

type TitleProps = {
  fullName: string;
};
type ContentProps = {
  teamGroups: MemberTeamGroupType[];
};
type FooterProps = {
  onClose: () => void;
};

type FormProps = {
  open: boolean;
  onClose: () => void;
  queryRef: PreloadedQuery<UserAdminFormQuery>;
  teamGroups: MemberTeamGroupType[];
};

type Props = {
  userId: string | null;
  onClose: () => void;
};

function Title({ fullName }: TitleProps) {
  return <DialogTitle title={`Administratörsstatus för ${fullName}`} />;
}

function Content({ teamGroups }: ContentProps) {
  return (
    <DialogContent>
      <Stack py={2}>
        <TeamAccordionContent
          teamGroups={teamGroups}
          groupsField="groupIds"
          teamsField="teamIds"
        />
      </Stack>
    </DialogContent>
  );
}

function Footer({ onClose }: FooterProps) {
  const { isSubmitting } = useFormikContext<FormValues>();
  return (
    <DialogActions>
      <Stack direction="row" gap={2}>
        <Button variant="cancel" onClick={onClose}>
          Avbryt
        </Button>
        <Button variant="primary" type="submit" disabled={isSubmitting}>
          Spara
        </Button>
      </Stack>
    </DialogActions>
  );
}

function Form({ queryRef, teamGroups, open, onClose }: FormProps) {
  const environment = useRelayEnvironment();
  const { user, teams } = usePreloadedQuery<UserAdminFormQuery>(
    query,
    queryRef,
  );
  const { id = "", fullName = "", groupAdminOf, teamAdminOf } = user || {};
  const groupIds = useMemo(
    () => connectionToArray(groupAdminOf).map((g) => g.id),
    [groupAdminOf],
  );
  const teamIds = useMemo(
    () => connectionToArray(teamAdminOf).map((g) => g.id),
    [teamAdminOf],
  );
  const initialValues: FormValues = {
    userId: id,
    groupIds,
    teamIds,
  };

  async function onSubmit(variables: FormValues) {
    await commitMutation<UserAdminFormMutation>(environment, {
      mutation,
      variables,
    })
      .then(onClose)
      .catch(console.error);
  }

  return (
    <TeamSelectFragmentRefProvider value={teams}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
          <FormikForm autoComplete="off">
            <Title fullName={fullName} />
            <Divider />
            <Content teamGroups={teamGroups} />
            <Divider />
            <Footer onClose={onClose} />
          </FormikForm>
        </Dialog>
      </Formik>
    </TeamSelectFragmentRefProvider>
  );
}

export function UserAdminForm({ userId, onClose }: Props) {
  const [queryRef, loadQuery, disposeQuery] =
    useQueryLoader<UserAdminFormQuery>(query);
  const { teamGroupOptions } = useTeamGroups();

  useEffect(() => {
    function dispose() {
      if (!disposeQuery) return;
      disposeQuery();
    }

    if (!userId) return dispose;

    loadQuery({ id: userId }, { fetchPolicy: "network-only" });

    return dispose;
  }, [userId, loadQuery, disposeQuery]);

  return (
    <Suspense fallback={null}>
      {!!userId && !!queryRef && (
        <Form
          open={!!userId}
          onClose={onClose}
          queryRef={queryRef}
          teamGroups={teamGroupOptions}
        />
      )}
    </Suspense>
  );
}
