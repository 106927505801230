/**
 * @generated SignedSource<<3bc380dad796be2448ebcb35be4d3d55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RuleGroupSettingBoxAB1_fragment$data = {
  readonly dayBreakAlternatives: ReadonlyArray<number>;
  readonly id: string;
  readonly ruleGroup: {
    readonly name: string;
  };
  readonly settingModules: ReadonlyArray<string>;
  readonly " $fragmentType": "RuleGroupSettingBoxAB1_fragment";
};
export type RuleGroupSettingBoxAB1_fragment$key = {
  readonly " $data"?: RuleGroupSettingBoxAB1_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RuleGroupSettingBoxAB1_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RuleGroupSettingBoxAB1_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayBreakAlternatives",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RuleGroupNode",
      "kind": "LinkedField",
      "name": "ruleGroup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RuleGroupSettingNode",
  "abstractKey": null
};

(node as any).hash = "1cd8d0f66100bd6df183d4fc50bd49be";

export default node;
