import { Suspense } from "react";

import { Visible } from "components/common/Visible";
import { SettingLevel3Placeholder } from "components/loading/pages";

import { Level3Box } from "../../common/Level3Box";

import { UserSettingBoxAB1 } from "./UserSettingBoxAB1";
import { UserSettingBoxAB2 } from "./UserSettingBoxAB2";
import { UserSettingBoxBase2 } from "./UserSettingBoxBase2";
import { UserSettingBoxFree1 } from "./UserSettingBoxFree1";
import { UserSettingBoxRhythm2 } from "./UserSettingBoxRhythm2";
import { UserSettingBoxRhythm3 } from "./UserSettingBoxRhythm3";
import { UserSettingBoxTypes3 } from "./UserSettingBoxTypes3";
import { UserSettingBoxTypes5 } from "./UserSettingBoxTypes5";
import { UserSettingBoxTypes6 } from "./UserSettingBoxTypes6";
import { UserSettingBoxTypes7 } from "./UserSettingBoxTypes7";
import { UserSettingBoxWorktime1 } from "./UserSettingBoxWorktime1";
import { UserSettingBoxWorktime2 } from "./UserSettingBoxWorktime2";
import { UserSettingGroupAdvanced } from "./UserSettingGroupAdvanced";

type Props = {
  ruleId: string;
  periodLengthWeeks: number;
  fragmentRef: any;
  shiftTypes: any[];
};

export function SettingBoxes({
  ruleId = "",
  fragmentRef,
  periodLengthWeeks,
  shiftTypes,
}: Props) {
  return (
    <Suspense fallback={<SettingLevel3Placeholder />}>
      <Level3Box>
        <Visible visible={ruleId === "ab-0"}>
          <UserSettingBoxAB1 fragmentRef={fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "ab-1"}>
          <UserSettingBoxAB2 fragmentRef={fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "d-1"}>
          <UserSettingBoxBase2
            fragmentRef={fragmentRef}
            periodLengthWeeks={periodLengthWeeks}
          />
        </Visible>
        <Visible visible={ruleId === "w-0"}>
          <UserSettingBoxFree1
            fragmentRef={fragmentRef}
            periodLengthWeeks={periodLengthWeeks}
          />
        </Visible>
        <Visible visible={ruleId === "r-1"}>
          <UserSettingBoxRhythm2 fragmentRef={fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "r-2"}>
          <UserSettingBoxRhythm3 fragmentRef={fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "s-2"}>
          <UserSettingBoxTypes3
            shiftTypes={shiftTypes}
            fragmentRef={fragmentRef}
          />
        </Visible>
        <Visible visible={ruleId === "n-0"}>
          <UserSettingBoxTypes5 fragmentRef={fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "n-1"}>
          <UserSettingBoxTypes6 fragmentRef={fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "n-2"}>
          <UserSettingBoxTypes7 fragmentRef={fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "t-0"}>
          <UserSettingBoxWorktime1 fragmentRef={fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "t-1"}>
          <UserSettingBoxWorktime2 fragmentRef={fragmentRef} />
        </Visible>
        <UserSettingGroupAdvanced fragmentRef={fragmentRef} ruleId={ruleId} />
      </Level3Box>
    </Suspense>
  );
}
