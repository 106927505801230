import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import { Visible } from "components/common/Visible";

import type { UserSettingGroupAdvanced_fragment$key as Key } from "./types";
import { UserSettingNightShifts as NightShifts } from "./UserSettingNightShifts";

type Props = {
  fragmentRef: Key;
  ruleId: string;
};

const fragment = graphql`
  fragment UserSettingGroupAdvanced_fragment on UserSettingNode {
    ...UserSettingNightShifts_fragment
  }
`;

export function UserSettingGroupAdvanced({ fragmentRef, ruleId }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);

  return (
    <>
      <Visible visible={ruleId === "advanced-6"}>
        <NightShifts fragmentRef={data} />
      </Visible>
    </>
  );
}
