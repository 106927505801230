import { useCallback, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
} from "@mui/material";
import { Form as FormikForm, Formik, useFormikContext } from "formik";

import { DialogTitle } from "components/common/Dialog";
import {
  CompetencesAccordion,
  EmploymentAccordion,
  NameAccordion,
  RuleGroupsAccordion,
  TeamAccordion,
  TimebankAccordion,
} from "components/forms/UserForm";
import type { CreateUserInput, EditUserInput } from "components/organisation";

import type {
  AdminCreateUserInput,
  AdminEditUserInput,
  TeamGroupType,
} from "../../types";

type ValidFormValues =
  | AdminCreateUserInput
  | AdminEditUserInput
  | CreateUserInput
  | EditUserInput;

type Props<FormValues extends ValidFormValues> = {
  open: boolean;
  onClose: () => void;
  title: string;
  teamGroups: TeamGroupType[];
  initialValues: FormValues;
  onSubmit: (input: FormValues) => Promise<void>;
  showPass?: boolean;
  name?: string;
  isAdminForm?: boolean;
  isCreate?: boolean;
};

function Footer() {
  const { isSubmitting, resetForm } = useFormikContext<ValidFormValues>();
  const onCancel = useCallback(() => resetForm(), [resetForm]);

  return (
    <DialogActions>
      <Button variant="primary" type="submit" disabled={isSubmitting}>
        Spara
      </Button>
      <Button variant="cancel" onClick={onCancel}>
        Avbryt
      </Button>
    </DialogActions>
  );
}

export function UserForm<V extends ValidFormValues>({
  open,
  onClose,
  title,
  teamGroups,
  initialValues,
  onSubmit,
  name,
  showPass = false,
  isAdminForm = false,
  isCreate = false,
}: Props<V>) {
  const [openIdx, setOpenIdx] = useState(0);
  const memberName = useMemo(() => name || "den nya personen", [name]);

  const handleClose: DialogProps["onClose"] = useCallback(
    (reason: "backdropClick" | "escapeKeyDown") => {
      if (reason && reason === "backdropClick") return;
      onClose();
    },
    [onClose],
  );

  const handleAccordion = (idx: number) => () => {
    setOpenIdx((prev: number) => (prev === idx ? -1 : idx));
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle title={title} />
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          await onSubmit(values).catch(console.error);
        }}
        onReset={(_, formikBag) => {
          formikBag.setValues(initialValues);
          onClose();
        }}
      >
        <FormikForm autoComplete="off">
          <DialogContent sx={{ p: 0 }}>
            <NameAccordion
              isAdminForm={isAdminForm}
              expanded={openIdx === 0}
              onChange={handleAccordion(0)}
              isCreate={isCreate}
              showPass={showPass}
            />

            <TeamAccordion
              expanded={openIdx === 1}
              onChange={handleAccordion(1)}
              teamGroups={teamGroups}
            />

            <EmploymentAccordion
              expanded={openIdx === 2}
              onChange={handleAccordion(2)}
              hideRuleGroup={isAdminForm}
            />

            {isAdminForm && (
              <RuleGroupsAccordion
                expanded={openIdx === 3}
                onChange={handleAccordion(3)}
              />
            )}

            <CompetencesAccordion
              expanded={openIdx === 4}
              onChange={handleAccordion(4)}
            />

            <TimebankAccordion
              expanded={openIdx === 5}
              onChange={handleAccordion(5)}
              name="timebankBaseBalance"
              memberName={memberName}
            />
          </DialogContent>
          <Footer />
        </FormikForm>
      </Formik>
    </Dialog>
  );
}
