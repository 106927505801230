import { Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  BigSwitch as Big,
  SmallSwitch as Small,
} from "components/common/Switch";

type Props = {
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
  showLabels?: boolean;
  labelOn?: string;
  labelOff?: string;
};

function BigSwitch({ name, disabled, readOnly }: Props) {
  const { value, setValue } = useFormikState<boolean>(name);
  function onChange() {
    if (readOnly) return;
    setValue(!value);
  }
  return (
    <Big
      name={name}
      checked={value}
      onChange={onChange}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

export function SmallSwitch({
  name,
  disabled,
  readOnly,
  showLabels = false,
  labelOn = "På",
  labelOff = "Av",
}: Props) {
  const { value, setValue } = useFormikState<boolean>(name);
  function onChange() {
    if (readOnly) return;
    setValue(!value);
  }
  return (
    <>
      <Small
        name={name}
        checked={value}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
      />
      {showLabels && <Typography>{value ? labelOn : labelOff}</Typography>}
    </>
  );
}
