import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import type { IEnvironment } from "relay-runtime";

import type {
  UpdateRuleGroupSettingBoxAB1Mutation as Mutation,
  UpdateRuleGroupSettingBoxAB1Mutation$variables as Variables,
} from "./types";

const mutation = graphql`
  mutation UpdateRuleGroupSettingBoxAB1Mutation(
    $input: UpdateRuleGroupSettingInput!
  ) {
    updateRuleGroupSetting(input: $input) {
      ok
      ruleGroupSetting {
        ...RuleGroupSettingBoxAB1_fragment
      }
    }
  }
`;

export async function UpdateRuleGroupSettingBoxAB1(
  environment: IEnvironment,
  variables: Variables,
) {
  return commitMutation<Mutation>(environment, {
    mutation,
    variables,
  });
}
