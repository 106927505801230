import type { ComponentProps } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import { Visible } from "components/common/Visible";

import { SettingClockwiseShiftRotation } from "./SettingClockwiseShiftRotation";
import { SettingNightShifts } from "./SettingNightShifts";
import { SettingOptimization } from "./SettingOptimization";
import { SettingParttimes } from "./SettingParttimes";
import { SettingRestBetweenShifts } from "./SettingRestBetweenShifts";
import { SettingVacation } from "./SettingVacation";
import { SettingWishedDaysOff } from "./SettingWishedDaysOff";
import type { SettingGroupAdvanced_fragment$key as Key } from "./types";

type Props = {
  fragmentRef: Key;
  ruleId: string;
  shifts: ComponentProps<typeof SettingParttimes>["shifts"];
};

const fragment = graphql`
  fragment SettingGroupAdvanced_fragment on SettingNode {
    ...SettingClockwiseShiftRotation_fragment
    ...SettingWishedDaysOff_fragment
    ...SettingVacation_fragment
    ...SettingNightShifts_fragment
    ...SettingOptimization_fragment
    ...SettingParttimes_fragment
    ...SettingRestBetweenShifts_fragment
  }
`;

export function SettingGroupAdvanced({ fragmentRef, ruleId, shifts }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);

  return (
    <>
      <Visible visible={ruleId === "advanced-0"}>
        <SettingOptimization fragmentRef={data} />
      </Visible>
      <Visible visible={ruleId === "advanced-1"}>
        <SettingParttimes fragmentRef={data} shifts={shifts} />
      </Visible>
      <Visible visible={ruleId === "advanced-2"}>
        <SettingRestBetweenShifts fragmentRef={data} />
      </Visible>
      <Visible visible={ruleId === "advanced-3"}>
        <SettingClockwiseShiftRotation fragmentRef={data} />
      </Visible>
      <Visible visible={ruleId === "advanced-4"}>
        <SettingWishedDaysOff fragmentRef={data} />
      </Visible>
      <Visible visible={ruleId === "advanced-5"}>
        <SettingVacation fragmentRef={data} />
      </Visible>
      <Visible visible={ruleId === "advanced-6"}>
        <SettingNightShifts fragmentRef={data} />
      </Visible>
    </>
  );
}
