import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { connectionToArray } from "relay-help/arrays";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";
import { workDayCadenceRestrictionSchema } from "components/setting/common/forms";

import { Rhythm3Form } from "./forms";
import { UpdateUserSettingBoxRhythm3 } from "./mutations";
import type {
  UpdateUserSettingInput as FormValues,
  UserSettingBoxRhythm3_fragment$key as Key,
} from "./types";

const fragment = graphql`
  fragment UserSettingBoxRhythm3_fragment on UserSettingNode {
    id
    maxConsecutiveWorkDays
    maxConsecutiveWorkDaysSoft
    minConsecutiveWorkDays
    minConsecutiveWorkDaysSoft
    workDayCadenceRestrictions {
      edges {
        node {
          id
          isSoft
          maxCadence
          calendarDays
        }
      }
    }
    settingModules
    user {
      fullName
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  maxConsecutiveWorkDays: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(1000, "Måste vara högst 1000")
    .required("Får ej vara tomt"),
  maxConsecutiveWorkDaysSoft: yup.boolean().required(),
  minConsecutiveWorkDays: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(1000, "Måste vara högst 1000")
    .required("Får ej vara tomt"),
  minConsecutiveWorkDaysSoft: yup.boolean().required(),
  workDayCadenceRestrictions: yup
    .array()
    .of(workDayCadenceRestrictionSchema)
    .required(),
  constraintModules: yup.array().of(yup.string()).required(),
});

type Props = {
  fragmentRef: Key;
};

function Content({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const data = useFragment<Key>(fragment, fragmentRef);
  const { fullName: name = "" } = data?.user || {};
  const initialValues = {
    id: data?.id || "",
    maxConsecutiveWorkDays: data?.maxConsecutiveWorkDays || 2,
    maxConsecutiveWorkDaysSoft: data?.maxConsecutiveWorkDaysSoft !== false, // default true
    minConsecutiveWorkDays: data?.minConsecutiveWorkDays || 2,
    minConsecutiveWorkDaysSoft: data?.minConsecutiveWorkDaysSoft !== false, // default true
    workDayCadenceRestrictions: connectionToArray(
      data.workDayCadenceRestrictions,
    ),
    constraintModules: data?.settingModules.slice() || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateUserSettingBoxRhythm3(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Rhythm3Form name={name} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function UserSettingBoxRhythm3({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
