import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import * as yup from "yup";

import { AutoSubmit } from "components/setting/common";

import { VacationForm as Form } from "../forms";
import { useUpdateSettingVacation } from "../mutations";
import type { UpdateSettingInput as FormValues } from "../types";

import type { SettingVacation_fragment$key as Key } from "./types";

type Props = {
  fragmentRef: Key;
};

const fragment = graphql`
  fragment SettingVacation_fragment on SettingNode {
    id
    constraintModules
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  constraintModules: yup.array().of(yup.string()).required(),
});

export function SettingVacation({ fragmentRef }: Props) {
  const teamGroup = useCurrentTeamGroup();
  const data = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useUpdateSettingVacation();
  const initialValues = useMemo<FormValues>(
    () => ({
      id: data.id,
      constraintModules: data.constraintModules.slice(),
    }),
    [data],
  );

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Form
            groupId={teamGroup?.id ?? ""}
            groupName={teamGroup?.name ?? ""}
          />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}
