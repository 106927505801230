import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import type { IEnvironment } from "relay-runtime";

import type {
  UpdateRuleGroupSettingBoxRhythm3Mutation as Mutation,
  UpdateRuleGroupSettingBoxRhythm3Mutation$variables as Variables,
} from "./types";

const mutation = graphql`
  mutation UpdateRuleGroupSettingBoxRhythm3Mutation(
    $input: UpdateRuleGroupSettingInput!
  ) {
    updateRuleGroupSetting(input: $input) {
      ok
      ruleGroupSetting {
        ...RuleGroupSettingBoxRhythm3_fragment
      }
    }
  }
`;

export async function UpdateRuleGroupSettingBoxRhythm3(
  environment: IEnvironment,
  variables: Variables,
) {
  return commitMutation<Mutation>(environment, {
    mutation,
    variables,
  });
}
