import React, { useCallback, useMemo } from "react";
import { Add } from "@mui/icons-material";
import {
  Button,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useBooleanState } from "utils/useBooleanState";

type ExceptionOption = {
  settingName: string;
  moduleName: string;
};

type Props = {
  name: string;
  options: ExceptionOption[];
  addException: (settingModule: string) => void;
};

export function ExceptionViewHeader({ name, options, addException }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>();
  const {
    value: addExceptionOpen,
    setTrue: openExceptionMenu,
    setFalse: closeExceptionMenu,
  } = useBooleanState();

  const openExceptionList = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      openExceptionMenu();
    },
    [openExceptionMenu],
  );

  const onClickException = useCallback(
    (moduleName: string) => {
      addException(moduleName);
      closeExceptionMenu();
    },
    [closeExceptionMenu, addException],
  );

  const menuItems = useMemo(() => {
    return options.map(({ moduleName, settingName }) => {
      return (
        <MenuItem key={moduleName} onClick={() => onClickException(moduleName)}>
          {settingName}
        </MenuItem>
      );
    });
  }, [options, onClickException]);

  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="h1" component="span" sx={{ color: "text.primary" }}>
        Undantag för {name}
      </Typography>
      <Button
        variant="outlined"
        onClick={openExceptionList}
        startIcon={<Add />}
      >
        Lägg till undantag
      </Button>
      <Menu
        open={addExceptionOpen}
        onClose={closeExceptionMenu}
        anchorEl={anchorEl}
        slotProps={{
          paper: { sx: { minWidth: "100px", maxHeight: "500px" } },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem disabled>Lägg till undantag för...</MenuItem>
        <Divider />
        {menuItems}
      </Menu>
    </Stack>
  );
}
