import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ArrowDropDown as OpenIcon,
  ArrowDropUp as CloseIcon,
  SettingsOutlined as SettingsIcon,
} from "@mui/icons-material";
import type { ButtonProps } from "@mui/material";
import {
  alpha,
  Button,
  Divider,
  FormControl,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useRequireMe } from "hooks/Me";

import LogoutButton from "./LogoutButton";

const MENU_WIDTH = 265;

const StyledInputLabel = styled(InputLabel)(
  ({
    theme: {
      palette: {
        primary: { contrastText },
      },
    },
  }) => ({
    color: alpha(contrastText, 0.7),
    "&.Mui-focused": {
      color: alpha(contrastText, 0.7),
    },
  }),
);

const StyledButton = styled(
  ({
    open,
    label,
    children,
    ...props
  }: ButtonProps & { label: string; open: boolean }) => (
    <FormControl fullWidth size="small">
      <StyledInputLabel
        shrink
        focused={open}
        sx={{ bgcolor: "primary.main", px: 1, ml: -0.5 }}
      >
        {label}
      </StyledInputLabel>
      <Button
        {...props}
        endIcon={open ? <CloseIcon /> : <OpenIcon />}
        disableRipple
        variant="outlined"
      >
        {children}
      </Button>
    </FormControl>
  ),
  { shouldForwardProp: (p) => p !== "open" },
)(
  ({
    open,
    theme: {
      palette: {
        primary: { contrastText },
      },
    },
  }) => ({
    height: 38.57,
    overflow: "clip",
    justifyContent: "space-between",
    color: contrastText,
    borderColor: alpha(contrastText, 0.23),
    "& .MuiSvgIcon-root": {
      color: alpha(contrastText, 0.23),
    },
    "&:hover": {
      borderColor: alpha(contrastText, 0.75),
      "& .MuiSvgIcon-root": {
        color: alpha(contrastText, 0.75),
      },
    },
    ...(open
      ? {
          borderColor: alpha(contrastText, 0.56),
          "& .MuiSvgIcon-root": {
            color: alpha(contrastText, 0.56),
          },
        }
      : {}),
  }),
);

function UserCard() {
  const { fullName, email, username } = useRequireMe();
  return (
    <Stack>
      <Typography variant="h4" sx={{ mb: 1 }}>
        {fullName}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        E-post: {email}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        Användarnamn: {username}
      </Typography>
    </Stack>
  );
}

type MenuProps = {
  anchorEl: null | HTMLElement;
  onClose: () => void;
};

function Menu({ anchorEl, onClose }: MenuProps) {
  const navigate = useNavigate();

  function goToSettings(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    navigate("/settings");
    onClose();
  }

  return (
    <MuiMenu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      slotProps={{ paper: { sx: { width: MENU_WIDTH } } }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: -8,
      }}
    >
      <ListItem>
        <UserCard />
      </ListItem>
      <Divider sx={{ mb: 1, mt: 0.5 }} />
      <MenuItem onClick={goToSettings}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText>Mina inställningar</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem>
        <LogoutButton />
      </MenuItem>
    </MuiMenu>
  );
}

const MenuButton: React.FC<
  { open: boolean } & Pick<ButtonProps, "onClick">
> = ({ open, onClick }) => {
  const me = useRequireMe();
  const value = me?.fullName || "Profil";

  return (
    <StyledButton onClick={onClick} open={open} label="Inloggad som">
      {value}
    </StyledButton>
  );
};

export default function UserMenu() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      <MenuButton open={!!anchorEl} onClick={onClick} />
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
    </>
  );
}
