import type { StackProps } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import type { CompetenceDemandSetting as CompetenceDemandSettingType } from "components/setting/types";

import { LabeledColorDot } from "../common";

type Competence = CompetenceDemandSettingType["competence"];

type Props = Omit<StackProps, "color"> & {
  name: string;
};

export function CompetenceLabel({ name, ...stackProps }: Props) {
  const { value } = useFormikState<Competence>(name);
  return (
    <LabeledColorDot label={value.name} color={value.color} {...stackProps} />
  );
}
