import { SxProps } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DateTime } from "luxon";

export default function DatePicker({
  label,
  state,
  sx,
}: {
  label?: string;
  state: [Date, (date: Date) => void];
  sx: SxProps;
}) {
  const [date, setDate] = state;

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setDate(e.target.valueAsDate!);
  }

  const dateAsString = DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");

  return (
    <TextField
      type="date"
      label={label}
      value={dateAsString}
      onChange={onChange}
      size="small"
      sx={{ ...sx, borderRadius: 1 }}
    />
  );
}
