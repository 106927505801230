/**
 * @generated SignedSource<<1bbc28155f6455e1b7d8b3db50f93e0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiScheduleTerminationConditionChoices = "FEASIBLE" | "HARD_CONSTRAINT_COLLISION" | "INFEASIBLE" | "INFEASIBLE_NOW_RERUN" | "MAX_STALL_TIME" | "MAX_TIME_LIMIT" | "OPTIMAL" | "OTHER" | "UNBOUNDED" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TabHeader_fragment$data = {
  readonly copiedFrom: {
    readonly id: string;
  } | null;
  readonly created: string;
  readonly period: {
    readonly endDate: string;
    readonly group: {
      readonly name: string;
    };
    readonly id: string;
    readonly startDate: string;
  };
  readonly terminationCondition: ApiScheduleTerminationConditionChoices | null;
  readonly " $fragmentType": "TabHeader_fragment";
};
export type TabHeader_fragment$key = {
  readonly " $data"?: TabHeader_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TabHeader_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TabHeader_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminationCondition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScheduleNode",
      "kind": "LinkedField",
      "name": "copiedFrom",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PeriodNode",
      "kind": "LinkedField",
      "name": "period",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamGroupNode",
          "kind": "LinkedField",
          "name": "group",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ScheduleNode",
  "abstractKey": null
};
})();

(node as any).hash = "98e819a95e6077b92261c52c15bd513b";

export default node;
