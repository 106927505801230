import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { minutes2HHMM } from "helpers/dateFunctions";
import { DateTime } from "luxon";

import { ShiftTimelineItem } from "./ShiftTimelineItem";

export function TimelineItemRenderer({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
}: any) {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  const fmtTs = (ts: number) => DateTime.fromMillis(ts).toFormat("T");
  const { title } = item;
  const startTime = fmtTs(item.start_time);
  const endTime = fmtTs(item.end_time);
  const dayTypeTitle = item.day_type_title;

  const tooltipTitle = (
    <Stack>
      <Typography>
        <strong>{title}</strong> {startTime}-{endTime}
      </Typography>
      <Typography>
        <strong>Längd</strong>{" "}
        {minutes2HHMM((item.end_time - item.start_time) / 60000)}
      </Typography>
      <Typography>
        <strong>Dygnstyp</strong> {dayTypeTitle}
      </Typography>
      {item.break_time > 0 ? (
        <Typography>
          <strong>Rast</strong> {item.break_time} minuter
        </Typography>
      ) : null}
    </Stack>
  );

  const { key, ...itemProps } = getItemProps(item.itemProps);

  return (
    <Tooltip placement="right" title={tooltipTitle}>
      <ShiftTimelineItem key={key} {...itemProps} title={undefined}>
        {itemContext.useResizeHandle ? <Box {...leftResizeProps} /> : ""}

        <Box
          className="rct-item-content"
          style={{ maxHeight: `${itemContext.dimensions.height}` }}
        />

        {itemContext.useResizeHandle ? <Box {...rightResizeProps} /> : ""}
      </ShiftTimelineItem>
    </Tooltip>
  );
}
