import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { yupTimeString } from "libs/yup";
import * as yup from "yup";

import { AutoSubmit } from "components/setting/common";
import { useSnackbar } from "components/Snackbar";

import { DefinitionsForm } from "./forms";
import { useUpdateDefinitionsMutation } from "./mutations";
import type {
  Definitions_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";
import { formatHmsToHm } from "./utils";

type Props = {
  fragmentRef: Key;
};

const fragment = graphql`
  fragment Definitions_fragment on SettingNode {
    id
    morningStartsNightEnds
    eveningStartsMorningEnds
    nightStartsEveningEnds
    fullDayDuration
    weekendStartsAt
    weekendStartsAtDay
    weekendEndsAt
    weekendEndsAtDay
  }
`;

const validationSchema = yup
  .object()
  .shape({
    id: yup.string().required("Får ej vara tomt"),
    morningStartsNightEnds: yupTimeString.required("Får ej vara tomt"),
    eveningStartsMorningEnds: yupTimeString.required("Får ej vara tomt"),
    nightStartsEveningEnds: yupTimeString.required("Får ej vara tomt"),
    fullDayDuration: yup.number().required("Får ej vara tomt"),
  })
  .test(
    "breakpoints-not-equal",
    "Start- och sluttider får inte vara samma",
    function (value: FormValues) {
      const {
        morningStartsNightEnds,
        eveningStartsMorningEnds,
        nightStartsEveningEnds,
      } = value;
      const errors: yup.ValidationError[] = [];

      if (morningStartsNightEnds === eveningStartsMorningEnds) {
        errors.push(
          new yup.ValidationError(
            "Får inte vara samma som kväll börjar",
            null,
            "morningStartsNightEnds",
          ),
        );
        errors.push(
          new yup.ValidationError(
            "Får inte vara samma som morgon börjar",
            null,
            "eveningStartsMorningEnds",
          ),
        );
      }
      if (morningStartsNightEnds === nightStartsEveningEnds) {
        errors.push(
          new yup.ValidationError(
            "Får inte vara samma som natt börjar",
            null,
            "morningStartsNightEnds",
          ),
        );
        errors.push(
          new yup.ValidationError(
            "Får inte vara samma som kväll börjar",
            null,
            "nightStartsEveningEnds",
          ),
        );
      }
      if (eveningStartsMorningEnds === nightStartsEveningEnds) {
        errors.push(
          new yup.ValidationError(
            "Får inte vara samma som natt börjar",
            null,
            "eveningStartsMorningEnds",
          ),
        );
        errors.push(
          new yup.ValidationError(
            "Får inte vara samma som morgon börjar",
            null,
            "nightStartsEveningEnds",
          ),
        );
      }

      if (errors?.length) {
        return new yup.ValidationError(errors);
      }
      return false;
    },
  )
  .test(
    "breakpoints-dont-overlap",
    "Intervallen får inte överlappa",
    function (value: FormValues) {
      const {
        morningStartsNightEnds,
        eveningStartsMorningEnds,
        nightStartsEveningEnds,
      } = value;
      const arbitraryDate = "2024-06-26T";
      function toTimestamp(time: string) {
        return (time && Date.parse(arbitraryDate.concat(time))) || 0;
      }
      const morning = toTimestamp(morningStartsNightEnds as string);
      const evening = toTimestamp(eveningStartsMorningEnds as string);
      const night = toTimestamp(nightStartsEveningEnds as string);

      const inBetweenError = new yup.ValidationError(
        "Kvällen måste börja mellan dagen och natten ",
        null,
        "eveningStartsMorningEnds",
      );
      if (morning > night && morning > evening && !(evening < night)) {
        return inBetweenError;
      }
      if (morning < night && evening < night && !(morning < evening)) {
        return inBetweenError;
      }
      if (morning < night && !(evening < night)) {
        return inBetweenError;
      }
      return false;
    },
  );

export function Definitions({ fragmentRef }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useUpdateDefinitionsMutation();
  const { addSnack } = useSnackbar();

  const initialValues = useMemo<FormValues>(
    () => ({
      id: data.id,
      morningStartsNightEnds: formatHmsToHm(data.morningStartsNightEnds),
      eveningStartsMorningEnds: formatHmsToHm(data.eveningStartsMorningEnds),
      nightStartsEveningEnds: formatHmsToHm(data.nightStartsEveningEnds),
      fullDayDuration: data.fullDayDuration,
      weekendStartsAt: data.weekendStartsAt,
      weekendStartsAtDay: data.weekendStartsAtDay,
      weekendEndsAt: data.weekendEndsAt,
      weekendEndsAtDay: data.weekendEndsAtDay,
    }),
    [data],
  );

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } }).catch(() => {
      addSnack({ severity: "error", message: "Uppdatering misslyckades" });
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <DefinitionsForm />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}
