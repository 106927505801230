import { Table, TableBody, TableContainer } from "@mui/material";
import { FieldArray } from "formik";
import useFormikState from "hooks/useFormikState";
import type { FormikFieldArrayFn } from "types/formik";

import type { CreateShiftsInput, ShiftInput } from "../types";

import { ShiftTableHead } from "./ShiftTableHead";
import { ShiftTableRow } from "./ShiftTableRow";

type FieldArrayProps = FormikFieldArrayFn<CreateShiftsInput>;

type Props = {
  name: string;
};

export default function ShiftTable({ name }: Props) {
  const { value: shifts } = useFormikState<ShiftInput[]>(name);
  return (
    <TableContainer>
      <Table size="small">
        {shifts?.length ? <ShiftTableHead /> : null}
        <TableBody>
          <FieldArray name={name}>
            {({ remove }: FieldArrayProps) =>
              (shifts || []).map((_, i) => (
                <ShiftTableRow
                  key={i}
                  name={`${name}.${i}`}
                  onRemove={() => remove(i)}
                />
              ))
            }
          </FieldArray>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
