import { useField } from "formik";

export default function useFormikState<T = any>(name: string) {
  const [field, meta, helpers] = useField<T>(name);
  const { value } = meta;
  const { setValue } = helpers;
  return {
    value,
    setValue,
    field,
    meta,
    helpers,
  };
}
