import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { Worktime2Form } from "./forms";
import { UpdateUserSettingBoxWorktime2 } from "./mutations";
import type {
  UpdateUserSettingInput as FormValues,
  UserSettingBoxWorktime2_fragment$key as Key,
} from "./types";

const fragment = graphql`
  fragment UserSettingBoxWorktime2_fragment on UserSettingNode {
    id
    resourceHoursMin
    resourceHoursMax
    prohibitedResourceWeekDays
    settingModules
    user {
      fullName
    }
  }
`;

const validationSchema = yup
  .object()
  .shape({
    id: yup.string().required(),
    resourceHoursMin: yup
      .number()
      .min(0, "Måste vara minst 0")
      .required("Får ej vara tomt"),
    resourceHoursMax: yup
      .number()
      .min(0, "Måste vara minst 0")
      .required("Får ej vara tomt"),
    prohibitedResourceWeekDays: yup
      .array()
      .of(yup.number().min(0).max(6))
      .required(),
    constraintModules: yup.array().of(yup.string()).required(),
  })
  .test(
    "max-less-than-min",
    "Minsta värdet får inte vara större än max-värdet",
    function (value: FormValues, context: any) {
      const { resourceHoursMin, resourceHoursMax } = value;
      if (
        resourceHoursMin &&
        resourceHoursMax &&
        resourceHoursMin > resourceHoursMax
      ) {
        return context.createError({
          message: "Minsta värdet får inte vara större än max-värdet",
          path: "resourceHoursMin",
        });
      }
    },
  );

type Props = {
  fragmentRef: Key;
};

function Content({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const data = useFragment<Key>(fragment, fragmentRef);
  const { fullName: name = "" } = data?.user || {};
  const initialValues: FormValues = {
    id: data?.id || "",
    resourceHoursMin: data?.resourceHoursMin || 0,
    resourceHoursMax: data?.resourceHoursMax || 0,
    prohibitedResourceWeekDays: data?.prohibitedResourceWeekDays || [],
    constraintModules: data?.settingModules.slice() || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateUserSettingBoxWorktime2(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Worktime2Form name={name} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function UserSettingBoxWorktime2({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
