import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  fetchQuery,
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from "react-relay/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";

import PageWrapper from "components/layout/PageWrapper";
import {
  SchedulePlaceholder,
  TabHeaderPlaceholder,
} from "components/loading/pages";
import { ScheduleProvider, ScheduleTabs, TabHeader } from "components/schedule";

import { OptimiserStatistics } from "./OptimiserStatistics";
import { Results } from "./Results";
import { ScheduleGroupSettings } from "./ScheduleGroupSettings";
import { ScheduleSettings } from "./ScheduleSettings";
import { Statistics } from "./Statistics";
import type { ScheduleQuery as Query } from "./types";

const query = graphql`
  query ScheduleQuery($id: ID!) {
    schedule(id: $id) {
      ...OptimiserStatistics_fragment
      ...Results_fragment
      ...ScheduleTabs_fragment
      ...Statistics_fragment
      ...TabHeader_fragment
      ...ScheduleContext_fragment
    }
  }
`;

type TParams = { id: string; index?: string };
type InnerParams = {
  scheduleId: string;
  queryRef: PreloadedQuery<Query>;
  index?: number;
  refresh: () => Promise<void>;
};

function InnerSchedule({ scheduleId, index, queryRef, refresh }: InnerParams) {
  const { schedule } = usePreloadedQuery<Query>(query, queryRef);

  const tabs = useMemo(
    () => [
      {
        label: "Resultat",
        content: !!schedule && (
          <Results
            fragmentRef={schedule}
            scheduleId={scheduleId}
            refresh={refresh}
          />
        ),
        id: "result",
        index: 0,
      },
      {
        label: "Statistik",
        content: !!schedule && (
          <Statistics fragmentRef={schedule} scheduleId={scheduleId} />
        ),
        id: "statistics",
        index: 1,
      },
      {
        label: "Inställningar",
        content: <ScheduleGroupSettings scheduleId={scheduleId} />,
        id: "group-settings",
        index: 2,
      },
      {
        label: "Optimeringsregler",
        content: !!schedule && <ScheduleSettings scheduleId={scheduleId} />,
        id: "settings",
        index: 3,
      },
      {
        label: "Optimeringsstatistik",
        content: !!schedule && (
          <OptimiserStatistics fragmentRef={schedule} scheduleId={scheduleId} />
        ),
        id: "optimiser-statistics",
        index: 4,
      },
    ],
    [schedule, refresh, scheduleId],
  );

  return (
    <PageWrapper sx={{ overflowX: "hidden" }}>
      {!!schedule && (
        <ScheduleProvider fragmentRef={schedule}>
          <Stack>
            <Suspense fallback={<TabHeaderPlaceholder />}>
              <TabHeader fragmentRef={schedule} />
            </Suspense>
            <ScheduleTabs tabs={tabs} index={index} fragmentRef={schedule} />
          </Stack>
        </ScheduleProvider>
      )}
    </PageWrapper>
  );
}

export function Schedule() {
  const { id, index } = useParams<TParams>();
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const env = useRelayEnvironment();
  const teamGroup = useCurrentTeamGroup();
  const navigate = useNavigate();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const refresh = useCallback(async () => {
    await fetchQuery(env, query, { id }).toPromise();
  }, [env, id]);

  // Hide old schedule runs when changing team group
  useEffect(() => {
    if (id && !isFirstRender) {
      navigate("/periods");
    }
  }, [teamGroup?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!id) return;
    loadQuery({ id });
  }, [loadQuery, id]);

  return (
    <Suspense fallback={<SchedulePlaceholder />}>
      {!!id && !!queryRef && (
        <InnerSchedule
          queryRef={queryRef}
          scheduleId={id}
          index={index ? parseInt(index) : undefined}
          refresh={refresh}
        />
      )}
    </Suspense>
  );
}
