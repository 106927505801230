/**
 * @generated SignedSource<<7f7cbed724e9ab12ea5f386f87685a2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiRuleGroupSettingShiftsEmploymentDegreeLogicChoices = "E" | "O" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RuleGroupSettingBoxBase2_fragment$data = {
  readonly hoursEmploymentDegreeFactor: number;
  readonly hoursPerWeek: number;
  readonly id: string;
  readonly ruleGroup: {
    readonly name: string;
  };
  readonly settingModules: ReadonlyArray<string>;
  readonly shiftsEmploymentDegreeFactor: number;
  readonly shiftsEmploymentDegreeLogic: ApiRuleGroupSettingShiftsEmploymentDegreeLogicChoices;
  readonly shiftsPerWeek: number;
  readonly " $fragmentType": "RuleGroupSettingBoxBase2_fragment";
};
export type RuleGroupSettingBoxBase2_fragment$key = {
  readonly " $data"?: RuleGroupSettingBoxBase2_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RuleGroupSettingBoxBase2_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RuleGroupSettingBoxBase2_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursPerWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsPerWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsEmploymentDegreeFactor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsEmploymentDegreeLogic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursEmploymentDegreeFactor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RuleGroupNode",
      "kind": "LinkedField",
      "name": "ruleGroup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RuleGroupSettingNode",
  "abstractKey": null
};

(node as any).hash = "7a6d2fcfc8d9be577ce96685a7452a2d";

export default node;
