import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";
import type { SelectorStoreUpdater } from "relay-runtime";

import {
  AdminCreateUserMutation as Mutation,
  AdminCreateUserMutation$data as Data,
} from "./types";

const mutation = graphql`
  mutation AdminCreateUserMutation($input: AdminCreateUserInput!) {
    adminCreateUser(input: $input) {
      user {
        id
        fullName
        username
        isActive
        archivedAt
        email
        firstName
        lastName
        employmentDegree
        employmentForm
        employmentTitle
        ruleGroups {
          edges {
            node {
              id
              name
            }
          }
        }
        competences {
          edges {
            node {
              id
              name
            }
          }
        }
        timebankBaseBalance
        userSetting {
          id
        }
        groupMemberOf {
          edges {
            node {
              id
              name
            }
          }
        }
        memberOf {
          edges {
            node {
              id
              name
              color
            }
          }
        }
        teamAdminOf {
          edges {
            node {
              id
            }
          }
        }
        groupAdminOf {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

const updater: SelectorStoreUpdater<Data> = (store) => {
  const newRecord = store
    .getRootField("adminCreateUser")
    .getLinkedRecord("user");
  const root = store.getRoot();
  const items = root.getLinkedRecords("allUsers");
  const newRecordsList = [...(items || []), newRecord];
  root.setLinkedRecords(newRecordsList, "allUsers");
};

export const useAdminCreateUser = () =>
  useMutation<Mutation>(mutation, { updater });
