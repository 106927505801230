import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import type { IEnvironment } from "relay-runtime";

import type {
  UpdateUserSettingBoxWorktime1Mutation as Mutation,
  UpdateUserSettingBoxWorktime1Mutation$variables as Variables,
} from "./types";

const mutation = graphql`
  mutation UpdateUserSettingBoxWorktime1Mutation(
    $input: UpdateUserSettingInput!
  ) {
    updateUserSetting(input: $input) {
      ok
      userSetting {
        ...UserSettingBoxWorktime1_fragment
      }
    }
  }
`;

export async function UpdateUserSettingBoxWorktime1(
  environment: IEnvironment,
  variables: Variables,
) {
  return commitMutation<Mutation>(environment, {
    mutation,
    variables,
  });
}
