import type { ReactNode } from "react";
import { useMemo } from "react";

import type { SelectedType } from "./types";

type Props = {
  selectedType: SelectedType | null;
  userSettings: ReactNode;
  ruleGroupSettings: ReactNode;
  teamGroupSettings: ReactNode;
};

export function Level2Renderer({
  selectedType,
  userSettings,
  ruleGroupSettings,
  teamGroupSettings,
}: Props) {
  const component = useMemo(() => {
    switch (selectedType) {
      case "group":
        return teamGroupSettings;
      case "person":
        return userSettings;
      case "ruleGroup":
        return ruleGroupSettings;
      default:
        return <></>;
    }
  }, [selectedType, userSettings, ruleGroupSettings, teamGroupSettings]);
  return <>{component}</>;
}
