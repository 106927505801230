import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { Types5Form } from "./forms";
import { UpdateUserSettingBoxTypes5 } from "./mutations";
import type {
  UpdateUserSettingInput as FormValues,
  UserSettingBoxTypes5_fragment$key as Key,
} from "./types";

const fragment = graphql`
  fragment UserSettingBoxTypes5_fragment on UserSettingNode {
    id
    settingModules
    shiftDayTypeDistributionSoft
    shiftDayTypeAllowedErrorMargin
    dayShiftDistributionShare
    eveningShiftDistributionShare
    nightShiftDistributionShare
    fullDayShiftDistributionShare
    user {
      fullName
      resolvedSettings(teamGroupId: $teamGroupId) {
        shiftsPerPeriod
      }
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  currentTeamGroupId: yup.string().required(),
  constraintModules: yup.array().of(yup.string()).required(),
  shiftDayTypeDistributionSoft: yup.boolean().required(),
  shiftDayTypeAllowedErrorMargin: yup.number().required(),
  dayShiftDistributionShare: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(100, "Måste vara högst 100")
    .required("Får ej vara tomt"),
  eveningShiftDistributionShare: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(100, "Måste vara högst 100")
    .required("Får ej vara tomt"),
  nightShiftDistributionShare: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(100, "Måste vara högst 100")
    .required("Får ej vara tomt"),
  fullDayShiftDistributionShare: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(100, "Måste vara högst 100")
    .required("Får ej vara tomt"),
});

type Props = {
  fragmentRef: Key;
};

const FormContainer = ({ fragmentRef }: Props) => {
  const environment = useRelayEnvironment();
  const data = useFragment<Key>(fragment, fragmentRef);
  const { fullName: name = "" } = data?.user || {};
  const teamGroup = useCurrentTeamGroup();
  const teamGroupId = teamGroup?.id || "";

  const initialValues = {
    id: data?.id || "",
    currentTeamGroupId: teamGroupId,
    constraintModules: data?.settingModules.slice() || [],
    shiftDayTypeDistributionSoft: data?.shiftDayTypeDistributionSoft || false,
    shiftDayTypeAllowedErrorMargin: data?.shiftDayTypeAllowedErrorMargin || 0,
    dayShiftDistributionShare:
      Math.round(data?.dayShiftDistributionShare * 1000) / 10 ?? 50,
    eveningShiftDistributionShare:
      Math.round(data?.eveningShiftDistributionShare * 1000) / 10 ?? 50,
    nightShiftDistributionShare:
      Math.round(data?.nightShiftDistributionShare * 1000) / 10 ?? 0,
    fullDayShiftDistributionShare:
      Math.round(data?.fullDayShiftDistributionShare * 1000) / 10 ?? 0,
  };

  async function onSubmit(values: FormValues) {
    const input = {
      ...values,
      dayShiftDistributionShare: (values?.dayShiftDistributionShare ?? 0) / 100,
      eveningShiftDistributionShare:
        (values?.eveningShiftDistributionShare ?? 0) / 100,
      nightShiftDistributionShare:
        (values?.nightShiftDistributionShare ?? 0) / 100,
      fullDayShiftDistributionShare:
        (values?.fullDayShiftDistributionShare ?? 0) / 100,
    };
    await UpdateUserSettingBoxTypes5(environment, { input, teamGroupId });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Types5Form
            name={name}
            groupName={teamGroup?.name || ""}
            totalShifts={Math.round(
              data?.user?.resolvedSettings?.shiftsPerPeriod || 0,
            )}
          />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
};

export function UserSettingBoxTypes5({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <FormContainer fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
