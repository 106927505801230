import type { ComponentProps } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { PaperProps, TableCellProps, TableProps } from "@mui/material";
import { Typography } from "@mui/material";
import type {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowData,
  MRT_TableState,
  MRT_VisibilityState,
} from "material-react-table";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_SV } from "material-react-table/locales/sv";
import { getTableConfig, setTableConfig } from "utils/localStorage";

type Props = ComponentProps<typeof MaterialReactTable> & {
  tableConfigKey?: string;
  columns: any;
  data: any;
};

type TableState = Partial<MRT_TableState<MRT_RowData>>;

type TableConfig = {
  pagination: MRT_PaginationState;
  columnFilters: MRT_ColumnFiltersState;
  columnVisibility: MRT_VisibilityState;
};

export default function MRTable({
  tableConfigKey,
  state: stateProp,
  columns,
  data,
  ...props
}: Props) {
  /* `state` may arrive from props.state or `tableConfig` (localStorage).
   * The order of precedence is:
   * 1. props.state
   * 2. tableConfig
   * 3. default (internally)
   *
   * `tableConfig` is only used if `tableConfigKey` is set.
   * If none of props.state and tableConfigKey is set, the state is handled internally.
   *
   * If state is hoisted, all columns need to be mentioned in columnVisibility, both false and true.
   * `state` in RequiredVsScheduledChart is an example for how it can be done.
   */

  const tableConfig = useMemo<TableConfig | null>(
    () => (tableConfigKey ? getTableConfig(tableConfigKey) : null),
    [tableConfigKey],
  );

  // ----------state----------

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    ...(tableConfig?.pagination ?? {
      pageIndex: 0,
      pageSize: 10,
    }),
  });
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    tableConfig?.columnFilters || [],
  );
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    tableConfig?.columnVisibility || {},
  );

  const initialState = useMemo<TableState>(
    () => ({
      density: "compact",
      expanded: true,
      ...(props.initialState ?? {}),
    }),
    [props.initialState],
  );

  const state = useMemo<TableState>(
    () => ({
      pagination,
      columnFilters,
      columnVisibility,
      ...(stateProp ?? {}),
    }),
    [stateProp, pagination, columnFilters, columnVisibility],
  );

  useEffect(() => {
    // update localStorage on change
    if (!tableConfigKey) return;

    const newConfig = {
      ...(getTableConfig(tableConfigKey) || {}),
      ...state,
    };
    setTableConfig(tableConfigKey, newConfig);
  }, [tableConfigKey, state]);

  // ----------styling---------

  const defaultColumn = useMemo(
    () => ({
      minSize: 40,
      maxSize: 300,
      size: 70,
      ...(props.defaultColumn ?? {}),
    }),
    [props.defaultColumn],
  );
  const muiTableProps = useMemo<TableProps>(
    () => ({
      ...(props.muiTableProps ?? {}),
      sx: (theme) => ({
        borderTop: `1px solid ${theme.palette.divider}`,
      }),
    }),
    [props.muiTableProps],
  );
  const muiTablePaperProps = useMemo<PaperProps>(
    () => ({
      variant: "box",
      ...(props.muiTablePaperProps ?? {}),
      sx: {
        p: "0px 8px",
      },
    }),
    [props.muiTablePaperProps],
  );
  const muiTableBodyCellProps = useMemo<TableCellProps>(
    () => ({
      ...(props.muiTableBodyCellProps ?? {}),
      sx: {
        p: "0px 8px",
      },
    }),
    [props.muiTableBodyCellProps],
  );
  const muiToolbarAlertBannerProps = useMemo(
    () => ({
      ...(props.muiToolbarAlertBannerProps ?? {}),
      sx: {
        backgroundColor: "white",
        fontSize: 14,
        color: "black",
      },
    }),
    [props.muiToolbarAlertBannerProps],
  );
  const muiTableFooterCellProps = useMemo(
    () => ({
      ...(props.muiTableFooterCellProps ?? {}),
      sx: {
        p: "10.8px 8px",
      },
    }),
    [props.muiTableFooterCellProps],
  );
  const muiTableHeadCellProps = useMemo<TableCellProps>(
    () => ({
      ...(props.muiTableHeadCellProps ?? {}),
      sx: {
        p: "0px 8px",
        ...((props?.muiTableHeadCellProps as TableCellProps)?.sx ?? {}),
      },
    }),
    [props.muiTableHeadCellProps],
  );
  const renderEmptyRowsFallback = useCallback(
    () => (
      <Typography fontStyle="italic" sx={{ lineHeight: 5, pl: 2 }}>
        Tomt
      </Typography>
    ),
    [],
  );
  const table = useMaterialReactTable({
    ...props,
    columns,
    data,
    enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    initialState: initialState,
    state: state,
    renderTopToolbarCustomActions: props.renderTopToolbarCustomActions,
    enableDensityToggle: false,
    localization: MRT_Localization_SV,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    renderEmptyRowsFallback: renderEmptyRowsFallback,
    defaultColumn: defaultColumn,
    muiTableBodyCellProps: muiTableBodyCellProps,
    muiTableFooterCellProps: muiTableFooterCellProps,
    muiTableHeadCellProps: muiTableHeadCellProps,
    muiTablePaperProps: muiTablePaperProps,
    muiTableProps: muiTableProps,
    muiToolbarAlertBannerProps: muiToolbarAlertBannerProps,
  });
  return <MaterialReactTable table={table} />;
}
