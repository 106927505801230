import { Suspense, useMemo } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { Base2Form } from "./forms";
import { UpdateUserSettingBoxBase2 } from "./mutations";
import type {
  UpdateUserSettingInput as FormValues,
  UserSettingBoxBase2_fragment$key as Key,
} from "./types";

const fragment = graphql`
  fragment UserSettingBoxBase2_fragment on UserSettingNode {
    id
    hoursPerWeek
    shiftsPerWeek
    settingModules
    user {
      fullName
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  hoursPerWeek: yup
    .number()
    .min(0, "Måste vara minst 0 timmar")
    .max(168, "Måste vara högst 168 timmar")
    .required("Får ej vara tomt"),
  shiftsPerWeek: yup
    .number()
    .min(0, "Måste vara minst 0 skift")
    .max(168, "Måste vara högst 168 skift")
    .required("Får ej vara tomt"),
  constraintModules: yup.array().of(yup.string()).required(),
});

type Props = {
  fragmentRef: Key;
  periodLengthWeeks: number;
};

function Content({ periodLengthWeeks, fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const data = useFragment<Key>(fragment, fragmentRef);
  const { fullName: name = "" } = data?.user || {};
  const initialValues = useMemo(
    () => ({
      id: data?.id || "",
      hoursPerWeek: data?.hoursPerWeek ?? 0,
      shiftsPerWeek: data?.shiftsPerWeek ?? 0,
      constraintModules: data?.settingModules.slice().filter(Boolean) ?? [],
    }),
    [data],
  );

  async function onSubmit(input: FormValues) {
    await UpdateUserSettingBoxBase2(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Base2Form
            name={`för ${name}`}
            periodLengthWeeks={periodLengthWeeks}
          />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function UserSettingBoxBase2({ fragmentRef, periodLengthWeeks }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && (
        <Content
          fragmentRef={fragmentRef}
          periodLengthWeeks={periodLengthWeeks}
        />
      )}
    </Suspense>
  );
}
