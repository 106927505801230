import { Typography } from "@mui/material";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/common/Accordion/FormAccordion";
import { FormikCompetenceSelect } from "components/common/selectors/CompetenceSelect";

type Props = {
  expanded: boolean;
  onChange: () => void;
};

export function CompetencesAccordion({ expanded, onChange }: Props) {
  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary>
        <Typography variant="h4">Kompetenser</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormikCompetenceSelect
          name="competences"
          multiple
          disableCloseOnSelect
        />
      </AccordionDetails>
    </Accordion>
  );
}
