/**
 * @generated SignedSource<<3c454627aa0b4271a1d80c913b2ff620>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScheduleQuery$variables = {
  id: string;
};
export type ScheduleQuery$data = {
  readonly schedule: {
    readonly " $fragmentSpreads": FragmentRefs<"OptimiserStatistics_fragment" | "Results_fragment" | "ScheduleContext_fragment" | "ScheduleTabs_fragment" | "Statistics_fragment" | "TabHeader_fragment">;
  } | null;
};
export type ScheduleQuery = {
  response: ScheduleQuery$data;
  variables: ScheduleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "color",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleNode",
        "kind": "LinkedField",
        "name": "schedule",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OptimiserStatistics_fragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Results_fragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScheduleTabs_fragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Statistics_fragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TabHeader_fragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScheduleContext_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScheduleQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleNode",
        "kind": "LinkedField",
        "name": "schedule",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "solutionStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "terminationCondition",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PeriodNode",
            "kind": "LinkedField",
            "name": "period",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamGroupNode",
                "kind": "LinkedField",
                "name": "group",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isMaster",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleNode",
            "kind": "LinkedField",
            "name": "copiedFrom",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetenceSnapshotNodeConnection",
            "kind": "LinkedField",
            "name": "competences",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompetenceSnapshotNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompetenceSnapshotNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamSnapshotNodeConnection",
            "kind": "LinkedField",
            "name": "teams",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamSnapshotNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamSnapshotNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserSnapshotNodeConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserSnapshotNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserSnapshotNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "546db5ab95a7509005b4069ee29526f9",
    "id": null,
    "metadata": {},
    "name": "ScheduleQuery",
    "operationKind": "query",
    "text": "query ScheduleQuery(\n  $id: ID!\n) {\n  schedule(id: $id) {\n    ...OptimiserStatistics_fragment\n    ...Results_fragment\n    ...ScheduleTabs_fragment\n    ...Statistics_fragment\n    ...TabHeader_fragment\n    ...ScheduleContext_fragment\n    id\n  }\n}\n\nfragment InfeasibleResults_fragment on ScheduleNode {\n  id\n  terminationCondition\n  solutionStatus\n}\n\nfragment OptimiserStatistics_fragment on ScheduleNode {\n  id\n  solutionStatus\n  terminationCondition\n}\n\nfragment Results_fragment on ScheduleNode {\n  id\n  solutionStatus\n  terminationCondition\n  period {\n    startDate\n    endDate\n    group {\n      id\n    }\n    id\n  }\n  ...InfeasibleResults_fragment\n}\n\nfragment ScheduleContext_fragment on ScheduleNode {\n  competences {\n    edges {\n      node {\n        id\n        name\n        color\n      }\n    }\n  }\n  teams {\n    edges {\n      node {\n        id\n        name\n        color\n      }\n    }\n  }\n  users {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment ScheduleTabs_fragment on ScheduleNode {\n  id\n  isMaster\n  solutionStatus\n  period {\n    id\n  }\n}\n\nfragment Statistics_fragment on ScheduleNode {\n  id\n  solutionStatus\n}\n\nfragment TabHeader_fragment on ScheduleNode {\n  created\n  terminationCondition\n  copiedFrom {\n    id\n  }\n  period {\n    id\n    startDate\n    endDate\n    group {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6a70629e6bfbfd87f5a77cb8a49ba499";

export default node;
