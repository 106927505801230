import { Suspense, useEffect, useRef, useState } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import { Dialog, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { Formik } from "formik";
import { toDatetimeLocal } from "libs/toDatetimeLocal";
import debounce from "lodash.debounce";

import { ValidationResultState } from "components/schedule/activities/validations/types";
import { validateEdit } from "components/schedule/activities/validations/ValidateEditActivity";
import { TranslateActivityType } from "components/schedule/types";
import { useSnackbar } from "components/Snackbar";

import { EditActivity } from "../mutations";
import type { EditActivityInput } from "../types";

import { ActivityForm, activityFormSchema } from "./common";
import type {
  EditActivityFormQuery,
  EditActivityPartType as ActivityPartType,
  EditActivityType as ActivityType,
} from "./types";

type Props = {
  open: boolean;
  onClose: () => void;
  activityId: string;
};

type FormProps = {
  activityId: string;
  queryRef: PreloadedQuery<EditActivityFormQuery>;
  open: boolean;
  onClose: () => void;
};

const query = graphql`
  query EditActivityFormQuery($id: ID!) {
    activity(id: $id) {
      start
      end
      breakTime
      activityType
      user {
        id
        name
      }
      team {
        id
      }
      activityParts {
        edges {
          node {
            id
            start
            end
            partType
          }
        }
      }
    }
  }
`;

function Form({ activityId, queryRef, open, onClose }: FormProps) {
  const navigate = useNavigate();
  const { activity } = usePreloadedQuery(query, queryRef);
  const environment = useRelayEnvironment();
  const { addSnack } = useSnackbar();

  // Validation helpers
  const [validationState, setValidationState] = useState<ValidationResultState>(
    { loading: false, result: undefined },
  );
  const lastValidatedValues = useRef<Partial<EditActivityInput> | undefined>(
    undefined,
  );

  const initialValues = {
    activityId,
    start: toDatetimeLocal(activity?.start) || "",
    end: toDatetimeLocal(activity?.end) || "",
    breakTime: activity?.breakTime || 0,
    userId: activity?.user?.id || "",
    teamId: activity?.team?.id || undefined,
    activityType: activity?.activityType as ActivityType,
    activityParts:
      activity?.activityParts?.edges
        .map((edge) => ({
          id: edge?.node?.id || "",
          start: toDatetimeLocal(edge?.node?.start) || "",
          end: toDatetimeLocal(edge?.node?.end) || "",
          partType: edge?.node?.partType as ActivityPartType,
        }))
        .filter((p) => p?.start) || [],
  };

  const debouncedValidateEdit = debounce(
    async (values: EditActivityInput) =>
      validateEdit({
        values,
        lastValidatedValues,
        setValidationState,
        validationState,
        activityId,
        environment,
      }),
    250,
  );

  async function onSubmit(input: EditActivityInput) {
    await EditActivity(environment, { input })
      .then((response) => {
        if (response?.editActivity?.ok) {
          onClose();
          addSnack({ message: "Händelse uppdaterad", severity: "success" });
          const clonedId = response?.editActivity?.clonedSchedule?.id;
          if (clonedId) {
            navigate(`/schedules/${clonedId}`);
          }
        } else {
          console.error("Could not create/update activity");
          addSnack({
            message: "Kunde inte uppdatera händelse",
            severity: "error",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        addSnack({
          message: "Kunde inte uppdatera händelse",
          severity: "error",
        });
      });
  }

  const Title = () => (
    <Typography variant="h2">
      Redigera händelse <b>{TranslateActivityType(activity?.activityType)}</b>{" "}
      för <b>{activity?.user?.name}</b>
    </Typography>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={activityFormSchema}
        validate={debouncedValidateEdit}
        onSubmit={onSubmit}
      >
        <ActivityForm
          title={<Title />}
          submitText="Spara ändringar"
          onClose={onClose}
          deletable={true}
          activityId={activityId}
          validationState={validationState}
        />
      </Formik>
    </Dialog>
  );
}

/** EditActivityForm
 */
export function EditActivityForm({ activityId: id, open, onClose }: Props) {
  const [queryRef, loadQuery] = useQueryLoader<EditActivityFormQuery>(query);

  useEffect(() => {
    if (!id) return;
    loadQuery({ id }, { fetchPolicy: "network-only" });
  }, [id, loadQuery]);

  return (
    <Suspense fallback={<div />}>
      {!!queryRef && (
        <Form
          activityId={id}
          queryRef={queryRef}
          open={open}
          onClose={onClose}
        />
      )}
    </Suspense>
  );
}
