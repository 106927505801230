import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { connectionToArray } from "relay-help/arrays";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { shiftTypeDistributionWeightChoices } from "./constants";
import { Types3Form } from "./forms";
import { UpdateSettingBoxTypes3 } from "./mutations";
import type {
  SettingBoxTypes3_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment SettingBoxTypes3_fragment on SettingNode {
    id
    shiftsPerWeek
    periodLengthWeeks
    optimizeEvenShiftTypeDistributionBetweenEmployees
    shiftTypeDistributionWeight
    prohibitedShiftTypes {
      edges {
        node {
          id
        }
      }
    }
    constraintModules
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string(),
  optimizeEvenShiftTypeDistributionBetweenEmployees: yup.boolean().required(),
  shiftTypeDistributionWeight: yup
    .string()
    .oneOf(shiftTypeDistributionWeightChoices)
    .required(),
  prohibitedShiftTypes: yup.array().of(yup.string()),
  constraintModules: yup.array().of(yup.string()),
});

type Props = {
  fragmentRef: Key;
  shiftTypes: any[];
};

function FormContainer({ fragmentRef, shiftTypes }: Props) {
  const environment = useRelayEnvironment();
  const teamGroup = useCurrentTeamGroup();
  const data = useFragment<Key>(fragment, fragmentRef);
  const initialValues: FormValues = {
    id: data?.id || "",
    optimizeEvenShiftTypeDistributionBetweenEmployees:
      data?.optimizeEvenShiftTypeDistributionBetweenEmployees !== false, // default false
    shiftTypeDistributionWeight: data?.shiftTypeDistributionWeight || "M",
    prohibitedShiftTypes: connectionToArray(data.prohibitedShiftTypes).map(
      (s) => s.id,
    ),
    constraintModules: data?.constraintModules.slice() || [],
  };
  async function onSubmit(input: FormValues) {
    await UpdateSettingBoxTypes3(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Types3Form
            groupName={teamGroup?.name || ""}
            shiftTypes={shiftTypes}
          />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxTypes3({ fragmentRef, shiftTypes }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && (
        <FormContainer fragmentRef={fragmentRef} shiftTypes={shiftTypes} />
      )}
    </Suspense>
  );
}
