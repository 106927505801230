import { memo, Suspense, useCallback, useMemo } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay/hooks";
import { Link } from "react-router-dom";
import {
  Paper,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { baseDemandSetting } from "settings/BaseDemand/BaseDemandSetting";
import type { GenericOptimizationSetting } from "settings/common/optimizationSetting";
import { consecutiveRestSetting } from "settings/ConsecutiveRest/ConsecutiveRestSetting";
import { consecutiveWorkSetting } from "settings/ConsecutiveWork/ConsecutiveWorkSetting";
import { dayNightDistributionSetting } from "settings/DayNightDistribution/DayNightDistributionSetting";
import { hoursAndShiftsSetting } from "settings/HoursAndShifts/HoursAndShiftsSetting";
import { resourceTimeSetting } from "settings/ResourceTime/ResourceTimeSetting";
import { responsibilityTimeSetting } from "settings/ResponsibilityTime/ResponsibilityTimeSetting";
import { SolutionStatus, TerminationCondition } from "types/Schedule";
import { optimizationRuleUrl } from "utils/urls";

import Card from "components/common/CardWithTitle";
import {
  InfoButtonAndModal,
  InfoModalProvider,
  InfoTitle,
} from "components/layout/InfoModal";
import Loading from "components/layout/Loading";
import { ErrorOrRunning } from "components/schedule/ErrorOrRunning";
import {
  MipLogChart,
  VariableSolutionsChart,
} from "components/schedule/graphs";

import type {
  OptimiserStatistics_fragment$key as Key,
  OptimiserStatisticsQuery as Query,
} from "./types";

const fragment = graphql`
  fragment OptimiserStatistics_fragment on ScheduleNode {
    id
    solutionStatus
    terminationCondition
  }
`;

const query = graphql`
  query OptimiserStatisticsQuery($id: ID!) {
    schedule(id: $id) {
      terminationCondition
      period {
        group {
          id
        }
      }
      ...MipLogChart_fragment
      ...VariableSolutionsChart_fragment
    }
  }
`;

type CommonProps = {
  scheduleId: string;
};

type Props = CommonProps & {
  fragmentRef: Key;
};

type ContentProps = CommonProps;

type InstructionsProps = {
  teamGroupId?: string;
};

const Instructions = memo(function InstructionsFn({
  teamGroupId,
}: InstructionsProps) {
  const getSettingUrl = useCallback(
    (setting: GenericOptimizationSetting) =>
      optimizationRuleUrl(teamGroupId, setting),
    [teamGroupId],
  );

  return (
    <Grid xs={12}>
      <Stack gap={3}>
        <Grid container spacing={5}>
          <Grid xs={3}>
            <Typography fontStyle="italic">
              Mjuka upp uppmjukbara regler
            </Typography>
          </Grid>
          <Grid xs={9}>
            <Typography>
              Notera att de hårda regelkrockar som syns på denna flik kan
              triggas av andra hårda regler (som faktiskt går att göra mjuka).
              Börja därför alltid med att kontrollera om du kan mjuka upp andra
              hårda regler såsom exempelvis{" "}
              <Link to={getSettingUrl(consecutiveRestSetting)}>
                Sammanhängande ledighet
              </Link>
              ,{" "}
              <Link to={getSettingUrl(consecutiveWorkSetting)}>
                Sammanhängande arbete
              </Link>{" "}
              och{" "}
              <Link to={getSettingUrl(dayNightDistributionSetting)}>
                Dag-/Kväll-/Natt-fördelning
              </Link>
              . Om grafen ovanför innehåller data om hårda regelkrockar så kan
              det ge dig en indikation på vilken eller vilka personer som du bör
              fokusera på.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid xs={3}>
            <Typography fontStyle="italic">
              Inkompatibelt hel-/deltidsmått
            </Typography>
          </Grid>
          <Grid xs={9}>
            <Typography>
              Detta regelbrott beror på att antalet timmar i kombination med
              antalet pass inte går att få ihop med den{" "}
              <Link to={"/shifts"}>Passprofil</Link> som du har angett. Gå till
              sidan <Link to={"/members"}>Personallista</Link> för att se
              antalet pass och antalet timmar för respektive person. Justera
              hel- och deltidsmått på enhets-, regelgrupps-, eller personnivå på
              sidan{" "}
              <Link to={getSettingUrl(hoursAndShiftsSetting)}>
                Optimeringsregler/Timmar & Pass
              </Link>
              .
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid xs={3}>
            <Typography fontStyle="italic">För många fasta fridagar</Typography>
          </Grid>
          <Grid xs={9}>
            <Typography>
              Detta regelbrott beror på att för många fasta fridagar har lagts
              in i <Link to={"/vacation"}>Händelsekalendern</Link> i förhållande
              till antalet pass. Det går alltså inte att få ut tillräckligt
              många pass i schemat, eftersom för många dagar har blivit
              blockerade som fridagar. Justera fridagarna i{" "}
              <Link to={"/vacation"}>Händelsekalendern</Link> eller justera
              arbetstidsmåttet på sidan{" "}
              <Link to={getSettingUrl(hoursAndShiftsSetting)}>
                Optimeringsregler/Timmar & Pass
              </Link>
              .
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid xs={3}>
            <Typography fontStyle="italic">Inkompatibla APT-pass</Typography>
          </Grid>
          <Grid xs={9}>
            <Typography>
              Detta regelbrott beror på att APT-passen är omöjliga att
              schemalägga. Detta kan i sin tur bero på att inga eller
              inkompatibla passtyper för APT har matats in i{" "}
              <Link to={"/shifts"}>Passprofilen</Link> och/eller{" "}
              <Link to={"/vacation"}>Händelsekalendern</Link>. Säkerställ att
              APT-passen som matats in Passprofilen och APT-händelserna som
              matats in i Händelsekalendern stämmer överens i veckodag och
              start- och sluttid. Säkerställ också att underbemanning är
              tillåten under{" "}
              <Link to={getSettingUrl(baseDemandSetting)}>
                Optimeringsregler/Grundbehov
              </Link>
              .
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid xs={3}>
            <Typography fontStyle="italic">
              Inkompatibelt ansvarstidsmått
            </Typography>
          </Grid>
          <Grid xs={9}>
            <Typography>
              Detta regelbrott beror på att den ansvarstid som du har angett är
              omöjlig att schemalägga. Detta kan i sin tur bero på att inga
              eller inkompatibla passtyper för ansvarstid har matats in i{" "}
              <Link to={"/shifts"}>Passprofilen</Link>. Säkerställ att passen
              med ansvarstid som matats in Passprofilen stämmer överens med de
              begränsningar i mesta och minsta timmar som du har angett i{" "}
              <Link to={getSettingUrl(responsibilityTimeSetting)}>
                Optimeringsregler/Ansvarstid
              </Link>{" "}
              för respektive person.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid xs={3}>
            <Typography fontStyle="italic">
              Inkompatibelt resurstidsmått
            </Typography>
          </Grid>
          <Grid xs={9}>
            <Typography>
              Detta regelbrott beror på att den resurstid som du har angett är
              omöjlig att schemalägga. Säkerställ att passen som matats in{" "}
              <Link to={"/shifts"}>Passprofilen</Link> stämmer överens med de
              begränsningar i mesta och minsta timmar som du har angett i{" "}
              <Link to={getSettingUrl(resourceTimeSetting)}>
                Optimeringsregler/Resurstid
              </Link>{" "}
              för respektive person.
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  );
});

function Content({ scheduleId: id }: ContentProps) {
  const { schedule } = useLazyLoadQuery<Query>(query, { id });
  const { terminationCondition, period } = schedule || {};

  if (
    terminationCondition === TerminationCondition.UNKNOWN ||
    terminationCondition === TerminationCondition.INFEASIBLE
  ) {
    return (
      <Stack
        gap={4}
        height={116}
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        <Typography fontStyle="italic">
          Ingen optimeringsstatistik att visa
        </Typography>
      </Stack>
    );
  }

  return (
    <Grid container spacing={5}>
      <Grid xs={12}>
        <Stack gap={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Lösningsstatistik</Typography>
            {terminationCondition === "HARD_CONSTRAINT_COLLISION" && (
              <InfoModalProvider>
                <InfoButtonAndModal
                  buttonLabel="Strategier för att lösa hårda regelkrockar"
                  title={
                    <InfoTitle>
                      Strategier för att lösa hårda regelkrockar
                    </InfoTitle>
                  }
                >
                  <Instructions teamGroupId={period?.group.id} />
                </InfoButtonAndModal>
              </InfoModalProvider>
            )}
          </Stack>
          {!!schedule && (
            <Card>
              <VariableSolutionsChart fragmentRef={schedule} />
            </Card>
          )}
        </Stack>
      </Grid>
      <Grid xs={12}>
        <Stack gap={1}>
          <Typography variant="h4">Optimeringslogg</Typography>
          {!!schedule && (
            <Card>
              <MipLogChart fragmentRef={schedule} />
            </Card>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

const paperSx = {
  p: 2,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
};

export function OptimiserStatistics({ fragmentRef, ...props }: Props) {
  const schedule = useFragment<Key>(fragment, fragmentRef);
  const { solutionStatus, terminationCondition } = schedule;

  const isValidSchedule = useMemo(
    () =>
      solutionStatus === SolutionStatus.FEASIBLE ||
      terminationCondition === TerminationCondition.HARD_CONSTRAINT_COLLISION ||
      terminationCondition === TerminationCondition.INFEASIBLE,
    [solutionStatus, terminationCondition],
  );

  if (!isValidSchedule) {
    return (
      <ErrorOrRunning solutionStatus={solutionStatus || SolutionStatus.ERROR} />
    );
  }

  return (
    <Paper variant="box" sx={paperSx}>
      <Suspense fallback={<Loading />}>
        <Content {...props} />
      </Suspense>
    </Paper>
  );
}
