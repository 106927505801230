/**
 * @generated SignedSource<<1dc9c18710459f8cd3561bc850e95888>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingGroupAdvanced_fragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SettingClockwiseShiftRotation_fragment" | "SettingNightShifts_fragment" | "SettingOptimization_fragment" | "SettingParttimes_fragment" | "SettingRestBetweenShifts_fragment" | "SettingVacation_fragment" | "SettingWishedDaysOff_fragment">;
  readonly " $fragmentType": "SettingGroupAdvanced_fragment";
};
export type SettingGroupAdvanced_fragment$key = {
  readonly " $data"?: SettingGroupAdvanced_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingGroupAdvanced_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingGroupAdvanced_fragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingClockwiseShiftRotation_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingWishedDaysOff_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingVacation_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingNightShifts_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingOptimization_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingParttimes_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingRestBetweenShifts_fragment"
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "5ab869b5002140da1346b8efee7505ad";

export default node;
