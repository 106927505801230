/**
 * @generated SignedSource<<5daf3df269c67bf9acbfef628fdf5fa3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingSplitShiftsWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SettingBoxTypes1_fragment$data = {
  readonly allowSplitShifts: boolean;
  readonly constraintModules: ReadonlyArray<string>;
  readonly id: string;
  readonly optimizeSplitShiftsDistributionBetweenEmployees: boolean;
  readonly splitShiftsWeight: ApiSettingSplitShiftsWeightChoices;
  readonly " $fragmentType": "SettingBoxTypes1_fragment";
};
export type SettingBoxTypes1_fragment$key = {
  readonly " $data"?: SettingBoxTypes1_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingBoxTypes1_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingBoxTypes1_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowSplitShifts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeSplitShiftsDistributionBetweenEmployees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "splitShiftsWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "a6e24d2d2d1ae0f7a6d9c740dadf6875";

export default node;
