import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import * as yup from "yup";

import Loading from "components/loading/Loading";
import { AutoSubmit } from "components/setting/common";

import { minWeeklyRestAbWeightChoices } from "./constants";
import { AB2Form } from "./forms";
import { UpdateSettingBoxAB2 } from "./mutations";
import type {
  SettingBoxAB2_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment SettingBoxAB2_fragment on SettingNode {
    id
    minWeeklyRestAbHours
    weeklyDailyCanOverlap
    minWeeklyRestAbSoft
    minWeeklyRestAbWeight
    weekBreakHourAlternatives
    weekBreakDaysAlternatives
    constraintModules
  }
`;

const validationSchema = yup.object().shape({
  minWeeklyRestAbHours: yup
    .number()
    .min(0, "Måste vara minst 0 timmar")
    .max(24 * 7, "Måste vara högst 168 timmar")
    .required("Får ej vara tomt"),
  weeklyDailyCanOverlap: yup.boolean(),
  minWeeklyRestAbSoft: yup.boolean(),
  minWeeklyRestAbWeight: yup
    .string()
    .oneOf(minWeeklyRestAbWeightChoices, "Felaktig vikt")
    .required("Måste anges"),
  weekBreakHourAlternatives: yup
    .array()
    .of(yup.number().min(0, "Måste vara minst 0 minuter"))
    .required("Måste anges"),
  weekBreakDaysAlternatives: yup
    .array()
    .of(yup.number().min(0, "Måste vara minst 0 dagar"))
    .required("Måste anges"),
  constraintModules: yup.array().of(yup.string()).required("Måste anges"),
});

type Props = {
  fragmentRef: Key;
};

type ContentProps = Props;

function Content({ fragmentRef }: ContentProps) {
  const environment = useRelayEnvironment();
  const teamGroup = useCurrentTeamGroup();
  const data = useFragment<Key>(fragment, fragmentRef);
  const initialValues: FormValues = {
    id: data?.id || "",
    minWeeklyRestAbHours: data?.minWeeklyRestAbHours || 0,
    weeklyDailyCanOverlap: data?.weeklyDailyCanOverlap !== false,
    minWeeklyRestAbSoft: data?.minWeeklyRestAbSoft !== false,
    minWeeklyRestAbWeight: data?.minWeeklyRestAbWeight || "M",
    weekBreakHourAlternatives: data?.weekBreakHourAlternatives.slice() || [15],
    weekBreakDaysAlternatives: data?.weekBreakDaysAlternatives.slice() || [0],
    constraintModules: data?.constraintModules.slice() || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateSettingBoxAB2(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <AB2Form groupName={teamGroup?.name || ""} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxAB2({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
