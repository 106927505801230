import { ScheduleWarningsDrawer_fragment$key } from "components/schedule/schedule_warnings";

export * from "./__generated__/Header_fragment.graphql";
export * from "./__generated__/WarningsDrawerButton_fragment.graphql";

export type DrawerFragmentRef = ScheduleWarningsDrawer_fragment$key;

export type ColorFocusType =
  | "activity_types"
  | "day_evening_night"
  | "teams"
  | "competences"
  | "shift_is_altered";

export enum colorFocuses {
  ACTIVITY_TYPES = "activity_types",
  DAY_EVENING_NIGHT = "activity_night",
  TEAMS = "teams",
  COMPETENCES = "competences",
  SHIFT_IS_ALTERED = "shift_is_altered",
}

export type NullableString = string | null;

export type NullableOption = {
  value: NullableString;
  label: string;
};
