import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks";
import * as yup from "yup";

import { AutoSubmit } from "components/setting/common";

import { OptimizationForm as Form } from "../forms";
import { useUpdateSettingOptimization } from "../mutations";
import type { UpdateSettingInput as FormValues } from "../types";

import type { SettingOptimization_fragment$key as Key } from "./types";

type Props = {
  fragmentRef: Key;
};

const fragment = graphql`
  fragment SettingOptimization_fragment on SettingNode {
    id
    mipgapLimit
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  mipgapLimit: yup
    .number()
    .required("får ej vara tomt")
    .min(0.01, "mipgap-gränsen måste vara större än 0")
    .max(100, "mipgap-gränsen måste vara mindre än 100"),
});

export function SettingOptimization({ fragmentRef }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useUpdateSettingOptimization();
  const teamGroup = useCurrentTeamGroup();
  const initialValues = useMemo<FormValues>(
    () => ({
      id: data.id,
      mipgapLimit: data.mipgapLimit,
    }),
    [data],
  );

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Form groupName={teamGroup?.name ?? ""} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}
