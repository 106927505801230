import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { Free1Form } from "./forms";
import { UpdateUserSettingBoxFree1 } from "./mutations";
import type {
  UpdateUserSettingInput as FormValues,
  UserSettingBoxFree1_fragment$key as Key,
} from "./types";

const fragment = graphql`
  fragment UserSettingBoxFree1_fragment on UserSettingNode {
    id
    workEveryXWeekend
    freeEveryXOfYWeekends
    freeWeekendConstraintTypeEveryX
    freeWeekendConstraintTypeXOfY
    freeWeekendSoft
    workEveryUnfreeWeekend
    workEveryUnfreeWeekendSoft
    settingModules
    user {
      fullName
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  workEveryXWeekend: yup
    .number()
    .required("Får ej vara tomt")
    .max(10, "Måste vara högst 10")
    .min(1, "Måste vara minst 1"),
  freeEveryXOfYWeekends: yup
    .number()
    .required("Får ej vara tomt")
    .max(52, "Måste vara högst 52")
    .min(0, "Måste vara minst 0"),
  freeWeekendConstraintTypeEveryX: yup.boolean().required(),
  freeWeekendConstraintTypeXOfY: yup.boolean().required(),
  freeWeekendSoft: yup.boolean().required(),
  workEveryUnfreeWeekend: yup.boolean().required(),
  workEveryUnfreeWeekendSoft: yup.boolean().required(),
  constraintModules: yup.array().of(yup.string()).required(),
});

type Props = {
  fragmentRef: Key;
  periodLengthWeeks: number;
};

const Content = ({ periodLengthWeeks, fragmentRef }: Props) => {
  const environment = useRelayEnvironment();
  const data = useFragment<Key>(fragment, fragmentRef);
  const { fullName: name = "" } = data?.user || {};
  const initialValues: FormValues = {
    id: data?.id || "",
    workEveryXWeekend: data?.workEveryXWeekend || 0,
    freeEveryXOfYWeekends: data?.freeEveryXOfYWeekends || 0,
    freeWeekendConstraintTypeEveryX:
      data?.freeWeekendConstraintTypeEveryX !== false, // default true
    freeWeekendConstraintTypeXOfY:
      data?.freeWeekendConstraintTypeXOfY !== false, // default true
    freeWeekendSoft: data?.freeWeekendSoft !== false,
    workEveryUnfreeWeekend: data?.workEveryUnfreeWeekend !== false,
    workEveryUnfreeWeekendSoft: data?.workEveryUnfreeWeekendSoft !== false,
    constraintModules: data?.settingModules.slice() || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateUserSettingBoxFree1(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Free1Form name={name} periodLengthWeeks={periodLengthWeeks} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
};

export function UserSettingBoxFree1({ periodLengthWeeks, fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && (
        <Content
          periodLengthWeeks={periodLengthWeeks}
          fragmentRef={fragmentRef}
        />
      )}
    </Suspense>
  );
}
