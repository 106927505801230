import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import * as yup from "yup";

import { AutoSubmit } from "components/setting/common";

import { minDailyRestWeightChoices } from "../constants";
import { RestBetweenShiftsForm as Form } from "../forms";
import { useUpdateSettingRestBetweenShifts } from "../mutations";
import type { UpdateSettingInput as FormValues } from "../types";

import type { SettingRestBetweenShifts_fragment$key as Key } from "./types";

type Props = {
  fragmentRef: Key;
};

const fragment = graphql`
  fragment SettingRestBetweenShifts_fragment on SettingNode {
    id
    constraintModules
    minDailyRestSoft
    minDailyRestHours
    minDailyRestWeight
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  constraintModules: yup.array().of(yup.string()),
  minDailyRestHours: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(24, "Måste vara högst 24")
    .required("Får ej vara tomt"),
  minDailyRestSoft: yup.boolean(),
  minDailyRestWeight: yup.string().oneOf(minDailyRestWeightChoices),
});

export function SettingRestBetweenShifts({ fragmentRef }: Props) {
  const teamGroup = useCurrentTeamGroup();
  const data = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useUpdateSettingRestBetweenShifts();
  const initialValues = useMemo<FormValues>(
    () => ({
      id: data.id,
      constraintModules: data.constraintModules.slice(),
      minDailyRestHours: data.minDailyRestHours,
      minDailyRestSoft: data.minDailyRestSoft,
      minDailyRestWeight: data.minDailyRestWeight,
    }),
    [data],
  );

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Form groupName={teamGroup?.name || ""} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}
