import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";

import { AutoSubmit } from "components/setting/common";
import { useSnackbar } from "components/Snackbar";

import { SchedulePeriodForm } from "./forms";
import { useUpdateSchedulePeriodMutation } from "./mutations";
import type {
  SchedulePeriod_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

type Props = {
  fragmentRef: Key;
};

const fragment = graphql`
  fragment SchedulePeriod_fragment on SettingNode {
    id
    constraintModules
    periodLengthWeeks
    wrapAroundPeriodEnd
    constraintModulesBridge
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required("Får ej vara tomt"),
  periodLengthWeeks: yup
    .number()
    .min(1, "Måste vara minst 1 vecka")
    .max(52, "Måste vara högst 52 veckor")
    .required("Får ej vara tomt"),
  wrapAroundPeriodEnd: yup.boolean().required("Måste anges"),
  constraintModulesBridge: yup.array().of(yup.string()).required("Måste anges"),
});

export function SchedulePeriod({ fragmentRef }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useUpdateSchedulePeriodMutation();
  const { addSnack } = useSnackbar();

  const initialValues = useMemo<FormValues>(
    () => ({
      id: data.id,
      constraintModules: data.constraintModules.slice(),
      periodLengthWeeks: data.periodLengthWeeks,
      wrapAroundPeriodEnd: data.wrapAroundPeriodEnd,
      constraintModulesBridge: data.constraintModulesBridge.slice(),
    }),
    [data],
  );

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } }).catch(() => {
      addSnack({ severity: "error", message: "Uppdatering misslyckades" });
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <SchedulePeriodForm />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}
