/**
 * @generated SignedSource<<46365935b849ef255c807cb80535fb77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScheduleWarningsDrawer_fragment$data = {
  readonly viewer: {
    readonly errors: ReadonlyArray<{
      readonly activity: {
        readonly end: string;
        readonly start: string;
      } | null;
      readonly hardError: boolean;
      readonly message: string;
      readonly module: string;
      readonly user: {
        readonly id: string;
        readonly name: string;
      } | null;
    }> | null;
  };
  readonly " $fragmentType": "ScheduleWarningsDrawer_fragment";
};
export type ScheduleWarningsDrawer_fragment$key = {
  readonly " $data"?: ScheduleWarningsDrawer_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScheduleWarningsDrawer_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScheduleWarningsDrawer_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ValidationResult",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ValidationError",
          "kind": "LinkedField",
          "name": "errors",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "module",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "message",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ActivityNode",
              "kind": "LinkedField",
              "name": "activity",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "start",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "end",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UserSnapshotNode",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hardError",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ScheduleValidationWarnings",
  "abstractKey": null
};

(node as any).hash = "b891450ce601fd98b3b47d03cf91ffcc";

export default node;
