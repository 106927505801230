import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import type { IEnvironment } from "relay-runtime";

import type {
  UpdateUserSettingBoxBase2Mutation as Mutation,
  UpdateUserSettingBoxBase2Mutation$variables as Variables,
} from "./types";

const mutation = graphql`
  mutation UpdateUserSettingBoxBase2Mutation($input: UpdateUserSettingInput!) {
    updateUserSetting(input: $input) {
      ok
      userSetting {
        ...UserSettingBoxBase2_fragment
      }
    }
  }
`;

export async function UpdateUserSettingBoxBase2(
  environment: IEnvironment,
  variables: Variables,
) {
  return commitMutation<Mutation>(environment, {
    mutation,
    variables,
  });
}
