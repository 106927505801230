import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useTeamGroups } from "contexts/TeamGroupsContext";
import { minutes2HHMM } from "helpers/dateFunctions";
import type { UserTimebanks_fragment$key as UserTimebankKey } from "hooks/types";
import { useUserTimebanks } from "hooks/UserTimebanks";
import { Duration } from "luxon";
import type { MRT_ColumnDef } from "material-react-table";
import type { ExtractNode } from "relay-help/arrays";
import { connectionToArray } from "relay-help/arrays";
import { ruleGroupExceptionsUrl, userExceptionsUrl } from "utils/urls";

import { CompetenceChip } from "components/common/CompetenceChip";
import Table, { sortListBy } from "components/common/MRTable";
import { TeamChip } from "components/common/TeamChip";

import EditUserItem from "./EditUserItem";
import type { Member, MemberList_fragment$key as Key } from "./types";
import { TranslateEmploymentForm, TranslateEmploymentTitle } from "./types";

const TABLE_CONFIG_KEY = "member-list";

const fragment = graphql`
  fragment MemberList_fragment on UserNode @relay(plural: true) {
    id
    fullName
    firstName
    lastName
    email
    employmentDegree
    employmentForm
    employmentTitle
    ruleGroups {
      edges {
        node {
          id
          name
          teamGroup {
            id
          }
        }
      }
    }
    resolvedSettings(teamGroupId: $teamGroupId) {
      shiftsPerPeriod
      hoursPerPeriod
      hourlyCost
      adminTimeSettings {
        restrictAdminTime
        adminHoursMin
        adminHoursMax
      }
      resourceTimeSettings {
        restrictResourceTime
        resourceHoursMin
        resourceHoursMax
      }
    }
    competences {
      edges {
        node {
          id
          name
          color
        }
      }
    }
    timebankBaseBalance
    userSetting {
      settingModules
    }
    memberOf {
      edges {
        node {
          id
          name
          color
        }
      }
    }
  }
`;

type Team = ExtractNode<Member["memberOf"]>;
type Competence = ExtractNode<Member["competences"]>;
type RuleGroup = ExtractNode<Member["ruleGroups"]>;

function round(value: number, decimals: number) {
  const multiplier = Math.pow(10, decimals || 0);
  return Math.round(value * multiplier) / multiplier;
}

// ----------------------------

type Props = {
  fragmentRef: Key;
  userTimebankFragmentRef: UserTimebankKey;
  periodLengthWeeks: number;
  onEdit: (id: string) => void;
};

export function MemberList({
  fragmentRef,
  userTimebankFragmentRef,
  periodLengthWeeks,
  onEdit,
}: Props) {
  const members = useFragment<Key>(fragment, fragmentRef);
  const userTimebanks = useUserTimebanks(userTimebankFragmentRef);
  const { selectedTeamGroup } = useTeamGroups();
  const selectedTeamGroupTeams: string[] = connectionToArray(
    selectedTeamGroup?.teams,
  ).map((t) => t.id);
  const teamGroupId = selectedTeamGroup?.id;

  const includeRelevantTeamsFilter = useCallback(
    (team: Team) => team.id && selectedTeamGroupTeams.includes(team.id),
    [selectedTeamGroupTeams],
  );

  const getUserTimebank = useCallback(
    (userId: string) =>
      userTimebanks?.users?.find(
        (ut: { user: { id: string } | null; timebank: number }) =>
          ut.user?.id === userId,
      )?.timebank || 0,
    [userTimebanks],
  );

  const columns = useMemo<MRT_ColumnDef<Member>[]>(
    () => [
      {
        header: "Redigera",
        enableGrouping: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        accessorFn: (member) => (
          <EditUserItem onEdit={() => onEdit(member.id)} />
        ),
      },
      {
        accessorKey: "fullName",
        header: "Namn",
        minSize: 120,
        enableGrouping: false,
        aggregationFn: "count",
        AggregatedCell: ({ cell }) => `${cell.getValue()} personer`,
        Footer: ({ table }) => {
          const peopleCount = table.getFilteredRowModel().flatRows.length || 0;
          return <Typography>{peopleCount} personer</Typography>;
        },
      },
      {
        header: "Avdelningar",
        enableColumnDragging: false,
        enableGrouping: false,
        id: "teams",
        accessorFn: (member) => connectionToArray(member.memberOf),
        filterFn: (row, _id, filterValue) =>
          connectionToArray(row.original.memberOf).some((x: any) =>
            x.name?.toLowerCase().includes(filterValue.toLowerCase()),
          ),
        sortingFn: (a, b) =>
          sortListBy<Team>(
            connectionToArray(a.original.memberOf),
            connectionToArray(b.original.memberOf),
            (x) => x.name || "",
          ),
        Cell: ({ cell }) => (
          <Stack direction="row" gap={0.5}>
            {cell
              .getValue<Team[]>()
              .filter(includeRelevantTeamsFilter)
              .map((x: Team) => (
                <TeamChip
                  key={`${cell.row.original.id}-${x.id}`}
                  team={x}
                  size="small"
                />
              ))}
          </Stack>
        ),
      },
      {
        accessorKey: "employmentTitle",
        header: "Yrkestitel",
        enableColumnDragging: false,
        accessorFn: (member) =>
          TranslateEmploymentTitle(member.employmentTitle),
      },
      {
        accessorKey: "employmentForm",
        header: "Anställningsform",
        enableColumnDragging: false,
        accessorFn: (member) => TranslateEmploymentForm(member.employmentForm),
      },
      {
        accessorKey: "employmentDegree",
        header: "Tjänstegrad",
        enableColumnDragging: false,
        accessorFn: (member) =>
          Math.round(Number(member.employmentDegree) * 100) / 100,
        Cell: ({ cell }) => `${round(cell.getValue<number>(), 2)}%`,
        aggregationFn: "sum",
        AggregatedCell: ({ cell }) =>
          `${round(cell.getValue<number>(), 2)} heltider`,
        Footer: ({ table }) => {
          const sum = table
            .getFilteredRowModel()
            .flatRows.map((x) => x.original.employmentDegree)
            .reduce((acc, curr) => acc + Number(curr) / 100, 0);
          return <Typography>{round(sum, 2)} heltider</Typography>;
        },
      },
      {
        header: "Regelgrupp",
        enableGrouping: false,
        id: "ruleGroup",
        accessorFn: (member) =>
          connectionToArray(member.ruleGroups).find(
            (r) => r.teamGroup?.id === teamGroupId,
          ),
        sortingFn: (a, b) =>
          b
            .getValue<RuleGroup>("ruleGroup")
            ?.name.localeCompare(a.getValue<RuleGroup>("ruleGroup")?.name),
        Cell: ({ cell }) => {
          const { id, name } = cell.getValue<RuleGroup>() || {};
          if (!id) return "-";
          return (
            <Typography
              component={Link}
              to={ruleGroupExceptionsUrl(teamGroupId!, id)}
            >
              {name}
            </Typography>
          );
        },
      },
      {
        header: "Personliga regler",
        enableGrouping: false,
        id: "settingModules",
        accessorFn: (member) =>
          (member?.userSetting?.settingModules || []).filter(Boolean),
        sortingFn: (a, b) =>
          (b.original?.userSetting?.settingModules || []).filter(Boolean)
            .length -
          (a.original?.userSetting?.settingModules || []).filter(Boolean)
            .length,
        Cell: ({ cell }) => {
          const memberId = cell.row.original?.id;
          const nModules = cell.getValue<string[]>().length || 0;
          const text = nModules ? `${nModules} st.` : "-";
          return (
            <Typography
              component={Link}
              to={userExceptionsUrl(teamGroupId!, memberId)}
            >
              {text}
            </Typography>
          );
        },
      },
      {
        accessorKey: "resolvedSettings.shiftsPerPeriod",
        header: "Pass",
        Cell: ({ cell }) => (
          <Typography>{round(cell.getValue<number>() || 0, 2)}</Typography>
        ),
        Footer: ({ table }) => {
          const sum = table
            .getFilteredRowModel()
            .flatRows.map(
              (x) => x.original?.resolvedSettings?.shiftsPerPeriod || 0,
            )
            .reduce((acc, curr) => acc + Number(curr), 0);
          return <Typography>{round(sum, 2)} pass</Typography>;
        },
      },
      {
        id: "freeDaysPerPeriod",
        header: "Fridagar",
        accessorFn: (member) =>
          periodLengthWeeks * 7 -
          (member?.resolvedSettings?.shiftsPerPeriod || 0),
        Cell: ({ cell }) => (
          <Typography>{round(cell.getValue<number>(), 2)}</Typography>
        ),
        Footer: ({ table }) => {
          const sum = table
            .getFilteredRowModel()
            .flatRows.map(
              (x) =>
                periodLengthWeeks * 7 -
                (x.original?.resolvedSettings?.shiftsPerPeriod || 0),
            )
            .reduce((acc, curr) => acc + Number(curr), 0);
          return <Typography>{Math.max(round(sum, 2), 0)} fridagar</Typography>;
        },
      },
      {
        accessorKey: "resolvedSettings.hoursPerPeriod",
        header: "Arbetstid",
        Cell: ({ cell }) => minutes2HHMM(60 * (cell.getValue<number>() || 0)),
        Footer: ({ table }) => {
          const sum = table
            .getFilteredRowModel()
            .flatRows.map(
              (x) => x.original?.resolvedSettings?.hoursPerPeriod || 0,
            )
            .reduce((acc, curr) => acc + Number(curr), 0);
          return <Typography>{minutes2HHMM(sum * 60)}</Typography>;
        },
      },
      {
        accessorKey: "resolvedSettings.adminTimeSettings.adminHoursMin",
        header: "Ansvarstid (min)",
        Cell: ({ cell }) => {
          const restrict =
            cell.row.original?.resolvedSettings?.adminTimeSettings
              .restrictAdminTime;
          return (
            <Typography>
              {restrict
                ? Duration.fromObject({
                    hours: cell.getValue<number>(),
                  }).toFormat("hh:mm")
                : "-"}
            </Typography>
          );
        },
        Footer: ({ table }) => {
          const sum = table
            .getFilteredRowModel()
            .flatRows.map((x) =>
              x.original?.resolvedSettings?.adminTimeSettings.restrictAdminTime
                ? x.original?.resolvedSettings?.adminTimeSettings
                    .adminHoursMin || 0
                : 0,
            )
            .reduce((acc, curr) => acc + Number(curr), 0);
          return (
            <Typography>
              {Duration.fromObject({ hours: sum }).toFormat("hh:mm")}
            </Typography>
          );
        },
      },
      {
        accessorKey: "resolvedSettings.adminTimeSettings.adminHoursMax",
        header: "Ansvarstid (max)",
        Cell: ({ cell }) => {
          const restrict =
            cell.row.original?.resolvedSettings?.adminTimeSettings
              .restrictAdminTime;
          return (
            <Typography>
              {restrict
                ? Duration.fromObject({
                    hours: cell.getValue<number>(),
                  }).toFormat("hh:mm")
                : "-"}
            </Typography>
          );
        },
        Footer: ({ table }) => {
          const sum = table
            .getFilteredRowModel()
            .flatRows.map((x) =>
              x.original?.resolvedSettings?.adminTimeSettings.restrictAdminTime
                ? x.original?.resolvedSettings?.adminTimeSettings
                    .adminHoursMax || 0
                : 0,
            )
            .reduce((acc, curr) => acc + Number(curr), 0);
          return (
            <Typography>
              {Duration.fromObject({ hours: sum }).toFormat("hh:mm")}
            </Typography>
          );
        },
      },
      {
        accessorKey: "resolvedSettings.resourceTimeSettings.resourceHoursMin",
        header: "Resurstid (min)",
        Cell: ({ cell }) => {
          const restrict =
            cell.row.original?.resolvedSettings?.resourceTimeSettings
              .restrictResourceTime;
          return (
            <Typography>
              {restrict
                ? Duration.fromObject({
                    hours: cell.getValue<number>(),
                  }).toFormat("hh:mm")
                : "-"}
            </Typography>
          );
        },
        Footer: ({ table }) => {
          const sum = table
            .getFilteredRowModel()
            .flatRows.map((x) =>
              x.original?.resolvedSettings?.resourceTimeSettings
                .restrictResourceTime
                ? x.original?.resolvedSettings?.resourceTimeSettings
                    .resourceHoursMin || 0
                : 0,
            )
            .reduce((acc, curr) => acc + Number(curr), 0);
          return (
            <Typography>
              {Duration.fromObject({ hours: sum }).toFormat("hh:mm")}
            </Typography>
          );
        },
      },
      {
        accessorKey: "resolvedSettings.resourceTimeSettings.resourceHoursMax",
        header: "Resurstid (max)",
        Cell: ({ cell }) => {
          const restrict =
            cell.row.original?.resolvedSettings?.resourceTimeSettings
              .restrictResourceTime;
          return (
            <Typography>
              {restrict
                ? Duration.fromObject({
                    hours: cell.getValue<number>(),
                  }).toFormat("hh:mm")
                : "-"}
            </Typography>
          );
        },
        Footer: ({ table }) => {
          const sum = table
            .getFilteredRowModel()
            .flatRows.map((x) =>
              x.original?.resolvedSettings?.resourceTimeSettings
                .restrictResourceTime
                ? x.original?.resolvedSettings?.resourceTimeSettings
                    .resourceHoursMax || 0
                : 0,
            )
            .reduce((acc, curr) => acc + Number(curr), 0);
          return (
            <Typography>
              {Duration.fromObject({ hours: sum }).toFormat("hh:mm")}
            </Typography>
          );
        },
      },
      {
        id: "averageShiftLength",
        header: "Passlängd (snitt)",
        accessorFn: (member) => {
          const shiftsPerPeriod =
            member?.resolvedSettings?.shiftsPerPeriod || 0;
          if (shiftsPerPeriod > 0) {
            return (
              (member?.resolvedSettings?.hoursPerPeriod || 0) / shiftsPerPeriod
            );
          } else {
            return 0;
          }
        },
        Cell: ({ cell }) => (
          <Typography>
            {Duration.fromObject({
              minutes: 60 * cell.getValue<number>(),
            }).toFormat("hh:mm")}
          </Typography>
        ),
      },
      {
        accessorKey: "resolvedSettings.hourlyCost",
        header: "Timkostnad",
        Cell: ({ cell }) => (
          <Typography>{round(cell.getValue<number>() || 0, 2)} kr</Typography>
        ),
        Footer: ({ table }) => {
          const peopleCount = table.getFilteredRowModel().flatRows.length || 1;
          const sum = table
            .getFilteredRowModel()
            .flatRows.map((x) => x.original?.resolvedSettings?.hourlyCost || 0)
            .reduce((acc, curr) => acc + Number(curr), 0);
          return (
            <Typography>
              {Math.round((10 * sum) / peopleCount) / 10} kr
            </Typography>
          );
        },
      },
      {
        header: "Timbank",
        enableGrouping: false,
        accessorKey: "timebankBaseBalance",
        accessorFn: (member) => getUserTimebank(member.id) || 0,
        Cell: ({ cell }) => minutes2HHMM(Math.round(cell.getValue<number>())),
        aggregationFn: "sum",
        AggregatedCell: ({ cell }) => minutes2HHMM(cell.getValue<number>()),
        Footer: ({ table }) => {
          const sum = minutes2HHMM(
            table
              .getFilteredRowModel()
              .flatRows.reduce(
                (acc, curr) =>
                  acc +
                  Number(
                    curr?.original?.id ? getUserTimebank(curr.original.id) : 0,
                  ),
                0,
              ),
          );
          return <Typography>{sum}</Typography>;
        },
      },
      {
        header: "Kompetenser",
        enableGrouping: false,
        id: "competences",
        accessorFn: (member) => connectionToArray(member.competences),
        filterFn: (row, _id, filterValue) =>
          connectionToArray(row.original.competences).some((x: any) =>
            x.name?.toLowerCase().includes(filterValue.toLowerCase()),
          ),
        sortingFn: (a, b) =>
          sortListBy<Competence>(
            connectionToArray(a.original.competences),
            connectionToArray(b.original.competences),
            (x) => x.name || "",
          ),
        Cell: ({ cell }) => {
          const member = cell.row.original;
          const l = cell.getValue<Competence[]>();
          if (!l) return <></>;
          return (
            <Stack gap={0.5} direction="row">
              {l.map((d: Competence) => (
                <CompetenceChip key={`${member?.id}-${d.id}`} competence={d} />
              ))}
            </Stack>
          );
        },
      },
    ],
    [
      periodLengthWeeks,
      onEdit,
      getUserTimebank,
      includeRelevantTeamsFilter,
      teamGroupId,
    ],
  );

  return (
    <Table
      tableConfigKey={TABLE_CONFIG_KEY}
      columns={columns as any[]}
      data={members as any[]}
      initialState={{
        sorting: [
          { id: "teams", desc: true },
          { id: "fullName", desc: false },
        ],
      }}
      renderEmptyRowsFallback={() => (
        <Typography sx={{ fontStyle: "italic" }}>
          Inga användare inlagda på denna enhet
        </Typography>
      )}
    />
  );
}
