import { Suspense } from "react";
import { TeamGroupSettingsQuery$data as TeamGroupData } from "pages/Settings/__generated__/TeamGroupSettingsQuery.graphql";
import { TeamGroupUsers } from "pages/types";
import { ConstraintModuleSwitch_fragment$key as ConstraintModuleSwitchKey } from "settings/common/__generated__/ConstraintModuleSwitch_fragment.graphql";
import { ALL_SETTINGS } from "settings/constants";

import { Visible } from "components/common/Visible";
import { SettingLevel3Placeholder } from "components/loading/pages";

import { Level3Box } from "../../common/Level3Box";

import { SettingBoxAB1 } from "./SettingBoxAB1";
import { SettingBoxAB2 } from "./SettingBoxAB2";
import { SettingBoxBase2 } from "./SettingBoxBase2";
import { SettingBoxBase3 } from "./SettingBoxBase3";
import { SettingBoxBase4 } from "./SettingBoxBase4";
import { SettingBoxBase5 } from "./SettingBoxBase5";
import { SettingBoxBase7 } from "./SettingBoxBase7";
import { SettingBoxBase8 } from "./SettingBoxBase8";
import { SettingBoxFree1 } from "./SettingBoxFree1";
import { SettingBoxRhythm2 } from "./SettingBoxRhythm2";
import { SettingBoxRhythm3 } from "./SettingBoxRhythm3";
import { SettingBoxTypes1 } from "./SettingBoxTypes1";
import { SettingBoxTypes2 } from "./SettingBoxTypes2";
import { SettingBoxTypes3 } from "./SettingBoxTypes3";
import { SettingBoxTypes5 } from "./SettingBoxTypes5";
import { SettingBoxTypes6 } from "./SettingBoxTypes6";
import { SettingBoxTypes7 } from "./SettingBoxTypes7";
import { SettingBoxWorktime1 } from "./SettingBoxWorktime1";
import { SettingBoxWorktime2 } from "./SettingBoxWorktime2";
import { SettingGroupAdvanced } from "./SettingGroupAdvanced";
import { TeamGroupSettingView } from "./TeamGroupSettingView";

type Props = {
  ruleId?: string;
  shiftTypes: any[];
  shifts: { worktimeHours: number }[];
  fragmentRef: any;
  teamGroupMembers: TeamGroupUsers;
  constraintModulesRef: ConstraintModuleSwitchKey;
  ruleGroups: TeamGroupData["ruleGroups"];
};

export function SettingBoxes({
  ruleId = "",
  shiftTypes,
  shifts,
  ...refs
}: Props) {
  return (
    <Suspense fallback={<SettingLevel3Placeholder />}>
      <Level3Box>
        {ALL_SETTINGS.map((setting) => (
          <Visible key={setting.urlId} visible={ruleId === setting.urlId}>
            <TeamGroupSettingView optimizationSetting={setting} {...refs} />
          </Visible>
        ))}
        <Visible visible={ruleId === "d-1"}>
          <SettingBoxBase2 fragmentRef={refs.fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "d-2"}>
          <SettingBoxBase3
            fragmentRef={refs.fragmentRef}
            shiftTypes={shiftTypes}
          />
        </Visible>
        <Visible visible={ruleId === "d-3"}>
          <SettingBoxBase4 fragmentRef={refs.fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "b-4"}>
          <SettingBoxBase5 fragmentRef={refs.fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "b-6"}>
          <SettingBoxBase7 fragmentRef={refs.fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "d-4"}>
          <SettingBoxBase8 fragmentRef={refs.fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "ab-0"}>
          <SettingBoxAB1 fragmentRef={refs.fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "ab-1"}>
          <SettingBoxAB2 fragmentRef={refs.fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "r-1"}>
          <SettingBoxRhythm2 fragmentRef={refs.fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "r-2"}>
          <SettingBoxRhythm3 fragmentRef={refs.fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "w-0"}>
          <SettingBoxFree1 fragmentRef={refs.fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "s-0"}>
          <SettingBoxTypes1 fragmentRef={refs.fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "s-1"}>
          <SettingBoxTypes2 fragmentRef={refs.fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "s-2"}>
          <SettingBoxTypes3
            fragmentRef={refs.fragmentRef}
            shiftTypes={shiftTypes}
          />
        </Visible>
        <Visible visible={ruleId === "n-0"}>
          <SettingBoxTypes5 fragmentRef={refs.fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "n-1"}>
          <SettingBoxTypes6 fragmentRef={refs.fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "n-2"}>
          <SettingBoxTypes7 fragmentRef={refs.fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "t-0"}>
          <SettingBoxWorktime1 fragmentRef={refs.fragmentRef} />
        </Visible>
        <Visible visible={ruleId === "t-1"}>
          <SettingBoxWorktime2 fragmentRef={refs.fragmentRef} />
        </Visible>
        <SettingGroupAdvanced
          fragmentRef={refs.fragmentRef}
          ruleId={ruleId}
          shifts={shifts}
        />
      </Level3Box>
    </Suspense>
  );
}
