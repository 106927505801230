import { Radio, Stack, TextField, Typography } from "@mui/material";
import { DateTime } from "luxon";

type Props = {
  value: Date | null;
  onChange: (value: Date | null) => void;
};

export default function UntilDateSelect({ value, onChange }: Props) {
  function handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value === "until") {
      onChange(new Date());
    } else {
      onChange(null);
    }
  }
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(event.target.valueAsDate || null);
  }

  const valueStr = value
    ? DateTime.fromJSDate(value).toFormat("yyyy-MM-dd")
    : "";
  return (
    <Stack direction="row" sx={{ display: "flex", alignItems: "center" }}>
      <Radio value="until" checked={!!value} onChange={handleRadioChange} />
      <Typography sx={{ pr: 1 }}>Sista datum</Typography>
      <TextField
        type="date"
        value={valueStr}
        onChange={handleChange}
        disabled={!value}
        size="small"
        sx={{ width: 230 }}
      />
    </Stack>
  );
}
