import { Stack, Typography } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { WeightField } from "settings/common/TeamGroupOnlyForms";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { SmallSwitch } from "components/forms/Switch";

import { shiftDistributionSetting } from "./ShiftDistributionSetting";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function ShiftDistributionTeamGroupOnlyForm({
  disabled: disabledProp,
  readOnly,
}: Props) {
  const disabled = !useIsConstraintModuleEnabled(
    shiftDistributionSetting.moduleName,
  );
  const formDisabled = disabled || disabledProp;

  return (
    <PaperWrapper>
      <Stack gap={2}>
        <Stack gap={1}>
          <Stack gap={2} direction="row">
            <Typography variant="h4">
              Eftersträva rättvis fördelning av passtyper
            </Typography>
            <SmallSwitch
              name="optimizeEvenShiftTypeDistributionBetweenEmployees"
              disabled={formDisabled}
              readOnly={readOnly}
            />
          </Stack>
          <Typography>
            Med denna inställning påslagen, så kommer optimeringsalgoritmen att
            eftersträva en rättvis fördelning av olika passtyper mellan
            medarbetare. Detta är alltid en mjuk regel.
          </Typography>
        </Stack>
        <WeightField
          weightFieldName="shiftTypeDistributionWeight"
          moduleName={shiftDistributionSetting.moduleName}
          readOnly={readOnly}
        />
      </Stack>
    </PaperWrapper>
  );
}
