import { useCallback, useMemo } from "react";
import { Add as AddIcon } from "@mui/icons-material";
import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  calculateTimeDuration,
  deriveShiftDayType,
  deriveWorktime,
} from "business_logic/Shifts";
import { minutes2HHMM } from "helpers/dateFunctions";
import useFormikState from "hooks/useFormikState";
import { useBooleanState } from "utils/useBooleanState";

import CustomField from "components/forms/CustomField";

import type { ShiftInput } from "../types";

import { initialShiftPart } from "./constants";
import { DeleteButton } from "./DeleteButton";
import { useShiftForm } from "./ShiftFormContext";
import ShiftPartTable from "./ShiftPartTable";
import { ShowButton } from "./ShowButton";

type Props = {
  name: string;
  onRemove: () => void;
};

export function ShiftTableRow({ name, onRemove }: Props) {
  const {
    value: showParts,
    setValue: setShowParts,
    toggle: toggleShowParts,
  } = useBooleanState();
  const { value: shift, setValue: setShift } = useFormikState<ShiftInput>(name);
  const parts = shift.shiftParts;

  const getName = useCallback((k: string) => `${name}.${k}`, [name]);

  const workTime = minutes2HHMM(deriveWorktime(shift));
  const duration = minutes2HHMM(calculateTimeDuration(shift.start, shift.end));

  const { dayTypeBreakpoints } = useShiftForm();
  const dayType = useMemo(
    () => deriveShiftDayType(shift, dayTypeBreakpoints),
    [shift, dayTypeBreakpoints],
  );

  function onAddPart() {
    const newPart = { ...initialShiftPart };
    newPart.start = shift.start;
    newPart.end = shift.end;

    const partsLength = shift.shiftParts.length;
    if (partsLength > 0) {
      newPart.start = shift.shiftParts[partsLength - 1].end;
    }

    const newShift = {
      ...shift,
      shiftParts: [...shift.shiftParts, newPart],
    };
    setShift(newShift);
    setShowParts(true);
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <CustomField type="text" name={getName("name")} fullWidth />
        </TableCell>
        <TableCell>
          <CustomField
            type="time"
            name={getName("start")}
            sx={{ minWidth: 100 }}
          />
        </TableCell>
        <TableCell>
          <CustomField
            type="time"
            name={getName("end")}
            sx={{ minWidth: 100 }}
          />
        </TableCell>
        <TableCell>
          <CustomField
            type="number"
            name={getName("breakTime")}
            sx={{ minWidth: 68 }}
          />
        </TableCell>
        <TableCell>
          <Typography sx={{ fontSize: 14 }}>{duration}</Typography>
        </TableCell>
        <TableCell>
          <Typography sx={{ fontSize: 14 }}>{workTime}</Typography>
        </TableCell>
        <TableCell>
          <Typography sx={{ fontSize: 14 }}>{dayType}</Typography>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center">
            <ShowButton
              show={parts?.length > 0 && showParts}
              toggle={toggleShowParts}
              disabled={parts?.length === 0}
            />
            <Typography variant="caption">{parts?.length || 0}st.</Typography>
            <IconButton onClick={onAddPart}>
              <AddIcon />
            </IconButton>
          </Stack>
        </TableCell>
        <TableCell>
          <DeleteButton onClick={onRemove} />
        </TableCell>
      </TableRow>

      {!!parts?.length && (
        <TableRow>
          <TableCell colSpan={9} sx={{ p: 0, borderBottom: "none" }}>
            <ShiftPartTable open={showParts} name={getName("shiftParts")} />
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
