/**
 * @generated SignedSource<<cdaaeccc83460878959b2c7cb2ef8790>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingBoxWorktime1_fragment$data = {
  readonly adminHoursMax: number;
  readonly adminHoursMin: number;
  readonly constraintModules: ReadonlyArray<string>;
  readonly id: string;
  readonly " $fragmentType": "SettingBoxWorktime1_fragment";
};
export type SettingBoxWorktime1_fragment$key = {
  readonly " $data"?: SettingBoxWorktime1_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingBoxWorktime1_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingBoxWorktime1_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminHoursMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminHoursMax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "37a49a9959f2e2459829f750b44264a9";

export default node;
