import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { UpdateUserSettingNightShiftsMutation as Mutation } from "./types";

const mutation = graphql`
  mutation UpdateUserSettingNightShiftsMutation(
    $input: UpdateUserSettingInput!
  ) {
    updateUserSetting(input: $input) {
      ok
      userSetting {
        ...UserSettingNightShifts_fragment
      }
    }
  }
`;

export const useUpdateUserSettingNightShifts = () =>
  useMutation<Mutation>(mutation);
