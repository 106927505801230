import type { ComponentProps } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import { Visible } from "components/common/Visible";

import { RuleGroupSettingNightShifts as NightShifts } from "./RuleGroupSettingNightShifts";
import { RuleGroupSettingParttimes as Parttimes } from "./RuleGroupSettingParttimes";
import type { RuleGroupSettingGroupAdvanced_fragment$key as Key } from "./types";

type Props = {
  fragmentRef: Key;
  ruleId: string;
  periodLengthWeeks: number;
  shifts: ComponentProps<typeof Parttimes>["shifts"];
};

const fragment = graphql`
  fragment RuleGroupSettingGroupAdvanced_fragment on RuleGroupSettingNode {
    ...RuleGroupSettingNightShifts_fragment
    ...RuleGroupSettingParttimes_fragment
  }
`;

export function RuleGroupSettingGroupAdvanced({
  fragmentRef,
  ruleId,
  periodLengthWeeks,
  shifts,
}: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);

  return (
    <>
      <Visible visible={ruleId === "advanced-1"}>
        <Parttimes
          fragmentRef={data}
          shifts={shifts}
          periodLengthWeeks={periodLengthWeeks}
        />
      </Visible>
      <Visible visible={ruleId === "advanced-6"}>
        <NightShifts fragmentRef={data} />
      </Visible>
    </>
  );
}
