import type { PropsWithChildren, ReactNode } from "react";
import {
  Check as SuccessIcon,
  WarningRounded as WarningIcon,
} from "@mui/icons-material";
import { Stack } from "@mui/material";

type BoxProps = {
  icon: ReactNode;
  borderColor: string;
};

export function NoticeBox({
  borderColor,
  icon,
  children,
}: PropsWithChildren<BoxProps>) {
  return (
    <Stack
      direction="row"
      gap={2}
      px={2}
      py={1.5}
      sx={{
        borderRadius: "0.25rem",
        borderColor,
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 10,
      }}
    >
      {icon}
      {children}
    </Stack>
  );
}

export function SuccessBox({ children }: PropsWithChildren<{}>) {
  const color = "success.main";

  return (
    <NoticeBox
      borderColor={color}
      icon={<SuccessIcon sx={{ color }} />}
      children={children}
    />
  );
}

export function WarningBox({ children }: PropsWithChildren<{}>) {
  const color = "warning.main";

  return (
    <NoticeBox
      borderColor={color}
      icon={<WarningIcon sx={{ color }} />}
      children={children}
    />
  );
}

export default WarningBox;
