/**
 * @generated SignedSource<<073428f75d3db98d01e46b6d43e2f9dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingWishedDaysOff_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly id: string;
  readonly maximizeApprovedWishes: boolean;
  readonly optimizeApprovedWishesDistributionBetweenEmployees: boolean;
  readonly " $fragmentType": "SettingWishedDaysOff_fragment";
};
export type SettingWishedDaysOff_fragment$key = {
  readonly " $data"?: SettingWishedDaysOff_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingWishedDaysOff_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingWishedDaysOff_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maximizeApprovedWishes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeApprovedWishesDistributionBetweenEmployees",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "bba9e7239a6f4af3d4545d6d90977710";

export default node;
