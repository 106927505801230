import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { resourceShiftsWeightChoices } from "./constants";
import { Types2Form } from "./forms";
import { UpdateSettingBoxTypes2 } from "./mutations";
import type {
  SettingBoxTypes2_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment SettingBoxTypes2_fragment on SettingNode {
    id
    optimizeResourceDistributionWithinTeams
    optimizeResourceDistributionBetweenTeams
    optimizeResourceDistributionBetweenEmployees
    resourceShiftsWeight
    constraintModules
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string(),
  optimizeResourceDistributionWithinTeams: yup.boolean().required(),
  optimizeResourceDistributionBetweenTeams: yup.boolean().required(),
  optimizeResourceDistributionBetweenEmployees: yup.boolean().required(),
  resourceShiftsWeight: yup
    .string()
    .oneOf(resourceShiftsWeightChoices)
    .required(),
  constraintModules: yup.array().of(yup.string()),
});

type Props = {
  fragmentRef: Key;
};

function Content({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const teamGroup = useCurrentTeamGroup();
  const data = useFragment<Key>(fragment, fragmentRef);
  const initialValues: FormValues = {
    id: data?.id || "",
    optimizeResourceDistributionWithinTeams:
      data?.optimizeResourceDistributionWithinTeams !== false, // default false
    optimizeResourceDistributionBetweenTeams:
      data?.optimizeResourceDistributionBetweenTeams !== false, // default false
    optimizeResourceDistributionBetweenEmployees:
      data?.optimizeResourceDistributionBetweenEmployees !== false, // default false
    resourceShiftsWeight: data?.resourceShiftsWeight || "M",
    constraintModules: data?.constraintModules.slice() || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateSettingBoxTypes2(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Types2Form groupName={teamGroup?.name || ""} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxTypes2({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
