import { useCallback, useMemo } from "react";
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  calculateTimeDuration,
  derivePartWorktime,
} from "business_logic/Shifts";
import { FieldArray } from "formik";
import { minutes2HHMM } from "helpers/dateFunctions";
import useFormikState from "hooks/useFormikState";
import type { FormikFieldArrayFn } from "types/formik";

import CustomField from "components/forms/CustomField";

import type { ShiftPartInput, ShiftPartTypeOption } from "../types";
import { shiftPartTypeChoices, TranslateShiftPartType } from "../types";

import { DeleteButton } from "./DeleteButton";
import SelectField from "./SelectField";

const partTypeOptions: ShiftPartTypeOption[] = shiftPartTypeChoices.map(
  (t) => ({
    value: t,
    label: TranslateShiftPartType(t) || "",
  }),
);

type FieldArrayProps = FormikFieldArrayFn<ShiftPartInput>;

type RowProps = {
  name: string;
  onRemove: () => void;
};

type Props = {
  open: boolean;
  name: string;
};

function RenderErrors({ error }: { error?: string | string[] }) {
  const errorStr = useMemo(() => {
    if (Array.isArray(error)) {
      return error
        .flatMap((x) => (typeof x === "string" ? x : Object.values(x)))
        .join(", ");
    }
    if (typeof error === "string") {
      return error;
    }
    if (typeof error === "object") {
      return Object.values(error).join(", ");
    }
    return "";
  }, [error]);

  return (
    <Typography color="error" variant="caption">
      {errorStr}
    </Typography>
  );
}

function ShiftPartRow({ name, onRemove }: RowProps) {
  const {
    value,
    meta: { error },
  } = useFormikState(name);
  const { start, end } = value || {};

  const duration = useMemo<string>(
    () => minutes2HHMM(calculateTimeDuration(start, end)),
    [start, end],
  );
  const workTime = useMemo<string>(
    () => minutes2HHMM(derivePartWorktime(value)),
    [value],
  );

  const getName = useCallback((k: string) => `${name}.${k}`, [name]);

  return (
    <>
      {!!error && (
        <TableRow>
          <TableCell
            colSpan={9}
            sx={{ py: 0, pl: 2, pr: 0, m: 0, borderBottom: "none" }}
          >
            <RenderErrors error={error} />
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        <TableCell sx={{ minWidth: 150, pl: 5 }}>
          <SelectField
            name={getName("partType")}
            options={partTypeOptions}
            fullWidth
          />
        </TableCell>
        <TableCell sx={{ width: 135 }}>
          <CustomField
            type="time"
            name={getName("start")}
            sx={{ minWidth: 100 }}
          />
        </TableCell>
        <TableCell sx={{ width: 135 }}>
          <CustomField
            type="time"
            name={getName("end")}
            sx={{ minWidth: 100 }}
          />
        </TableCell>
        <TableCell sx={{ width: 100 }} />
        <TableCell sx={{ width: 69 }}>
          <Typography>{duration}</Typography>
        </TableCell>
        <TableCell sx={{ width: 87 }}>
          <Typography>{workTime}</Typography>
        </TableCell>
        <TableCell sx={{ width: 89 }} />
        <TableCell sx={{ width: 132 }} />
        <TableCell align="right" sx={{ width: 71 }}>
          <DeleteButton onClick={onRemove} />
        </TableCell>
      </TableRow>
    </>
  );
}

export default function ShiftPartTable({ name, open }: Props) {
  const { value: parts } = useFormikState(name);
  return (
    <Collapse in={open} sx={{ p: 0, m: 0 }}>
      <Table size="small">
        <TableBody>
          {!!parts?.length && (
            <FieldArray name={name}>
              {({ remove }: FieldArrayProps) =>
                (parts || []).map((_: unknown, i: number) => (
                  <ShiftPartRow
                    key={`passdel-${i}`}
                    name={`${name}.${i}`}
                    onRemove={() => remove(i)}
                  />
                ))
              }
            </FieldArray>
          )}
        </TableBody>
      </Table>
    </Collapse>
  );
}
