import { Suspense, useEffect } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  fetchQuery,
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from "react-relay/hooks";
import { Paper } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";

import { PageWrapper } from "components/layout/PageWrapper";
import { ShiftsPlaceholder as Placeholder } from "components/loading/pages";
import {
  ShiftForm,
  ShiftHeader,
  ShiftProfileChart,
  ShiftTimeline,
} from "components/shifts";

import { ShiftsQuery } from "./__generated__/ShiftsQuery.graphql";

const query = graphql`
  query ShiftsQuery($teamGroupId: ID!) {
    shiftProfile(teamGroupId: $teamGroupId) {
      ...ShiftProfileChart_fragment
    }
    shiftTimeline(teamGroupId: $teamGroupId) {
      ...ShiftTimeline_fragment
    }
    shifts(teamGroupId: $teamGroupId) {
      ...ShiftForm_fragment
    }
    settingForTeamGroup(teamGroupId: $teamGroupId) {
      morningStartsNightEnds
      eveningStartsMorningEnds
      nightStartsEveningEnds
      fullDayDuration
    }
  }
`;

type InnerProps = {
  queryRef: PreloadedQuery<ShiftsQuery>;
};

function Inner({ queryRef }: InnerProps) {
  const data = usePreloadedQuery<ShiftsQuery>(query, queryRef);
  const teamGroupId = useCurrentTeamGroup()?.id || "";
  const env = useRelayEnvironment();

  async function refresh() {
    await fetchQuery(env, query, { teamGroupId }).toPromise();
  }

  return (
    <>
      <Paper variant="box" sx={{ p: 2, pr: 0 }}>
        <ShiftTimeline fragmentRef={data.shiftTimeline} />
        <ShiftProfileChart fragmentRef={data.shiftProfile} />
      </Paper>
      <Suspense fallback={null}>
        <ShiftForm
          fragmentRef={data.shifts}
          teamGroupId={teamGroupId}
          shiftDayTypeBreakpoints={data.settingForTeamGroup}
          afterSubmit={refresh}
        />
      </Suspense>
    </>
  );
}

export function Shifts() {
  const [queryRef, loadQuery] = useQueryLoader<ShiftsQuery>(query);
  const teamGroup = useCurrentTeamGroup();
  const teamGroupId = teamGroup?.id || "";
  const teamGroupName = teamGroup?.name || "";

  useEffect(() => {
    if (!teamGroupId) return;
    loadQuery({ teamGroupId });
  }, [loadQuery, teamGroupId]);

  return (
    <PageWrapper header={<ShiftHeader teamGroupName={teamGroupName} />}>
      <Suspense fallback={<Placeholder />}>
        {!!queryRef && <Inner queryRef={queryRef} />}
      </Suspense>
    </PageWrapper>
  );
}
