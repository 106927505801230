import { Stack, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";
import PaperWrapper from "settings/common/PaperWrapper";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { DayChips } from "components/common/DayCard";

import { resourceTimeSetting } from "../ResourceTimeSetting";

type Props = {
  readOnly?: boolean;
};

function FormikDayChips({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  const { value, setValue } = useFormikState<number[]>(
    "prohibitedResourceWeekDays",
  );

  function onChange(i: number) {
    if (value.includes(i)) {
      setValue(value.filter((x) => x !== i));
    } else {
      setValue([...value, i]);
    }
  }

  return (
    <DayChips
      checked={value}
      onToggle={onChange}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function ResourceTimeUserOnlyForm({ readOnly }: Props) {
  const disabled = !useIsConstraintModuleEnabled(
    resourceTimeSetting.moduleName,
  );

  return (
    <PaperWrapper>
      <Stack gap={2}>
        <Stack gap={1}>
          <Typography variant="h4">
            Begränsa resurspass på givna dagar
          </Typography>
          <Typography>
            De veckodagar du väljer kommer inte få några resurspass tilldelade.
          </Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <FormikDayChips readOnly={readOnly} disabled={disabled} />
        </Stack>
      </Stack>
    </PaperWrapper>
  );
}
