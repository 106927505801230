import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { NightShiftsOldForm } from "settings/NightShift/NightShiftsOldForm";
import * as yup from "yup";

import { AutoSubmit } from "components/setting/common";

import {
  nightShiftRestrictionChoices,
  nightShiftRotationChoices,
} from "../constants";
import { useUpdateUserSettingNightShifts } from "../mutations";
import type { UpdateUserSettingInput as FormValues } from "../types";

import type { UserSettingNightShifts_fragment$key as Key } from "./types";

type Props = {
  fragmentRef: Key;
};

const fragment = graphql`
  fragment UserSettingNightShifts_fragment on UserSettingNode {
    id
    settingModules
    nightShiftRestriction
    nightShiftRotation
    user {
      fullName
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  constraintModules: yup.array().of(yup.string()),
  nightShiftRestriction: yup
    .string()
    .oneOf(nightShiftRestrictionChoices)
    .required(),
  nightShiftRotation: yup.string().oneOf(nightShiftRotationChoices).required(),
});

export function UserSettingNightShifts({ fragmentRef }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useUpdateUserSettingNightShifts();
  const initialValues = useMemo<FormValues>(
    () => ({
      id: data.id,
      constraintModules: data.settingModules.slice(),
      nightShiftRestriction: data.nightShiftRestriction,
      nightShiftRotation: data.nightShiftRotation,
    }),
    [data],
  );

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <NightShiftsOldForm />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}
