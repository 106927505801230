import { useCallback } from "react";
import { useFragment } from "react-relay/hooks";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { minutes2HHMM } from "helpers/dateFunctions";

import { TranslateKPIType } from "../types";

import { ProfileTotalsChart_fragment$key as Key } from "./types";

const fragment = graphql`
  fragment ProfileTotalsChart_fragment on ScheduleNode {
    scheduleProfile {
      viewerTotals {
        teamId
        competenceId
        kpiTypes {
          hours
          kpiName
        }
      }
    }
  }
`;

type Props = {
  fragmentRef: Key;
  teamId: string | null;
  competence: string | null;
};

export function ProfileTotalsChart({ fragmentRef, teamId, competence }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);

  const teamFilter = useCallback((row: any) => row.teamId === teamId, [teamId]);
  const competenceFilter = useCallback(
    (row: any) => row.competenceId === competence,
    [competence],
  );

  const selectedData = data.scheduleProfile.viewerTotals.find(
    (el) => teamFilter(el) && competenceFilter(el),
  );

  const thead = (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell>Tid</TableCell>
        <TableCell>Andel av behov</TableCell>
      </TableRow>
    </TableHead>
  );

  const demand = selectedData?.kpiTypes.find((k) => k.kpiName === "demand");
  const getRatio = (demand: number, kpi: number) =>
    demand > 0 ? `${Math.round((1000 * kpi) / demand) / 10}%` : "-";

  const tableRow = (kpi_title: string, bold: boolean) => {
    const kpiData = selectedData?.kpiTypes.find((k) => k.kpiName === kpi_title);
    const name =
      TranslateKPIType(kpiData?.kpiName || "") || TranslateKPIType(kpi_title);
    const minutes = Math.round((kpiData?.hours || 0) * 60);
    const ratio = getRatio(demand?.hours || 0, kpiData?.hours || 0);

    if (bold) {
      return (
        <TableRow
          sx={{
            bgcolor: "ture.10",
          }}
        >
          <TableCell>
            <b>{name}</b>
          </TableCell>
          <TableCell align="center">
            <b>=</b>
          </TableCell>
          <TableCell>
            <b>{minutes2HHMM(minutes)}</b>
          </TableCell>
          <TableCell>
            <b>{ratio}</b>
          </TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow>
          <TableCell>{name}</TableCell>
          <TableCell align="center">
            <Typography variant="caption" lineHeight={1} color="grey">
              +
            </Typography>
          </TableCell>
          <TableCell>{minutes2HHMM(minutes)}</TableCell>
          <TableCell>{ratio}</TableCell>
        </TableRow>
      );
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={6}>
        <Box sx={{ overflowX: "auto" }}>
          <TableContainer>
            <Table size="small">
              {thead}
              <TableBody>
                {tableRow("covered_demand", false)}
                {tableRow("overstaffed", false)}
                {tableRow("staffed", true)}
                {tableRow("resources", false)}
                {tableRow("apt", false)}
                {tableRow("work_outside_demand", false)}
                {tableRow("scheduled", true)}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
      <Grid xs={6}>
        <Box sx={{ overflowX: "auto" }}>
          <TableContainer>
            <Table size="small">
              {thead}
              <TableBody>
                {tableRow("understaffed_actual", false)}
                {tableRow("understaffed_covered", false)}
                {tableRow("understaffed_apt", false)}
                {tableRow("understaffed", true)}
                {tableRow("covered_demand", false)}
                {tableRow("shift_breaks", false)}
                {tableRow("demand", true)}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
}
