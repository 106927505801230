/**
 * @generated SignedSource<<f444e01c1fcc10fa4e698ab220851992>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingDayTypeDistributionWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SettingBoxTypes5_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly dayShiftDistributionShare: number;
  readonly dayTypeDistributionWeight: ApiSettingDayTypeDistributionWeightChoices;
  readonly eveningShiftDistributionShare: number;
  readonly fullDayShiftDistributionShare: number;
  readonly id: string;
  readonly nightShiftDistributionShare: number;
  readonly periodLengthWeeks: number;
  readonly shiftDayTypeAllowedErrorMargin: number;
  readonly shiftDayTypeDistributionSoft: boolean;
  readonly shiftsPerWeek: number;
  readonly " $fragmentType": "SettingBoxTypes5_fragment";
};
export type SettingBoxTypes5_fragment$key = {
  readonly " $data"?: SettingBoxTypes5_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingBoxTypes5_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingBoxTypes5_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftDayTypeDistributionSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftDayTypeAllowedErrorMargin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eveningShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullDayShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayTypeDistributionWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsPerWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodLengthWeeks",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "86035ece47194ef5c43bd895668b4b22";

export default node;
