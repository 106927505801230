import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { Rhythm2Form } from "./forms";
import { UpdateUserSettingBoxRhythm2 } from "./mutations";
import type {
  UpdateUserSettingInput as FormValues,
  UserSettingBoxRhythm2_fragment$key as Key,
} from "./types";

const fragment = graphql`
  fragment UserSettingBoxRhythm2_fragment on UserSettingNode {
    id
    maxConsecutiveFreeDays
    maxConsecutiveFreeDaysSoft
    minConsecutiveFreeDays
    minConsecutiveFreeDaysSoft
    settingModules
    user {
      fullName
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  maxConsecutiveFreeDays: yup
    .number()
    .min(1, "Måste vara minst 1")
    .max(20, "Måste vara högst 20")
    .required("Får ej vara tomt"),
  maxConsecutiveFreeDaysSoft: yup.boolean().required(),
  minConsecutiveFreeDays: yup
    .number()
    .min(1, "Måste vara minst 1")
    .max(20, "Måste vara högst 20")
    .required("Får ej vara tomt"),
  minConsecutiveFreeDaysSoft: yup.boolean().required(),
  constraintModules: yup.array().of(yup.string()).required(),
});

type Props = {
  fragmentRef: Key;
};

function Content({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const data = useFragment<Key>(fragment, fragmentRef);
  const { fullName: name = "" } = data?.user || {};
  const initialValues = {
    id: data?.id || "",
    maxConsecutiveFreeDays: data?.maxConsecutiveFreeDays || 2,
    maxConsecutiveFreeDaysSoft: data?.maxConsecutiveFreeDaysSoft !== false, // default true
    minConsecutiveFreeDays: data?.minConsecutiveFreeDays || 2,
    minConsecutiveFreeDaysSoft: data?.minConsecutiveFreeDaysSoft !== false, // default true
    constraintModules: data?.settingModules.slice() || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateUserSettingBoxRhythm2(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Rhythm2Form name={name} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function UserSettingBoxRhythm2({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
