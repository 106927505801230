import { useCallback } from "react";
import { Autocomplete, Divider, ListItem, Paper } from "@mui/material";

import { OptionCheckbox } from "./OptionCheckbox";
import { SelectAllOption } from "./SelectAllOption";
import type {
  DisableClearable,
  FreeSolo,
  GenericOwnerState,
  GenericRenderOptionState,
  MultiAutocompleteProps,
  MultiProps,
} from "./types";

type RenderOptionParams = React.HTMLAttributes<HTMLLIElement>;
type OwnerState<V> = GenericOwnerState<V, true>;
type PaperComponentProps = React.PropsWithChildren<any>;

export function MultiSelect<V = any>({
  onSelectAll,
  selectAllLabel,
  ...props
}: MultiProps<V>) {
  const { value, options } = props;

  const renderOption = useCallback(
    (
      params: RenderOptionParams,
      option: V,
      { selected }: GenericRenderOptionState,
      ownerState: OwnerState<V>,
    ) => (
      <ListItem {...params}>
        <OptionCheckbox checked={selected} />
        {ownerState.getOptionLabel(option)}
      </ListItem>
    ),
    [],
  );

  const PaperComponent = useCallback(
    (paperProps: PaperComponentProps) => {
      if (!onSelectAll) return <Paper {...paperProps} />;

      const { children, ...rest } = paperProps;

      return (
        <Paper {...rest} sx={{ pt: 1 }}>
          <SelectAllOption
            label={selectAllLabel}
            checked={value?.length === options.length}
            onClick={onSelectAll}
          />
          <Divider />
          {children}
        </Paper>
      );
    },
    [selectAllLabel, onSelectAll, value, options],
  );

  return (
    <Autocomplete<V, true, DisableClearable, FreeSolo>
      disableClearable
      renderOption={renderOption}
      PaperComponent={PaperComponent}
      {...(props as MultiAutocompleteProps<V>)}
      multiple
    />
  );
}
