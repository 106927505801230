import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";

import { AutoSubmit } from "components/setting/common";
import { useSnackbar } from "components/Snackbar";

import { DemandProfileForm } from "./forms";
import { useUpdateDemandProfileMutation } from "./mutations";
import type {
  DemandProfile_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

type Props = { fragmentRef: Key };

const fragment = graphql`
  fragment DemandProfile_fragment on SettingNode {
    id
    uniqueWeeks
    useDutyDemand
    useDemandWeights
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required("Får ej vara tomt"),
  uniqueWeeks: yup.boolean().required("Måste anges"),
  useDutyDemand: yup.boolean().required("Måste anges"),
  useDemandWeights: yup.boolean().required("Måste anges"),
});

export function DemandProfile({ fragmentRef }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useUpdateDemandProfileMutation();
  const { addSnack } = useSnackbar();
  const initialValues = useMemo<FormValues>(
    () => ({
      id: data.id,
      uniqueWeeks: data.uniqueWeeks,
      useDutyDemand: data.useDutyDemand,
      useDemandWeights: data.useDemandWeights,
    }),
    [data],
  );

  async function onSubmit(input: FormValues) {
    await commit({
      variables: { input },
    }).catch(() => {
      addSnack({
        severity: "error",
        message: "Uppdatering hos behovsprofil misslyckades",
      });
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <DemandProfileForm />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}
