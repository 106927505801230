import graphql from "babel-plugin-relay/macro";
import { connectionToArray, ExtractNode } from "relay-help/arrays";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctionsOnlyTeamGroup,
} from "settings/common/optimizationSetting";
import { NN } from "types";
import * as yup from "yup";

import { workDayCadenceRestrictionSchema } from "components/setting/common/forms";

import {
  WorkDayCadenceSettingTeamGroup_fragment$data as TeamGroupData,
  WorkDayCadenceSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/WorkDayCadenceSettingTeamGroup_fragment.graphql";
import {
  WorkDayCadenceSettingUserSetting_fragment$data as UserSettingData,
  WorkDayCadenceSettingUserSetting_fragment$key as UserSettingKey,
} from "./__generated__/WorkDayCadenceSettingUserSetting_fragment.graphql";
import { WorkDayCadence } from "./WorkDayCadenceForm";
import { WorkDayCadenceInfo } from "./WorkDayCadenceInfo";

const teamGroupFragment = graphql`
  fragment WorkDayCadenceSettingTeamGroup_fragment on SettingNode {
    id
    workDayCadenceRestrictions {
      edges {
        node {
          id
          isSoft
          maxCadence
          calendarDays
        }
      }
    }
    constraintModules
  }
`;
const userSettingFragment = graphql`
  fragment WorkDayCadenceSettingUserSetting_fragment on UserSettingNode {
    id
    workDayCadenceRestrictions {
      edges {
        node {
          id
          isSoft
          maxCadence
          calendarDays
        }
      }
    }
    settingModules
  }
`;

const SETTING_NAME = "Schemarytm";
const MODULE_NAME = "WorkDayCadence";
const SETTING_URL_ID = "rhythm";

type WorkDayCadenceRestrictions = ExtractNode<
  NN<TeamGroupData["workDayCadenceRestrictions"]>
>;
type WorkDayCadenceFormValues = {
  id: string;
  workDayCadenceRestrictions: ReadonlyArray<WorkDayCadenceRestrictions>;
  readonly constraintModules: readonly string[];
};

type AdditionalData = {};

const validationSchema = yup.object().shape({
  id: yup.string(),
  workDayCadenceRestrictions: yup
    .array()
    .of(workDayCadenceRestrictionSchema)
    .required(),
});

function convertTeamGroupDataToInitialValues(
  teamGroupData: TeamGroupData,
): WorkDayCadenceFormValues {
  return {
    id: teamGroupData.id,
    workDayCadenceRestrictions: connectionToArray(
      teamGroupData.workDayCadenceRestrictions,
    ),
    constraintModules: teamGroupData.constraintModules,
  };
}

function convertUserDataToInitialValues(
  userSettingData: UserSettingData,
): WorkDayCadenceFormValues {
  return {
    id: userSettingData?.id || "",
    workDayCadenceRestrictions: connectionToArray(
      userSettingData.workDayCadenceRestrictions,
    ),
    constraintModules: userSettingData.settingModules,
  };
}

function renderComponent(
  initialValues: WorkDayCadenceFormValues,
  onSubmit: (formValues: WorkDayCadenceFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <WorkDayCadence />
    </BaseSettings>
  );
}

export const workdayCadenceSetting: OptimizationSetting<
  TeamGroupKey,
  UserSettingKey,
  undefined,
  WorkDayCadenceFormValues,
  AdditionalData
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment,
  ruleGroups: undefined,
  convertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctionsOnlyTeamGroup,
  renderComponent,
  InfoComponent: WorkDayCadenceInfo,
};
