import { Delete as DeleteIcon } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";

export function DeleteButton({ onClick }: { onClick: () => void }) {
  return (
    <IconButton onClick={onClick} color="primary" size="small" sx={{ p: 0 }}>
      <Stack alignItems="center">
        <DeleteIcon />
        <Typography variant="caption">Radera</Typography>
      </Stack>
    </IconButton>
  );
}
