import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { Types6Form } from "./forms";
import { UpdateUserSettingBoxTypes6 } from "./mutations";
import type {
  UpdateUserSettingInput as FormValues,
  UserSettingBoxTypes6_fragment$key as Key,
} from "./types";

const fragment = graphql`
  fragment UserSettingBoxTypes6_fragment on UserSettingNode {
    id
    settingModules
    includeInDayTypeWeekendFairness
    user {
      fullName
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  currentTeamGroupId: yup.string().required(),
  constraintModules: yup.array().of(yup.string()).required(),
  includeInDayTypeWeekendFairness: yup.boolean().required(),
});

type Props = {
  fragmentRef: Key;
};

const FormContainer = ({ fragmentRef }: Props) => {
  const environment = useRelayEnvironment();
  const data = useFragment<Key>(fragment, fragmentRef);
  const { fullName: name = "" } = data?.user || {};
  const teamGroup = useCurrentTeamGroup();
  const currentTeamGroupId = teamGroup?.id || "";

  const initialValues = {
    id: data?.id || "",
    currentTeamGroupId,
    constraintModules: data?.settingModules.slice() || [],
    includeInDayTypeWeekendFairness:
      data?.includeInDayTypeWeekendFairness || false,
  };

  async function onSubmit(input: FormValues) {
    await UpdateUserSettingBoxTypes6(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Types6Form name={name} groupName={teamGroup?.name || ""} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
};

export function UserSettingBoxTypes6({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <FormContainer fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
