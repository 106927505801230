import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { connectionToArray } from "relay-help/arrays";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { weightChoices } from "./constants";
import { Base4Form } from "./forms";
import { UpdateSettingBoxBase4 } from "./mutations";
import type {
  SettingBoxBase4_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment SettingBoxBase4_fragment on SettingNode {
    id
    group {
      teams {
        edges {
          node {
            teamDemandSetting {
              id
              allowUnderstaffing
              understaffingWeight
              weekendUnderstaffingWeight
              overstaffingWeight
              weekendOverstaffingWeight
              team {
                id
                name
                color
              }
            }
          }
        }
      }
    }
  }
`;

const weightSchema = yup.string().oneOf(weightChoices, "Ogiltig vikt");

const teamDemandSettingSchema = yup.object().shape({
  id: yup.string().required("Måste anges"),
  allowUnderstaffing: yup.boolean().required("Måste anges"),
  understaffingWeight: weightSchema.required("Måste anges"),
  weekendUnderstaffingWeight: weightSchema.required("Måste anges"),
  overstaffingWeight: weightSchema.required("Måste anges"),
});

const validationSchema = yup.object().shape({
  id: yup.string().required("Måste anges"),
  teamDemandSettings: yup
    .array()
    .of(teamDemandSettingSchema)
    .required("Måste anges"),
});

type Props = {
  fragmentRef: Key;
};

function Content({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const teamGroup = useCurrentTeamGroup();
  const data = useFragment(fragment, fragmentRef);
  const initialValues: FormValues = {
    id: data?.id || "",
    teamDemandSettings: connectionToArray(data.group?.teams).map(
      (x) => x.teamDemandSetting,
    ),
  };

  async function onSubmit(values: FormValues) {
    const input = {
      ...values,
      teamDemandSettings: (values.teamDemandSettings || []).map((setting) => ({
        id: setting?.id || "",
        allowUnderstaffing: setting?.allowUnderstaffing || false,
        understaffingWeight: setting?.understaffingWeight || "M",
        weekendUnderstaffingWeight: setting?.weekendUnderstaffingWeight || "M",
        overstaffingWeight: setting?.overstaffingWeight || "M",
        weekendOverstaffingWeight: setting?.weekendOverstaffingWeight || "M",
      })),
    };
    await UpdateSettingBoxBase4(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Base4Form groupName={teamGroup?.name || ""} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxBase4({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
