/**
 * @generated SignedSource<<f7d842e015f869ac6c2b444466c0896c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiBlockedTimeSlotTimeslotTypeChoices = "K" | "O" | "P" | "V" | "W" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditBlockedTimeSlot_fragment$data = {
  readonly approvedByAdmin: boolean;
  readonly end: string | null;
  readonly id: string;
  readonly recurrences: string | null;
  readonly shift: {
    readonly id: string;
  } | null;
  readonly shiftDate: string | null;
  readonly start: string | null;
  readonly timeslotType: ApiBlockedTimeSlotTimeslotTypeChoices;
  readonly users: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly fullName: string;
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "EditBlockedTimeSlot_fragment";
};
export type EditBlockedTimeSlot_fragment$key = {
  readonly " $data"?: EditBlockedTimeSlot_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditBlockedTimeSlot_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditBlockedTimeSlot_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "start",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "end",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeslotType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvedByAdmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recurrences",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftNode",
      "kind": "LinkedField",
      "name": "shift",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNodeConnection",
      "kind": "LinkedField",
      "name": "users",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BlockedTimeSlotNode",
  "abstractKey": null
};
})();

(node as any).hash = "dd14380830de198074fe929da498a28c";

export default node;
