export function toCamelCase(x: Record<string, any>) {
  const o: typeof x = {};

  const snakeToCamel = (s: string): string =>
    s
      .toLowerCase()
      .replace(/([_][a-z])/, (g) => g.toUpperCase().replace("_", ""));

  Object.keys(x).forEach((k) => {
    const newK = snakeToCamel(k);
    let v = x[k];
    if (typeof v === "object") {
      v = toCamelCase(v);
    }
    o[newK] = v;
  });

  return o;
}
