import { Link } from "react-router-dom";
import {
  InputAdornment,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import useFormikState from "hooks/useFormikState";

import CustomField from "components/forms/CustomField";
import { SmallSwitch } from "components/forms/Switch";
import type { ShiftType } from "components/setting/types";

import { TimebankSelect } from "../components/TimebankSelect";

import { ShiftSelect } from "./common/ShiftSelect";

type Props = {
  readOnly?: boolean;
  shiftTypes: ReadonlyArray<ShiftType>;
};

export function Base3({ readOnly, shiftTypes }: Props) {
  const timebankMode =
    useFormikState<string>("timebankMode")?.value.toUpperCase();

  return (
    <Stack gap={5}>
      <Stack gap={1}>
        <Grid container spacing={2} columns={2}>
          <Grid xs={1}>
            <Typography variant="h4">Per schemaperiod (timmar)</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography variant="h4">Totalt (timmar)</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} columns={2}>
          <Grid xs={1}>
            <Typography>
              Minsta / största tillåtna timbankssaldo per period.
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>
              Minsta / största tillåtna ackumulerade timbankssaldo.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} columns={2}>
          <Grid xs={1}>
            <Stack direction="row" gap={1}>
              <CustomField
                name="minTimebankPeriod"
                fullWidth
                size="small"
                type="number"
                InputProps={{
                  readOnly,
                  endAdornment: (
                    <InputAdornment position="end">min</InputAdornment>
                  ),
                }}
              />
              <CustomField
                name="maxTimebankPeriod"
                fullWidth
                size="small"
                type="number"
                InputProps={{
                  readOnly,
                  endAdornment: (
                    <InputAdornment position="end">max</InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Grid>
          <Grid xs={1}>
            <Stack direction="row" gap={1}>
              <CustomField
                name="minTimebankTotal"
                fullWidth
                size="small"
                type="number"
                InputProps={{
                  readOnly,
                  endAdornment: (
                    <InputAdornment position="end">min</InputAdornment>
                  ),
                }}
              />
              <CustomField
                name="maxTimebankTotal"
                fullWidth
                size="small"
                type="number"
                InputProps={{
                  readOnly,
                  endAdornment: (
                    <InputAdornment position="end">max</InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>

      <Stack gap={1}>
        <Typography variant="h4">
          Hur ska över- och underskottstid fördelas?
        </Typography>
        <Typography>
          Välj mellan att eventuell över- och underskottstid överförs till
          timbanken, eller att den fördelas i mindre portioner genom att vissa
          utvalda pass förkortas eller förlängs.
        </Typography>
        <TimebankSelect name="timebankMode" readOnly={readOnly} />
      </Stack>

      <Stack gap={1}>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant="h4">Eftersträva nollsaldo</Typography>
          <SmallSwitch
            name="offsetTimebankImbalance"
            readOnly={readOnly}
            disabled={timebankMode === "S"}
          />
        </Stack>
        <Typography>
          Om detta fält är påslaget kommer algoritmen eftersträva att det totala
          timbankssaldot (sammanräknat för alla fastställda scheman) för varje
          medarbetare ligger så nära ±0 som möjligt. Det innebär att om en
          medarbetare sedan tidigare ligger på minus i totala timbankssaldo, så
          kommer algoritmen eftersträva att denna medarbetares timbankssaldo i
          nästkommande schema ligger på plus. Detta fält är endast tillgängligt
          om timbanken är påslagen.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Stack gap={1}>
          <Typography variant="h4">
            Otillåtna förkortningar och förlängningar
          </Typography>
          <Typography>
            Här kan du ange vilka passtyper som ska få lov att ha en
            senare/tidigare start respektive ett senare/tidigare slut. Schemats
            olika passtyper anges under fliken som heter{" "}
            <Link to="/shifts">
              <b>Passprofil</b>
            </Link>
            .
          </Typography>
        </Stack>

        <Grid container spacing={2} columns={2}>
          <Grid xs={1}>
            <Stack gap={1}>
              <Typography variant="h4">Tidigare start</Typography>
              <Typography>
                Dessa passtyper får inte ha en tidigare start.
              </Typography>
              <ShiftSelect
                name="shiftsEarlierStartNotOk"
                options={shiftTypes}
                readOnly={readOnly}
              />
            </Stack>
          </Grid>

          <Grid xs={1}>
            <Stack gap={1}>
              <Typography variant="h4">Senare start</Typography>
              <Typography>
                Dessa passtyper får inte ha en senare start.
              </Typography>
              <ShiftSelect
                name="shiftsLaterStartNotOk"
                options={shiftTypes}
                readOnly={readOnly}
              />
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={2} columns={2}>
          <Grid xs={1}>
            <Stack gap={1}>
              <Typography variant="h4">Tidigare slut</Typography>
              <Typography>
                Dessa passtyper får inte ha ett tidigare slut.
              </Typography>
              <ShiftSelect
                name="shiftsEarlierEndNotOk"
                options={shiftTypes}
                readOnly={readOnly}
              />
            </Stack>
          </Grid>

          <Grid xs={1}>
            <Stack gap={1}>
              <Typography variant="h4">Senare slut</Typography>
              <Typography>
                Dessa passtyper får inte ha ett senare slut.
              </Typography>
              <ShiftSelect
                name="shiftsLaterEndNotOk"
                options={shiftTypes}
                readOnly={readOnly}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
