import type { ComponentProps } from "react";
import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks";
import * as yup from "yup";

import { AutoSubmit } from "components/setting/common";

import { shiftsEmploymentDegreeLogicChoices } from "../constants";
import { ParttimesForm as Form } from "../forms";
import { useUpdateSettingParttimes } from "../mutations";
import type { UpdateSettingInput as FormValues } from "../types";

import type { SettingParttimes_fragment$key as Key } from "./types";

type Props = {
  fragmentRef: Key;
  shifts: ComponentProps<typeof Form>["shifts"];
};

const fragment = graphql`
  fragment SettingParttimes_fragment on SettingNode {
    id
    hoursEmploymentDegreeFactor
    shiftsEmploymentDegreeFactor
    shiftsEmploymentDegreeLogic
    totalHoursMargin
    totalShiftsMargin
    periodLengthWeeks
    shiftsPerWeek
    hoursPerWeek
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  hoursEmploymentDegreeFactor: yup
    .number()
    .min(0, "Måste vara minst 0%")
    .max(100, "Måste vara högst 100%")
    .required("Får ej vara tomt"),
  shiftsEmploymentDegreeFactor: yup
    .number()
    .min(0, "Måste vara minst 0%")
    .max(100, "Måste vara högst 100%")
    .required("Får ej vara tomt"),
  shiftsEmploymentDegreeLogic: yup
    .string()
    .oneOf(shiftsEmploymentDegreeLogicChoices)
    .required(),
  totalHoursMargin: yup
    .number()
    .min(0, "Måste vara minst 0%")
    .max(100, "Måste vara högst 100%")
    .required("Får ej vara tomt"),
  totalShiftsMargin: yup
    .number()
    .min(0, "Måste vara minst 0%")
    .max(100, "Måste vara högst 100%")
    .required("Får ej vara tomt"),
});

export function SettingParttimes({ fragmentRef, shifts }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useUpdateSettingParttimes();
  const teamGroup = useCurrentTeamGroup();

  const initialValues = useMemo<FormValues>(
    () => ({
      id: data.id,
      hoursEmploymentDegreeFactor: data.hoursEmploymentDegreeFactor,
      shiftsEmploymentDegreeFactor: data.shiftsEmploymentDegreeFactor,
      shiftsEmploymentDegreeLogic: data.shiftsEmploymentDegreeLogic,
      totalHoursMargin: data.totalHoursMargin,
      totalShiftsMargin: data.totalShiftsMargin,
    }),
    [data],
  );

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Form
            groupName={teamGroup?.name ?? ""}
            periodLengthWeeks={data.periodLengthWeeks}
            shiftsPerWeek={data.shiftsPerWeek}
            hoursPerWeek={data.hoursPerWeek}
            shifts={shifts}
          />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}
