import { Grid } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import type { CompetenceDemandSetting as CompetenceDemandSettingType } from "components/setting/types";

import { CompetenceDemandSetting } from "./CompetenceDemandSetting";

type Props = { name: string; disabled?: boolean; readOnly?: boolean };

export function CompetenceDemandSettings({ name, disabled, readOnly }: Props) {
  const { value } = useFormikState<CompetenceDemandSettingType[]>(name);

  return (
    <Grid container spacing={2} paddingTop={1}>
      {value?.map((_, index) => (
        <Grid key={index} item md={12} lg={6} xl={4}>
          <CompetenceDemandSetting
            name={`${name}.${index}`}
            disabled={disabled}
            readOnly={readOnly}
          />
        </Grid>
      ))}
    </Grid>
  );
}
