/**
 * @generated SignedSource<<065ce156e60fef7dbe6c867dd31ed96a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingBoxBase2_fragment$data = {
  readonly hoursPerWeek: number;
  readonly id: string;
  readonly periodLengthWeeks: number;
  readonly shiftsPerWeek: number;
  readonly " $fragmentType": "SettingBoxBase2_fragment";
};
export type SettingBoxBase2_fragment$key = {
  readonly " $data"?: SettingBoxBase2_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingBoxBase2_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingBoxBase2_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodLengthWeeks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursPerWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsPerWeek",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "c518e347357865f8bf5725f5c71d6f39";

export default node;
