import { Suspense, useEffect, useState } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  fetchQuery,
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { useTeamGroups } from "contexts/TeamGroupsContext";
import { useCurrentTeamGroup, useOrganisationParams } from "hooks";
import { useConnectionToArray } from "relay-help/arrays";

import { CompetenceSelectFragmentRefProvider } from "components/common/selectors/CompetenceSelect";
import { RuleGroupSelectFragmentRefProvider } from "components/common/selectors/RuleGroupSelect";
import { TeamSelectFragmentRefProvider } from "components/common/selectors/TeamSelect";
import PageWrapper from "components/layout/PageWrapper";
import { OrganisationPlaceholder as Placeholder } from "components/loading/pages";
import {
  CreateUserForm,
  EditUserForm,
  MemberList,
  OrganisationHeader,
} from "components/organisation";

import type { OrganisationQuery as Query } from "./types";

const query = graphql`
  query OrganisationQuery($teamGroupId: ID!) {
    usersOnTeamGroup(teamGroupId: $teamGroupId) {
      ...MemberList_fragment
    }
    settingForTeamGroup(teamGroupId: $teamGroupId) {
      periodLengthWeeks
    }
    competences {
      ...CompetenceSelect_fragment
    }
    ruleGroups(teamGroupId: $teamGroupId) {
      ...RuleGroupSelect_fragment
    }
    teams {
      ...TeamSelect_fragment
    }
    userTimebanks(teamGroupIds: [$teamGroupId]) {
      ...UserTimebanks_fragment
    }
  }
`;

type Props = { queryRef: PreloadedQuery<Query>; teamGroupId: string };

function Content({ queryRef, teamGroupId }: Props) {
  const data = usePreloadedQuery<Query>(query, queryRef);
  const { newUser: createUser, setNewUser: setCreateUser } =
    useOrganisationParams();
  const [editUser, setEditUser] = useState<string | null>(null);
  const env = useRelayEnvironment();
  const { selectedTeamGroup, teamGroupOptions } = useTeamGroups();

  async function refresh() {
    if (!teamGroupId) return;
    await fetchQuery(env, query, { teamGroupId }).toPromise();
  }

  const teams = useConnectionToArray(selectedTeamGroup?.teams);

  const onEdit = (id: string) => setEditUser(id);
  const openCreateMember = () => setCreateUser(true);
  const closeCreateMember = () => setCreateUser(false);
  const editMemberOpen = !!editUser;
  const closeEditMember = () => setEditUser(null);

  return (
    <>
      <PageWrapper
        header={
          <OrganisationHeader
            openCreateMember={openCreateMember}
            groupName={selectedTeamGroup?.name || ""}
            teams={teams}
          />
        }
      >
        <MemberList
          fragmentRef={data.usersOnTeamGroup}
          userTimebankFragmentRef={data.userTimebanks}
          onEdit={onEdit}
          periodLengthWeeks={data.settingForTeamGroup?.periodLengthWeeks || 1}
        />
      </PageWrapper>
      <CompetenceSelectFragmentRefProvider value={data.competences}>
        <RuleGroupSelectFragmentRefProvider value={data.ruleGroups}>
          <TeamSelectFragmentRefProvider value={data.teams}>
            <CreateUserForm
              open={createUser}
              onClose={closeCreateMember}
              teamGroups={teamGroupOptions}
              teamGroupId={teamGroupId}
              refresh={refresh}
              initialValues={{ teamGroups: [teamGroupId] }}
            />
            {!!editUser && (
              <EditUserForm
                open={editMemberOpen}
                onClose={closeEditMember}
                userId={editUser}
                teamGroupId={teamGroupId}
                teamGroups={teamGroupOptions}
              />
            )}
          </TeamSelectFragmentRefProvider>
        </RuleGroupSelectFragmentRefProvider>
      </CompetenceSelectFragmentRefProvider>
    </>
  );
}

export function Organisation() {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const { clear } = useOrganisationParams();
  const teamGroup = useCurrentTeamGroup();
  const teamGroupId = teamGroup?.id || "";
  const name = teamGroup?.name || "";

  useEffect(() => {
    if (!teamGroupId) return;
    loadQuery({ teamGroupId }, { fetchPolicy: "store-and-network" });
    clear();
  }, [teamGroupId, loadQuery, clear]);

  return (
    <Suspense fallback={<Placeholder name={name} />}>
      {!!queryRef && <Content queryRef={queryRef} teamGroupId={teamGroupId} />}
    </Suspense>
  );
}
