import { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/common/Accordion/FormAccordion";
import { FormikTeamSelect as TeamSelect } from "components/common/selectors/TeamSelect";
import type { MemberTeamGroupType } from "components/organisation/types";

import { TeamGroupSelect } from "./TeamGroupSelect";

type Props = {
  expanded: boolean;
  onChange: () => void;
  teamGroups: MemberTeamGroupType[];
};

type ContentProps = {
  teamGroups: MemberTeamGroupType[];
  groupsField: string;
  teamsField: string;
};

export function TeamAccordionContent({
  teamGroups,
  groupsField,
  teamsField,
}: ContentProps) {
  const { value: selectedTeamGroups } = useFormikState(groupsField);

  const filteredGroupIds = useMemo(
    () =>
      teamGroups
        .filter((teamGroup) => selectedTeamGroups.includes(teamGroup.id))
        .map((x) => x.id),
    [teamGroups, selectedTeamGroups],
  );

  return (
    <Stack gap={1}>
      <TeamGroupSelect
        name={groupsField}
        teamGroups={teamGroups}
        disableCloseOnSelect
      />
      <TeamSelect
        multiple
        name={teamsField}
        groupByGroup
        filterOnGroupIds={filteredGroupIds}
        disableCloseOnSelect
      />
    </Stack>
  );
}

export function TeamAccordion({ expanded, onChange, teamGroups }: Props) {
  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary>
        <Typography variant="h4">Enhets- och avdelningstillhörighet</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TeamAccordionContent
          teamGroups={teamGroups}
          groupsField="teamGroups"
          teamsField="teams"
        />
      </AccordionDetails>
    </Accordion>
  );
}
