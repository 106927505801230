/**
 * @generated SignedSource<<302b77f01ad347249103bee412abc35d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSettingBoxRhythm3_fragment$data = {
  readonly id: string;
  readonly maxConsecutiveWorkDays: number;
  readonly maxConsecutiveWorkDaysSoft: boolean;
  readonly minConsecutiveWorkDays: number;
  readonly minConsecutiveWorkDaysSoft: boolean;
  readonly settingModules: ReadonlyArray<string>;
  readonly user: {
    readonly fullName: string;
  };
  readonly workDayCadenceRestrictions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly calendarDays: number;
        readonly id: string;
        readonly isSoft: boolean;
        readonly maxCadence: number;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "UserSettingBoxRhythm3_fragment";
};
export type UserSettingBoxRhythm3_fragment$key = {
  readonly " $data"?: UserSettingBoxRhythm3_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSettingBoxRhythm3_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserSettingBoxRhythm3_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveWorkDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveWorkDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveWorkDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveWorkDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserWorkDayCadenceRestrictionNodeConnection",
      "kind": "LinkedField",
      "name": "workDayCadenceRestrictions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserWorkDayCadenceRestrictionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserWorkDayCadenceRestrictionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isSoft",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maxCadence",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "calendarDays",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserSettingNode",
  "abstractKey": null
};
})();

(node as any).hash = "537a2779949ab47f3f63ccdca715d542";

export default node;
