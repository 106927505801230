import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { connectionToArray } from "relay-help/arrays";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { Base3Form } from "./forms";
import { UpdateSettingBoxBase3 } from "./mutations";
import type {
  SettingBoxBase3_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment SettingBoxBase3_fragment on SettingNode {
    id
    timebankMode
    offsetTimebankImbalance
    maxTimebankPeriod
    minTimebankPeriod
    maxTimebankTotal
    minTimebankTotal
    shiftsLaterStartNotOk {
      edges {
        node {
          id
          name
        }
      }
    }
    shiftsLaterEndNotOk {
      edges {
        node {
          id
          name
        }
      }
    }
    shiftsEarlierStartNotOk {
      edges {
        node {
          id
          name
        }
      }
    }
    shiftsEarlierEndNotOk {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const validationSchema = yup.object().shape({
  maxTimebankPeriod: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(168, "Måste vara högst 168 timmar")
    .required("Får ej vara tomt"),
  minTimebankPeriod: yup
    .number()
    .min(-168, "Måste vara minst -168 timmar")
    .max(0, "Måste vara högst 0")
    .required("Får ej vara tomt"),
  maxTimebankTotal: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(168, "Måste vara högst 168 timmar")
    .required("Får ej vara tomt"),
  minTimebankTotal: yup
    .number()
    .min(-168, "Måste vara minst -168 timmar")
    .max(0, "Måste vara högst 0")
    .required("Får ej vara tomt"),
  shiftsLaterStartNotOk: yup.array().of(yup.string()).required("Måste anges"),
  shiftsLaterEndNotOk: yup.array().of(yup.string()).required("Måste anges"),
  shiftsEarlierStartNotOk: yup.array().of(yup.string()).required("Måste anges"),
  shiftsEarlierEndNotOk: yup.array().of(yup.string()).required("Måste anges"),
});

type Props = {
  fragmentRef: Key;
  shiftTypes: any[];
};

function Content({ fragmentRef, shiftTypes }: Props) {
  const environment = useRelayEnvironment();
  const teamGroup = useCurrentTeamGroup();
  const data = useFragment<Key>(fragment, fragmentRef);
  const initialValues: FormValues = {
    id: data?.id || "",
    timebankMode: data?.timebankMode || "S",
    offsetTimebankImbalance: data?.offsetTimebankImbalance !== false,
    maxTimebankPeriod: data?.maxTimebankPeriod || 0,
    minTimebankPeriod: data?.minTimebankPeriod || 0,
    maxTimebankTotal: data?.maxTimebankTotal || 0,
    minTimebankTotal: data?.minTimebankTotal || 0,
    shiftsLaterStartNotOk: connectionToArray(data.shiftsLaterStartNotOk).map(
      (s) => s.id,
    ),
    shiftsLaterEndNotOk: connectionToArray(data.shiftsLaterEndNotOk).map(
      (s) => s.id,
    ),
    shiftsEarlierStartNotOk: connectionToArray(
      data.shiftsEarlierStartNotOk,
    ).map((s) => s.id),
    shiftsEarlierEndNotOk: connectionToArray(data.shiftsEarlierEndNotOk).map(
      (s) => s.id,
    ),
  };

  async function onSubmit(input: FormValues) {
    await UpdateSettingBoxBase3(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Base3Form
            groupName={teamGroup?.name || ""}
            shiftTypes={shiftTypes}
          />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxBase3({ fragmentRef, shiftTypes }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && (
        <Content fragmentRef={fragmentRef} shiftTypes={shiftTypes} />
      )}
    </Suspense>
  );
}
