import { ApiShiftPartPartTypeChoices as ShiftPartType } from "./form/types";
import { ShiftDayType, ShiftTimelineType } from "./graphs/types";

export * from "./form/types";
export * from "./graphs/types";
export * from "./mutations/types";

export const shiftPartTypeChoices: ShiftPartType[] = ["P", "Z", "J", "M", "B"];

export enum shiftPartTypes {
  APT = "P",
  WORK_OUTSIDE_DEMAND = "Z",
  BILAGA_J_JOUR = "J",
  AB_JOUR = "M",
  UNCOMPENSATED_BREAK = "B",
}

export function TranslateShiftPartType(
  type: ShiftPartType,
): string | undefined {
  switch (type) {
    case shiftPartTypes.APT:
      return "APT";
    case shiftPartTypes.WORK_OUTSIDE_DEMAND:
      return "Ansvarstid";
    case shiftPartTypes.BILAGA_J_JOUR:
      return "Jour (Bilaga J)";
    case shiftPartTypes.AB_JOUR:
      return "Jour (Allmänna Bestämmelser)";
    case shiftPartTypes.UNCOMPENSATED_BREAK:
      return "Hål";
    case "%future added value":
    default:
      return "Okänd passdelstyp";
  }
}

export type ShiftPartTypeOption = {
  value: ShiftPartType;
  label: string;
};

export function TranslateShiftType(shiftType: ShiftTimelineType) {
  switch (shiftType) {
    case "S":
      return "Pass";
    case "B":
      return "Hål";
    case "Z":
      return "Ansvarstid";
    case "P":
      return "APT";
    case "J":
      return "Jour (Bilaga J)";
    case "M":
      return "Jour (AB)";
    case "%future added value":
      return "Annan passtyp";
    default:
      return "Okänd passtyp";
  }
}

export const shiftDayTypeChoices: ShiftDayType[] = ["D", "E", "N", "F"];

export enum shiftDayTypes {
  DAY = "D",
  EVENING = "E",
  NIGHT = "N",
  FULL_DAY = "F",
}
export function TranslateShiftDayType(dayType: ShiftDayType) {
  switch (dayType) {
    case shiftDayTypes.DAY:
      return "Dag";
    case shiftDayTypes.EVENING:
      return "Kväll";
    case shiftDayTypes.NIGHT:
      return "Natt";
    case shiftDayTypes.FULL_DAY:
      return "Dygn";
    case "%future added value":
    default:
      return "Okänd dagtyp";
  }
}

export type DayTypeBreakpoints = {
  morningStartsNightEnds: string;
  eveningStartsMorningEnds: string;
  nightStartsEveningEnds: string;
  fullDayDuration: number;
};
