import { useFragment } from "react-relay/hooks";
import { Button } from "@mui/material";
import graphql from "babel-plugin-relay/macro";

import {
  Fail as ErrorIcon,
  Success as SuccessIcon,
  Warning as WarningIcon,
} from "components/icons";
import { useScheduleWarningsContext } from "components/schedule/schedule_warnings";

import { buttonSx } from "./constants";
import type { WarningsDrawerButton_fragment$key as Key } from "./types";

const fragment = graphql`
  fragment WarningsDrawerButton_fragment on ScheduleValidationWarnings {
    viewer {
      errors {
        hardError
      }
    }
  }
`;

type Props = {
  onClick: () => void;
};

export function WarningsDrawerButton({ onClick }: Props) {
  const fragmentRef = useScheduleWarningsContext();
  const drawerData = useFragment<Key>(fragment, fragmentRef);
  const warningCount = drawerData?.viewer?.errors?.length ?? 0;
  const hardErrorCount =
    drawerData?.viewer.errors?.filter((e) => e.hardError).length || 0;

  const icon =
    hardErrorCount > 0 ? (
      <ErrorIcon sx={{ color: "error.main" }} />
    ) : warningCount > 0 ? (
      <WarningIcon sx={{ color: "warning.main" }} />
    ) : (
      <SuccessIcon sx={{ color: "secondary.main" }} />
    );

  const text =
    hardErrorCount > 0
      ? `${hardErrorCount} hårda krockar`
      : warningCount > 0
        ? `${warningCount} varning${warningCount !== 1 ? "ar" : ""}`
        : "Alla regler uppfyllda";

  return (
    <Button variant="icon" onClick={onClick} startIcon={icon} sx={buttonSx}>
      {text}
    </Button>
  );
}
