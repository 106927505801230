import { Suspense, useMemo } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay/hooks";
import {
  Paper,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import useIdFromObjectList from "hooks/useIdFromObjectList";
import { SolutionStatus } from "types/Schedule";

import Card from "components/common/CardWithTitle";
import { BaseCompetenceSelect } from "components/common/selectors/CompetenceSelect";
import { BaseTeamSelect } from "components/common/selectors/TeamSelect";
import { StatisticsPlaceholder } from "components/loading";
import { ErrorOrRunning } from "components/schedule/ErrorOrRunning";
import {
  AlteredShiftsChart,
  ProfileTotalsChart,
  RequiredVsScheduledChart,
  ShiftDayTypeDistributionChart,
  ShiftNameDistributionChart,
  ShiftVsResourceChart,
  WishedDaysOffChart,
} from "components/schedule/graphs";
import { useScheduleContext } from "components/schedule/ScheduleContext";

import type {
  Statistics_fragment$key as Key,
  StatisticsQuery as Query,
} from "./types";

const fragment = graphql`
  fragment Statistics_fragment on ScheduleNode {
    id
    solutionStatus
  }
`;

const query = graphql`
  query StatisticsQuery($id: ID!) {
    schedule(id: $id) {
      ...ProfileTotalsChart_fragment
    }
    scheduleStats(scheduleId: $id) {
      ...RequiredVsScheduledChart_fragment
      ...ShiftVsResourceChart_fragment
      ...ShiftDayTypeDistributionChart_fragment
      ...ShiftNameDistributionChart_fragment
      ...WishedDaysOffChart_fragment
      ...AlteredShiftsChart_fragment
    }
  }
`;

type CommonProps = {
  scheduleId: string;
};

type Props = CommonProps & {
  fragmentRef: Key;
};
type ContentProps = CommonProps;

const ALL_TEAMS = "";
const allTeams = {
  id: ALL_TEAMS,
  name: "Alla avdelningar",
  color: "",
  group: { id: "", name: "" },
};
const ALL_COMPETENCES = "";
const allCompetences = {
  id: ALL_COMPETENCES,
  name: "Alla kompetenser",
  color: "",
};

function ChartCard({
  title,
  children,
  noCard = false,
}: React.PropsWithChildren<{ title: React.ReactNode; noCard?: boolean }>) {
  return (
    <Stack gap={1}>
      <Typography variant="h4">{title}</Typography>
      {noCard ? children : <Card sx={{ overflow: "initial" }}>{children}</Card>}
    </Stack>
  );
}

function StatisticsDashboard({ scheduleId: id }: ContentProps) {
  const { id: groupId = "", name: groupName = "" } =
    useCurrentTeamGroup() || {};
  const { schedule, scheduleStats } = useLazyLoadQuery<Query>(query, {
    id,
  });
  const { competences: competencesP, teams: teamsP } = useScheduleContext();

  const teams = useMemo(
    () =>
      teamsP.map((t) => ({
        ...t,
        group: { id: groupId, name: groupName },
      })),
    [teamsP, groupId, groupName],
  );
  const { id: team, setId: setTeam } = useIdFromObjectList<string>({
    nullValue: ALL_TEAMS,
    getFn: (o) => o.id,
    options: teams,
  });
  const filterTeam = useMemo(() => team !== ALL_TEAMS, [team]);

  const competences = useMemo(
    () =>
      competencesP.map((t) => ({
        id: t?.id || "",
        name: t?.name || "",
        color: t?.color || "",
      })),
    [competencesP],
  );
  const { id: competence, setId: setCompetence } = useIdFromObjectList<string>({
    nullValue: ALL_COMPETENCES,
    getFn: (o) => o.id,
    options: competences,
  });
  const filterCompetence = useMemo(
    () => competence !== ALL_COMPETENCES,
    [competence],
  );
  const teamId = useMemo(() => (team === ALL_TEAMS ? null : team), [team]);
  const competenceId = useMemo(
    () => (competence === ALL_COMPETENCES ? null : competence),
    [competence],
  );

  return (
    <Grid container spacing={5} py={2}>
      <Grid xs={12}>
        <Stack direction="row" gap={1}>
          <BaseTeamSelect
            options={teams}
            value={team}
            onChange={(v: string | null) => setTeam(v || ALL_TEAMS)}
            insert={{ before: [allTeams] }}
            sx={{ width: 210 }}
          />
          <BaseCompetenceSelect
            options={competences}
            value={competence}
            getOptionLabel={(o) => o.name}
            onChange={(v: string | null) => setCompetence(v || ALL_COMPETENCES)}
            insert={{ before: [allCompetences] }}
            sx={{ width: 210 }}
          />
        </Stack>
      </Grid>
      <Grid xs={12}>
        <ChartCard noCard title="Arbetstider summerat">
          {!!schedule && (
            <ProfileTotalsChart
              fragmentRef={schedule}
              teamId={teamId}
              competence={competenceId}
            />
          )}
        </ChartCard>
      </Grid>
      <Grid xs={12}>
        <ChartCard noCard title="Arbetstider per person">
          <RequiredVsScheduledChart
            fragmentRef={scheduleStats}
            teamId={teamId}
            filterTeam={filterTeam}
            competence={competenceId}
            filterCompetence={filterCompetence}
          />
        </ChartCard>
      </Grid>
      <Grid xs={12} md={6}>
        <ChartCard title="Standardpass vs. resurspass">
          <ShiftVsResourceChart
            fragmentRef={scheduleStats}
            teamId={teamId}
            competence={competenceId}
            filterCompetence={filterCompetence}
          />
        </ChartCard>
      </Grid>
      <Grid xs={12} md={6}>
        <ChartCard title="Fördelning av Dag/Kväll/Natt">
          <ShiftDayTypeDistributionChart
            fragmentRef={scheduleStats}
            teamId={teamId}
            competenceId={competenceId}
            filterCompetence={filterCompetence}
          />
        </ChartCard>
      </Grid>
      <Grid xs={12} md={6}>
        <ChartCard title="Beviljad vs. nekad önskad ledig tid">
          <WishedDaysOffChart
            fragmentRef={scheduleStats}
            teamId={teamId}
            competence={competenceId}
            filterCompetence={filterCompetence}
          />
        </ChartCard>
      </Grid>
      <Grid xs={12} md={6}>
        <ChartCard title="Fördelning av passtyper">
          <ShiftNameDistributionChart
            fragmentRef={scheduleStats}
            teamId={teamId}
            competence={competenceId}
            filterCompetence={filterCompetence}
          />
        </ChartCard>
      </Grid>
      <Grid xs={12} md={6}>
        <ChartCard title="Förlängda/förkortade pass">
          <AlteredShiftsChart
            fragmentRef={scheduleStats}
            teamId={teamId}
            competence={competenceId}
            filterCompetence={filterCompetence}
          />
        </ChartCard>
      </Grid>
    </Grid>
  );
}

const paperSx = {
  px: 2,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
};

export function Statistics({ fragmentRef, ...props }: Props) {
  const schedule = useFragment<Key>(fragment, fragmentRef);
  const { users } = useScheduleContext();
  const userCount = useMemo(() => users.length ?? 0, [users]);

  const isValidSchedule = useMemo(
    () =>
      schedule.solutionStatus === SolutionStatus.FEASIBLE ||
      schedule.solutionStatus === SolutionStatus.HARD_CONSTRAINT_COLLISION,
    [schedule.solutionStatus],
  );

  if (!isValidSchedule) {
    return <ErrorOrRunning solutionStatus={schedule?.solutionStatus} />;
  }

  return (
    <Paper variant="box" sx={paperSx}>
      <Suspense fallback={<StatisticsPlaceholder users={userCount} />}>
        <StatisticsDashboard {...props} />
      </Suspense>
    </Paper>
  );
}
