import type { ComponentProps } from "react";
import { Typography } from "@mui/material";
import { useFormikState } from "hooks";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/common/Accordion/FormAccordion";

import { FormikUserRuleGroupsField } from "./UserRuleGroupsField";

type Props = Pick<ComponentProps<typeof Accordion>, "expanded" | "onChange">;

export function RuleGroupsAccordion(props: Props) {
  const { value: teamGroups } = useFormikState<string[]>("teamGroups");

  return (
    <Accordion {...props}>
      <AccordionSummary>
        <Typography variant="h4">Regelgrupper</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormikUserRuleGroupsField
          name="ruleGroups"
          teamGroupsToShow={teamGroups}
        />
      </AccordionDetails>
    </Accordion>
  );
}
