/**
 * @generated SignedSource<<249957856994f0324175f413e69075d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ApiBlockedTimeSlotTimeslotTypeChoices = "K" | "O" | "P" | "V" | "W" | "%future added value";
export type CreateBlockedTimeSlotInput = {
  approvedByAdmin: boolean;
  clientMutationId?: string | null;
  editFutureRecurrences?: boolean | null;
  editOne?: boolean | null;
  end?: string | null;
  originalTimeslotId?: string | null;
  recurrences?: string | null;
  shiftDate?: string | null;
  shiftId?: string | null;
  start?: string | null;
  timeslotType: ApiBlockedTimeSlotTimeslotTypeChoices;
  userIds: ReadonlyArray<string | null>;
};
export type CreateBlockedTimeSlotMutation$variables = {
  input: CreateBlockedTimeSlotInput;
};
export type CreateBlockedTimeSlotMutation$data = {
  readonly createBlockedTimeSlot: {
    readonly blockedTimeSlot: {
      readonly id: string;
    } | null;
    readonly ok: boolean | null;
  } | null;
};
export type CreateBlockedTimeSlotMutation = {
  response: CreateBlockedTimeSlotMutation$data;
  variables: CreateBlockedTimeSlotMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateBlockedTimeSlotPayload",
    "kind": "LinkedField",
    "name": "createBlockedTimeSlot",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BlockedTimeSlotNode",
        "kind": "LinkedField",
        "name": "blockedTimeSlot",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateBlockedTimeSlotMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateBlockedTimeSlotMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b6a46d26deba165551f34f79fd3dbe7e",
    "id": null,
    "metadata": {},
    "name": "CreateBlockedTimeSlotMutation",
    "operationKind": "mutation",
    "text": "mutation CreateBlockedTimeSlotMutation(\n  $input: CreateBlockedTimeSlotInput!\n) {\n  createBlockedTimeSlot(input: $input) {\n    ok\n    blockedTimeSlot {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "68a29dd35517f861db91d3807044b237";

export default node;
