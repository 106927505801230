import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";

type Props = React.ComponentProps<typeof IconButton> & {
  show: boolean;
  toggle: () => void;
};

export function ShowButton({ show, toggle, ...props }: Props) {
  return (
    <IconButton onClick={toggle} {...props}>
      {show ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  );
}
