type MyWindow = Window & { API_URL?: string };

const windowApiUrl = (window as MyWindow)?.API_URL;
const reactAppApiUrl = process.env.REACT_APP_API_BASE_URL;
const localApiUrl = "http://localhost:8000";

export const API_BASE_URL = windowApiUrl ?? reactAppApiUrl ?? localApiUrl;

export const GRAPHQL_URL = `${API_BASE_URL}/graphql/`;

export const EMPTY_ARRAY: ReadonlyArray<any> = [];
