import { ApiSettingShiftsEmploymentDegreeLogicChoices } from "components/setting/types";

export function scaleHours(
  empDegree: number,
  factor: number,
  total: number,
  round: number,
) {
  const invertedDegree = 1 - empDegree;
  const result = (1 - invertedDegree * factor * 0.01) * total;
  return Math.round(round * result) / round;
}

export function scaleShifts(
  empDegree: number,
  factor: number,
  shiftsPerWeek: number,
  periodLengthWeeks: number,
  shiftsScalingLogic: ApiSettingShiftsEmploymentDegreeLogicChoices,
) {
  if (shiftsScalingLogic === "O") {
    const shifts = Math.round(
      shiftsPerWeek * periodLengthWeeks -
        Math.floor((100 - empDegree * 100) / (factor + 0.01)),
    );
    return Math.max(0, shifts);
  } else {
    const total = Math.round(shiftsPerWeek * periodLengthWeeks);
    const invertedDegree = 1 - empDegree;
    const shifts = Math.round((1 - invertedDegree * factor * 0.01) * total);
    return Math.max(0, shifts);
  }
}
