import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import { Environment } from "relay-runtime";

import { EditActivityInput } from "components/schedule/activities/mutations/__generated__/EditActivityMutation.graphql";
import {
  checkHasChanged,
  checkIsPopulated,
  checkStartEnd,
} from "components/schedule/activities/validations/BasicValidationChecks";

import { ValidateEditActivityMutation } from "./__generated__/ValidateEditActivityMutation.graphql";

const validateEditMutation = graphql`
  mutation ValidateEditActivityMutation($input: ValidateEditActivityInput!) {
    validateEditActivity(input: $input) {
      results {
        ok
        errors {
          module
          message
          activity {
            id
            activityType
            start
            end
            activityParts {
              edges {
                node {
                  id
                  partType
                  start
                  end
                }
              }
            }
          }
          user {
            name
          }
        }
      }
    }
  }
`;

const commitEditValidation = async (
  environment: Environment,
  values: EditActivityInput,
  activityId?: string,
) => {
  // Validate create mutation
  const res = await commitMutation<ValidateEditActivityMutation>(environment, {
    mutation: validateEditMutation,
    variables: {
      input: {
        ...(values as EditActivityInput),
        activityId: activityId || "",
      },
    },
  });
  return res.validateEditActivity?.results;
};

type Props = {
  values: any;
  lastValidatedValues: any;
  setValidationState: any;
  validationState: any;
  activityId: string;
  environment: any;
};

export async function validateEdit(props: Props) {
  // Validate activity with constraint module rules. This validation method
  // is performed in addition to the field-level validation.
  // Note: We don't use Formik's errors for this, but a custom error state.

  // Basic validation
  const invalidStartEnd = checkStartEnd(props.values);
  if (invalidStartEnd) {
    return { end: "Sluttid inträffar före starttid" };
  }
  const isPopulated = checkIsPopulated(props.values);
  if (!isPopulated) {
    return;
  }
  const hasChanged = checkHasChanged(
    props.lastValidatedValues.current,
    props.values,
  );
  if (!hasChanged) {
    return;
  }

  // Perform server validation
  props.setValidationState({
    loading: true,
    result: props.validationState?.result,
  });
  props.lastValidatedValues.current = props.values;
  await commitEditValidation(
    props.environment,
    props.values,
    props.activityId,
  ).then((result) => props.setValidationState({ loading: false, result }));
}
