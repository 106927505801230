import { CreateShiftsInput as FormValues } from "components/shifts/types";

type Day = "Mån" | "Tis" | "Ons" | "Tors" | "Fre" | "Lör" | "Sön";
export const DAYS: ReadonlyArray<Day> = [
  "Mån",
  "Tis",
  "Ons",
  "Tors",
  "Fre",
  "Lör",
  "Sön",
];

export function getDaysLeft(days: ReadonlyArray<Day>, formValues: FormValues) {
  const selectedDays = formValues.shiftGroups.reduce(
    (acc, curr) => acc.concat(curr.days),
    [] as number[],
  );
  return days.map((_, i) => i).filter((i) => !selectedDays.includes(i));
}
