/**
 * @generated SignedSource<<1625b4af373ecc77ba239d7b42c9f3be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingMinDailyRestWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SettingRestBetweenShifts_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly id: string;
  readonly minDailyRestHours: number;
  readonly minDailyRestSoft: boolean;
  readonly minDailyRestWeight: ApiSettingMinDailyRestWeightChoices;
  readonly " $fragmentType": "SettingRestBetweenShifts_fragment";
};
export type SettingRestBetweenShifts_fragment$key = {
  readonly " $data"?: SettingRestBetweenShifts_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingRestBetweenShifts_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingRestBetweenShifts_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minDailyRestSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minDailyRestHours",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minDailyRestWeight",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "e9453e396d315559a008584e971ba870";

export default node;
