import graphql from "babel-plugin-relay/macro";
import environment from "environment";
import { commitMutation } from "libs/commitMutation";

import { DeleteScheduleMutation } from "./__generated__/DeleteScheduleMutation.graphql";

const mutation = graphql`
  mutation DeleteScheduleMutation($scheduleId: ID!) {
    deleteSchedule(scheduleId: $scheduleId) {
      ok
    }
  }
`;

export default async function DeleteSchedule(scheduleId: string) {
  const variables = {
    scheduleId,
  };

  return commitMutation<DeleteScheduleMutation>(environment, {
    mutation,
    variables,
  });
}
