import { Add as AddIcon } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { useFormikContext } from "formik";

import type { CreateShiftsInput } from "../types";

type RenderProps = {
  onAdd: () => void;
  onReset: () => void;
  onSubmit: () => void;
  addDisabled?: boolean;
  resetDisabled?: boolean;
  submitDisabled?: boolean;
};

export function FooterRender({
  onAdd,
  onReset,
  onSubmit,
  addDisabled,
  resetDisabled,
  submitDisabled,
}: RenderProps) {
  return (
    <Stack id="footer" direction="row" gap={2} justifyContent="space-between">
      <Stack direction="row" gap={2}>
        <Button
          variant="primary"
          onClick={onAdd}
          endIcon={<AddIcon />}
          disabled={addDisabled}
        >
          Lägg till dagblock
        </Button>
      </Stack>

      <Stack direction="row" gap={2} alignItems="center">
        <Button variant="outlined" disabled={resetDisabled} onClick={onReset}>
          Återställ
        </Button>
        <Button
          variant="secondary"
          disabled={submitDisabled}
          onClick={onSubmit}
        >
          Spara ändringar
        </Button>
      </Stack>
    </Stack>
  );
}

export function Footer() {
  const {
    isValid,
    dirty,
    values,
    setFieldValue,
    isSubmitting,
    resetForm,
    submitForm,
  } = useFormikContext<CreateShiftsInput>();

  function addGroup() {
    const newGroup = { days: [], shifts: [] };
    setFieldValue("shiftGroups", [...values.shiftGroups, newGroup]);
  }

  return (
    <FooterRender
      onAdd={addGroup}
      resetDisabled={!dirty || isSubmitting}
      onReset={resetForm}
      submitDisabled={!dirty || isSubmitting || !isValid}
      onSubmit={submitForm}
    />
  );
}
