/**
 * @generated SignedSource<<4019f85d0ee6aaa7dfdfcd59c18bc701>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSettingBoxTypes5_fragment$data = {
  readonly dayShiftDistributionShare: number;
  readonly eveningShiftDistributionShare: number;
  readonly fullDayShiftDistributionShare: number;
  readonly id: string;
  readonly nightShiftDistributionShare: number;
  readonly settingModules: ReadonlyArray<string>;
  readonly shiftDayTypeAllowedErrorMargin: number;
  readonly shiftDayTypeDistributionSoft: boolean;
  readonly user: {
    readonly fullName: string;
    readonly resolvedSettings: {
      readonly shiftsPerPeriod: number;
    } | null;
  };
  readonly " $fragmentType": "UserSettingBoxTypes5_fragment";
};
export type UserSettingBoxTypes5_fragment$key = {
  readonly " $data"?: UserSettingBoxTypes5_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSettingBoxTypes5_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "teamGroupId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserSettingBoxTypes5_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftDayTypeDistributionSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftDayTypeAllowedErrorMargin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eveningShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullDayShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "teamGroupId",
              "variableName": "teamGroupId"
            }
          ],
          "concreteType": "ResolvedSettings",
          "kind": "LinkedField",
          "name": "resolvedSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shiftsPerPeriod",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserSettingNode",
  "abstractKey": null
};

(node as any).hash = "669309cd9a0cb33ac6103f305f62a145";

export default node;
