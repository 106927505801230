import type { ReactNode } from "react";
import { Stack, Unstable_Grid2 as Grid } from "@mui/material";
import { useFormikState } from "hooks";

import type { ApiSettingShiftsEmploymentDegreeLogicChoices } from "components/setting/types";

import { AverageShiftLength } from "./AverageShiftLength";
import {
  Description as ScalingHoursDesc,
  Graph as ScalingHoursGraph,
  HoursEmploymentDegreeFactorField as ScalingHoursField,
  Title as ScalingHoursTitle,
} from "./ParttimeScalingHours";
import {
  Description as ScalingShiftsDesc,
  Graph as ScalingShiftsGraph,
  ShiftsEmploymentDegreeFactorAndLogic as ScalingShiftsField,
  Title as ScalingShiftsTitle,
} from "./ParttimeScalingShifts";
import {
  Description as TotalMarginHoursDesc,
  Example as TotalMarginHoursExample,
  Title as TotalMarginHoursTitle,
  TotalHoursMarginField,
} from "./TotalMarginHours";
import {
  Description as TotalMarginShiftsDesc,
  Example as TotalMarginShiftsExample,
  Title as TotalMarginShiftsTitle,
  TotalShiftsMarginField,
} from "./TotalMarginShifts";
import type { GraphData, Shift } from "./useGraphData";
import { useGraphData } from "./useGraphData";

type Who = {
  part: ReactNode;
  full: ReactNode;
  type: ReactNode;
};

type DisabledAndReadOnlyProps = {
  disabled?: boolean;
  readOnly?: boolean;
};

type Props = DisabledAndReadOnlyProps & {
  name: string;
  periodLengthWeeks: number;
  shiftsPerWeek: number;
  hoursPerWeek: number;
  shifts: ReadonlyArray<Shift>;
  who?: Who;
};

type ScalingProps = DisabledAndReadOnlyProps & {
  part?: Who["part"];
  graphData: GraphData;
};

type TotalMarginsProps = Pick<
  Props,
  "periodLengthWeeks" | "hoursPerWeek" | "shiftsPerWeek"
>;

export function Scaling({ part, graphData, disabled, readOnly }: ScalingProps) {
  return (
    <Stack gap={1}>
      <Grid id="titles" container spacing={2} columns={2}>
        <Grid xs={1}>
          <ScalingHoursTitle />
        </Grid>
        <Grid xs={1}>
          <ScalingShiftsTitle />
        </Grid>
      </Grid>
      <Grid id="descs" container spacing={2} columns={2}>
        <Grid xs={1}>
          <ScalingHoursDesc part={part} />
        </Grid>
        <Grid xs={1}>
          <ScalingShiftsDesc part={part} />
        </Grid>
      </Grid>
      <Grid id="inputs" container spacing={2} columns={2}>
        <Grid xs={1}>
          <ScalingHoursField disabled={disabled} readOnly={readOnly} />
        </Grid>
        <Grid xs={1}>
          <ScalingShiftsField disabled={disabled} readOnly={readOnly} />
        </Grid>
      </Grid>
      {!disabled && (
        <Grid id="graphs" container spacing={2} columns={2}>
          <Grid xs={1}>
            <ScalingHoursGraph graphData={graphData} />
          </Grid>
          <Grid xs={1}>
            <ScalingShiftsGraph graphData={graphData} />
          </Grid>
        </Grid>
      )}
    </Stack>
  );
}

function TotalMargins({
  periodLengthWeeks,
  hoursPerWeek,
  shiftsPerWeek,
}: TotalMarginsProps) {
  return (
    <Stack gap={1}>
      <Grid id="titles" container spacing={5} columns={2}>
        <Grid xs={1}>
          <TotalMarginHoursTitle />
        </Grid>
        <Grid xs={1}>
          <TotalMarginShiftsTitle />
        </Grid>
      </Grid>
      <Grid id="descs" container spacing={5} columns={2}>
        <Grid xs={1}>
          <TotalMarginHoursDesc />
        </Grid>
        <Grid xs={1}>
          <TotalMarginShiftsDesc />
        </Grid>
      </Grid>
      <Grid id="fields" container spacing={5} columns={2}>
        <Grid xs={1}>
          <TotalHoursMarginField />
        </Grid>
        <Grid xs={1}>
          <TotalShiftsMarginField />
        </Grid>
      </Grid>
      <Grid id="examples" container spacing={5} columns={2}>
        <Grid xs={1}>
          <TotalMarginHoursExample
            periodLengthWeeks={periodLengthWeeks}
            hoursPerWeek={hoursPerWeek}
          />
        </Grid>
        <Grid xs={1}>
          <TotalMarginShiftsExample
            periodLengthWeeks={periodLengthWeeks}
            shiftsPerWeek={shiftsPerWeek}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}

export function FullAndPartTimeMeasureField({
  periodLengthWeeks,
  shiftsPerWeek,
  hoursPerWeek,
  shifts,
  disabled,
  readOnly,
}: Props) {
  const totalShiftsMargin = useFormikState<number>("totalShiftsMargin")?.value;
  const totalHoursMargin = useFormikState<number>("totalHoursMargin")?.value;
  const shiftsFactor = useFormikState<number>(
    "shiftsEmploymentDegreeFactor",
  )?.value;
  const shiftsScalingLogic =
    useFormikState<ApiSettingShiftsEmploymentDegreeLogicChoices>(
      "shiftsEmploymentDegreeLogic",
    )?.value.toUpperCase() as ApiSettingShiftsEmploymentDegreeLogicChoices;
  const hoursFactor = useFormikState<number>(
    "hoursEmploymentDegreeFactor",
  )?.value;

  const graphData = useGraphData({
    periodLengthWeeks,
    hoursPerWeek,
    shiftsPerWeek,
    shiftsFactor,
    shiftsScalingLogic,
    hoursFactor,
    shifts,
    hoursMargin: totalHoursMargin,
    shiftsMargin: totalShiftsMargin,
  });

  return (
    <Stack gap={4}>
      <Scaling graphData={graphData} disabled={disabled} readOnly={readOnly} />
      <AverageShiftLength graphData={graphData} />
      <TotalMargins
        periodLengthWeeks={periodLengthWeeks}
        hoursPerWeek={hoursPerWeek}
        shiftsPerWeek={shiftsPerWeek}
      />
    </Stack>
  );
}
