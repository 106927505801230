import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { yupTimeString } from "libs/yup";
import * as yup from "yup";

import { AutoSubmit } from "components/setting/common";
import { Base5 as LoadingBase5 } from "components/setting/common/loading/forms";

import { Base5Form } from "./forms";
import { UpdateSettingBoxBase5 } from "./mutations";
import type {
  SettingBoxBase5_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";
import { formatHmsToHm } from "./utils";

const fragment = graphql`
  fragment SettingBoxBase5_fragment on SettingNode {
    id
    morningStartsNightEnds
    eveningStartsMorningEnds
    nightStartsEveningEnds
    fullDayDuration
  }
`;

const validationSchema = yup
  .object()
  .shape({
    id: yup.string().required("Får ej vara tomt"),
    morningStartsNightEnds: yupTimeString.required("Får ej vara tomt"),
    eveningStartsMorningEnds: yupTimeString.required("Får ej vara tomt"),
    nightStartsEveningEnds: yupTimeString.required("Får ej vara tomt"),
    fullDayDuration: yup.number().required("Får ej vara tomt"),
  })
  .test(
    "breakpoints-not-equal",
    "Start- och sluttider får inte vara samma",
    function (value: FormValues) {
      const {
        morningStartsNightEnds,
        eveningStartsMorningEnds,
        nightStartsEveningEnds,
      } = value;
      const errors: yup.ValidationError[] = [];

      if (morningStartsNightEnds === eveningStartsMorningEnds) {
        errors.push(
          new yup.ValidationError(
            "Får inte vara samma som kväll börjar",
            null,
            "morningStartsNightEnds",
          ),
        );
        errors.push(
          new yup.ValidationError(
            "Får inte vara samma som morgon börjar",
            null,
            "eveningStartsMorningEnds",
          ),
        );
      }
      if (morningStartsNightEnds === nightStartsEveningEnds) {
        errors.push(
          new yup.ValidationError(
            "Får inte vara samma som natt börjar",
            null,
            "morningStartsNightEnds",
          ),
        );
        errors.push(
          new yup.ValidationError(
            "Får inte vara samma som kväll börjar",
            null,
            "nightStartsEveningEnds",
          ),
        );
      }
      if (eveningStartsMorningEnds === nightStartsEveningEnds) {
        errors.push(
          new yup.ValidationError(
            "Får inte vara samma som natt börjar",
            null,
            "eveningStartsMorningEnds",
          ),
        );
        errors.push(
          new yup.ValidationError(
            "Får inte vara samma som morgon börjar",
            null,
            "nightStartsEveningEnds",
          ),
        );
      }

      if (errors?.length) {
        return new yup.ValidationError(errors);
      }
      return false;
    },
  )
  .test(
    "breakpoints-dont-overlap",
    "Intervallen får inte överlappa",
    function (value: FormValues) {
      const {
        morningStartsNightEnds,
        eveningStartsMorningEnds,
        nightStartsEveningEnds,
      } = value;
      const arbitraryDate = "2024-06-26T";
      function toTimestamp(time: string) {
        return (time && Date.parse(arbitraryDate.concat(time))) || 0;
      }
      const morning = toTimestamp(morningStartsNightEnds as string);
      const evening = toTimestamp(eveningStartsMorningEnds as string);
      const night = toTimestamp(nightStartsEveningEnds as string);

      const inBetweenError = new yup.ValidationError(
        "Kvällen måste börja mellan dagen och natten ",
        null,
        "eveningStartsMorningEnds",
      );
      if (morning > night && morning > evening && !(evening < night)) {
        return inBetweenError;
      }
      if (morning < night && evening < night && !(morning < evening)) {
        return inBetweenError;
      }
      if (morning < night && !(evening < night)) {
        return inBetweenError;
      }
      return false;
    },
  );

type Props = {
  fragmentRef: Key;
};

function FormContainer({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const data = useFragment(fragment, fragmentRef);
  const teamGroup = useCurrentTeamGroup();
  const groupName = teamGroup?.name || "";
  const {
    morningStartsNightEnds: morning,
    eveningStartsMorningEnds: evening,
    nightStartsEveningEnds: night,
    fullDayDuration,
  } = data;
  const initialValues: FormValues = {
    id: data.id || "",
    morningStartsNightEnds: morning ? formatHmsToHm(morning) : "07:00",
    eveningStartsMorningEnds: evening ? formatHmsToHm(evening) : "16:00",
    nightStartsEveningEnds: night ? formatHmsToHm(night) : "21:00",
    fullDayDuration,
  };

  async function onSubmit(input: FormValues) {
    await UpdateSettingBoxBase5(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Base5Form groupName={groupName} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxBase5({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<LoadingBase5 />}>
      {!!fragmentRef && <FormContainer fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
