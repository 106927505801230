import { Stack } from "@mui/material";

import { BlockedTimeSlotLabel } from "./BlockedTimeSlotLabel";

export function BlockedTimeSlotLabels() {
  return (
    <Stack
      direction="row"
      gap={3}
      justifyContent="center"
      my={2}
      sx={{ textAlign: "center" }}
    >
      <BlockedTimeSlotLabel className="ka" name="Fast pass" />
      <BlockedTimeSlotLabel className="pa" name="APT" />
      <BlockedTimeSlotLabel className="va" name="Semester" />
      <BlockedTimeSlotLabel className="wa" name="Önskad fridag" />
      <BlockedTimeSlotLabel className="oa" name="Fast fridag" />
    </Stack>
  );
}
