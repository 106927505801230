import type { TimelineItemBase } from "react-calendar-timeline";
import type { ExtractNode, Unwrap } from "relay-help/arrays";

import type { ActivityPartTypeType } from "components/schedule/activities/types";
import { activityParts } from "components/schedule/activities/types";
import { activityList as activityTypeList } from "components/schedule/types";

import type {
  ActivityGanttTimelineQuery$data as Data,
  ApiActivityActivityTypeChoices as ActivityType,
} from "./__generated__/ActivityGanttTimelineQuery.graphql";

export * from "./__generated__/ActivityGanttTimelineQuery.graphql";

type Schedule = NonNullable<Data["schedule"]>;
export type ActivityGanttTimelineActivity = ExtractNode<Schedule["activities"]>;
export type ActivityGanttTimelinePreviousWeekActivity = Unwrap<
  Schedule["previousWeekActivities"]
>;

export type User = ExtractNode<Schedule["users"]>;
export type UserTeam = ExtractNode<NonNullable<User["teams"]>>;
export type UserCompetence = ExtractNode<NonNullable<User["competences"]>>;
export type UserItem = Omit<User, "competences" | "name" | "teams"> & {
  title: string;
  competences: ReadonlyArray<UserCompetence>;
  teams: ReadonlyArray<UserTeam>;
};

type TimelineTeam = ActivityGanttTimelineActivity["team"];

type PrevTeam = ActivityGanttTimelinePreviousWeekActivity["team"];

export type BaseTimelineItem = TimelineItemBase<number>;

type CommonTimelineItem = BaseTimelineItem & {
  // id
  // group
  // title
  // start_time
  // end_time
  titleCode: string;
  itemTitle: string;
  activityId: string;
  isPart: boolean | null;
  shiftName: string;
  shiftDayType: string;
  breakTime: number | null;
  shiftIsAltered: boolean | null;
  alterationLength: number | null;
  duration: number;
  worktime: number | null;
};

export type TimelineItem = CommonTimelineItem & {
  team: TimelineTeam;
  activity: ActivityGanttTimelineActivity;
};

export type PrevTimelineItem = CommonTimelineItem & {
  team: PrevTeam;
  activity: ActivityGanttTimelinePreviousWeekActivity;
};

export function isActivityType(arg: string): arg is ActivityType {
  return activityTypeList.includes(arg as ActivityType);
}

export function isActivityPartType(arg: string): arg is ActivityPartTypeType {
  return activityParts.includes(arg as ActivityPartTypeType);
}

export enum activityTypes {
  // A = "A",
  DAY_BREAK = "B", // TODO: Remove since backend ActivityType should not have this option
  // C = "C",
  // D = "D",
  WEEK_BREAK = "E", // TODO: Remove since backend ActivityType should not have this option
  // F = "F",
  BILAGAJ_JOUR = "J",
  AB_JOUR = "M",
  // O = "O",
  // P = "P",
  // Q = "Q",
  RESOURCE = "R",
  SHIFT = "S",
  // V = "V",
  // W = "W",
  // Y = "Y",
  WORK_OUTSIDE_DEMAND = "Z",
}
