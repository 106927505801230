import type { ComponentProps } from "react";
import { useFormikState } from "hooks";

import { UserRuleGroupsField } from "./UserRuleGroupsField";

type Props = ComponentProps<typeof UserRuleGroupsField>;
type FormikProps = { name: string } & Omit<Props, "value" | "onChange">;

export function FormikUserRuleGroupsField({ name, ...props }: FormikProps) {
  const { setValue, meta } = useFormikState<string[]>(name);
  const { initialValue } = meta;
  return (
    <UserRuleGroupsField
      {...props}
      defaultValue={initialValue}
      onChange={setValue}
    />
  );
}
