import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { consecutiveFreeDaysWeightChoices } from "./constants";
import { Rhythm2Form } from "./forms";
import { UpdateSettingBoxRhythm2 } from "./mutations";
import type {
  SettingBoxRhythm2_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment SettingBoxRhythm2_fragment on SettingNode {
    id
    maxConsecutiveFreeDays
    maxConsecutiveFreeDaysSoft
    minConsecutiveFreeDays
    minConsecutiveFreeDaysSoft
    consecutiveFreeDaysWeight
    constraintModules
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string(),
  maxConsecutiveFreeDays: yup
    .number()
    .min(1, "Måste vara minst 1")
    .max(20, "Måste vara högst 20")
    .required("Får ej vara tomt"),
  maxConsecutiveFreeDaysSoft: yup.boolean().required("Får ej vara tomt"),
  minConsecutiveFreeDays: yup
    .number()
    .min(1, "Måste vara minst 1")
    .max(20, "Måste vara högst 20")
    .required("Får ej vara tomt"),
  minConsecutiveFreeDaysSoft: yup.boolean().required("Får ej vara tomt"),
  consecutiveFreeDaysWeight: yup
    .string()
    .oneOf(consecutiveFreeDaysWeightChoices)
    .required(),
  constraintModules: yup.array().of(yup.string()),
});

type Props = {
  fragmentRef: Key;
};

function Content({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const teamGroup = useCurrentTeamGroup();
  const data = useFragment<Key>(fragment, fragmentRef);
  const initialValues: FormValues = {
    id: data?.id || "",
    maxConsecutiveFreeDays: data?.maxConsecutiveFreeDays || 2,
    maxConsecutiveFreeDaysSoft: data?.maxConsecutiveFreeDaysSoft !== false, // default true
    minConsecutiveFreeDays: data?.minConsecutiveFreeDays || 2,
    minConsecutiveFreeDaysSoft: data?.minConsecutiveFreeDaysSoft !== false, // default true
    consecutiveFreeDaysWeight: data?.consecutiveFreeDaysWeight || "M",
    constraintModules: data?.constraintModules.slice() || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateSettingBoxRhythm2(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Rhythm2Form groupName={teamGroup?.name || ""} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxRhythm2({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
