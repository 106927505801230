import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";

import { AutoSubmit } from "components/setting/common";
import { useSnackbar } from "components/Snackbar";

import { AdvancedForm } from "./forms";
import { useUpdateAdvancedMutation } from "./mutations";
import type {
  Advanced_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

type Props = {
  fragmentRef: Key;
};

const fragment = graphql`
  fragment Advanced_fragment on SettingNode {
    id
    showAdvancedSettings
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required("Får ej vara tomt"),
  showAdvancedSettings: yup.boolean(),
});

export function Advanced({ fragmentRef }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useUpdateAdvancedMutation();
  const { addSnack } = useSnackbar();

  const initialValues = useMemo<FormValues>(
    () => ({
      id: data.id,
      showAdvancedSettings: data.showAdvancedSettings,
    }),
    [data],
  );

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } }).catch((e) => {
      console.error(e);
      addSnack({ severity: "error", message: "Uppdatering misslyckades" });
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <AdvancedForm />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}
