/**
 * @generated SignedSource<<053f8df12117855a91860fdb6153bff6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiUserDayTypeCadenceRestrictionDayTypeChoices = "D" | "E" | "F" | "N" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserSettingBoxTypes7_fragment$data = {
  readonly dayTypeCadenceRestrictions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly calendarDays: number;
        readonly dayType: ApiUserDayTypeCadenceRestrictionDayTypeChoices;
        readonly id: string;
        readonly isSoft: boolean;
        readonly maxCadence: number;
      } | null;
    } | null>;
  };
  readonly id: string;
  readonly settingModules: ReadonlyArray<string>;
  readonly user: {
    readonly fullName: string;
  };
  readonly " $fragmentType": "UserSettingBoxTypes7_fragment";
};
export type UserSettingBoxTypes7_fragment$key = {
  readonly " $data"?: UserSettingBoxTypes7_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSettingBoxTypes7_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserSettingBoxTypes7_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserDayTypeCadenceRestrictionNodeConnection",
      "kind": "LinkedField",
      "name": "dayTypeCadenceRestrictions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserDayTypeCadenceRestrictionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserDayTypeCadenceRestrictionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dayType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maxCadence",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "calendarDays",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isSoft",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserSettingNode",
  "abstractKey": null
};
})();

(node as any).hash = "807132324a41da84035de1ad08d53fc7";

export default node;
