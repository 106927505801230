import { useMemo } from "react";

import type { GroupedOptions, Option } from "./types";

/** Group options on `group` */
export function useGroupedOptions(options: Option[]) {
  return useMemo<GroupedOptions>(
    () =>
      options.reduce((acc, { teamGroup, ...option }) => {
        const groupId = teamGroup.id;
        if (!acc[groupId]) acc[groupId] = { teamGroup, options: [] };
        acc[groupId].options.push(option);
        return acc;
      }, {} as GroupedOptions),
    [options],
  );
}
