import { useMemo, useState } from "react";
import { useFragment } from "react-relay/hooks";
import { Link } from "react-router-dom";
import {
  ArrowForward,
  Delete as DeleteIcon,
  Refresh as RedoIcon,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import useInterval from "hooks/useInterval";
import { Duration } from "luxon";
import type { Unwrap } from "relay-help/arrays";
import { SolutionStatus } from "types/Schedule";

import DeleteModal from "components/common/DeleteModal";
import TimeTicker from "components/common/TimeTicker";
import { Success as SuccessIcon } from "components/icons";
import { TranslateStopCriteriaType } from "components/schedule/types";

import DeleteSchedule from "./mutations/DeleteSchedule";
import { useRerunPostprocessing } from "./mutations/RerunPostprocessing";
import ScheduleStatusIcon from "./ScheduleStatusIcon";
import type {
  ScheduleRunsTable_fragment$data as Data,
  ScheduleRunsTable_fragment$key as Key,
} from "./types";

const fragment = graphql`
  fragment ScheduleRunsTable_fragment on ScheduleNode @relay(plural: true) {
    id
    created
    createdBy {
      id
      fullName
    }
    mipgap
    timeLimit
    totalSolvingTime
    terminationCondition
    solutionStatus
    manuallyEdited
    copiedFrom {
      id
    }
    masterForPeriod {
      id
    }
  }
`;

type Schedule = Unwrap<Data>;

function ScheduleRunsTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Skapad</TableCell>
        <TableCell>Tidsgräns</TableCell>
        <TableCell>Lösningstid</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Stoppkriterium</TableCell>
        <TableCell>MIP-gap</TableCell>
        <TableCell>Fastställt</TableCell>
        <TableCell>Redigerad</TableCell>
        <TableCell>Skapad av</TableCell>
        <TableCell>Lösningsdetaljer</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

type IndicesType = { [key: string]: number };

type ScheduleTableRowProps = {
  scheduleRun: Schedule;
  index: number;
  indices: IndicesType;
  redoPostprocessing: () => void;
  onDelete: (id: string) => void;
};

function isSolutionStatusPending(solutionStatus: string): boolean {
  return [
    SolutionStatus.RUNNING as string,
    SolutionStatus.QUEUED as string,
    SolutionStatus.RUNNING_WITH_INFEASIBILITY_VENTS as string,
  ].includes(solutionStatus);
}

function ScheduleTableRow({
  scheduleRun,
  index,
  indices,
  redoPostprocessing,
  onDelete,
}: ScheduleTableRowProps) {
  const {
    id,
    created,
    createdBy,
    timeLimit,
    totalSolvingTime,
    solutionStatus,
    terminationCondition,
    mipgap,
    masterForPeriod,
    manuallyEdited,
    copiedFrom,
  } = scheduleRun || {};

  const isRunningOrQueued = useMemo(
    () => isSolutionStatusPending(solutionStatus),
    [solutionStatus],
  );
  const isRerunning = useMemo(
    () => solutionStatus === SolutionStatus.RUNNING_WITH_INFEASIBILITY_VENTS,
    [solutionStatus],
  );

  function rerun(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    redoPostprocessing();
  }
  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        {created && new Date(Date.parse(created)).toLocaleString()}
      </TableCell>
      <TableCell>
        {!!timeLimit &&
          Duration.fromObject({ seconds: timeLimit }).toFormat("hh:mm:ss")}
      </TableCell>
      <TableCell>
        {solutionStatus === "ERROR" ? (
          "-"
        ) : totalSolvingTime ? (
          Duration.fromObject({ seconds: totalSolvingTime }).toFormat(
            "hh:mm:ss",
          )
        ) : (
          <TimeTicker since={Date.parse(created || "")} />
        )}
      </TableCell>
      <TableCell>
        <ScheduleStatusIcon status={solutionStatus} />
      </TableCell>
      <TableCell>
        {isRunningOrQueued && !isRerunning
          ? "-"
          : TranslateStopCriteriaType(terminationCondition || undefined)}
      </TableCell>
      <TableCell>
        {mipgap !== undefined && mipgap !== null
          ? `${Math.round(10 * mipgap) / 10}%`
          : "-"}
      </TableCell>
      <TableCell>
        {masterForPeriod ? (
          <SuccessIcon sx={{ color: "annkatrin.100" }} />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell>
        {manuallyEdited ? (
          <>
            Kopia av{" "}
            <Link to={`/schedules/${copiedFrom?.id}`}>
              {indices[copiedFrom?.id || ""]}
            </Link>
          </>
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell>{createdBy && createdBy?.fullName}</TableCell>
      <TableCell>
        <Link to={`/schedules/${id}`}>
          {solutionStatus === "FEASIBLE" ? (
            <Stack
              justifyItems="top"
              direction="row"
              gap={0.5}
              alignItems="center"
            >
              <Typography fontWeight={700}>Till schemat</Typography>
              <ArrowForward fontSize="small" />
            </Stack>
          ) : isRunningOrQueued ? (
            "-"
          ) : (
            "Hjälp"
          )}
        </Link>
      </TableCell>
      <TableCell>
        <Stack direction="row">
          {mipgap !== undefined && mipgap !== null ? (
            <IconButton onClick={rerun} sx={{ py: 0, px: 0.5 }} disabled>
              <RedoIcon />
            </IconButton>
          ) : (
            <Box sx={{ px: 2 }} />
          )}
          <IconButton
            onClick={() => onDelete(id || "")}
            sx={{ py: 0, px: 0.5 }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      </TableCell>
    </TableRow>
  );
}

type ScheduleRunsTableProps = {
  schedules: Schedule[];
  refresh: () => Promise<void>;
};

const ScheduleRunsTable = ({ schedules, refresh }: ScheduleRunsTableProps) => {
  const [toDelete, setToDelete] = useState<string | null>(null);
  const [rerunPostprocessing] = useRerunPostprocessing();

  function onDelete(id: string) {
    setToDelete(id);
  }
  function confirmDelete() {
    if (!toDelete) return;
    DeleteSchedule(toDelete)
      .then(() => setToDelete(null))
      .then(() => refresh());
  }

  // Reformat
  const indices: IndicesType =
    schedules?.reduce(
      (obj, elem, i) => ({ ...obj, [elem?.id || ""]: i + 1 }),
      {},
    ) || {};

  const renderRow = (s: Schedule, idx: number) => (
    <ScheduleTableRow
      key={s?.id}
      scheduleRun={s}
      index={idx}
      indices={indices}
      redoPostprocessing={() =>
        !!s?.id && rerunPostprocessing({ variables: { scheduleId: s.id } })
      }
      onDelete={onDelete}
    />
  );

  if (schedules?.length === 0)
    return (
      <TableContainer sx={{ py: 1.5, px: 1 }}>
        <Typography variant="body2">Här är det tomt!</Typography>
      </TableContainer>
    );

  return (
    <>
      <TableContainer>
        <Table size="small">
          <ScheduleRunsTableHead />
          <TableBody>{schedules?.map(renderRow)}</TableBody>
        </Table>
      </TableContainer>
      <DeleteModal
        show={!!toDelete}
        onHide={() => setToDelete(null)}
        onDeleteClick={confirmDelete}
        deleteTitle="Ta bort schema"
        deleteMessage="Är du säker på att du vill ta bort schemat? Detta går inte att ångra."
        buttonText="Ta bort"
      />
    </>
  );
};

type Props = {
  fragmentRef: Key;
  periodId: string;
  periodName: string;
  refresh: () => Promise<void>;
};

export default function ScheduleRunTables({
  fragmentRef,
  periodId,
  periodName,
  refresh,
}: Props) {
  const schedules = useFragment<Key>(fragment, fragmentRef);

  const masterSchedule = (schedules || []).find(
    (x) => x?.masterForPeriod?.id === periodId,
  );
  const otherSchedules = (schedules || []).filter(
    (x) => x?.id !== masterSchedule?.id,
  );

  // Set up auto-refresh if any schedule is currently running or queued
  const shouldRefetch = useMemo(
    () =>
      (schedules || [])
        .map((s) => s.solutionStatus || "")
        .some(isSolutionStatusPending),
    [schedules],
  );
  // TODO: Change interval to something larger, or depending on time since start
  useInterval(refresh, shouldRefetch ? 5000 : null);

  return (
    <>
      <Stack gap={1}>
        <Typography variant="h4">{`Fastställt schema för vecka ${periodName}`}</Typography>
        <Paper variant="box" sx={{ p: 1.5 }}>
          <ScheduleRunsTable
            schedules={masterSchedule ? [masterSchedule] : []}
            refresh={refresh}
          />
        </Paper>
      </Stack>

      <Stack gap={1}>
        <Typography variant="h4">{`Utkast för vecka ${periodName}`}</Typography>
        <Paper variant="box" sx={{ p: 1.5 }}>
          <ScheduleRunsTable schedules={otherSchedules} refresh={refresh} />
        </Paper>
      </Stack>
    </>
  );
}
