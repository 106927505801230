import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IconButton, MenuItem, Select, Stack } from "@mui/material";

import { CurrentDate } from "./CurrentDate";
import { useDemandGraphSpanContext } from "./DemandGraphSpanContext";
import { useDemandGraphSpanLogic } from "./hooks";

export function DemandGraphSpan() {
  const context = useDemandGraphSpanContext();
  const {
    span,
    backDisabled,
    forwardDisabled,
    goBack,
    goForward,
    changeSpan,
    changeStart,
  } = useDemandGraphSpanLogic(context);
  const { start } = context;

  return (
    <Stack gap={2} direction="row">
      <Stack direction="row" alignItems="center">
        <IconButton
          onClick={() => goBack()}
          disabled={backDisabled}
          sx={{ p: 0 }}
        >
          <ChevronLeft />
        </IconButton>
        <Select
          value={span}
          onChange={changeSpan}
          size="small"
          sx={{ background: "background", borderRadius: 1 }}
        >
          <MenuItem value="week">Vecka</MenuItem>
          <MenuItem value="2weeks">2 veckor</MenuItem>
          <MenuItem value="period">Period</MenuItem>
        </Select>
        <IconButton
          onClick={() => goForward()}
          disabled={forwardDisabled}
          sx={{ p: 0 }}
        >
          <ChevronRight />
        </IconButton>
      </Stack>
      <CurrentDate
        value={start}
        onChange={changeStart}
        label="Antagen periodstart"
      />
    </Stack>
  );
}
