/**
 * @generated SignedSource<<821f560fed6dd1570adf8aaf2f381fb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingFreeWeekendWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SettingBoxFree1_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly freeEveryXOfYWeekends: number;
  readonly freeWeekendConstraintTypeEveryX: boolean;
  readonly freeWeekendConstraintTypeXOfY: boolean;
  readonly freeWeekendSoft: boolean;
  readonly freeWeekendWeight: ApiSettingFreeWeekendWeightChoices;
  readonly id: string;
  readonly periodLengthWeeks: number;
  readonly weekendEndsAt: string;
  readonly weekendEndsAtDay: number;
  readonly weekendStartsAt: string;
  readonly weekendStartsAtDay: number;
  readonly workEveryUnfreeWeekend: boolean;
  readonly workEveryUnfreeWeekendSoft: boolean;
  readonly workEveryXWeekend: number;
  readonly " $fragmentType": "SettingBoxFree1_fragment";
};
export type SettingBoxFree1_fragment$key = {
  readonly " $data"?: SettingBoxFree1_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingBoxFree1_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingBoxFree1_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodLengthWeeks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendStartsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendStartsAtDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendEndsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendEndsAtDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workEveryXWeekend",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeEveryXOfYWeekends",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendConstraintTypeEveryX",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendConstraintTypeXOfY",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workEveryUnfreeWeekend",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workEveryUnfreeWeekendSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "79bcc11d5f8807ea41e20553cd3efd88";

export default node;
