/**
 * @generated SignedSource<<178e3584b1fd2be4cd95170af97f61c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApiCompetenceDemandSettingUnderstaffingWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiSettingClockwiseRotationWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiSettingConsecutiveFreeDaysWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiSettingConsecutiveWorkDaysWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiSettingDayTypeCadenceWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiSettingDayTypeDistributionWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiSettingDayTypeFairnessWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiSettingFreeWeekendWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiSettingMinDailyRestAbWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiSettingMinDailyRestWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiSettingMinWeeklyRestAbWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiSettingNightShiftRestrictionChoices = "L0" | "L1" | "L2" | "L3" | "N" | "%future added value";
export type ApiSettingNightShiftRotationChoices = "N" | "R1" | "R2" | "R3" | "%future added value";
export type ApiSettingResourceShiftsWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiSettingShiftTypeDistributionWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiSettingShiftsEmploymentDegreeLogicChoices = "E" | "O" | "%future added value";
export type ApiSettingSplitShiftsWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiSettingTimebankModeChoices = "S" | "T" | "%future added value";
export type ApiSettingWishedFreeDaysWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiShiftDayTypeChoices = "D" | "E" | "F" | "N" | "%future added value";
export type ApiTeamDemandSettingOverstaffingWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamDemandSettingUnderstaffingWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamDemandSettingWeekendOverstaffingWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamDemandSettingWeekendUnderstaffingWeightChoices = "H" | "L" | "M" | "%future added value";
export type UpdateSettingInput = {
  adminHoursMax?: number | null;
  adminHoursMin?: number | null;
  allowSplitShifts?: boolean | null;
  cadenceRestrictions?: ReadonlyArray<DayTypeCadenceRestrictionInput | null> | null;
  clientMutationId?: string | null;
  clockwiseRotationLimitHours?: number | null;
  clockwiseRotationSoft?: boolean | null;
  clockwiseRotationWeight?: ApiSettingClockwiseRotationWeightChoices | null;
  competenceDemandSettings?: ReadonlyArray<CompetenceDemandSettingInput | null> | null;
  comprestCanCutDaybreakAbjour?: boolean | null;
  comprestCanCutDaybreakBilagaj?: boolean | null;
  consecutiveFreeDaysWeight?: ApiSettingConsecutiveFreeDaysWeightChoices | null;
  consecutiveWorkDaysWeight?: ApiSettingConsecutiveWorkDaysWeightChoices | null;
  constraintModules?: ReadonlyArray<string> | null;
  constraintModulesBridge?: ReadonlyArray<string> | null;
  dayBreakAlternatives?: ReadonlyArray<number> | null;
  dayShiftDistributionShare?: number | null;
  dayTypeCadenceWeight?: ApiSettingDayTypeCadenceWeightChoices | null;
  dayTypeDistributionWeight?: ApiSettingDayTypeDistributionWeightChoices | null;
  dayTypeFairnessWeight?: ApiSettingDayTypeFairnessWeightChoices | null;
  eveningShiftDistributionShare?: number | null;
  eveningStartsMorningEnds?: string | null;
  freeEveryXOfYWeekends?: number | null;
  freeWeekendConstraintTypeEveryX?: boolean | null;
  freeWeekendConstraintTypeXOfY?: boolean | null;
  freeWeekendSoft?: boolean | null;
  freeWeekendWeight?: ApiSettingFreeWeekendWeightChoices | null;
  fullDayDuration?: number | null;
  fullDayShiftDistributionShare?: number | null;
  hourlyCost?: number | null;
  hoursEmploymentDegreeFactor?: number | null;
  hoursPerWeek?: number | null;
  id: string;
  maxConsecutiveFreeDays?: number | null;
  maxConsecutiveFreeDaysSoft?: boolean | null;
  maxConsecutiveWorkDays?: number | null;
  maxConsecutiveWorkDaysSoft?: boolean | null;
  maxTimebankPeriod?: number | null;
  maxTimebankTotal?: number | null;
  maximizeApprovedWishes?: boolean | null;
  minConsecutiveFreeDays?: number | null;
  minConsecutiveFreeDaysSoft?: boolean | null;
  minConsecutiveWorkDays?: number | null;
  minConsecutiveWorkDaysSoft?: boolean | null;
  minDailyRestAbHours?: number | null;
  minDailyRestAbSoft?: boolean | null;
  minDailyRestAbWeight?: ApiSettingMinDailyRestAbWeightChoices | null;
  minDailyRestHours?: number | null;
  minDailyRestSoft?: boolean | null;
  minDailyRestWeight?: ApiSettingMinDailyRestWeightChoices | null;
  minTimebankPeriod?: number | null;
  minTimebankTotal?: number | null;
  minWeeklyRestAbHours?: number | null;
  minWeeklyRestAbSoft?: boolean | null;
  minWeeklyRestAbWeight?: ApiSettingMinWeeklyRestAbWeightChoices | null;
  mipgapLimit?: number | null;
  morningStartsNightEnds?: string | null;
  nightShiftDistributionShare?: number | null;
  nightShiftRestriction?: ApiSettingNightShiftRestrictionChoices | null;
  nightShiftRotation?: ApiSettingNightShiftRotationChoices | null;
  nightStartsEveningEnds?: string | null;
  offsetTimebankImbalance?: boolean | null;
  optimizeApprovedWishesDistributionBetweenEmployees?: boolean | null;
  optimizeDayTypeFairness?: boolean | null;
  optimizeDayTypeFairnessWeekend?: boolean | null;
  optimizeEvenShiftTypeDistributionBetweenEmployees?: boolean | null;
  optimizeResourceDistributionBetweenEmployees?: boolean | null;
  optimizeResourceDistributionBetweenTeams?: boolean | null;
  optimizeResourceDistributionWithinTeams?: boolean | null;
  optimizeSplitShiftsDistributionBetweenEmployees?: boolean | null;
  periodLengthWeeks?: number | null;
  prohibitedShiftTypes?: ReadonlyArray<string | null> | null;
  resourceHoursMax?: number | null;
  resourceHoursMin?: number | null;
  resourceShiftsWeight?: ApiSettingResourceShiftsWeightChoices | null;
  shiftDayTypeAllowedErrorMargin?: number | null;
  shiftDayTypeDistributionSoft?: boolean | null;
  shiftTypeDistributionWeight?: ApiSettingShiftTypeDistributionWeightChoices | null;
  shiftsEarlierEndNotOk?: ReadonlyArray<string> | null;
  shiftsEarlierStartNotOk?: ReadonlyArray<string> | null;
  shiftsEmploymentDegreeFactor?: number | null;
  shiftsEmploymentDegreeLogic?: ApiSettingShiftsEmploymentDegreeLogicChoices | null;
  shiftsLaterEndNotOk?: ReadonlyArray<string> | null;
  shiftsLaterStartNotOk?: ReadonlyArray<string> | null;
  shiftsPerWeek?: number | null;
  showAdvancedSettings?: boolean | null;
  splitShiftsWeight?: ApiSettingSplitShiftsWeightChoices | null;
  teamDemandSettings?: ReadonlyArray<TeamDemandSettingInput | null> | null;
  teamsExemptFromResourcePool?: ReadonlyArray<string> | null;
  timebankMode?: ApiSettingTimebankModeChoices | null;
  totalHoursMargin?: number | null;
  totalShiftsMargin?: number | null;
  uniqueWeeks?: boolean | null;
  useDemandWeights?: boolean | null;
  useDutyDemand?: boolean | null;
  weekBreakDaysAlternatives?: ReadonlyArray<number> | null;
  weekBreakHourAlternatives?: ReadonlyArray<number> | null;
  weekendEndsAt?: string | null;
  weekendEndsAtDay?: number | null;
  weekendStartsAt?: string | null;
  weekendStartsAtDay?: number | null;
  weeklyDailyCanOverlap?: boolean | null;
  wishedFreeDaysWeight?: ApiSettingWishedFreeDaysWeightChoices | null;
  workDayCadenceRestrictions?: ReadonlyArray<WorkDayCadenceRestrictionInput> | null;
  workEveryUnfreeWeekend?: boolean | null;
  workEveryUnfreeWeekendSoft?: boolean | null;
  workEveryXWeekend?: number | null;
  wrapAroundPeriodEnd?: boolean | null;
};
export type TeamDemandSettingInput = {
  allowUnderstaffing: boolean;
  id: string;
  overstaffingWeight: ApiTeamDemandSettingOverstaffingWeightChoices;
  understaffingWeight: ApiTeamDemandSettingUnderstaffingWeightChoices;
  weekendOverstaffingWeight: ApiTeamDemandSettingWeekendOverstaffingWeightChoices;
  weekendUnderstaffingWeight: ApiTeamDemandSettingWeekendUnderstaffingWeightChoices;
};
export type CompetenceDemandSettingInput = {
  allowUnderstaffing: boolean;
  id: string;
  understaffingWeight: ApiCompetenceDemandSettingUnderstaffingWeightChoices;
};
export type WorkDayCadenceRestrictionInput = {
  calendarDays: number;
  id?: string | null;
  isSoft: boolean;
  maxCadence?: number | null;
};
export type DayTypeCadenceRestrictionInput = {
  calendarDays: number;
  dayType: ApiShiftDayTypeChoices;
  id?: string | null;
  isSoft: boolean;
  maxCadence?: number | null;
};
export type UpdateSettingRestBetweenShiftsMutation$variables = {
  input: UpdateSettingInput;
};
export type UpdateSettingRestBetweenShiftsMutation$data = {
  readonly updateSetting: {
    readonly ok: boolean | null;
    readonly setting: {
      readonly " $fragmentSpreads": FragmentRefs<"SettingRestBetweenShifts_fragment">;
    } | null;
  } | null;
};
export type UpdateSettingRestBetweenShiftsMutation = {
  response: UpdateSettingRestBetweenShiftsMutation$data;
  variables: UpdateSettingRestBetweenShiftsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSettingRestBetweenShiftsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSettingPayload",
        "kind": "LinkedField",
        "name": "updateSetting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingNode",
            "kind": "LinkedField",
            "name": "setting",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SettingRestBetweenShifts_fragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSettingRestBetweenShiftsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSettingPayload",
        "kind": "LinkedField",
        "name": "updateSetting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingNode",
            "kind": "LinkedField",
            "name": "setting",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "constraintModules",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minDailyRestSoft",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minDailyRestHours",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minDailyRestWeight",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1a9f8522a32c0d3d1c0b1554828ae638",
    "id": null,
    "metadata": {},
    "name": "UpdateSettingRestBetweenShiftsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSettingRestBetweenShiftsMutation(\n  $input: UpdateSettingInput!\n) {\n  updateSetting(input: $input) {\n    ok\n    setting {\n      ...SettingRestBetweenShifts_fragment\n      id\n    }\n  }\n}\n\nfragment SettingRestBetweenShifts_fragment on SettingNode {\n  id\n  constraintModules\n  minDailyRestSoft\n  minDailyRestHours\n  minDailyRestWeight\n}\n"
  }
};
})();

(node as any).hash = "4d9e59660c9cad168d6c782fe2319e8e";

export default node;
