import {
  InputAdornment,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import useFormikState from "hooks/useFormikState";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { DayChips } from "components/common/DayCard";
import CustomField from "components/forms/CustomField";

type Props = {
  who: string;
  readOnly?: boolean;
  showProhibitedResourceWeekDays?: boolean;
};

function FormikDayChips({
  name,
  readOnly,
  disabled,
}: {
  name: string;
  readOnly?: boolean;
  disabled?: boolean;
}) {
  const { value, setValue } = useFormikState<number[]>(name);

  function onChange(i: number) {
    if (value.includes(i)) {
      setValue(value.filter((x) => x !== i));
    } else {
      setValue([...value, i]);
    }
  }

  return (
    <DayChips
      checked={value}
      onToggle={onChange}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function Worktime2({
  who,
  readOnly,
  showProhibitedResourceWeekDays,
}: Props) {
  const disabled = !useIsConstraintModuleEnabled("ResourceTime");

  return (
    <Stack gap={5}>
      <Stack gap={1}>
        <Typography variant="h4">Begränsa resurstid</Typography>
        <Typography>
          Här kan du styra hur många timmar av resurspass som ska schemaläggas
          {who}. Om både minsta och mesta antalet timmar är 0 så innebär det att
          ingen resurstid kommer schemaläggas.
        </Typography>

        <Grid container spacing={2} columns={2}>
          <Grid xs={1}>
            <Typography>Minsta antalet resurstimmar per period</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>Mesta antalet resurstimmar per period</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} columns={2}>
          <Grid xs={1}>
            <CustomField
              name="resourceHoursMin"
              fullWidth
              disabled={disabled}
              size="small"
              type="number"
              inputProps={{ step: 0.5 }}
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">timmar</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={1}>
            <CustomField
              name="resourceHoursMax"
              fullWidth
              disabled={disabled}
              size="small"
              type="number"
              inputProps={{ step: 0.5 }}
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">timmar</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Stack>
      {showProhibitedResourceWeekDays && (
        <Stack gap={2}>
          <Stack gap={1}>
            <Typography variant="h4">
              Begränsa resurspass på givna dagar
            </Typography>
            <Typography>
              De veckodagar du väljer kommer inte få några resurspass
              tilldelade.
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <FormikDayChips
              name="prohibitedResourceWeekDays"
              readOnly={readOnly}
              disabled={disabled}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
