import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { freeWeekendWeightChoices } from "./constants";
import { Free1Form } from "./forms";
import { UpdateSettingBoxFree1 } from "./mutations";
import type {
  SettingBoxFree1_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment SettingBoxFree1_fragment on SettingNode {
    id
    periodLengthWeeks
    weekendStartsAt
    weekendStartsAtDay
    weekendEndsAt
    weekendEndsAtDay
    workEveryXWeekend
    freeEveryXOfYWeekends
    freeWeekendConstraintTypeEveryX
    freeWeekendConstraintTypeXOfY
    freeWeekendWeight
    freeWeekendSoft
    workEveryUnfreeWeekend
    workEveryUnfreeWeekendSoft
    constraintModules
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required("Får ej vara tomt"),
  // NOTE! If we ever allow the weekend to start and end on the same week day,
  // then we need to ensure that the weekend doesn't start after its end.
  weekendStartsAt: yup.string().required("Får ej vara tomt"),
  weekendStartsAtDay: yup
    .number()
    .oneOf([4, 5], "Måste starta på fredag eller lördag")
    .required("Får ej vara tomt"),
  weekendEndsAt: yup.string().required("Får ej vara tomt"),
  weekendEndsAtDay: yup
    .number()
    .oneOf([6, 0], "Måste sluta på söndag eller måndag")
    .required("Får ej vara tomt"),
  workEveryXWeekend: yup
    .number()
    .required("Får ej vara tomt")
    .max(10, "Måste vara högst 10")
    .min(1, "Måste vara minst 1"),
  freeEveryXOfYWeekends: yup
    .number()
    .required("Får ej vara tomt")
    .max(52, "Måste vara högst 52")
    .min(0, "Måste vara minst 0"),
  freeWeekendConstraintTypeEveryX: yup.boolean().required("Får ej vara tomt"),
  freeWeekendConstraintTypeXOfY: yup.boolean().required("Får ej vara tomt"),
  freeWeekendWeight: yup
    .string()
    .oneOf(freeWeekendWeightChoices)
    .required("Får ej vara tomt"),
  freeWeekendSoft: yup.boolean().required("Får ej vara tomt"),
  workEveryUnfreeWeekend: yup.boolean().required("Får ej vara tomt"),
  workEveryUnfreeWeekendSoft: yup.boolean().required("Får ej vara tomt"),
  constraintModules: yup.array().of(yup.string()),
});

type Props = {
  fragmentRef: Key;
};

function Content({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const teamGroup = useCurrentTeamGroup();
  const data = useFragment(fragment, fragmentRef);

  const initialValues: FormValues = {
    id: data?.id || "",
    weekendStartsAt: data?.weekendStartsAt || "",
    weekendStartsAtDay: data?.weekendStartsAtDay || 4,
    weekendEndsAt: data?.weekendEndsAt || "",
    weekendEndsAtDay: data?.weekendEndsAtDay || 0,
    workEveryXWeekend: data?.workEveryXWeekend || 0,
    freeEveryXOfYWeekends: data?.freeEveryXOfYWeekends || 0,
    freeWeekendConstraintTypeEveryX:
      data?.freeWeekendConstraintTypeEveryX !== false, // default true
    freeWeekendConstraintTypeXOfY:
      data?.freeWeekendConstraintTypeXOfY !== false, // default true
    freeWeekendSoft: data?.freeWeekendSoft !== false,
    freeWeekendWeight: data?.freeWeekendWeight || "M",
    workEveryUnfreeWeekend: data?.workEveryUnfreeWeekend !== false,
    workEveryUnfreeWeekendSoft: data?.workEveryUnfreeWeekendSoft !== false,
    constraintModules: data?.constraintModules.slice() || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateSettingBoxFree1(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Free1Form
            groupName={teamGroup?.name || ""}
            periodLengthWeeks={data?.periodLengthWeeks || 0}
          />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxFree1({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
