import { Suspense, useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import { Box, Paper, Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useResultsParams } from "hooks";
import { DateTime } from "luxon";

import { date2string } from "components/common/GraphAxisFormat";
import {
  CreateActivityForm,
  EditActivityForm,
} from "components/schedule/activities";
import {
  ActivityGanttTimeline,
  ScheduleProfileChart,
} from "components/schedule/graphs";

import { Header } from "./Header";
import type { ActivityCharts_fragment$key as Key } from "./types";

const fragment = graphql`
  fragment ActivityCharts_fragment on ScheduleNode {
    id
    period {
      startDate
      endDate
    }
    ...Header_fragment
  }
`;

type ActivityChartsProps = {
  fragmentRef: Key;
  toggleDrawer: () => void;
  refresh: () => Promise<void>;
};

const allTeams = {
  id: "",
  name: "Alla avdelningar",
  color: "",
  group: { id: "", name: "" },
};
const allCompetences = { id: "", name: "Alla kompetenser", color: "" };

export function ActivityCharts({
  fragmentRef,
  toggleDrawer,
  refresh,
}: ActivityChartsProps) {
  const schedule = useFragment<Key>(fragment, fragmentRef);
  const { startDate, endDate } = schedule.period || {};
  const periodStart = useMemo(
    () => DateTime.fromISO(startDate).toJSDate(),
    [startDate],
  );
  const periodEnd = useMemo(
    () => DateTime.fromISO(endDate).toJSDate(),
    [endDate],
  );
  const {
    new: showCreate,
    setNew: setShowCreate,
    edit: selectedActivityId,
    setEdit: setSelectedActivityId,
    colorFocus,
    setColorFocus,
    team,
    setTeam,
    competence,
    setCompetence,
    activities: selectedActivities,
    setActivities,
    from,
    to,
  } = useResultsParams({
    periodStart,
    periodEnd,
  });

  function afterEditActivity() {
    setSelectedActivityId("");
    refresh();
  }

  function afterCreateActivity() {
    setShowCreate(false);
    refresh();
  }

  return (
    <Stack gap={2}>
      <Header
        fragmentRef={schedule}
        team={team}
        setTeam={(id: string | null) => setTeam(id || allTeams.id)}
        competence={competence}
        setCompetence={(id: string | null) =>
          setCompetence(id || allCompetences.id)
        }
        allTeams={allTeams}
        allCompetences={allCompetences}
        activities={selectedActivities}
        colorFocus={colorFocus}
        setColorFocus={setColorFocus}
        setActivities={setActivities}
        setShowCreate={setShowCreate}
        toggleDrawer={toggleDrawer}
      />
      <Paper variant="box" sx={{ p: 2, mb: 2 }}>
        <Suspense fallback="">
          {!!schedule?.id && (
            <ScheduleProfileChart
              scheduleId={schedule.id}
              minDate={date2string(from.getTime())}
              maxDate={date2string(to.getTime())}
              selectedTeam={team === allTeams.id ? null : team}
              selectedCompetence={
                competence === allCompetences.id ? null : competence
              }
              colorFocus={colorFocus}
            />
          )}
        </Suspense>
        <Box sx={{ mr: "2px" }}>
          <Suspense fallback={null}>
            {!!schedule?.id && (
              <ActivityGanttTimeline
                scheduleId={schedule.id}
                onItemSelect={(id) => setSelectedActivityId(id)}
                minDate={date2string(from.getTime())}
                maxDate={date2string(to.getTime())}
                selectedTeam={team === allTeams.id ? null : team}
                selectedActivities={selectedActivities}
                selectedCompetence={
                  competence === allCompetences.id ? null : competence
                }
                colorFocus={colorFocus}
              />
            )}
          </Suspense>
        </Box>
      </Paper>
      <Suspense fallback={null}>
        <EditActivityForm
          open={Boolean(selectedActivityId)}
          onClose={afterEditActivity}
          activityId={selectedActivityId}
        />
      </Suspense>
      <Suspense fallback={null}>
        <CreateActivityForm
          scheduleId={schedule.id}
          open={showCreate}
          onClose={afterCreateActivity}
        />
      </Suspense>
    </Stack>
  );
}
