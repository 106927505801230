import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { Base7Form } from "./forms";
import { UpdateSettingBoxBase7 } from "./mutations";
import type {
  SettingBoxBase7_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment SettingBoxBase7_fragment on SettingNode {
    id
    hourlyCost
  }
`;

const base7Validation = yup.object().shape({
  id: yup.string(),
  hourlyCost: yup
    .number()
    .min(0, "Måste vara minst 0")
    .required("Får ej vara tomt"),
});

type Props = {
  fragmentRef: Key;
};

function Content({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const teamGroup = useCurrentTeamGroup();
  const data = useFragment<Key>(fragment, fragmentRef);
  const initialValues: FormValues = {
    id: data?.id || "",
    hourlyCost: data?.hourlyCost || 0,
  };

  async function onSubmit(input: FormValues) {
    await UpdateSettingBoxBase7(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={base7Validation}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Base7Form groupName={teamGroup?.name || ""} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxBase7({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
