import { Stack, Typography } from "@mui/material";

import { SmallSwitch } from "components/forms/Switch";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function Types6User({ disabled, readOnly }: Props) {
  return (
    <Stack gap={5}>
      <Stack gap={1}>
        <Stack gap={2} direction="row">
          <Typography variant="h4">
            Inkludera i balansering av Dag-/Kväll-/Natt-rättvisa på helg
          </Typography>
          <SmallSwitch
            name="includeInDayTypeWeekendFairness"
            disabled={disabled}
          />
        </Stack>
        <Typography>
          Med denna inställning påslagen, så kommer optimeringsalgoritmen att
          inkludera användaren i rättvis balansering av Dag/Kväll/Natt mellan
          medarbetare på helger. Detta är alltid en mjuk regel.
        </Typography>
      </Stack>
    </Stack>
  );
}
