/**
 * @generated SignedSource<<de046a0f0a68072e99b6bb1a8d8be873>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ApiSettingShiftsEmploymentDegreeLogicChoices = "E" | "O" | "%future added value";
export type ApiUserSettingNightShiftRestrictionChoices = "L0" | "L1" | "L2" | "L3" | "N" | "%future added value";
export type ApiUserSettingNightShiftRotationChoices = "N" | "R1" | "R2" | "R3" | "%future added value";
export type UpdateRuleGroupSettingInput = {
  clientMutationId?: string | null;
  constraintModules?: ReadonlyArray<string> | null;
  currentTeamGroupId?: string | null;
  dayBreakAlternatives?: ReadonlyArray<number> | null;
  freeEveryXOfYWeekends?: number | null;
  freeWeekendConstraintTypeEveryX?: boolean | null;
  freeWeekendConstraintTypeXOfY?: boolean | null;
  freeWeekendSoft?: boolean | null;
  hourlyCost?: number | null;
  hoursEmploymentDegreeFactor?: number | null;
  hoursPerWeek?: number | null;
  id: string;
  maxConsecutiveFreeDays?: number | null;
  maxConsecutiveFreeDaysSoft?: boolean | null;
  maxConsecutiveWorkDays?: number | null;
  maxConsecutiveWorkDaysSoft?: boolean | null;
  minConsecutiveFreeDays?: number | null;
  minConsecutiveFreeDaysSoft?: boolean | null;
  minConsecutiveWorkDays?: number | null;
  minConsecutiveWorkDaysSoft?: boolean | null;
  nightShiftRestriction?: ApiUserSettingNightShiftRestrictionChoices | null;
  nightShiftRotation?: ApiUserSettingNightShiftRotationChoices | null;
  prohibitedShiftTypes?: ReadonlyArray<string | null> | null;
  shiftsEmploymentDegreeFactor?: number | null;
  shiftsEmploymentDegreeLogic?: ApiSettingShiftsEmploymentDegreeLogicChoices | null;
  shiftsPerWeek?: number | null;
  weekBreakDaysAlternatives?: ReadonlyArray<number> | null;
  weekBreakHourAlternatives?: ReadonlyArray<number> | null;
  workEveryUnfreeWeekend?: boolean | null;
  workEveryUnfreeWeekendSoft?: boolean | null;
  workEveryXWeekend?: number | null;
};
export type modifyRuleGroupSettingModulesMutation$variables = {
  input: UpdateRuleGroupSettingInput;
};
export type modifyRuleGroupSettingModulesMutation$data = {
  readonly updateRuleGroupSetting: {
    readonly ok: boolean | null;
    readonly ruleGroupSetting: {
      readonly settingModules: ReadonlyArray<string>;
    } | null;
  } | null;
};
export type modifyRuleGroupSettingModulesMutation = {
  response: modifyRuleGroupSettingModulesMutation$data;
  variables: modifyRuleGroupSettingModulesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "settingModules",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "modifyRuleGroupSettingModulesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRuleGroupSettingPayload",
        "kind": "LinkedField",
        "name": "updateRuleGroupSetting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleGroupSettingNode",
            "kind": "LinkedField",
            "name": "ruleGroupSetting",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "modifyRuleGroupSettingModulesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRuleGroupSettingPayload",
        "kind": "LinkedField",
        "name": "updateRuleGroupSetting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleGroupSettingNode",
            "kind": "LinkedField",
            "name": "ruleGroupSetting",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7c81941416f2705ec371ee14fdc4fc3a",
    "id": null,
    "metadata": {},
    "name": "modifyRuleGroupSettingModulesMutation",
    "operationKind": "mutation",
    "text": "mutation modifyRuleGroupSettingModulesMutation(\n  $input: UpdateRuleGroupSettingInput!\n) {\n  updateRuleGroupSetting(input: $input) {\n    ok\n    ruleGroupSetting {\n      settingModules\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "578e687d657a42bd5a95760d58144fee";

export default node;
