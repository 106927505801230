import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { Base7Form } from "./forms";
import { UpdateRuleGroupSettingBoxBase7 } from "./mutations";
import type {
  RuleGroupSettingBoxBase7_fragment$key as Key,
  UpdateRuleGroupSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment RuleGroupSettingBoxBase7_fragment on RuleGroupSettingNode {
    id
    hourlyCost
    settingModules
    ruleGroup {
      name
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  hourlyCost: yup
    .number()
    .min(0, "Måste vara minst 0")
    .required("Får ej vara tomt"),
  constraintModules: yup.array().of(yup.string()).required(),
});

type Props = {
  fragmentRef: Key;
};

const Content = ({ fragmentRef }: Props) => {
  const environment = useRelayEnvironment();
  const data = useFragment<Key>(fragment, fragmentRef);
  const { name = "" } = data?.ruleGroup || {};
  const initialValues = {
    id: data?.id || "",
    hourlyCost: data?.hourlyCost || 0,
    constraintModules: data?.settingModules.slice().filter(Boolean) || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateRuleGroupSettingBoxBase7(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Base7Form name={name} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
};

export function RuleGroupSettingBoxBase7({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
