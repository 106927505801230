import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { WishedDaysOffOldForm } from "settings/RequestedDaysOff/form/WishedDaysOffOldForm";
import * as yup from "yup";

import { AutoSubmit } from "components/setting/common";

import { useUpdateSettingWishedDaysOff } from "../mutations";
import type { UpdateSettingInput as FormValues } from "../types";

import type { SettingWishedDaysOff_fragment$key as Key } from "./types";

type Props = {
  fragmentRef: Key;
};

const fragment = graphql`
  fragment SettingWishedDaysOff_fragment on SettingNode {
    id
    constraintModules
    maximizeApprovedWishes
    optimizeApprovedWishesDistributionBetweenEmployees
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  constraintModules: yup.array().of(yup.string()).required(),
  maximizeApprovedWishes: yup.boolean().required(),
  optimizeApprovedWishesDistributionBetweenEmployees: yup.boolean().required(),
});

export function SettingWishedDaysOff({ fragmentRef }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useUpdateSettingWishedDaysOff();
  const initialValues = useMemo<FormValues>(
    () => ({
      id: data.id,
      constraintModules: data.constraintModules.slice(),
      maximizeApprovedWishes: data.maximizeApprovedWishes,
      optimizeApprovedWishesDistributionBetweenEmployees:
        data.optimizeApprovedWishesDistributionBetweenEmployees,
    }),
    [data],
  );

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <WishedDaysOffOldForm />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}
