import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { Rhythm3Form } from "./forms";
import { UpdateRuleGroupSettingBoxRhythm3 } from "./mutations";
import type {
  RuleGroupSettingBoxRhythm3_fragment$key as Key,
  UpdateRuleGroupSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment RuleGroupSettingBoxRhythm3_fragment on RuleGroupSettingNode {
    id
    maxConsecutiveWorkDays
    maxConsecutiveWorkDaysSoft
    minConsecutiveWorkDays
    minConsecutiveWorkDaysSoft
    settingModules
    ruleGroup {
      name
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  maxConsecutiveWorkDays: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(31, "Måste vara högst 31")
    .required("Får ej vara tomt"),
  maxConsecutiveWorkDaysSoft: yup.boolean().required(),
  minConsecutiveWorkDays: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(31, "Måste vara högst 31")
    .required("Får ej vara tomt"),
  minConsecutiveWorkDaysSoft: yup.boolean().required(),
  constraintModules: yup.array().of(yup.string()).required(),
});

type Props = {
  fragmentRef: Key;
};

const Content = ({ fragmentRef }: Props) => {
  const environment = useRelayEnvironment();
  const data = useFragment(fragment, fragmentRef);
  const { name = "" } = data?.ruleGroup || {};
  const initialValues = {
    id: data?.id || "",
    maxConsecutiveWorkDays: data?.maxConsecutiveWorkDays || 2,
    maxConsecutiveWorkDaysSoft: data?.maxConsecutiveWorkDaysSoft !== false, // default true
    minConsecutiveWorkDays: data?.minConsecutiveWorkDays || 2,
    minConsecutiveWorkDaysSoft: data?.minConsecutiveWorkDaysSoft !== false, // default true
    constraintModules: data?.settingModules.slice().filter(Boolean) || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateRuleGroupSettingBoxRhythm3(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Rhythm3Form name={name} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
};

export function RuleGroupSettingBoxRhythm3({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
