import { AdminHeader } from "components/admin";
import { PageWrapper } from "components/layout/PageWrapper";

import { Tabs } from "./Tabs";

export function Admin() {
  return (
    <PageWrapper header={<AdminHeader />}>
      <Tabs />
    </PageWrapper>
  );
}
