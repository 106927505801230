import { Route, Routes } from "react-router-dom";
import {
  Admin,
  BlockedTimeSlot,
  Demand,
  GroupSettings,
  Home,
  Login,
  Organisation,
  Page404,
  Period,
  Schedule,
  Settings,
  Shifts,
  UserSettings,
} from "pages";

import { ProtectedRoute } from "components/ProtectedRoute";
import { RequireTeamGroupRoute } from "components/RequireTeamGroupRoute";

const routes = () => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route element={<ProtectedRoute redirectTo="/login" />}>
      <Route index element={<Home />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/settings" element={<UserSettings />} />
      <Route element={<RequireTeamGroupRoute redirectTo="/" />}>
        <Route path="/demand" element={<Demand />} />
        <Route path="/group-settings" element={<GroupSettings />} />
        <Route path="/members" element={<Organisation />} />
        <Route path="/periods/:id?" element={<Period />} />
        <Route path="/schedules/:id/:index?" element={<Schedule />} />
        <Route path="/shifts" element={<Shifts />} />
        <Route path="/vacation" element={<BlockedTimeSlot />} />
        <Route
          path="/schedule-settings/:levelId?/:ruleId?"
          element={<Settings />}
        />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Route>
  </Routes>
);

export default routes;
