import { Formik } from "formik";

import {
  Base5 as Base5Box,
  base5DefaultValues as defaultValues,
} from "components/setting/common/forms/Base5";

export function Base5() {
  return (
    <Formik initialValues={defaultValues} onSubmit={() => {}}>
      <Base5Box disabled />
    </Formik>
  );
}
