import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { Base2Form } from "./forms";
import { UpdateSettingBoxBase2 } from "./mutations";
import type {
  SettingBoxBase2_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment SettingBoxBase2_fragment on SettingNode {
    id
    periodLengthWeeks
    hoursPerWeek
    shiftsPerWeek
  }
`;

const validationSchema = yup.object().shape({
  hoursPerWeek: yup
    .number()
    .min(0, "Måste vara minst 0 timmar")
    .max(168, "Måste vara högst 168 timmar")
    .required("Får ej vara tomt"),
  shiftsPerWeek: yup
    .number()
    .min(0, "Måste vara minst 0 skift")
    .max(168, "Måste vara högst 168 skift")
    .required("Får ej vara tomt"),
});

type Props = {
  fragmentRef: Key;
};

function Content({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const teamGroup = useCurrentTeamGroup();
  const data = useFragment(fragment, fragmentRef);
  const { periodLengthWeeks = 0 } = data || {};
  const initialValues: FormValues = {
    id: data?.id || "",
    hoursPerWeek: data?.hoursPerWeek || 0,
    shiftsPerWeek: data?.shiftsPerWeek || 0,
  };

  async function onSubmit(input: FormValues) {
    await UpdateSettingBoxBase2(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Base2Form
            groupName={teamGroup?.name || ""}
            periodLengthWeeks={periodLengthWeeks}
          />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxBase2({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
