/**
 * @generated SignedSource<<a3a545ef6e77c6d93a42ec6033780809>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RuleGroupSettingGroupAdvanced_fragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RuleGroupSettingNightShifts_fragment" | "RuleGroupSettingParttimes_fragment">;
  readonly " $fragmentType": "RuleGroupSettingGroupAdvanced_fragment";
};
export type RuleGroupSettingGroupAdvanced_fragment$key = {
  readonly " $data"?: RuleGroupSettingGroupAdvanced_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RuleGroupSettingGroupAdvanced_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RuleGroupSettingGroupAdvanced_fragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RuleGroupSettingNightShifts_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RuleGroupSettingParttimes_fragment"
    }
  ],
  "type": "RuleGroupSettingNode",
  "abstractKey": null
};

(node as any).hash = "8c2147be7420a41e59202876b8787c9c";

export default node;
