/**
 * @generated SignedSource<<a65eb5773bd053914d0d9f446de8850c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingResourceShiftsWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SettingBoxTypes2_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly id: string;
  readonly optimizeResourceDistributionBetweenEmployees: boolean;
  readonly optimizeResourceDistributionBetweenTeams: boolean;
  readonly optimizeResourceDistributionWithinTeams: boolean;
  readonly resourceShiftsWeight: ApiSettingResourceShiftsWeightChoices;
  readonly " $fragmentType": "SettingBoxTypes2_fragment";
};
export type SettingBoxTypes2_fragment$key = {
  readonly " $data"?: SettingBoxTypes2_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingBoxTypes2_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingBoxTypes2_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeResourceDistributionWithinTeams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeResourceDistributionBetweenTeams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeResourceDistributionBetweenEmployees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resourceShiftsWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "de599220e970ca3fb16049c6deac5b61";

export default node;
