import { TextField } from "@mui/material";
import { DateTime } from "luxon";

import type { RecurrenceRuleType } from "components/blocked_time_slot/common/Recurrence/types";

type Props = {
  value: RecurrenceRuleType;
  onChange: (value: RecurrenceRuleType) => void;
};

export default function RecurrenceDateItem({ value, onChange }: Props) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const valueDate = event.target.valueAsDate || new Date();
    // A date input uses UTC, which means we end up with the wrong local time when using valueAsDate.
    // Instead, we initialize a new local date below to ensure we're representing 00:00 local time.
    const dtstart = new Date(
      valueDate.getUTCFullYear(),
      valueDate.getUTCMonth(),
      valueDate.getUTCDate(),
    );
    onChange({ ...value, dtstart });
  }

  return (
    <TextField
      type="date"
      label="Datum"
      value={DateTime.fromJSDate(value?.dtstart || new Date()).toFormat(
        "yyyy-MM-dd",
      )}
      onChange={handleChange}
      size="small"
      sx={{ width: 200 }}
    />
  );
}
