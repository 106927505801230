import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import { Link } from "react-router-dom";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { Button, Chip, Stack, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { TerminationCondition } from "types/Schedule";

import type { TabHeader_fragment$key as Key } from "./__generated__/TabHeader_fragment.graphql";

const fragment = graphql`
  fragment TabHeader_fragment on ScheduleNode {
    created
    terminationCondition
    copiedFrom {
      id
    }
    period {
      id
      startDate
      endDate
      group {
        name
      }
    }
  }
`;

type TabProps = {
  fragmentRef: Key;
};

export function TabHeader({ fragmentRef }: TabProps) {
  const schedule = useFragment<Key>(fragment, fragmentRef);
  const isHardCollision = useMemo(
    () =>
      schedule.terminationCondition ===
      TerminationCondition.HARD_CONSTRAINT_COLLISION,
    [schedule],
  );
  const periodStart = new Date(schedule.period.startDate);
  const periodEnd = new Date(schedule.period.endDate);
  // Transform some dates into other formats
  const yearStart = new Date(periodStart.getFullYear(), 0, 1);
  var startWeek = Math.ceil(
    Math.floor(
      (periodStart.getTime() - yearStart.getTime()) / (24 * 60 * 60 * 1000),
    ) / 7,
  );
  const yearEnd = new Date(periodEnd.getFullYear(), 0, 1);
  var endWeek = Math.ceil(
    Math.floor(
      (periodEnd.getTime() - yearEnd.getTime()) / (24 * 60 * 60 * 1000),
    ) / 7,
  );

  const buttonSx = {
    "& .MuiButton-startIcon > *": {
      marginRight: "-7px",
      marginLeft: "-4px",
      marginTop: "-2px",
      fontWeight: "bold",
    },
  };

  return (
    <Stack>
      <Stack direction="row">
        <Button
          startIcon={<ChevronLeftIcon />}
          component={Link}
          to={`/periods/${schedule.period.id}`}
          sx={buttonSx}
          variant="icon"
        >
          Tillbaka till periodvyn
        </Button>
      </Stack>

      <Stack direction="row" spacing={1} justifyContent="space-between">
        <Stack id="title" gap={1} pt={1} pb={4}>
          <Stack direction="row" gap={1}>
            <Typography variant="h1">
              Schema för enhet <b>{schedule.period.group.name}</b>
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography>
              Skapades {new Date(schedule.created).toLocaleString()}{" "}
              &nbsp;&nbsp; | &nbsp;&nbsp; Gäller för perioden vecka {startWeek}-
              {endWeek} &nbsp;&nbsp; | &nbsp;&nbsp;
            </Typography>
            <Chip
              label={
                schedule.copiedFrom?.id ? "Manuellt redigerad" : "Original"
              }
              size="small"
            />
            {isHardCollision && (
              <Chip label="Hård regelkrock" size="small" color="error" />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
