import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import type { IEnvironment } from "relay-runtime";

import type {
  UpdateUserSettingBoxAB2Mutation as Mutation,
  UpdateUserSettingBoxAB2Mutation$variables as Variables,
} from "./types";

const mutation = graphql`
  mutation UpdateUserSettingBoxAB2Mutation($input: UpdateUserSettingInput!) {
    updateUserSetting(input: $input) {
      ok
      userSetting {
        ...UserSettingBoxAB2_fragment
      }
    }
  }
`;

export async function UpdateUserSettingBoxAB2(
  environment: IEnvironment,
  variables: Variables,
) {
  return commitMutation<Mutation>(environment, {
    mutation,
    variables,
  });
}
