/**
 * @generated SignedSource<<5fb3d885db8764aac956f1e0d35f57e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSettingBoxTypes3_fragment$data = {
  readonly id: string;
  readonly prohibitedShiftTypes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly shiftParts: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
  readonly settingModules: ReadonlyArray<string>;
  readonly user: {
    readonly fullName: string;
  };
  readonly " $fragmentType": "UserSettingBoxTypes3_fragment";
};
export type UserSettingBoxTypes3_fragment$key = {
  readonly " $data"?: UserSettingBoxTypes3_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSettingBoxTypes3_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserSettingBoxTypes3_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftNodeConnection",
      "kind": "LinkedField",
      "name": "prohibitedShiftTypes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ShiftNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShiftNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShiftPartNodeConnection",
                  "kind": "LinkedField",
                  "name": "shiftParts",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ShiftPartNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ShiftPartNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserSettingNode",
  "abstractKey": null
};
})();

(node as any).hash = "dab0cd5233736cbc2a8d8af05933d2bc";

export default node;
