import { useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay/hooks";
import { Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { minutes2HHMM, padThousands } from "helpers/dateFunctions";
import type {
  MRT_ColumnDef,
  MRT_TableInstance,
  MRT_VisibilityState,
} from "material-react-table";
import type { ExtractNode, Unwrap } from "relay-help/arrays";
import { connectionToArray } from "relay-help/arrays";
import { Colors } from "styles/colors";
import type { Presets } from "types/MaterialReactTable";
import { getTableConfig } from "utils/localStorage";

import MRTable, { sortListBy } from "components/common/MRTable";
import type { Column as PresetColumn } from "components/common/MRTable/TablePreset";
import {
  NO_PRESET_KEY,
  Select as PresetSelect,
} from "components/common/MRTable/TablePreset";
import { TeamChip } from "components/common/TeamChip";

import {
  RequiredVsScheduledChart_fragment$data as Data,
  RequiredVsScheduledChart_fragment$key as Key,
} from "./__generated__/RequiredVsScheduledChart_fragment.graphql";
import { activityShiftDayTypes } from "./types";

const TABLE_CONFIG_KEY = "required-vs-scheduled-chart";

const PREDEFINED_PRESETS: Presets = [
  {
    key: NO_PRESET_KEY,
    name: "Alla kolumner",
    columns: [
      "name",
      "teams",
      "employmentDegree",
      "cost",
      "requiredHours",
      "scheduledHours",
      "timebankBalance",
      "hoursDiff",
      "timebankBalanceNew",
      "requiredShifts",
      "scheduledShifts",
      "averageShiftLength",
      "dayWishes",
      "eveningWishes",
      "nightWishes",
      "fullDayWishes",
      "shiftDayTypeWishes",
      "dayActual",
      "eveningActual",
      "nightActual",
      "fullDayActual",
      "dayActual",
      "shiftDayTypeActual",
      "expectedWeekendWorkdayCount",
      "actualWeekendWorkdayCount",
    ],
  },
  {
    key: "scheduling",
    name: "Timmar",
    columns: [
      "name",
      "originalHours",
      "requiredHours",
      "subtractedVacationHours",
      "scheduledHours",
    ],
  },
  {
    key: "shift",
    name: "Pass",
    columns: [
      "name",
      "originalShifts",
      "subtractedVacationShifts",
      "requiredShifts",
      "scheduledShifts",
      "averageShiftLength",
    ],
  },
  {
    key: "timebank",
    name: "Timbank",
    columns: ["name", "timebankBalance", "hoursDiff", "timebankBalanceNew"],
  },
];

const RESERVED_PRESET_KEYS = PREDEFINED_PRESETS.map((p) => p.key);

const fragment = graphql`
  fragment RequiredVsScheduledChart_fragment on ScheduleStats {
    userStats {
      id
      name
      employmentDegree
      timebankBalance
      subtractedVacationHours
      subtractedVacationShifts
      hourlyCost
      requiredHours
      requiredShifts
      averageLength
      minLength
      maxLength
      scheduledHours
      scheduledShifts
      expectedWeekendWorkdayCount
      actualWeekendWorkdayCount
      activities {
        edges {
          node {
            shiftDayType
          }
        }
      }
      competences {
        edges {
          node {
            id
          }
        }
      }
      teams {
        edges {
          node {
            id
            name
            color
          }
        }
      }
      userSetting {
        settingModules
        dayShiftDistributionShare
        eveningShiftDistributionShare
        nightShiftDistributionShare
        fullDayShiftDistributionShare
      }
      schedule {
        teamgroupsettingsnapshot {
          constraintModules
          dayShiftDistributionShare
          eveningShiftDistributionShare
          nightShiftDistributionShare
          fullDayShiftDistributionShare
        }
      }
    }
  }
`;

type UserType = Unwrap<Data["userStats"]>;
type Competence = ExtractNode<NonNullable<UserType>["competences"]>;
type Activity = ExtractNode<NonNullable<UserType>["activities"]>;
type Team = ExtractNode<NonNullable<UserType>["teams"]>;
type RowType = Omit<UserType, "activities" | "competences" | "teams"> & {
  activities: ReadonlyArray<Activity>;
  competences: ReadonlyArray<Competence>;
  teams: ReadonlyArray<Team>;
};
type ColumnType = MRT_ColumnDef<RowType>;

type ShiftDayTypeArray = [number, number, number, number];

type Props = {
  fragmentRef: Key;
  teamId: string | null;
  filterTeam: boolean;
  competence: string | null;
  filterCompetence: boolean;
};

// Color styles
const GREENSTYLE = { color: Colors.TANG, fontWeight: 750 };
const PURPLESTYLE = { color: Colors.ANEMON, fontWeight: 750 };
const ORANGESTYLE = { color: Colors.LAND, fontWeight: 750 };

function round(value: number, decimals: number = 1) {
  const mult = 10 ** decimals;
  return Math.round(value * mult) / mult;
}

function isTolerated(value: number) {
  if (value > -0.5 && value < 0.5) {
    return true;
  }
  return false;
}
function getTextSx(value: number) {
  if (isTolerated(value)) {
    return GREENSTYLE;
  }
  if (value > 0) {
    return PURPLESTYLE;
  }
  return ORANGESTYLE;
}

function ColoredText({ value, diff }: { value: string; diff: number }) {
  return <Typography sx={getTextSx(diff)}>{value}</Typography>;
}

function getDistributionColumnFooter(
  table: MRT_TableInstance<RowType>,
  columnKey: string,
) {
  const { value, total } = table.getFilteredRowModel().flatRows.reduce(
    (acc, row) => {
      const shifts = row.getValue<number>("requiredShifts");
      acc.value += row.getValue<number>(columnKey) * shifts;
      acc.total += shifts;
      return acc;
    },
    { value: 0, total: 0 },
  );
  const share = value / total;
  const label = share >= 0 ? `${round(share * 100)}%` : "-";
  return label;
}

export function RequiredVsScheduledChart({
  fragmentRef,
  teamId,
  filterTeam,
  competence,
  filterCompetence,
}: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);

  const teamFilter = useCallback(
    (row: RowType) => !filterTeam || row.teams.some((e) => e?.id === teamId),
    [teamId, filterTeam],
  );
  const competenceFilter = useCallback(
    (row: RowType) =>
      !filterCompetence || row.competences.some((e) => e.id === competence),
    [competence, filterCompetence],
  );
  const filter = useCallback(
    (r: RowType) => teamFilter(r) && competenceFilter(r),
    [teamFilter, competenceFilter],
  );

  const filteredData = useMemo<RowType[]>(
    () =>
      (data.userStats || [])
        .map(({ activities, competences, teams, ...u }) => ({
          ...u,
          activities: connectionToArray(activities),
          competences: connectionToArray(competences),
          teams: connectionToArray(teams),
        }))
        .filter(filter),
    [data, filter],
  );

  const columns = useMemo<ColumnType[]>(
    () => [
      {
        header: "Namn",
        accessorKey: "name",
        id: "name",
        Footer: () => <b>Totalt</b>,
      },
      {
        header: "Avdelningar",
        enableGrouping: false,
        id: "teams",
        accessorFn: (user: RowType) => user.teams,
        filterFn: (row, _id, filterValue) =>
          row
            .getValue<Team[]>("teams")
            .some((x: any) =>
              x.name.toLowerCase().includes(filterValue.toLowerCase()),
            ),
        sortingFn: (a, b) =>
          sortListBy<Team>(
            a.original.teams,
            b.original.teams,
            (x) => x?.name || "",
          ),
        Cell: ({ cell }) => (
          <>
            {cell.getValue<RowType[]>().map((t: any) => (
              <TeamChip team={t} key={t?.id} size="small" />
            ))}
          </>
        ),
      },
      {
        header: "Tjänstegrad",
        accessorKey: "employmentDegree",
        accessorFn: (user: RowType) =>
          Math.round(user?.employmentDegree * 100) / 100,
        Cell: ({ cell }) => `${cell.getValue<number>()}%`,
        Footer: ({ table }) => {
          const sum = table
            .getFilteredRowModel()
            .flatRows.reduce(
              (acc, row) => acc + row.original.employmentDegree,
              0,
            );
          return <Typography>{Math.round(sum) / 100} heltider</Typography>;
        },
      },
      {
        header: "Kostnad",
        id: "cost",
        accessorFn: (view: RowType) => view.hourlyCost * view.scheduledHours,
        Cell: ({ cell }) => `${padThousands(cell.getValue<number>())} kr`,
        Footer: ({ table }) => {
          const sum = table
            .getFilteredRowModel()
            .flatRows.reduce(
              (acc, row) =>
                acc + row.original.hourlyCost * row.original.scheduledHours,
              0,
            );
          return <Typography>{padThousands(sum)} kr</Typography>;
        },
      },
      {
        header: "Timmar",
        id: "hours",
        Header: () => <Typography fontStyle="italic">Timmar</Typography>,
        columns: [
          {
            header: "Ordinarie arbetstidsmått",
            id: "originalHours",
            accessorFn: (view: RowType) =>
              (view.requiredHours + view.subtractedVacationHours) * 60,
            Cell: ({ cell }) => minutes2HHMM(cell.getValue<number>()),
            Footer: ({ table }) => {
              const sum = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) =>
                    acc +
                    (row.original.requiredHours +
                      row.original.subtractedVacationHours) *
                      60,
                  0,
                );
              return <Typography>{minutes2HHMM(sum)}</Typography>;
            },
          },
          {
            header: "Avdragen semester",
            accessorKey: "subtractedVacationHours",
            accessorFn: (view: RowType) => view.subtractedVacationHours * 60,
            Cell: ({ cell }) => minutes2HHMM(cell.getValue<number>()),
            Footer: ({ table }) => {
              const sum = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) => acc + row.original.subtractedVacationHours * 60,
                  0,
                );
              return <Typography>{minutes2HHMM(sum)}</Typography>;
            },
          },
          {
            header: "Förväntat",
            accessorKey: "requiredHours",
            accessorFn: (view: RowType) => view.requiredHours * 60,
            Cell: ({ cell }) => minutes2HHMM(cell.getValue<number>()),
            Footer: ({ table }) => {
              const sum = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) => acc + row.original.requiredHours * 60,
                  0,
                );
              return <Typography>{minutes2HHMM(sum)}</Typography>;
            },
          },
          {
            header: "Schemalagt",
            accessorKey: "scheduledHours",
            accessorFn: (view: RowType) => view.scheduledHours * 60,
            Cell: ({ cell }) => {
              const value = cell.getValue<number>();
              const diff = value - cell.row.original.requiredHours * 60;
              return <ColoredText value={minutes2HHMM(value)} diff={diff} />;
            },
            Footer: ({ table }) => {
              const sum = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) => acc + row.original.scheduledHours * 60,
                  0,
                );
              return <Typography>{minutes2HHMM(sum)}</Typography>;
            },
          },
        ],
      },
      {
        header: "Timbank",
        id: "timebank",
        Header: () => <Typography fontStyle="italic">Timbank</Typography>,
        columns: [
          {
            header: "Saldo",
            accessorKey: "timebankBalance",
            accessorFn: (view: RowType) => view.timebankBalance,
            Cell: ({ cell }) =>
              minutes2HHMM(cell.getValue<number>(), "plusMinus"),
            Footer: ({ table }) => {
              const sum = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) => acc + row.original.timebankBalance,
                  0,
                );
              return <Typography>{minutes2HHMM(sum, "plusMinus")}</Typography>;
            },
          },
          {
            header: "Ändring",
            id: "hoursDiff",
            accessorFn: (view: RowType) =>
              (view.scheduledHours - view.requiredHours) * 60,
            Cell: ({ cell }) => {
              const value = cell.getValue<number>();
              return (
                <ColoredText
                  value={minutes2HHMM(
                    value,
                    !isTolerated(value) ? "plusMinus" : "onlyMinus",
                  )}
                  diff={value}
                />
              );
            },
            Footer: ({ table }) => {
              const sum = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) =>
                    acc +
                    (row.original.scheduledHours - row.original.requiredHours) *
                      60,
                  0,
                );
              return (
                <Typography>
                  {minutes2HHMM(
                    sum,
                    !isTolerated(sum) ? "plusMinus" : "onlyMinus",
                  )}
                </Typography>
              );
            },
          },
          {
            header: "Nytt saldo",
            id: "timebankBalanceNew",
            accessorFn: (view: RowType) =>
              view.timebankBalance +
              (view.scheduledHours - view.requiredHours) * 60,
            Cell: ({ cell, row }) => {
              const value = cell.getValue<number>();
              const difference =
                row.getValue<number>("scheduledHours") -
                row.getValue<number>("requiredHours");
              if (isTolerated(difference)) {
                return (
                  <Typography>{minutes2HHMM(value, "plusMinus")}</Typography>
                );
              } else {
                return (
                  <ColoredText
                    value={minutes2HHMM(value, "plusMinus")}
                    diff={value}
                  />
                );
              }
            },
            Footer: ({ table }) => {
              const sum = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) =>
                    acc +
                    row.original.timebankBalance +
                    (row.original.scheduledHours - row.original.requiredHours) *
                      60,
                  0,
                );
              return (
                <Typography>
                  {minutes2HHMM(
                    sum,
                    !isTolerated(sum) ? "plusMinus" : "onlyMinus",
                  )}
                </Typography>
              );
            },
          },
        ],
      },
      {
        header: "Pass",
        id: "shifts",
        Header: () => <Typography fontStyle="italic">Pass</Typography>,
        columns: [
          {
            header: "Ordinarie passmått",
            id: "originalShifts",
            accessorFn: (view: RowType) =>
              view.requiredShifts + view.subtractedVacationShifts,
            Footer: ({ table }) => {
              const sum = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) =>
                    acc +
                    row.original.requiredShifts +
                    row.original.subtractedVacationShifts,
                  0,
                );
              return <Typography>{sum} pass</Typography>;
            },
          },
          {
            header: "Avdragen semester",
            accessorKey: "subtractedVacationShifts",
            accessorFn: (view: RowType) => view.subtractedVacationShifts,
            Footer: ({ table }) => {
              const sum = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) => acc + row.original.subtractedVacationShifts,
                  0,
                );
              return <Typography>{sum} pass</Typography>;
            },
          },
          {
            header: "Förväntat",
            accessorKey: "requiredShifts",
            accessorFn: (view: RowType) => view.requiredShifts,
            Footer: ({ table }) => {
              const sum = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) => acc + row.original.requiredShifts,
                  0,
                );
              return <Typography>{sum} pass</Typography>;
            },
          },
          {
            header: "Schemalagt",
            accessorKey: "scheduledShifts",
            accessorFn: (view: RowType) => view.scheduledShifts,
            Cell: ({ cell }) => {
              const value = cell.getValue<number>();
              const diff = value - cell.row.original.requiredShifts;
              return <ColoredText value={value.toString()} diff={diff} />;
            },
            Footer: ({ table }) => {
              const sum = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) => acc + row.original.scheduledShifts,
                  0,
                );
              return <Typography>{sum} pass</Typography>;
            },
          },
          {
            header: "Passlängd (snitt)",
            id: "averageShiftLength",
            accessorFn: (view: RowType) =>
              view.scheduledShifts > 0
                ? view.scheduledHours / view.scheduledShifts
                : 0,
            Cell: ({ cell }) => {
              const value = cell.getValue<number>();
              return <Typography>{minutes2HHMM(value * 60)}</Typography>;
            },
            Footer: ({ table }) => {
              const sumHours = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) => acc + row.original.scheduledHours,
                  0,
                );
              const sumShifts = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) => acc + row.original.scheduledShifts,
                  0,
                );
              const average = sumShifts > 0 ? sumHours / sumShifts : 0;
              return <Typography>{minutes2HHMM(average * 60)}</Typography>;
            },
          },
        ],
      },
      {
        header: "Dag/Kväll/Natt/Dygn-fördelning",
        id: "dayEveningNightFullday",
        columns: [
          {
            header: "Dag, önskad fördelning",
            id: "dayWishes",
            sortUndefined: -1,
            accessorFn: ({ userSetting, schedule }: RowType) => {
              if (
                (userSetting?.settingModules || []).includes(
                  "ShiftDayTypeDistribution",
                )
              ) {
                return userSetting?.dayShiftDistributionShare ?? 0;
              }
              return schedule.teamgroupsettingsnapshot
                ?.dayShiftDistributionShare;
            },
            Cell: ({ cell }) => {
              const value = cell.getValue<number>();
              const label = value !== undefined ? `${value * 100}%` : "-";
              return <Typography>{label}</Typography>;
            },
            Footer: ({ table }) => {
              const label = getDistributionColumnFooter(table, "dayWishes");
              return <Typography>{label}</Typography>;
            },
          },
          {
            header: "Kväll, önskad fördelning",
            id: "eveningWishes",
            sortUndefined: -1,
            accessorFn: ({ userSetting, schedule }: RowType) => {
              if (
                (userSetting?.settingModules || []).includes(
                  "ShiftDayTypeDistribution",
                )
              ) {
                return userSetting?.eveningShiftDistributionShare ?? 0;
              }
              return schedule.teamgroupsettingsnapshot
                ?.eveningShiftDistributionShare;
            },
            Cell: ({ cell }) => {
              const value = cell.getValue<number>();
              const label = value >= 0 ? `${value * 100}%` : "-";
              return <Typography>{label}</Typography>;
            },
            Footer: ({ table }) => {
              const label = getDistributionColumnFooter(table, "eveningWishes");
              return <Typography>{label}</Typography>;
            },
          },
          {
            header: "Natt, önskad fördelning",
            id: "nightWishes",
            sortUndefined: -1,
            accessorFn: ({ userSetting, schedule }: RowType) => {
              if (
                (userSetting?.settingModules || []).includes(
                  "ShiftDayTypeDistribution",
                )
              ) {
                return userSetting?.nightShiftDistributionShare ?? 0;
              }
              return schedule.teamgroupsettingsnapshot
                ?.nightShiftDistributionShare;
            },
            Cell: ({ cell }) => {
              const value = cell.getValue<number>();
              const label = value >= 0 ? `${value * 100}%` : "-";
              return <Typography>{label}</Typography>;
            },
            Footer: ({ table }) => {
              const label = getDistributionColumnFooter(table, "nightWishes");
              return <Typography>{label}</Typography>;
            },
          },
          {
            header: "Dygn, önskad fördelning",
            id: "fullDayWishes",
            sortUndefined: -1,
            accessorFn: ({ userSetting, schedule }: RowType) => {
              if (
                (userSetting?.settingModules || []).includes(
                  "ShiftDayTypeDistribution",
                )
              ) {
                return userSetting?.fullDayShiftDistributionShare ?? 0;
              }
              return schedule.teamgroupsettingsnapshot
                ?.fullDayShiftDistributionShare;
            },
            Cell: ({ cell }) => {
              const value = cell.getValue<number>();
              const label = value >= 0 ? `${value * 100}%` : "-";
              return <Typography>{label}</Typography>;
            },
            Footer: ({ table }) => {
              const label = getDistributionColumnFooter(table, "fullDayWishes");
              return <Typography>{label}</Typography>;
            },
          },
          {
            header: "Dag/Kväll/Natt/Dygn, önskad fördelning",
            id: "shiftDayTypeWishes",
            sortUndefined: -1,
            accessorFn: ({ userSetting, schedule }: RowType) => {
              if (
                (userSetting?.settingModules || []).includes(
                  "ShiftDayTypeDistribution",
                )
              ) {
                return [
                  userSetting?.dayShiftDistributionShare ?? 0,
                  userSetting?.eveningShiftDistributionShare ?? 0,
                  userSetting?.nightShiftDistributionShare ?? 0,
                  userSetting?.fullDayShiftDistributionShare ?? 0,
                ];
              }
              return [
                schedule.teamgroupsettingsnapshot?.dayShiftDistributionShare ??
                  0,
                schedule.teamgroupsettingsnapshot
                  ?.eveningShiftDistributionShare ?? 0,
                schedule.teamgroupsettingsnapshot
                  ?.nightShiftDistributionShare ?? 0,
                schedule.teamgroupsettingsnapshot
                  ?.fullDayShiftDistributionShare ?? 0,
              ];
            },
            Cell: ({ cell }) => {
              const [d, e, n, f] = cell.getValue<ShiftDayTypeArray>();
              const total = d + e + n + f;
              if (!total) {
                return <Typography>-</Typography>;
              }
              const label = [d, e, n, f]
                .map((v) => `${round((v / total) * 100)}%`)
                .join(" / ");
              return <Typography>{label}</Typography>;
            },
            Footer: ({ table }) => {
              const { day, evening, night, fullDay, total } = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) => {
                    const [d, e, n, f] =
                      row.getValue<ShiftDayTypeArray>("shiftDayTypeWishes");
                    const total = row.getValue<number>("scheduledShifts");
                    acc.day += d * total;
                    acc.evening += e * total;
                    acc.night += n * total;
                    acc.fullDay += f * total;
                    acc.total += total;
                    return acc;
                  },
                  { day: 0, evening: 0, night: 0, fullDay: 0, total: 0 },
                );
              const label = [day, evening, night, fullDay]
                .map((v) => `${round((v / total) * 100)}%`)
                .join(" / ");
              return <Typography>{label}</Typography>;
            },
          },
          {
            header: "Dag, faktisk fördelning",
            id: "dayActual",
            sortUndefined: -1,
            accessorFn: ({ activities, scheduledShifts }: RowType) =>
              activities.filter(
                (a) => a.shiftDayType === activityShiftDayTypes.DAY,
              ).length / scheduledShifts,
            Cell: ({ cell }) => (
              <Typography>{round(cell.getValue<number>() * 100)}%</Typography>
            ),
            Footer: ({ table }) => {
              const label = getDistributionColumnFooter(table, "dayActual");
              return <Typography>{label}</Typography>;
            },
          },
          {
            header: "Kväll, faktisk fördelning",
            id: "eveningActual",
            sortUndefined: -1,
            accessorFn: ({ activities, scheduledShifts }: RowType) =>
              activities.filter(
                (a) => a.shiftDayType === activityShiftDayTypes.EVENING,
              ).length / scheduledShifts,
            Cell: ({ cell }) => (
              <Typography>{round(cell.getValue<number>() * 100)}%</Typography>
            ),
            Footer: ({ table }) => {
              const label = getDistributionColumnFooter(table, "eveningActual");
              return <Typography>{label}</Typography>;
            },
          },
          {
            header: "Natt, faktisk fördelning",
            id: "nightActual",
            sortUndefined: -1,
            accessorFn: ({ activities, scheduledShifts }: RowType) =>
              activities.filter(
                (a) => a.shiftDayType === activityShiftDayTypes.NIGHT,
              ).length / scheduledShifts,
            Cell: ({ cell }) => (
              <Typography>{round(cell.getValue<number>() * 100)}%</Typography>
            ),
            Footer: ({ table }) => {
              const label = getDistributionColumnFooter(table, "nightActual");
              return <Typography>{label}</Typography>;
            },
          },
          {
            header: "Dygn, faktisk fördelning",
            id: "fullDayActual",
            sortUndefined: -1,
            accessorFn: ({ activities, scheduledShifts }: RowType) =>
              activities.filter(
                (a) => a.shiftDayType === activityShiftDayTypes.FULL_DAY,
              ).length / scheduledShifts,
            Cell: ({ cell }) => (
              <Typography>{round(cell.getValue<number>() * 100)}%</Typography>
            ),
            Footer: ({ table }) => {
              const label = getDistributionColumnFooter(table, "fullDayActual");
              return <Typography>{label}</Typography>;
            },
          },
          {
            header: "Dag/Kväll/Natt, faktiskt fördelning",
            id: "shiftDayTypeActual",
            sortUndefined: -1,
            accessorFn: ({ activities }: RowType): ShiftDayTypeArray => {
              const { day, evening, night, fullDay } = activities.reduce(
                (acc, a) => {
                  if (a.shiftDayType === activityShiftDayTypes.DAY) {
                    acc.day += 1;
                  } else if (a.shiftDayType === activityShiftDayTypes.EVENING) {
                    acc.evening += 1;
                  } else if (a.shiftDayType === activityShiftDayTypes.NIGHT) {
                    acc.night += 1;
                  } else if (
                    a.shiftDayType === activityShiftDayTypes.FULL_DAY
                  ) {
                    acc.fullDay += 1;
                  }
                  return acc;
                },
                { day: 0, evening: 0, night: 0, fullDay: 0 },
              );
              return [day, evening, night, fullDay];
            },
            Cell: ({ cell }) => {
              const [d, e, n, f] = cell.getValue<ShiftDayTypeArray>();
              const total = d + e + n + f;
              const label = [d, e, n, f]
                .map((v) => `${round((v / total) * 100)}%`)
                .join(" / ");

              return <Typography>{label}</Typography>;
            },
            Footer: ({ table }) => {
              const { day, evening, night, fullDay } = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) => {
                    const [d, e, n, f] =
                      row.getValue<ShiftDayTypeArray>("shiftDayTypeActual");
                    acc.day += d;
                    acc.evening += e;
                    acc.night += n;
                    acc.fullDay += f;
                    return acc;
                  },
                  {
                    day: 0,
                    evening: 0,
                    night: 0,
                    fullDay: 0,
                  },
                );
              const total = day + evening + night + fullDay;
              const label = [day, evening, night, fullDay]
                .map((v) => `${round((v / total) * 100)}%`)
                .join(" / ");

              return <Typography>{label}</Typography>;
            },
          },
        ],
      },
      {
        header: "Helgpass",
        id: "weekendWorkday",
        columns: [
          {
            header: "Helgpass, förväntade",
            accessorKey: "expectedWeekendWorkdayCount",
            Footer: ({ table }) => {
              const sum = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) => acc + row.original.expectedWeekendWorkdayCount,
                  0,
                );
              return <Typography>{sum} helgpass</Typography>;
            },
          },
          {
            header: "Helgpass, faktiskt",
            accessorKey: "actualWeekendWorkdayCount",
            Footer: ({ table }) => {
              const sum = table
                .getFilteredRowModel()
                .flatRows.reduce(
                  (acc, row) => acc + row.original.actualWeekendWorkdayCount,
                  0,
                );
              return <Typography>{sum} helgpass</Typography>;
            },
          },
        ],
      },
    ],
    [],
  );
  const columnKeys = useCallback(
    (cols: ColumnType[]): string[] =>
      // Columns list to a list of its leaf keys (id or accessorKey)
      cols.reduce(
        (acc, c) => [
          ...acc,
          ...(c.columns
            ? columnKeys(c.columns)
            : [c.id || (c.accessorKey as string)]),
        ],
        [] as string[],
      ),
    [],
  );

  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    () => {
      const config = getTableConfig(TABLE_CONFIG_KEY);
      if (config?.columnVisibility) return config.columnVisibility;
      return columnKeys(columns).reduce(
        (acc, c) => ({ ...acc, [c]: true }),
        {},
      );
    },
  );

  const columnsForPreset = useMemo<PresetColumn[]>(
    () =>
      columns.reduce((acc, x) => {
        const format = (x: ColumnType): PresetColumn => ({
          key: x.id || (x.accessorKey as string),
          label: x.header,
          columns: x?.columns ? x.columns.map(format) : undefined,
        });
        return [...acc, format(x)];
      }, [] as PresetColumn[]),
    [columns],
  );

  const state = useMemo(
    () => ({
      columnVisibility: columnKeys(columns).reduce(
        (acc, c) => ({
          ...acc,
          [c]: columnVisibility[c] ?? false,
        }),
        {},
      ),
    }),
    [columnVisibility, columns, columnKeys],
  );

  function renderTopToolbarCustomActions() {
    function onSetColumns(cols: string[]) {
      const newColVis = cols.reduce((acc, x) => ({ ...acc, [x]: true }), {});
      setColumnVisibility(newColVis);
    }

    return (
      <PresetSelect
        tableKey={TABLE_CONFIG_KEY}
        predefinedPresets={PREDEFINED_PRESETS}
        reservedPresetKeys={RESERVED_PRESET_KEYS}
        columns={columnsForPreset}
        selectedColumns={Object.keys(columnVisibility)}
        setSelectedColumns={onSetColumns}
        sx={{ width: 200 }}
      />
    );
  }

  return (
    <MRTable
      tableConfigKey={TABLE_CONFIG_KEY}
      columns={columns as any[]}
      data={filteredData as any[]}
      onColumnVisibilityChange={setColumnVisibility}
      renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      state={state}
      initialState={{
        sorting: [
          { id: "teams", desc: true },
          { id: "name", desc: false },
        ],
      }}
      muiTableHeadCellProps={{
        sx: {
          "& .Mui-TableHeadCell-Content": {
            justifyContent: "flex-start",
          },
        },
      }}
    />
  );
}
