import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { Free1Form } from "./forms";
import { UpdateRuleGroupSettingBoxFree1 } from "./mutations";
import type {
  RuleGroupSettingBoxFree1_fragment$key as Key,
  UpdateRuleGroupSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment RuleGroupSettingBoxFree1_fragment on RuleGroupSettingNode {
    id
    workEveryXWeekend
    freeEveryXOfYWeekends
    freeWeekendConstraintTypeEveryX
    freeWeekendConstraintTypeXOfY
    freeWeekendSoft
    workEveryUnfreeWeekend
    workEveryUnfreeWeekendSoft
    settingModules
    ruleGroup {
      name
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  workEveryXWeekend: yup
    .number()
    .min(1, "Måste vara minst 1")
    .max(100, "Måste vara högst 100")
    .required("Får ej vara tomt"),
  freeEveryXOfYWeekends: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(100, "Måste vara högst 100")
    .required("Får ej vara tomt"),
  freeWeekendConstraintTypeEveryX: yup.boolean().required(),
  freeWeekendConstraintTypeXOfY: yup.boolean().required(),
  freeWeekendSoft: yup.boolean().required(),
  workEveryUnfreeWeekend: yup.boolean().required(),
  workEveryUnfreeWeekendSoft: yup.boolean().required(),
  constraintModules: yup.array().of(yup.string()).required(),
});

type Props = {
  fragmentRef: Key;
  periodLengthWeeks: number;
};

const Content = ({ periodLengthWeeks, fragmentRef }: Props) => {
  const environment = useRelayEnvironment();
  const data = useFragment(fragment, fragmentRef);
  const { name = "" } = data?.ruleGroup || {};
  const initialValues = {
    id: data?.id || "",
    workEveryXWeekend: data?.workEveryXWeekend || 0,
    freeEveryXOfYWeekends: data?.freeEveryXOfYWeekends || 0,
    freeWeekendConstraintTypeEveryX:
      data?.freeWeekendConstraintTypeEveryX !== false, // default true
    freeWeekendConstraintTypeXOfY:
      data?.freeWeekendConstraintTypeXOfY !== false, // default true
    freeWeekendSoft: data?.freeWeekendSoft !== false,
    workEveryUnfreeWeekend: data?.workEveryUnfreeWeekend !== false,
    workEveryUnfreeWeekendSoft: data?.workEveryUnfreeWeekendSoft !== false,
    constraintModules: data?.settingModules.slice().filter(Boolean) || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateRuleGroupSettingBoxFree1(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Free1Form name={name} periodLengthWeeks={periodLengthWeeks} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
};

export function RuleGroupSettingBoxFree1({
  fragmentRef,
  periodLengthWeeks,
}: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && (
        <Content
          fragmentRef={fragmentRef}
          periodLengthWeeks={periodLengthWeeks}
        />
      )}
    </Suspense>
  );
}
