import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import {
  CheckCircle as CheckCirleIcon,
  DoDisturb as DoDisturbIcon,
} from "@mui/icons-material";
import { List, ListItemText, Stack, Tooltip, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { DateTime } from "luxon";
import type { MRT_ColumnDef } from "material-react-table";
import type { ExtractNode } from "relay-help/arrays";
import { connectionToArray } from "relay-help/arrays";
import type { NN } from "types";

import DeletePopover from "components/common/DeletePopover";
import Table, { sortListBy } from "components/common/MRTable";
import { TeamChip } from "components/common/TeamChip";

import {
  ActivateButton,
  ArchiveButton,
  DeleteButton,
  EditButton,
} from "../common/AdminTableIconButtons";
import type { TeamGroup, TeamGroupsTable_fragment$key as Key } from "../types";

const TABLE_CONFIG_KEY = "admin-team-groups-table-config";

const fragment = graphql`
  fragment TeamGroupsTable_fragment on TeamGroupNode @relay(plural: true) {
    id
    name
    isActive
    archivedAt
    deleteable
    teams {
      edges {
        node {
          id
          name
          color
        }
      }
    }
  }
`;

type ColumnType = NN<TeamGroup>;

type Props = {
  fragmentRef: Key;
  onEdit: (id: string) => void;
  onArchive: (id: string) => void;
  onActivate: (id: string) => void;
  onDelete: (teamGroup: TeamGroup) => void;
};

type TeamGroupsTableRowProps = {
  item: TeamGroup;
  onEdit: () => void;
  onArchive: () => void;
  onActivate: () => void;
  onDelete: () => void;
};

type Team = ExtractNode<NN<TeamGroup>["teams"]>;

type TeamsTooltipProps = {
  teams: Team[];
};

function TeamsTooltip({ teams = [] }: TeamsTooltipProps) {
  return (
    <List sx={{ p: 0, m: 0 }}>
      {teams.map((team) => (
        <ListItemText
          key={team.id}
          primaryTypographyProps={{ variant: "body2" }}
        >
          {`\u2022`}&nbsp;&nbsp;{team.name}
        </ListItemText>
      ))}
    </List>
  );
}

function Actions({
  item,
  onEdit,
  onArchive,
  onActivate,
  onDelete,
}: TeamGroupsTableRowProps) {
  return (
    <Stack direction="row" justifyContent="flex-end">
      <EditButton onClick={() => onEdit()} />
      {item?.isActive ? (
        <DeletePopover
          deleteMessage={`Är du säker på att du vill inaktivera avdelning ${item?.name}?`}
          buttonText="Inaktivera"
          onDeleteClick={() => onArchive()}
        >
          <ArchiveButton />
        </DeletePopover>
      ) : (
        <ActivateButton onClick={() => onActivate()} />
      )}
      <DeleteButton onClick={() => onDelete()} />
    </Stack>
  );
}

export default function TeamGroupsTable({
  fragmentRef,
  onEdit,
  onArchive,
  onActivate,
  onDelete,
}: Props) {
  const items = useFragment<Key>(fragment, fragmentRef);
  const columns = useMemo<MRT_ColumnDef<ColumnType>[]>(
    () => [
      { accessorKey: "id", header: "ID" },
      {
        accessorKey: "isActive",
        header: "Aktiv",
        Cell: ({ cell }) =>
          cell.getValue<boolean>() ? (
            <CheckCirleIcon color="primary" sx={{ p: "2px" }} />
          ) : (
            <DoDisturbIcon color="disabled" sx={{ p: "2px" }} />
          ),
        aggregationFn: "count",
        AggregatedCell: ({ cell }) => `${cell.getValue()} aktiva`,
        Footer: ({ table }) => {
          const activeCount =
            table
              .getFilteredRowModel()
              .flatRows.filter((row) => row.original.isActive).length || 0;
          return <Typography>{activeCount} aktiva</Typography>;
        },
      },
      {
        accessorKey: "archivedAt",
        header: "Tid för arkivering",
        accessorFn: (c) =>
          c?.archivedAt ? DateTime.fromISO(c.archivedAt).toFormat("F") : null,
        Cell: ({ cell }) => cell.getValue<string>() || "-",
      },
      {
        accessorKey: "name",
        header: "Namn",
        aggregationFn: "count",
        AggregatedCell: ({ cell }) => `${cell.getValue()} enheter`,
        Footer: ({ table }) => {
          const teamGroupCount =
            table
              .getFilteredRowModel()
              .flatRows.filter((row) => row.original.isActive).length || 0;
          return <Typography>{teamGroupCount} enheter</Typography>;
        },
      },
      {
        accessorKey: "deleteable",
        header: "Kan raderas",
        Cell: ({ cell }) =>
          cell.getValue<boolean>() ? (
            <CheckCirleIcon color="primary" sx={{ p: "2px" }} />
          ) : (
            <DoDisturbIcon color="disabled" sx={{ p: "2px" }} />
          ),
      },
      {
        header: "Avdelningar",
        id: "teams",
        accessorFn: (item) => connectionToArray(item?.teams),
        filterFn: (row, _id, filterValue) =>
          connectionToArray(row.original.teams).some((x) =>
            x.name?.toLowerCase().includes(filterValue.toLowerCase()),
          ),
        sortingFn: (a, b) =>
          sortListBy(
            connectionToArray(a.original?.teams),
            connectionToArray(b.original?.teams),
            (x) => x.name,
          ),
        Cell: ({ cell }) => {
          const teams = cell.getValue<Team[]>();
          return (
            <Tooltip
              title={<TeamsTooltip teams={teams} />}
              placement="right"
              followCursor
              arrow
            >
              <Stack gap={0.5} direction="row">
                {teams?.map((item) => (
                  <TeamChip team={item} key={item.id} size="small" />
                ))}
              </Stack>
            </Tooltip>
          );
        },
      },
      {
        header: "Åtgärder",
        id: "actions",
        Cell: ({ row }) => {
          const item = row.original;
          return (
            <Actions
              item={item}
              onEdit={() => item && onEdit(item.id)}
              onArchive={() => item?.id && onArchive(item.id)}
              onActivate={() => item?.id && onActivate(item.id)}
              onDelete={() => item && onDelete(item)}
            />
          );
        },
      },
    ],
    [onEdit, onDelete, onActivate, onArchive],
  );

  return (
    <Table
      tableConfigKey={TABLE_CONFIG_KEY}
      columns={columns as any[]}
      data={items as any[]}
    />
  );
}
