import { InputAdornment, Stack, Typography } from "@mui/material";

import CustomField from "components/forms/CustomField";
import { SmallSwitch } from "components/forms/Switch";

import { DayBreakAlternativeSelect } from "../components/DayBreakAlternativeSelect";
import { WeekBreakDaySelect } from "../components/WeekBreakDaySelect";

type Props = {
  disabled?: any;
  readOnly?: boolean;
};

export function AB2A({ disabled, readOnly }: Props) {
  return (
    <>
      <Stack gap={1}>
        <Typography variant="h4">Veckovilans längd (timmar)</Typography>
        <Typography>
          Detta fält styr hur lång veckovilan minst ska vara, uttryckt i antal
          timmar.
        </Typography>
        <CustomField
          fullWidth
          size="small"
          type="number"
          InputProps={{
            readOnly,
            endAdornment: (
              <InputAdornment position="end">timmar</InputAdornment>
            ),
          }}
          name="minWeeklyRestAbHours"
          disabled={disabled}
        />
      </Stack>

      <Stack gap={1}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="h4">
            Veckovila får överlappa med dygnsvila
          </Typography>
          <SmallSwitch
            name="weeklyDailyCanOverlap"
            disabled={disabled}
            readOnly={readOnly}
            showLabels={true}
            labelOn="Ja"
            labelOff="Nej"
          />
        </Stack>
        <Typography>
          Om detta alternativ är påslaget så tillåts veckovilan att överlappa
          med dygnsvilan. Om alternativet är avstängt, så innebär det att
          veckovilan och dygnsvilan aldrig får överlappa.
        </Typography>
      </Stack>
    </>
  );
}

export function AB2B({ disabled, readOnly }: Props) {
  return (
    <Stack gap={1}>
      <Typography variant="h4">Veckobryt</Typography>
      <Typography>Dessa två fält styr när veckobrytet ska infalla.</Typography>
      <Stack direction="row" gap={4}>
        <WeekBreakDaySelect
          name="weekBreakDaysAlternatives"
          disabled={disabled}
          readOnly={readOnly}
        />
        <DayBreakAlternativeSelect
          name="weekBreakHourAlternatives"
          disabled={disabled}
          readOnly={readOnly}
        />
      </Stack>
    </Stack>
  );
}

export function AB2({ disabled, readOnly }: Props) {
  return (
    <Stack gap={5}>
      <AB2A disabled={disabled} readOnly={readOnly} />
      <AB2B disabled={disabled} readOnly={readOnly} />
    </Stack>
  );
}
