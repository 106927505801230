import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { connectionToArray } from "relay-help/arrays";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { weightChoices } from "./constants";
import { Base8Form } from "./forms";
import { UpdateSettingBoxBase8 } from "./mutations";
import type {
  SettingBoxBase8_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment SettingBoxBase8_fragment on SettingNode {
    id
    group {
      competenceDemandSettings {
        edges {
          node {
            id
            allowUnderstaffing
            understaffingWeight
            competence {
              name
              color
            }
          }
        }
      }
    }
  }
`;

const weightSchema = yup.string().oneOf(weightChoices, "Ogiltig vikt");

const competenceDemandSettingSchema = yup.object().shape({
  id: yup.string().required("Måste anges"),
  allowUnderstaffing: yup.boolean().required("Måste anges"),
  understaffingWeight: weightSchema.required("Måste anges"),
});

const validationSchema = yup.object().shape({
  id: yup.string().required("Måste anges"),
  competenceDemandSettings: yup
    .array()
    .of(competenceDemandSettingSchema)
    .required("Måste anges"),
});

type Props = {
  fragmentRef: Key;
};

function Content({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const teamGroup = useCurrentTeamGroup();
  const data = useFragment(fragment, fragmentRef);
  const initialValues: FormValues = {
    id: data?.id || "",
    competenceDemandSettings: connectionToArray(
      data.group?.competenceDemandSettings,
    ),
  };

  async function onSubmit(values: FormValues) {
    const input = {
      ...values,
      competenceDemandSettings: (values.competenceDemandSettings || []).map(
        (s) => ({
          id: s?.id || "",
          allowUnderstaffing: s?.allowUnderstaffing || false,
          understaffingWeight: s?.understaffingWeight || "M",
        }),
      ),
    };
    await UpdateSettingBoxBase8(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <Base8Form groupName={teamGroup?.name || ""} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function SettingBoxBase8({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
