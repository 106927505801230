import CheckIcon from "@mui/icons-material/Check";
import type { CSSObject } from "@mui/material";
import { Chip as MuiChip } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import type { ChipsProps as Props } from "./types";

const chipIconOnRight: CSSObject = {
  px: 0.4,
  flexDirection: "row-reverse",
  "& .MuiChip-icon": {
    mx: 0,
    pl: 0.25,
    fontSize: 18.5,
  },
  "& .MuiChip-label": {
    mx: 0,
    pl: 0.35,
    pr: 0,
  },
};

const noop = () => {};

type FormikProps = Omit<Props, "checked" | "onToggle"> & { name: string };

export function Chips({
  checked,
  options,
  onToggle,
  disabled,
  readOnly,
  sx = {},
}: Props) {
  const isChecked = (idx: number) => checked.includes(idx);

  function handleChange(e: any, idx: number) {
    e.stopPropagation();
    e.preventDefault();
    onToggle(idx);
  }
  const chipSx: CSSObject = {
    width: 74,
    lineHeight: "normal",
    ...chipIconOnRight,
    ...sx,
  };

  return (
    <>
      {options.map((label, i) => {
        const isOn = isChecked(i);
        return (
          <MuiChip
            key={i}
            label={label}
            id={label + "-" + i}
            variant={isOn ? "filled" : "outlined"}
            icon={isOn ? <CheckIcon /> : <></>}
            onClick={(e: any) => (readOnly ? noop : handleChange(e, i))}
            disabled={disabled}
            sx={chipSx}
          />
        );
      })}
    </>
  );
}

export function FormikChips({ name, ...props }: FormikProps) {
  const { value, setValue } = useFormikState<number[]>(name);

  function onToggle(idx: number) {
    if (value.includes(idx)) {
      setValue(value.filter((x) => x !== idx));
    } else {
      setValue([...value, idx]);
    }
  }

  return <Chips checked={value} onToggle={onToggle} {...props} />;
}
