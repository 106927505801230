import { useState } from "react";
import { useRelayEnvironment } from "react-relay/hooks";
import { Dialog, Typography as Text } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { commitMutation } from "libs/commitMutation";
import { DateTime } from "luxon";

import { useSnackbar } from "components/Snackbar";

import BlockedTimeSlotForm, {
  validationSchema,
} from "../common/BlockedTimeSlotForm";
import { prepareBeforeForm, prepareBeforeSubmit } from "../common/Recurrence";
import type { VerifyCbProps } from "../common/VerifyDialog";
import VerifyDialog from "../common/VerifyDialog";
import type { BlockedTimeSlotTimelineItem } from "../graphs";

import type {
  BlockedTimeSlotFormValues as FormValues,
  BlockedTimeSlotType,
} from "./types";
import { CreateBlockedTimeSlotMutation } from "./types";

type Props = {
  open: boolean;
  onClose: () => void;
  copyFrom?: any;
  onOpenOriginalTimeSlot: () => void;
};

type VerifyData = {
  values: FormValues;
};

const mutation = graphql`
  mutation CreateBlockedTimeSlotMutation($input: CreateBlockedTimeSlotInput!) {
    createBlockedTimeSlot(input: $input) {
      ok
      blockedTimeSlot {
        id
      }
    }
  }
`;

const dtFormat = "yyyy-MM-dd'T'T";

function formatCopyFrom(copyFrom: BlockedTimeSlotTimelineItem): FormValues {
  return {
    ...copyFrom,
    userIds: (copyFrom?.users || []).map((edge) => edge.id ?? null),
    start: DateTime.fromMillis(copyFrom.start_time).toFormat(dtFormat),
    end: DateTime.fromMillis(copyFrom.end_time).toFormat(dtFormat),
    recurrences: copyFrom?.recurrences
      ? prepareBeforeForm(copyFrom.recurrences)
      : null,
  };
}

const defaultInitialValues: FormValues = {
  start: DateTime.now().startOf("day").toFormat(dtFormat),
  end: DateTime.now().endOf("day").toFormat(dtFormat),
  approvedByAdmin: true,
  userIds: [],
  timeslotType: "V" as BlockedTimeSlotType,
  shiftId: null,
  shiftDate: null,
  recurrences: null,
};

export function CreateBlockedTimeSlot(props: Props) {
  /** CreateBlockedTimeSlot can be a new instance or a copy of an existing one.
   *
   *   If submitting a copy of a recurrence, we ask the user if they want to overwrite the
   *   selected instance or all future instances.
   *   If submitting a new instance, we just submit the form.
   *   */
  const [verifyData, setVerifyData] = useState<VerifyData | null>(null);
  const environment = useRelayEnvironment();
  const { addSnack } = useSnackbar();

  const teamGroup = useCurrentTeamGroup();

  const initialValues = props.copyFrom
    ? formatCopyFrom(props.copyFrom)
    : defaultInitialValues;

  const isEditingRecurrenceItem = !!props.copyFrom;
  const verifyDialogOpen = !!verifyData;

  async function onSubmit(values: FormValues & VerifyCbProps) {
    try {
      const { start, end, shiftId, shiftDate, recurrences } =
        prepareBeforeSubmit({ ...values });
      const input = {
        ...values,
        start,
        end,
        shiftId,
        shiftDate,
        recurrences,
      };

      await commitMutation<CreateBlockedTimeSlotMutation>(environment, {
        mutation,
        variables: { input },
      })
        .then(() => {
          props.onClose();
          addSnack({ message: "Händelse skapad", severity: "success" });
        })
        .catch((error) => {
          console.error(error);
          addSnack({
            message: "Något gick fel. Kunde inte skapa händelse.",
            severity: "error",
          });
        })
        .finally(() => {
          setVerifyData(null);
        });
    } catch (error) {
      console.error(error);
      addSnack({
        message: "Något gick fel. Kunde inte skapa händelse.",
        severity: "error",
      });
    }
  }

  function openVerifyDialog(data: VerifyData) {
    setVerifyData(data);
  }
  async function confirmVerifyCb({
    editOne,
    editFutureRecurrences,
  }: VerifyCbProps) {
    if (verifyData) {
      await onSubmit({ ...verifyData.values, editOne, editFutureRecurrences });
    }
  }
  function cancelVerifyCb() {
    setVerifyData(null);
  }

  function Title() {
    if (isEditingRecurrenceItem) {
      return (
        <Text variant="h2">
          Redigera upprepad händelse{" "}
          <Text
            variant="h2"
            fontWeight={700}
            fontStyle={"bold"}
            component="span"
            onClick={props.onOpenOriginalTimeSlot}
            sx={{ cursor: "pointer", textDecoration: "underline" }}
          >
            (Öppna ursprunglig händelse)
          </Text>
        </Text>
      );
    }
    return (
      <Text variant="h2">
        Skapa ny händelse för enhet <b>{teamGroup?.name}</b>
      </Text>
    );
  }

  const { open, onClose } = props;
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            if (isEditingRecurrenceItem) {
              openVerifyDialog({ values });
            } else {
              await onSubmit(values);
            }
          }}
        >
          <BlockedTimeSlotForm
            title={<Title />}
            submitText="Skapa händelse"
            modalProps={props}
            deletable={isEditingRecurrenceItem}
          />
        </Formik>
      </Dialog>
      <VerifyDialog
        open={verifyDialogOpen}
        onClose={cancelVerifyCb}
        onConfirm={confirmVerifyCb}
      />
    </>
  );
}
