import { createContext, useContext } from "react";

const ShiftFormContext = createContext({
  dayTypeBreakpoints: {
    morningStartsNightEnds: "",
    eveningStartsMorningEnds: "",
    nightStartsEveningEnds: "",
    fullDayDuration: 1e6,
  },
});

export const ShiftFormProvider = ShiftFormContext.Provider;

export const useShiftForm = () => useContext(ShiftFormContext);
