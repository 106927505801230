import { Suspense, useEffect, useMemo } from "react";
import type { PreloadedQuery } from "react-relay";
import { usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { ALLOWED_RULE_GROUP_EXCEPTIONS } from "settings/constants";
import { hoursAndShiftsSetting } from "settings/HoursAndShifts/HoursAndShiftsSetting";
import { EMPTY_ARRAY } from "utils/constants";
import { optimizationRuleUrl } from "utils/urls";
import { useBooleanState } from "utils/useBooleanState";

import { SettingsPlaceholder as Placeholder } from "components/loading/pages";
import { Level1Menu, Level2Renderer, SettingsPanel } from "components/setting";
import type { SelectedType } from "components/setting/types";

import { RuleGroupSettings } from "./RuleGroupSettings";
import { RuleGroupSettingsExceptions } from "./RuleGroupSettingsExceptions";
import { TeamGroupSettings } from "./TeamGroupSettings";
import { SettingsQuery as Query } from "./types";
import { UserSettings } from "./UserSettings";
import { UserSettingsExceptions } from "./UserSettingsExceptions";

// Temp. feature flag
// TODO Remove this and useNewPanel state when pivoting.
const USE_NEW_PANEL = true;

export const query = graphql`
  query SettingsQuery($teamGroupId: ID!) {
    ruleGroups(teamGroupId: $teamGroupId) {
      id
      name
      ruleGroupSetting {
        settingModules
      }
    }
    teamGroup(id: $teamGroupId) {
      setting {
        constraintModules
        showAdvancedSettings
      }
      teams {
        edges {
          node {
            id
            name
          }
        }
      }
      members {
        edges {
          node {
            id
            fullName
          }
        }
      }
    }
    usersOnTeamGroup(teamGroupId: $teamGroupId) {
      fullName
      id
      userSetting {
        settingModules
        id
      }
    }
  }
`;

type TParams = {
  levelId?: string;
  ruleId?: string;
};

type ContentProps = {
  levelId: string;
  ruleId: string;
  queryRef: PreloadedQuery<Query>;
  teamGroup: ReturnType<typeof useCurrentTeamGroup>;
};

export enum SettingPanelType {
  SETTINGS,
  RULE_GROUP_EXCEPTIONS,
  USER_EXCEPTIONS,
}

function Content({ levelId, ruleId, queryRef, teamGroup }: ContentProps) {
  const data = usePreloadedQuery<Query>(query, queryRef);
  const navigate = useNavigate();
  // TODO Remove when pivoting
  const { value: useNewPanel, setFalse: enableOldPanel } =
    useBooleanState(USE_NEW_PANEL);

  useEffect(() => {
    (window as any).enableOldPanel = enableOldPanel;
  }, [enableOldPanel]);

  // Person options
  const personOptions = useMemo(
    () =>
      data.usersOnTeamGroup.map((user) => ({
        value: user.id,
        label: user.fullName,
        ruleExceptions: user?.userSetting?.settingModules.length,
      })) ?? EMPTY_ARRAY,
    [data.usersOnTeamGroup],
  );

  const ruleGroupOptions = useMemo(
    () =>
      data.ruleGroups.map((ruleGroup) => ({
        value: ruleGroup.id,
        label: ruleGroup.name,
        ruleExceptions: ALLOWED_RULE_GROUP_EXCEPTIONS.filter((setting) =>
          ruleGroup.ruleGroupSetting?.settingModules.includes(
            setting.moduleName,
          ),
        ).length,
      })) ?? EMPTY_ARRAY,
    [data.ruleGroups],
  );

  function getSelectedType(): SelectedType | null {
    if (teamGroup.id === levelId) return "group";
    if (personOptions.some((x) => x?.value === levelId)) return "person";
    if (ruleGroupOptions.some((x) => x?.value === levelId)) return "ruleGroup";
    return null;
  }

  function onSelect(level: string) {
    navigate(optimizationRuleUrl(level, ruleId));
  }

  const groupModules = useMemo(
    () => data?.teamGroup?.setting?.constraintModules?.slice?.() ?? [],
    [data?.teamGroup?.setting?.constraintModules],
  );

  const settingPanelType = useMemo(() => {
    try {
      const decoded = atob(ruleId);
      if (decoded.startsWith("UserNode:")) {
        return SettingPanelType.USER_EXCEPTIONS;
      } else if (decoded.startsWith("RuleGroupNode:")) {
        return SettingPanelType.RULE_GROUP_EXCEPTIONS;
      }
    } catch {
      // Could not decode id. This means we have a regular setting
    }
    return SettingPanelType.SETTINGS;
  }, [ruleId]);

  const sx = {
    height: "auto",
    minHeight: "100%",
  };
  return (
    <Stack direction="row" minHeight="100%">
      {useNewPanel ? (
        <>
          <SettingsPanel
            settingPanelType={settingPanelType}
            ruleId={ruleId}
            users={personOptions}
            ruleGroups={ruleGroupOptions}
            setRuleId={(newRule: string) =>
              navigate(optimizationRuleUrl(teamGroup.id, newRule))
            }
            modules={groupModules}
            showAdvanced={data.teamGroup?.setting?.showAdvancedSettings}
          />
          {settingPanelType === SettingPanelType.SETTINGS && (
            <TeamGroupSettings
              ruleId={ruleId}
              sx={sx}
              USE_NEW_PANEL={USE_NEW_PANEL}
            />
          )}
          {settingPanelType === SettingPanelType.USER_EXCEPTIONS && (
            <UserSettingsExceptions
              userId={ruleId}
              teamGroupId={teamGroup.id}
            />
          )}
          {settingPanelType === SettingPanelType.RULE_GROUP_EXCEPTIONS && (
            <RuleGroupSettingsExceptions
              ruleGroupId={ruleId}
              teamGroupId={teamGroup.id}
            />
          )}
        </>
      ) : (
        // TODO Remove when pivoting
        <>
          <Level1Menu
            teamGroupId={teamGroup.id}
            teamGroupName={teamGroup?.name || ""}
            levelId={levelId}
            ruleGroups={ruleGroupOptions}
            users={personOptions}
            onSelect={onSelect}
            sx={sx}
          />
          <Level2Renderer
            selectedType={getSelectedType()}
            userSettings={
              <UserSettings
                levelId={levelId}
                ruleId={ruleId}
                showAdvanced={data?.teamGroup?.setting?.showAdvancedSettings}
                sx={sx}
              />
            }
            ruleGroupSettings={
              <RuleGroupSettings
                levelId={levelId}
                ruleId={ruleId}
                showAdvanced={data?.teamGroup?.setting?.showAdvancedSettings}
                sx={sx}
              />
            }
            teamGroupSettings={
              <TeamGroupSettings
                ruleId={ruleId}
                showAdvanced={data?.teamGroup?.setting?.showAdvancedSettings}
                sx={sx}
              />
            }
          />
        </>
      )}
    </Stack>
  );
}

export function Settings() {
  const teamGroup = useCurrentTeamGroup();
  const teamGroupId = teamGroup.id;
  const { levelId, ruleId } = useParams<TParams>();
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const navigate = useNavigate();

  useEffect(() => {
    if (!teamGroupId) return;

    if (!levelId && !ruleId) {
      navigate(optimizationRuleUrl(teamGroupId, hoursAndShiftsSetting));
    }
  }, [navigate, teamGroupId, levelId, ruleId]);

  useEffect(() => {
    if (teamGroupId) {
      loadQuery({ teamGroupId });
    }
  }, [loadQuery, teamGroupId]);

  return (
    <Suspense fallback={<Placeholder />}>
      {!!queryRef && (
        <Content
          levelId={levelId || ""}
          ruleId={ruleId || ""}
          queryRef={queryRef}
          teamGroup={teamGroup}
        />
      )}
    </Suspense>
  );
}
