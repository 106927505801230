import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { AB2Form } from "./forms";
import { UpdateRuleGroupSettingBoxAB2 } from "./mutations";
import type {
  RuleGroupSettingBoxAB2_fragment$key as Key,
  UpdateRuleGroupSettingInput as FormValues,
} from "./types";

const fragment = graphql`
  fragment RuleGroupSettingBoxAB2_fragment on RuleGroupSettingNode {
    id
    weekBreakHourAlternatives
    weekBreakDaysAlternatives
    settingModules
    ruleGroup {
      name
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  weekBreakHourAlternatives: yup
    .array()
    .of(yup.number().min(0).required())
    .required(),
  weekBreakDaysAlternatives: yup
    .array()
    .of(yup.number().min(0).max(7).required())
    .required(),
  constraintModules: yup.array().of(yup.string()).required(),
});

type Props = {
  fragmentRef: Key;
};

function Content({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const data = useFragment(fragment, fragmentRef);
  const { name = "" } = data?.ruleGroup || {};
  const initialValues: FormValues = {
    id: data?.id || "",
    weekBreakHourAlternatives: data?.weekBreakHourAlternatives.slice() || [15],
    weekBreakDaysAlternatives: data?.weekBreakDaysAlternatives.slice() || [0],
    constraintModules: data?.settingModules.slice().filter(Boolean) || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateRuleGroupSettingBoxAB2(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <AB2Form name={name} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function RuleGroupSettingBoxAB2({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
