import { useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay/hooks";
import { Box, Stack } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import graphql from "babel-plugin-relay/macro";
import type { ExtractNode, Unwrap } from "relay-help/arrays";
import { connectionToArray } from "relay-help/arrays";
import { EMPTY_ARRAY } from "utils/constants";

import GraphTooltip from "../common/GraphTooltip";
import { XOption, XSelect } from "../common/XSelect";

import {
  ShiftVsResourceChart_fragment$data as Data,
  ShiftVsResourceChart_fragment$key as Key,
} from "./__generated__/ShiftVsResourceChart_fragment.graphql";
import { chartColor } from "./constants";
import { calcPercent } from "./utils";

const fragment = graphql`
  fragment ShiftVsResourceChart_fragment on ScheduleStats {
    userStats {
      id
      name
      nrShifts
      nrResources
      nrShiftAdmin
      nrResourceAdmin
      hourShifts
      hourResources
      hourShiftAdmin
      hourResourceAdmin
      competences {
        edges {
          node {
            id
          }
        }
      }
      teams {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

type UserType = Unwrap<Data["userStats"]>;
type Competence = ExtractNode<UserType["competences"]>;
type Team = ExtractNode<UserType["teams"]>;
type RowType = Omit<UserType, "teams" | "competences"> & {
  teams: ReadonlyArray<Team>;
  competences: ReadonlyArray<Competence>;
};

type ChartData = {
  userKey: string;
  userName: string;
  userId: string;
  shift: number;
  resource: number;
  shiftAdmin: number;
  resourceAdmin: number;
};

type Props = {
  fragmentRef: Key;
  teamId: string | null;
  competence: string | null;
  filterCompetence: boolean;
};

type TotalValuesType = Record<string, number>;

type XAxisValue = "shifts" | "hours" | "percent" | "percentHours";
type OptionType = XOption<XAxisValue> & { unit: string };
const DEFAULT_OPTION: OptionType = {
  value: "shifts",
  label: "Antal pass",
  unit: "pass",
};
const OPTIONS: ReadonlyArray<OptionType> = [
  DEFAULT_OPTION,
  { value: "hours", label: "Antal timmar", unit: "timmar" },
  { value: "percent", label: "Procent pass", unit: "procent" },
  { value: "percentHours", label: "Procent timmar", unit: "procent" },
];

const LEGEND_DATA = [
  {
    id: "shift",
    label: "Pass",
    color: chartColor.SHIFT,
  },
  {
    id: "resource",
    label: "Resurspass",
    color: chartColor.RESOURCE,
  },
  {
    id: "shiftAdmin",
    label: "Pass (med ansvarstid)",
    color: chartColor.SHIFTADMIN,
  },
  {
    id: "resourceAdmin",
    label: "Resurspass (med ansvarstid)",
    color: chartColor.RESOURCEADMIN,
  },
];

export function ShiftVsResourceChart({
  fragmentRef,
  teamId,
  competence,
  filterCompetence,
}: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);

  const teamFilter = useCallback(
    (row: RowType) => !teamId || row.teams.some((t) => t.id === teamId),
    [teamId],
  );
  const competenceFilter = useCallback(
    (row: RowType) =>
      !filterCompetence ||
      row.competences.some((edge) => edge.id === competence),
    [competence, filterCompetence],
  );

  const dataConverted: Record<
    XAxisValue,
    ReadonlyArray<ChartData>
  > = useMemo(() => {
    const shifts: ChartData[] = [];
    const hours: ChartData[] = [];
    const percent: ChartData[] = [];
    const percentHours: ChartData[] = [];

    (data.userStats ?? EMPTY_ARRAY)
      .map(({ competences, teams, ...u }) => ({
        ...u,
        teams: connectionToArray(teams),
        competences: connectionToArray(competences),
      }))
      .filter((u) => teamFilter(u) && competenceFilter(u))
      .forEach((row) => {
        const common = {
          userKey: `${row.id} ${row.name}`,
          userName: row.name,
          userId: row.id,
        };
        shifts.push({
          ...common,
          shift: row.nrShifts,
          resource: row.nrResources,
          shiftAdmin: row.nrShiftAdmin,
          resourceAdmin: row.nrResourceAdmin,
        });
        hours.push({
          ...common,
          shift: +row.hourShifts.toFixed(2),
          resource: +row.hourResources.toFixed(2),
          shiftAdmin: +row.hourShiftAdmin.toFixed(2),
          resourceAdmin: +row.hourResourceAdmin.toFixed(2),
        });
        const totalShifts =
          row.nrShifts +
          row.nrResources +
          row.nrShiftAdmin +
          row.nrResourceAdmin;
        percent.push({
          ...common,
          shift: calcPercent(row.nrShifts, totalShifts),
          resource: calcPercent(row.nrResources, totalShifts),
          shiftAdmin: calcPercent(row.nrShiftAdmin, totalShifts),
          resourceAdmin: calcPercent(row.nrResourceAdmin, totalShifts),
        });
        const totalHours =
          row.hourShifts +
          row.hourResources +
          row.hourShiftAdmin +
          row.hourResourceAdmin;
        percentHours.push({
          ...common,
          shift: calcPercent(row.hourShifts, totalHours),
          resource: calcPercent(row.hourResources, totalHours),
          shiftAdmin: calcPercent(row.hourShiftAdmin, totalHours),
          resourceAdmin: calcPercent(row.hourResourceAdmin, totalHours),
        });
      });
    return { shifts, hours, percent, percentHours };
  }, [data, teamFilter, competenceFilter]);

  // Axis switch options
  const [xAxis, setXAxis] = useState(DEFAULT_OPTION);

  const usedChartData = useMemo(
    () => dataConverted[xAxis.value],
    [dataConverted, xAxis.value],
  );

  const totalValues = useMemo<TotalValuesType>(
    () =>
      usedChartData.reduce((acc, row) => {
        const { userId: id, shift, resource, shiftAdmin, resourceAdmin } = row;
        if (id) {
          acc[id] = shift + resource + shiftAdmin + resourceAdmin;
        }

        return acc;
      }, {} as TotalValuesType),
    [usedChartData],
  );

  // Calculate minimal chart height and margins
  const height = 75 + usedChartData.length * 21;
  const leftMargin =
    13 + Math.max(0, ...usedChartData.map((u) => u.userName.length)) * 5.6;

  const legendWidth = 83;

  return (
    <Stack px={1} alignItems="center">
      <Box sx={{ height, width: "100%" }}>
        {usedChartData.length > 0 && (
          <ResponsiveBar
            data={usedChartData}
            keys={["shift", "shiftAdmin", "resource", "resourceAdmin"]}
            layout="horizontal"
            indexBy="userName"
            colors={[
              chartColor.SHIFT,
              chartColor.SHIFT,
              chartColor.RESOURCE,
              chartColor.RESOURCE,
            ]}
            defs={[
              {
                id: "shift_lines",
                type: "patternLines",
                background: "inherit",
                color: chartColor.SHIFTADMIN,
                rotation: -45,
                lineWidth: 5,
                spacing: 10,
              },
              {
                id: "resource_lines",
                type: "patternLines",
                background: "inherit",
                color: chartColor.RESOURCEADMIN,
                rotation: -45,
                lineWidth: 5,
                spacing: 10,
              },
            ]}
            fill={[
              {
                match: {
                  id: "shiftAdmin",
                },
                id: "shift_lines",
              },
              {
                match: {
                  id: "resourceAdmin",
                },
                id: "resource_lines",
              },
            ]}
            margin={{
              top: 25,
              right: legendWidth + 10,
              bottom: 50,
              left: leftMargin,
            }}
            labelSkipWidth={12}
            labelTextColor="white"
            theme={{ text: { fontFamily: "Nunito" } }}
            tooltip={({ id, value, data }) => (
              <GraphTooltip
                keyId={id}
                translateKey={(k) =>
                  LEGEND_DATA.find((d) => d.id === k)?.label || k
                }
                value={value}
                totalValue={totalValues[data.userId] ?? 1}
                user={data.userName}
                unit={xAxis.unit}
              />
            )}
            borderRadius={3}
            innerPadding={1}
            legends={[
              {
                data: LEGEND_DATA.slice(0, 2),
                dataFrom: "keys",
                anchor: "right",
                direction: "column",
                translateX: legendWidth + 10,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: legendWidth,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        )}
      </Box>
      <Box>
        <XSelect
          value={xAxis}
          onChange={setXAxis}
          options={OPTIONS}
          defaultValue={DEFAULT_OPTION}
        />
      </Box>
    </Stack>
  );
}
