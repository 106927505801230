import { Suspense, useCallback, useEffect, useMemo } from "react";
import Timeline, { DateHeader, TimelineHeaders } from "react-calendar-timeline";
import type {
  PreloadedQuery,
  UseQueryLoaderLoadQueryOptions,
  VariablesOf,
} from "react-relay";
import {
  fetchQuery,
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from "react-relay/hooks";
import { Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { INIT_ACTIVITIES } from "hooks";
import resizeDetector from "libs/react-calendar-timeline/container-resize-detector";
import type { ExtractNode } from "relay-help/arrays";
import { useConnectionToArray } from "relay-help/arrays";
import { EMPTY_ARRAY } from "utils/constants";

import { activityList as ALL_ACTIVITIES } from "components/schedule/types";

import type { ColorFocusType, NullableString } from "../../types";

import {
  useGroups,
  useItemsToTimelineItems,
  useRemapReferences,
} from "./hooks";
import { TimelineItemRenderer } from "./TimelineItemRenderer";
import type {
  ActivityGanttTimelineQuery as Query,
  ActivityGanttTimelineQuery$data as Data,
  ApiActivityActivityTypeChoices as ActivityType,
  PrevTimelineItem,
  TimelineItem,
  UserItem,
} from "./types";
import { activityTypes } from "./types";
import { useUserBreaksToTimelineItem } from "./useUserBreaksToTimelineItem";

const HOUR = 60 * 60 * 1000;

export const query = graphql`
  query ActivityGanttTimelineQuery(
    $id: ID!
    $selectedActivities: [ApiActivityActivityTypeChoices!]
  ) {
    schedule(id: $id) {
      activities(activityType_In: $selectedActivities) {
        edges {
          node {
            id
            shiftDayType
            activityType
            user {
              id
              name
            }
            shift {
              id
              name
            }
            team {
              id
              name
              color
            }
            ganttItems {
              id
              title
              titleCode
              endTime
              startTime
              breakTime
              shiftIsAltered
              alterationLength
              isPart
              duration
              worktime
            }
          }
        }
      }
      users {
        edges {
          node {
            id
            referenceId
            name
            dayBreak
            weekBreak
            weekBreakDay
            competences {
              edges {
                node {
                  id
                  color
                }
              }
            }
            teams {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
      previousWeekActivities {
        id
        shiftDayType
        activityType
        user {
          id
          referenceId
          name
        }
        shift {
          id
          name
        }
        team {
          id
          name
          color
        }
        ganttItems {
          id
          title
          titleCode
          endTime
          startTime
          breakTime
          shiftIsAltered
          alterationLength
          isPart
          duration
          worktime
        }
      }
    }
  }
`;

type Activity = ExtractNode<NonNullable<Data["schedule"]>["activities"]>;

type CommonProps = {
  scheduleId: string;
  onItemSelect: (activityId: string) => void;
  minDate: string;
  maxDate: string;
  selectedTeam: NullableString;
  selectedActivities: string[];
  selectedCompetence: NullableString;
  colorFocus: ColorFocusType;
};

type ContentProps = CommonProps & {
  queryRef: PreloadedQuery<Query>;
  loadQuery: (
    variables: VariablesOf<Query>,
    options?: UseQueryLoaderLoadQueryOptions,
  ) => void;
};

type Props = CommonProps & {};

function ActivityGanttTimelineContent({
  queryRef,
  loadQuery,
  scheduleId: id,
  onItemSelect: onActivitySelect,
  minDate,
  maxDate,
  selectedTeam,
  selectedActivities,
  selectedCompetence,
  colorFocus,
}: ContentProps) {
  const env = useRelayEnvironment();
  const data = usePreloadedQuery<Query>(query, queryRef);

  useEffect(() => {
    // Fetch all activities after init prefetch
    fetchQuery(env, query, {
      id,
      selectedActivities: ALL_ACTIVITIES,
    }).subscribe({
      complete: () => {
        loadQuery(
          { id, selectedActivities: ALL_ACTIVITIES },
          { fetchPolicy: "store-only" },
        );
      },
    });
  }, [env, id, loadQuery]);

  const groups = useGroups(data, { selectedTeam, selectedCompetence });
  const schedule = useMemo(() => data?.schedule, [data]);
  const activityArray = useConnectionToArray<Activity>(schedule?.activities);
  const items: TimelineItem[] = useItemsToTimelineItems(activityArray, {
    groups,
    colorFocus,
    selectedActivities,
    selectedTeam,
  });
  const prevPeriodItems = useRemapReferences(
    useItemsToTimelineItems(schedule?.previousWeekActivities ?? EMPTY_ARRAY, {
      groups,
      colorFocus,
      selectedActivities,
      selectedTeam,
      itemProps: { style: { opacity: 0.75 } },
    }) as PrevTimelineItem[],
    groups,
  );

  const start = useMemo(() => Date.parse(minDate || ""), [minDate]);
  const end = useMemo(() => Date.parse(maxDate || ""), [maxDate]);
  const periodStart = useMemo(() => new Date(start), [start]);
  const periodEnd = useMemo(() => new Date(end), [end]);

  const showDayBreak = useMemo(
    () => selectedActivities.includes(activityTypes.DAY_BREAK as string),
    [selectedActivities],
  );
  const showWeekBreak = useMemo(
    () => selectedActivities.includes(activityTypes.WEEK_BREAK as string),
    [selectedActivities],
  );

  const { dayBreakItems, weekBreakItems } = useUserBreaksToTimelineItem(
    groups,
    {
      periodStart,
      periodEnd,
    },
  );

  const renderSidebar = useCallback(
    ({ group: { title } }: { group: UserItem }) => {
      return <Typography variant="caption">{title}</Typography>;
    },
    [],
  );

  const onItemSelect = useCallback(
    (itemId: string) => {
      const item = items.find((i) => i.id === itemId);
      if (item) {
        onActivitySelect(item.activityId);
      }
    },
    [items, onActivitySelect],
  );

  const timelineItems = useMemo(
    () => [
      ...prevPeriodItems,
      ...items,
      ...(showDayBreak ? dayBreakItems : EMPTY_ARRAY),
      ...(showWeekBreak ? weekBreakItems : EMPTY_ARRAY),
    ],
    [
      prevPeriodItems,
      items,
      showDayBreak,
      dayBreakItems,
      showWeekBreak,
      weekBreakItems,
    ],
  );

  return (
    <Timeline
      groups={groups}
      items={timelineItems}
      visibleTimeStart={start}
      visibleTimeEnd={end}
      sidebarWidth={170}
      groupRenderer={renderSidebar}
      canChangeGroup={false}
      canMove={false}
      canResize={false}
      itemHeightRatio={0.6}
      minZoom={24 * HOUR}
      maxZoom={end - start}
      onItemSelect={onItemSelect}
      onItemClick={onItemSelect}
      itemRenderer={TimelineItemRenderer}
      resizeDetector={resizeDetector as any}
    >
      <TimelineHeaders>
        <DateHeader unit="year" height={0} />
      </TimelineHeaders>
    </Timeline>
  );
}

export function ActivityGanttTimeline(props: Props) {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);

  useEffect(() => {
    // init prefetch
    loadQuery({
      id: props.scheduleId,
      selectedActivities: INIT_ACTIVITIES as ActivityType[],
    });
  }, [loadQuery, props.scheduleId]);

  return (
    <Suspense fallback={null}>
      {!!queryRef && (
        <ActivityGanttTimelineContent
          {...props}
          queryRef={queryRef}
          loadQuery={loadQuery}
        />
      )}
    </Suspense>
  );
}
