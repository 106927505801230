import type { GroupSettingCardProps } from "./GroupSettingCard";
import { GroupSettingCard } from "./GroupSettingCard";

type Props = Omit<GroupSettingCardProps, "title" | "action">;

const TITLE = "Kompetenser";

export function CompetencesCard(props: Props) {
  return <GroupSettingCard title={TITLE} {...props} />;
}

CompetencesCard.id = "competences" as const;
CompetencesCard.title = TITLE;
