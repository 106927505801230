import type { Dispatch } from "react";
import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import { Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useResultsParams } from "hooks";
import { DateTime } from "luxon";

import type { Option as CompetenceOption } from "components/common/selectors/CompetenceSelect";
import { BaseCompetenceSelect as CompetenceSelect } from "components/common/selectors/CompetenceSelect";
import type { Option as TeamOption } from "components/common/selectors/TeamSelect";
import { BaseTeamSelect as TeamSelect } from "components/common/selectors/TeamSelect";
import { useScheduleContext } from "components/schedule";

import SelectDurationSpan from "../../SelectDurationSpan";

import { ActivityListSelect } from "./ActivityListSelect";
import { ColorFocus } from "./ColorFocus";
import { NewActivityButton } from "./NewActivityButton";
import type {
  ColorFocusType,
  Header_fragment$key as Key,
  NullableString,
} from "./types";
import { WarningsDrawerButton } from "./WarningsDrawerButton";

const fragment = graphql`
  fragment Header_fragment on ScheduleNode {
    period {
      startDate
      endDate
    }
  }
`;

type Props = {
  fragmentRef: Key;
  team: string;
  setTeam: (team: NullableString) => void;
  competence: string;
  setCompetence: (competence: NullableString) => void;
  activities: string[];
  setActivities: (activities: string[]) => void;
  colorFocus: ColorFocusType;
  setColorFocus: Dispatch<ColorFocusType>;
  setShowCreate: (show: boolean) => void;
  toggleDrawer: () => void;

  allTeams: TeamOption;
  allCompetences: CompetenceOption;
};

export function Header({
  fragmentRef,
  team,
  setTeam,
  competence,
  setCompetence,
  colorFocus,
  setColorFocus,
  activities,
  setActivities,
  setShowCreate,
  toggleDrawer,

  allTeams,
  allCompetences,
}: Props) {
  const schedule = useFragment<Key>(fragment, fragmentRef);
  const periodStart = useMemo(
    () => DateTime.fromISO(schedule.period.startDate).toJSDate(),
    [schedule.period.startDate],
  );
  const periodEnd = useMemo(
    () => DateTime.fromISO(schedule.period.endDate).toJSDate(),
    [schedule.period.endDate],
  );
  const { competences, teams: teamsProp } = useScheduleContext();
  const { from, to, setFrom, setTo } = useResultsParams({
    periodStart,
    periodEnd,
  });
  const teams = useMemo(
    () =>
      teamsProp.map((x) => ({
        ...x,
        group: { id: "", name: "" },
      })),
    [teamsProp],
  );

  function changeSpan(newFrom: Date, newTo: Date) {
    setFrom(newFrom);
    setTo(newTo);
  }

  return (
    <Stack gap={2}>
      <Stack gap={1} direction="row" justifyContent="space-between">
        <Stack direction="row" flexWrap="wrap">
          <SelectDurationSpan
            minDate={DateTime.fromJSDate(periodStart)
              .minus({ weeks: 1 })
              .toJSDate()}
            maxDate={periodEnd}
            periodStart={periodStart}
            periodEnd={periodEnd}
            from={from}
            to={to}
            onChange={changeSpan}
          />
        </Stack>

        <Stack direction="row" rowGap={0} flexWrap="wrap" columnGap={1}>
          <NewActivityButton onClick={() => setShowCreate(true)} />
          <WarningsDrawerButton onClick={toggleDrawer} />
        </Stack>
      </Stack>

      <Stack gap={2}>
        <Stack gap={1} direction="row" flexWrap="wrap" rowGap={2}>
          <TeamSelect
            value={team}
            onChange={setTeam}
            insert={{ before: [allTeams] }}
            options={teams}
            sx={{ width: 210 }}
          />
          <CompetenceSelect
            value={competence}
            onChange={setCompetence}
            insert={{ before: [allCompetences] }}
            options={competences}
            sx={{ width: 210 }}
          />
          <ColorFocus
            value={colorFocus}
            onChange={(cf: ColorFocusType) => setColorFocus(cf)}
            sx={{ minWidth: 210 }}
          />
        </Stack>
        <ActivityListSelect
          value={activities}
          onChange={setActivities}
          sx={{ flexGrow: 2 }}
          disabled={colorFocus !== "activity_types"}
        />
      </Stack>
    </Stack>
  );
}
