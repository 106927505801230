import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import type {
  ScheduleWarningsContextQuery as Query,
  ScheduleWarningsContextQuery$data as Data,
} from "./types";

const query = graphql`
  query ScheduleWarningsContextQuery($scheduleId: ID!) {
    scheduleValidationWarnings(scheduleId: $scheduleId) {
      ...ScheduleWarningsDrawer_fragment
      ...WarningsDrawerButton_fragment
    }
  }
`;

type Key = Data["scheduleValidationWarnings"];
type ScheduleWarningsContextType = Key | undefined;
type Props = { scheduleId: string };

const Context = createContext<ScheduleWarningsContextType>(undefined);
Context.displayName = "ScheduleWarningsContext";

export const useScheduleWarningsContext = () => {
  const fragmentRef = useContext(Context);
  if (!fragmentRef) {
    throw new Error(
      "useScheduleWarnings must be used within ScheduleWarningsProvider",
    );
  }
  return fragmentRef;
};

export const ScheduleWarningsProvider = ({
  scheduleId,
  children,
}: PropsWithChildren<Props>) => {
  const data = useLazyLoadQuery<Query>(query, { scheduleId });
  return (
    <Context.Provider
      value={data.scheduleValidationWarnings}
      children={children}
    />
  );
};
