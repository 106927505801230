import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";

import { Loading } from "components/loading";
import { AutoSubmit } from "components/setting/common";

import { AB1Form } from "./forms";
import { UpdateUserSettingBoxAB1 } from "./mutations";
import type {
  UpdateUserSettingInput as FormValues,
  UserSettingBoxAB1_fragment$key as Key,
} from "./types";

const fragment = graphql`
  fragment UserSettingBoxAB1_fragment on UserSettingNode {
    id
    dayBreakAlternatives
    settingModules
    user {
      fullName
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  dayBreakAlternatives: yup.array().of(yup.number().min(0)).required(),
  constraintModules: yup.array().of(yup.string()).required(),
});

type Props = {
  fragmentRef: Key;
};

function Content({ fragmentRef }: Props) {
  const environment = useRelayEnvironment();
  const data = useFragment<Key>(fragment, fragmentRef);
  const { fullName: name = "" } = data?.user || {};
  const initialValues: FormValues = {
    id: data?.id || "",
    dayBreakAlternatives: data?.dayBreakAlternatives.slice() || [30],
    constraintModules: data?.settingModules.slice() || [],
  };

  async function onSubmit(input: FormValues) {
    await UpdateUserSettingBoxAB1(environment, { input });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <AB1Form name={name} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}

export function UserSettingBoxAB1({ fragmentRef }: Props) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && <Content fragmentRef={fragmentRef} />}
    </Suspense>
  );
}
