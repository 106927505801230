/**
 * @generated SignedSource<<dc93cbaed27be655250b5f9f15f5f5b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RuleGroupSettingBoxFree1_fragment$data = {
  readonly freeEveryXOfYWeekends: number;
  readonly freeWeekendConstraintTypeEveryX: boolean;
  readonly freeWeekendConstraintTypeXOfY: boolean;
  readonly freeWeekendSoft: boolean;
  readonly id: string;
  readonly ruleGroup: {
    readonly name: string;
  };
  readonly settingModules: ReadonlyArray<string>;
  readonly workEveryUnfreeWeekend: boolean;
  readonly workEveryUnfreeWeekendSoft: boolean;
  readonly workEveryXWeekend: number;
  readonly " $fragmentType": "RuleGroupSettingBoxFree1_fragment";
};
export type RuleGroupSettingBoxFree1_fragment$key = {
  readonly " $data"?: RuleGroupSettingBoxFree1_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RuleGroupSettingBoxFree1_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RuleGroupSettingBoxFree1_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workEveryXWeekend",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeEveryXOfYWeekends",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendConstraintTypeEveryX",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendConstraintTypeXOfY",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workEveryUnfreeWeekend",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workEveryUnfreeWeekendSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RuleGroupNode",
      "kind": "LinkedField",
      "name": "ruleGroup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RuleGroupSettingNode",
  "abstractKey": null
};

(node as any).hash = "a1004d5553cb6c58762156f5b77c188d";

export default node;
