/**
 * @generated SignedSource<<00979d68dbd59a4eb5362c82329751ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingConsecutiveWorkDaysWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SettingBoxRhythm3_fragment$data = {
  readonly consecutiveWorkDaysWeight: ApiSettingConsecutiveWorkDaysWeightChoices;
  readonly constraintModules: ReadonlyArray<string>;
  readonly id: string;
  readonly maxConsecutiveWorkDays: number;
  readonly maxConsecutiveWorkDaysSoft: boolean;
  readonly minConsecutiveWorkDays: number;
  readonly minConsecutiveWorkDaysSoft: boolean;
  readonly workDayCadenceRestrictions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly calendarDays: number;
        readonly id: string;
        readonly isSoft: boolean;
        readonly maxCadence: number;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "SettingBoxRhythm3_fragment";
};
export type SettingBoxRhythm3_fragment$key = {
  readonly " $data"?: SettingBoxRhythm3_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingBoxRhythm3_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingBoxRhythm3_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveWorkDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveWorkDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveWorkDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveWorkDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "consecutiveWorkDaysWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkDayCadenceRestrictionNodeConnection",
      "kind": "LinkedField",
      "name": "workDayCadenceRestrictions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkDayCadenceRestrictionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorkDayCadenceRestrictionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isSoft",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maxCadence",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "calendarDays",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};
})();

(node as any).hash = "e0b14e2e56681d376e575482f1f3dc34";

export default node;
