import React, { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import { Add, GroupsOutlined, PersonOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { EMPTY_ARRAY } from "utils/constants";
import { useBooleanState } from "utils/useBooleanState";

import { SettingExceptionsRuleGroup_fragment$key as RuleGroupKey } from "./__generated__/SettingExceptionsRuleGroup_fragment.graphql";
import { SettingExceptionsUser_fragment$key as UserKey } from "./__generated__/SettingExceptionsUser_fragment.graphql";
import { useModifyRuleGroupSettingModulesMutation } from "./mutations/modifyRuleGroupSettingModules";
import { useModifyUserSettingModulesMutation } from "./mutations/modifyUserSettingModules";
import {
  ruleGroupExceptionsFragment,
  userExceptionsFragment,
} from "./SettingExceptions";

type ExceptionOption = {
  id: string;
  name: string;
  settingModules: ReadonlyArray<string>;
};

type Props = {
  exceptionTypeName: string;
  options: ReadonlyArray<ExceptionOption>;
  onCreateException: (option: ExceptionOption) => void;
  Icon: React.ElementType;
};

const BaseSettingExceptionsHeader = React.memo(
  function BaseSettingExceptionsHeaderFn({
    exceptionTypeName,
    options,
    onCreateException,
    Icon,
  }: Props) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>();

    const {
      value: addExceptionOpen,
      setTrue: openExceptionMenu,
      setFalse: closeExceptionMenu,
    } = useBooleanState();

    const openExceptionList = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        openExceptionMenu();
      },
      [openExceptionMenu],
    );

    const onExceptionClick = useCallback(
      (option: ExceptionOption) => {
        onCreateException(option);
        closeExceptionMenu();
      },
      [onCreateException, closeExceptionMenu],
    );

    const menuItems = useMemo(() => {
      return options.map((option) => {
        return (
          <MenuItem key={option.id} onClick={() => onExceptionClick(option)}>
            {option.name}
          </MenuItem>
        );
      });
    }, [options, onExceptionClick]);

    return (
      <Box
        display="flex"
        paddingBottom={1}
        sx={{ "&:only-child": { pb: 0 }, pb: 1, alignItems: "center" }}
      >
        <Icon />
        <Typography variant="h3" sx={{ ml: 2, flexGrow: 1 }}>
          {exceptionTypeName}
        </Typography>
        <Button
          variant="outlined"
          onClick={openExceptionList}
          startIcon={<Add />}
        >
          Lägg till undantag
        </Button>
        <Menu
          open={addExceptionOpen}
          onClose={closeExceptionMenu}
          anchorEl={anchorEl}
          slotProps={{
            paper: { sx: { minWidth: "100px", maxHeight: "500px" } },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem disabled>
            {menuItems.length
              ? "Lägg till undantag för..."
              : `Inga ${exceptionTypeName === "Regelgrupper" ? "regelgrupper" : "medarbetare"} har skapats`}
          </MenuItem>
          <Divider />
          {menuItems}
        </Menu>
      </Box>
    );
  },
);

type UserSettingExceptionsHeaderProps = {
  settingModule: string;
  fragmentRef: UserKey;
};

export const UserSettingExceptionsHeader = React.memo(
  function UserSettingExceptionsHeaderFn({
    settingModule,
    fragmentRef,
  }: UserSettingExceptionsHeaderProps) {
    const data = useFragment(userExceptionsFragment, fragmentRef);
    const [modifyUserSettingModules] = useModifyUserSettingModulesMutation();

    const usersWithoutSettingException: ReadonlyArray<ExceptionOption> =
      useMemo(() => {
        return data.reduce((acc, user) => {
          if (
            user.userSetting != null &&
            !user.userSetting.settingModules.includes(settingModule)
          ) {
            acc.push({
              id: user.userSetting?.id,
              name: user.fullName,
              settingModules: user.userSetting?.settingModules ?? EMPTY_ARRAY,
            });
          }
          return acc;
        }, [] as ExceptionOption[]);
      }, [data, settingModule]);

    const onCreateUserException = useCallback(
      (option: ExceptionOption) => {
        const newConstraintModules = [...option.settingModules, settingModule];
        console.log(
          `Updating modules for ${option.name} (${option.id}) to ${newConstraintModules}`,
        );
        modifyUserSettingModules({
          variables: {
            input: { id: option.id, constraintModules: newConstraintModules },
          },
        });
      },
      [modifyUserSettingModules, settingModule],
    );

    return (
      <BaseSettingExceptionsHeader
        exceptionTypeName="Medarbetare"
        options={usersWithoutSettingException}
        onCreateException={onCreateUserException}
        Icon={PersonOutline}
      />
    );
  },
);

type RuleGroupSettingExceptionsHeaderProps = {
  settingModule: string;
  fragmentRef: RuleGroupKey;
};

export const RuleGroupSettingExceptionsHeader = React.memo(
  function RuleGroupSettingExceptionsHeaderFn({
    settingModule,
    fragmentRef,
  }: RuleGroupSettingExceptionsHeaderProps) {
    const data = useFragment(ruleGroupExceptionsFragment, fragmentRef);
    const [modifyRuleGroupSettingModules] =
      useModifyRuleGroupSettingModulesMutation();

    const ruleGroupsWithoutSettingException: ReadonlyArray<ExceptionOption> =
      useMemo(() => {
        return data.reduce((acc, ruleGroup) => {
          if (
            ruleGroup.ruleGroupSetting != null &&
            !ruleGroup.ruleGroupSetting.settingModules.includes(settingModule)
          ) {
            acc.push({
              id: ruleGroup.ruleGroupSetting.id,
              name: ruleGroup.name,
              settingModules:
                ruleGroup.ruleGroupSetting?.settingModules ?? EMPTY_ARRAY,
            });
          }
          return acc;
        }, [] as ExceptionOption[]);
      }, [data, settingModule]);

    const onCreateRuleGroupException = useCallback(
      (option: ExceptionOption) => {
        const newConstraintModules = [...option.settingModules, settingModule];
        modifyRuleGroupSettingModules({
          variables: {
            input: { id: option.id, constraintModules: newConstraintModules },
          },
        });
      },
      [modifyRuleGroupSettingModules, settingModule],
    );

    return (
      <BaseSettingExceptionsHeader
        exceptionTypeName="Regelgrupper"
        options={ruleGroupsWithoutSettingException}
        onCreateException={onCreateRuleGroupException}
        Icon={GroupsOutlined}
      />
    );
  },
);
